import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import FeedbackExitRoom from '../../controllers/FeedbackExitRoom';
import FeedbackPage from '../../controllers/feedbackPage';
import FeedbackRoom from '../../controllers/feedbackRoom';
import FeedbackUpdateFeed from '../../controllers/FeedbackUpdateFeed/FeedbackUpdateFeed';
import MeetLeave from '../../controllers/Meet/MeetLeave';
import MeetRoom from '../../controllers/Meet/MeetRoom';
import RecordingExitRoom from '../../controllers/recordingExitRoom';
import RecordingPage from '../../controllers/recordingPage';
import RecordingRoom from '../../controllers/recordingRoom';
import UpdateFeedbackExitRoom from '../../controllers/UpdateFeedbackExitRoom';
import UpdateFeedbackRoom from '../../controllers/UpdateFeedbackRoom/UpdateFeedbackRoom';
import ErrorPage from '../ErrorPage';
import FullPageProgress from '../FullPageProgress';
import UrlGeneratedId from '../Pages/UrlGeneratedId';
import PostLeave from '../PostLeave';
import RedirectToPreview from '../RedirectToPreview/RedirectToPreview';
import JoiningScreen from '../zoomSdk/JoiningScreen';
const PreviewScreen = React.lazy(() => import('../PreviewScreen'));
const Conference = React.lazy(() => import('../conference'));

const RouteList = ({ getDetails, authTokenByRoomCodeEndpoint }) => {
    return (
        <Routes>
            <Route path="meet">
                <Route
                    path="preview/:role/:roomId"
                    element={<MeetRoom endPoint={authTokenByRoomCodeEndpoint} roomType="preview" />}
                />
                <Route path="meeting/:role/:roomId" element={<MeetRoom roomType="meeting" />} />
                <Route path="recording/:roomId" element={<MeetRoom roomType="recording" />} />
                <Route path="transcript/:roomId" element={<MeetRoom roomType="transcript" />} />
                <Route path="leave/:role/:roomId" element={<MeetLeave />} />
            </Route>
            <Route path="meet/:interviewId/:urlGenerateId" element={<UrlGeneratedId />} />
            <Route path="interview">
                <Route path="room/:roomId" element={<JoiningScreen />} />
            </Route>
            <Route path="recording">
                <Route path="room/:interviewId/:roomId/:role" element={<RecordingRoom />} />
                <Route path="room/:interviewId/:roomId" element={<RecordingRoom />} />
                <Route path=":roomId" element={<RecordingPage />} />
            </Route>
            <Route path="feedback">
                <Route path="room/:interviewId/:roomId" element={<FeedbackRoom />} />
                <Route path=":roomId" element={<FeedbackPage />} />
            </Route>
            <Route path="feedback-update">
                <Route path="room/:interviewId/:interviewKey" element={<FeedbackUpdateFeed />} />
            </Route>
            <Route path="feedbackUpdate">
                <Route path="room/:interviewId/:roomId/:role" element={<UpdateFeedbackRoom />} />
                <Route path="room/:interviewId/:roomId" element={<UpdateFeedbackRoom />} />
            </Route>
            <Route path="incexit">
                <Route path="feedbackUpdate/room/:interviewId/:roomId/:role" element={<UpdateFeedbackExitRoom />} />
                <Route path="feedbackUpdate/room/:interviewId/:roomId" element={<UpdateFeedbackExitRoom />} />
                <Route path="recording/room/:interviewId/:roomId" element={<RecordingExitRoom />} />
                <Route path="feedback/room/:interviewId/:roomId" element={<FeedbackExitRoom />} />
                <Route
                    path="preview/:role/:roomId"
                    element={
                        <Suspense fallback={<FullPageProgress />}>
                            <PreviewScreen authTokenByRoomCodeEndpoint={authTokenByRoomCodeEndpoint} inCExit />
                        </Suspense>
                    }
                />
                <Route
                    path="meeting/:role/:roomId"
                    element={
                        <Suspense fallback={<FullPageProgress />}>
                            <Conference inCExit />
                        </Suspense>
                    }
                />
                <Route path="leave/:role/:roomId" element={<PostLeave inCExit />} />
            </Route>
            <Route path="preview">
                <Route
                    path=":role/:roomId"
                    element={
                        <Suspense fallback={<FullPageProgress />}>
                            <PreviewScreen authTokenByRoomCodeEndpoint={authTokenByRoomCodeEndpoint} />
                        </Suspense>
                    }
                />
            </Route>
            <Route path="meeting">
                <Route
                    path=":role/:roomId"
                    element={
                        <Suspense fallback={<FullPageProgress />}>
                            <Conference />
                        </Suspense>
                    }
                />
            </Route>
            <Route path="leave">
                <Route path=":role/:roomId" element={<PostLeave />} />
            </Route>
            <Route path="/:role/:roomId" element={<RedirectToPreview getDetails={getDetails} />} />
            <Route path="*" element={<ErrorPage error="Invalid URL!" />} />
        </Routes>
    );
};

export default RouteList;

import React, { Fragment, memo, useState } from 'react';
import { SettingsIcon } from '@100mslive/react-icons';
import IconButton from '../../../../../../IconButton';
import SettingsModal from '../../../../../Settings/SettingsModal';

const PreviewSettings = memo(() => {
    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            <IconButton
                data-testid="preview_setting_btn"
                onClick={() => setOpen((value) => !value)}
                className="preview-btn"
            >
                <SettingsIcon />
            </IconButton>
            {open && <SettingsModal open={open} onOpenChange={setOpen} />}
        </Fragment>
    );
});

export default PreviewSettings;

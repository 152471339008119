import React from 'react';
import { getSkill } from '../../components/OtherComponents/Helpers';
export const Ratings = ({ data }) => {
    return (
        <div className="ratings-div">
            <div className="skills-div">
                <span className="soft-skills-span">Ratings</span>
            </div>
            <div className="ratings-wrapper">
                {data &&
                    data.length > 0 &&
                    data.map((item, key) => (
                        <div className="ratings-graph-div" key={key}>
                            <img className="graph-img" src={getSkill(item.value)} alt="ratings" />
                            <p className="ratings-text" title={item.title}>
                                {item.title}
                            </p>
                        </div>
                    ))}
            </div>
        </div>
    );
};

import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Flex, styled } from '@100mslive/roomkit-react';
import { FeedbackContainerV2 } from '../../components/Feedback/FeedbackContainerV2';
import { FeedbackHeader } from '../../components/Feedback/FeedbackHeader';
import { ToastManager } from '../../components/Toast/ToastManager';
import logo from '../../images/incruiter-logo.svg';
import { getAllScreenshots, getFeedback, getInterviewToken, getSkills } from '../../redux/action';
import { CUSTOM, DEFAULT, FEEDBACK, ROLES } from '../../common/constants';

const LogoImg = styled('img', {
    maxHeight: '$17',
    p: '$2',
    w: 'auto',
    '@md': {
        maxHeight: '$15',
    },
});

const Loader = memo(() => {
    return (
        <div className="wrapper">
            <div className="loader" />
        </div>
    );
});

export default function UpdateFeedbackForMobileScreen() {
    const dispatch = useDispatch();
    const { interviewId, roomId, role } = useParams();
    const [question, setQuestion] = useState('');
    const rolePage = role ? role : 'interviewer';
    const [isUpdate, setIsUpdate] = useState(false);
    const loading = useSelector((state) => state.Interview.loading);
    const skills = useSelector((state) => state.Interview.skills);
    const feedbackDetails = useSelector((state) => state.Interview.feedbackDetails);
    const allScreenshots = useSelector((state) => state.Interview.allScreenshots);
    const [page, setPage] = useState(0);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const MasterSkills = useMemo(() => {
        const updatedMasterSkills = [];
        if (skills && skills.length > 0) {
            skills.forEach((itm) => {
                updatedMasterSkills.push({
                    value: itm.name,
                    label: itm.name,
                });
            });
        }
        return updatedMasterSkills;
    }, [skills]);
    const DEFAULT_FEEDBACK_FORM = {
        skills: [],
        screeningQuestions: [],
        finalRemark: null,
        finalRating: null,
        status: null,
        interviewId: null,
        codeImages: [],
        suspectedProxy: false,
        candidateDrop: false,
        key: '',
        activeTab: 1,
        ratingType: 1,
        code: [],
        codeEditor: null,
        roomId: roomId,
        transcriptStatus: false,
        transcript: [],
        interviewSummaryStatus: false,
        interviewSummary: [],
        codeUpdated: false,
    };
    const [feedbackForm, setFeedbackForm] = useState(DEFAULT_FEEDBACK_FORM);

    useEffect(() => {
        if (roomId && interviewId) {
            dispatch(getInterviewToken({ roomId, interviewId }));
            dispatch(getFeedback({ roomId, interviewId }));
            dispatch(getAllScreenshots({ roomId, interviewId }));
        }
    }, [dispatch, roomId, interviewId]);

    useEffect(() => {
        if (!skills) {
            dispatch(getSkills());
        }
    }, [dispatch, skills]);

    // updating screenWidth whenever window is resized
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        updateFeedbackForm();
    }, [feedbackDetails]);

    const updateFeedbackForm = () => {
        let tmpSkills = [],
            tmpScreeningQues = [];
        if (feedbackDetails) {
            if (feedbackDetails?.interviewId?.feedback) {
                let mandatorySkills = feedbackDetails.interviewId?.skills
                    ? feedbackDetails.interviewId?.skills.length > 0
                        ? feedbackDetails.interviewId?.skills.filter(
                              (nItem) => nItem?.skillType === FEEDBACK.SKILLS.TYPES.MANDATORY
                          )
                        : ''
                    : '';
                let optionalSkills = feedbackDetails.interviewId?.skills
                    ? feedbackDetails.interviewId?.skills.length > 0
                        ? feedbackDetails.interviewId?.skills.filter(
                              (nItem) => nItem?.skillType === FEEDBACK.SKILLS.TYPES.OPTIONAL
                          )
                        : ''
                    : '';
                let screeningQuestionSkills = feedbackDetails.interviewId?.screeningQuestions
                    ? feedbackDetails.interviewId?.screeningQuestions.length > 0
                        ? feedbackDetails.interviewId?.screeningQuestions
                        : ''
                    : '';
                feedbackDetails?.interviewId?.feedback?.skills &&
                    feedbackDetails.interviewId?.feedback?.skills.length > 0 &&
                    feedbackDetails.interviewId?.feedback?.skills.map((skillList, index) => {
                        let object = {
                            skill: skillList.skill,
                            rating: skillList.rating ? skillList.rating : '',
                            comment: skillList.comment ? skillList.comment : '',
                            commentRequired:
                                skillList.skillType === FEEDBACK.SKILLS.TYPES.MANDATORY
                                    ? mandatorySkills
                                        ? mandatorySkills.length > 0
                                            ? mandatorySkills[0].commentRequired
                                            : false
                                        : false
                                    : skillList.skillType === FEEDBACK.SKILLS.TYPES.OPTIONAL
                                      ? optionalSkills
                                          ? optionalSkills.length > 0
                                              ? optionalSkills[0].commentRequired
                                              : false
                                          : false
                                      : false,
                            commentLength:
                                skillList.skillType === FEEDBACK.SKILLS.TYPES.MANDATORY
                                    ? mandatorySkills
                                        ? mandatorySkills.length > 0
                                            ? mandatorySkills[0].commentLength
                                            : { min: 75, max: 1000 }
                                        : { min: 75, max: 1000 }
                                    : skillList.skillType === FEEDBACK.SKILLS.TYPES.OPTIONAL
                                      ? optionalSkills
                                          ? optionalSkills.length > 0
                                              ? optionalSkills[0].commentLength
                                              : { min: 0, max: 1000 }
                                          : { min: 0, max: 1000 }
                                      : { min: 0, max: 1000 },
                            position: index,
                            skillType: skillList.skillType,
                            delete: rolePage === ROLES.ADMIN ? true : false,
                            type: DEFAULT,
                            commentBoxVisible:
                                skillList.skillType === FEEDBACK.SKILLS.TYPES.MANDATORY
                                    ? mandatorySkills
                                        ? mandatorySkills.length > 0
                                            ? mandatorySkills[0].commentRequired
                                            : false
                                        : false
                                    : skillList.skillType === FEEDBACK.SKILLS.TYPES.OPTIONAL
                                      ? optionalSkills
                                          ? optionalSkills.length > 0
                                              ? optionalSkills[0].commentRequired
                                              : false
                                          : false
                                      : false,
                        };
                        tmpSkills.push(object);
                    });
                feedbackDetails?.interviewId?.feedback?.screeningQuestions &&
                    feedbackDetails?.interviewId?.feedback?.screeningQuestions.length > 0 &&
                    feedbackDetails?.interviewId?.feedback?.screeningQuestions.map((screeningQuestionsList, index) => {
                        let object = {
                            question: screeningQuestionsList.question,
                            comment: screeningQuestionsList?.comment,
                            commentRequired: screeningQuestionSkills
                                ? screeningQuestionSkills.length > 0
                                    ? screeningQuestionSkills[0].commentRequired
                                    : false
                                : false,
                            commentLength: screeningQuestionSkills
                                ? screeningQuestionSkills.length > 0
                                    ? screeningQuestionSkills[0].commentLength
                                    : { min: 50, max: 500 }
                                : { min: 50, max: 500 },
                            position: index,
                            delete: rolePage === ROLES.ADMIN ? true : false,
                            type: DEFAULT,
                            commentBoxVisible: screeningQuestionSkills
                                ? screeningQuestionSkills.length > 0
                                    ? screeningQuestionSkills[0].commentRequired
                                    : false
                                : false,
                        };
                        tmpScreeningQues.push(object);
                    });
            } else {
                let newLists = [];
                if (
                    (feedbackDetails?.interviewId?.ratingType === 2 &&
                        feedbackDetails?.interviewId?.candidate?.experience > 9.9) ||
                    feedbackDetails?.interviewId?.ratingType === 1
                ) {
                    newLists = [
                        {
                            name: 'Confidence',
                            type: 'soft',
                        },
                        {
                            name: 'Communication',
                            type: 'soft',
                        },
                        {
                            name: 'Pressure Handling',
                            type: 'soft',
                        },
                        {
                            name: 'Approach & Attitude',
                            type: 'analytics',
                        },
                        {
                            name: 'Problem Solving',
                            type: 'analytics',
                        },
                        {
                            name: 'Result Oriented',
                            type: 'analytics',
                        },
                        {
                            name: 'Decision Making',
                            type: 'leadership',
                        },
                        {
                            name: 'Team Management',
                            type: 'leadership',
                        },
                        {
                            name: 'Accountability',
                            type: 'leadership',
                        },
                    ];
                } else {
                    newLists = [
                        {
                            name: 'Confidence',
                            type: 'soft',
                        },
                        {
                            name: 'Communication',
                            type: 'soft',
                        },
                        {
                            name: 'Pressure Handling',
                            type: 'soft',
                        },
                        {
                            name: 'Approach & Attitude',
                            type: 'analytics',
                        },
                        {
                            name: 'Problem Solving',
                            type: 'analytics',
                        },
                        {
                            name: 'Result Oriented',
                            type: 'analytics',
                        },
                    ];
                }
                feedbackDetails?.interviewId?.skills &&
                    feedbackDetails?.interviewId?.skills.length > 0 &&
                    feedbackDetails?.interviewId?.skills.map((skillList, index) => {
                        let object = {
                            skill: skillList.name,
                            rating: '',
                            comment: '',
                            commentRequired: skillList.commentRequired,
                            commentLength: skillList.commentLength,
                            position: index,
                            type: DEFAULT,
                            skillType: skillList.skillType,
                            delete: rolePage === ROLES.ADMIN ? true : false,
                            commentBoxVisible: skillList.commentRequired ? skillList.commentRequired : false,
                        };
                        tmpSkills.push(object);
                    });
                newLists.map((skillList) => {
                    let object = {
                        skill: skillList.name,
                        rating: '',
                        comment: '',
                        commentRequired: false,
                        commentLength: 0,
                        position: tmpSkills.length,
                        skillType: skillList.type,
                        delete: false,
                        type: DEFAULT,
                        commentBoxVisible: false,
                    };
                    tmpSkills.push(object);
                });
                feedbackDetails?.interviewId?.screeningQuestions &&
                    feedbackDetails?.interviewId?.screeningQuestions.length > 0 &&
                    feedbackDetails?.interviewId?.screeningQuestions.map((screeningQuestionsList, index) => {
                        let object = {
                            question: screeningQuestionsList.question,
                            comment: '',
                            commentRequired: screeningQuestionsList.commentRequired,
                            commentLength: screeningQuestionsList.commentLength,
                            position: index,
                            type: DEFAULT,
                            delete: rolePage === ROLES.ADMIN ? true : false,
                            commentBoxVisible: screeningQuestionsList.commentRequired
                                ? screeningQuestionsList.commentRequired
                                : false,
                        };
                        tmpScreeningQues.push(object);
                    });
            }
            const transcript =
                feedbackDetails?.interviewId?.transcript?.length > 0
                    ? feedbackDetails?.interviewId?.transcript
                    : feedbackDetails?.interviewId?.feedback?.transcript;
            const interviewSummary =
                feedbackDetails?.interviewId?.interviewSummary?.length > 0
                    ? feedbackDetails?.interviewId?.interviewSummary
                    : feedbackDetails?.interviewId?.feedback?.interviewSummary;
            setFeedbackForm((prevState) => ({
                ...prevState,
                skills: feedbackForm?.skills.length > 0 ? feedbackForm?.skills : tmpSkills,
                screeningQuestions:
                    feedbackForm?.screeningQuestions.length > 0 ? feedbackForm?.screeningQuestions : tmpScreeningQues,
                finalRemark: feedbackForm?.finalRemark
                    ? feedbackForm?.finalRemark
                    : feedbackDetails?.interviewId?.feedback
                      ? feedbackDetails?.interviewId?.feedback?.finalRemark === null
                          ? ''
                          : feedbackDetails?.interviewId?.feedback?.finalRemark
                      : '',
                finalRating: feedbackForm?.finalRating
                    ? feedbackForm?.finalRating
                    : feedbackDetails?.interviewId?.feedback
                      ? feedbackDetails?.interviewId?.feedback?.finalRating === null
                          ? ''
                          : feedbackDetails?.interviewId?.feedback?.finalRating
                      : '',
                status: feedbackForm?.status
                    ? feedbackForm?.status
                    : feedbackDetails?.interviewId?.feedback
                      ? feedbackDetails?.interviewId?.feedback?.status === null
                          ? ''
                          : feedbackDetails?.interviewId?.feedback?.status
                      : '',
                interviewId: feedbackForm?.interviewId ? feedbackForm?.interviewId : feedbackDetails?.interviewId?._id,
                suspectedProxy: feedbackForm?.suspectedProxy
                    ? feedbackForm?.suspectedProxy
                    : feedbackDetails?.interviewId?.feedback
                      ? feedbackDetails?.interviewId?.feedback?.suspectedProxy
                      : false,
                candidateDrop: feedbackForm?.candidateDrop
                    ? feedbackForm?.candidateDrop
                    : feedbackDetails?.interviewId?.feedback
                      ? feedbackDetails?.interviewId?.feedback?.candidateDrop
                      : false,
                ratingType: feedbackDetails?.interviewId?.ratingType,
                codeImages: feedbackDetails?.interviewId?.feedback?.codeImages
                    ? feedbackDetails?.interviewId?.feedback?.codeImages
                    : [],
                codeEditor: feedbackDetails?.interviewId?.feedback?.code
                    ? feedbackDetails?.interviewId?.feedback?.code[0]
                    : '',
                code: feedbackDetails?.interviewId?.feedback?.code ?? [],
                transcript: transcript || [],
                interviewSummary: interviewSummary || [],
            }));
        }
    };

    const handleRatingComment = (type, mode, value, index) => {
        if (mode === 'comment' && type !== FEEDBACK.SKILLS.TYPES.SCREENING_QUESTIONS && value.length > 1000) {
            ToastManager.addToast({
                title: "Comments can't be more than 1000 characters.",
            });
        } else {
            let tmpData = feedbackForm[type];
            tmpData[index][mode] = value;
            setFeedbackForm((prevState) => ({
                ...prevState,
                [type]: tmpData,
                codeUpdated: true,
            }));
        }
        setIsUpdate(!isUpdate);
    };

    const handleRemarks = (type, value) => {
        let status = feedbackForm?.status;
        if (feedbackForm?.ratingType === 2 && type === FEEDBACK.SKILLS.TYPES.FINAL_RATING.TEXT) {
            if (FEEDBACK.SKILLS.TYPES.FINAL_RATING.TEXT && value > -1 && value < 3) {
                status = FEEDBACK.SKILLS.TYPES.FINAL_RATING.VALUES.VERY_POOR;
            } else if (FEEDBACK.SKILLS.TYPES.FINAL_RATING.TEXT && value > 2 && value < 5) {
                status = FEEDBACK.SKILLS.TYPES.FINAL_RATING.VALUES.POOR;
            } else if (FEEDBACK.SKILLS.TYPES.FINAL_RATING.TEXT && value === 5) {
                status = FEEDBACK.SKILLS.TYPES.FINAL_RATING.VALUES.AVERAGE;
            } else if (FEEDBACK.SKILLS.TYPES.FINAL_RATING.TEXT && value === 6) {
                status = FEEDBACK.SKILLS.TYPES.FINAL_RATING.VALUES.ABOVE_AVERAGE;
            } else if (FEEDBACK.SKILLS.TYPES.FINAL_RATING.TEXT && value === 7) {
                status = FEEDBACK.SKILLS.TYPES.FINAL_RATING.VALUES.GOOD;
            } else if (FEEDBACK.SKILLS.TYPES.FINAL_RATING.TEXT && value === 8) {
                status = FEEDBACK.SKILLS.TYPES.FINAL_RATING.VALUES.VERY_GOOD;
            } else if (FEEDBACK.SKILLS.TYPES.FINAL_RATING.TEXT && value > 8 && value < 11) {
                status = FEEDBACK.SKILLS.TYPES.FINAL_RATING.VALUES.EXCELLENT;
            }
        }
        let tmpData = feedbackForm;
        tmpData[type] = value;
        if (type === FEEDBACK.SKILLS.TYPES.FINAL_RATING.TEXT) {
            setFeedbackForm((prevState) => ({
                ...prevState,
                [type]: value,
                status,
                codeUpdated: true,
            }));
        } else {
            setFeedbackForm((prevState) => ({
                ...prevState,
                [type]: value,
                codeUpdated: true,
            }));
        }
        setIsUpdate(!isUpdate);
    };

    const handleDelete = (data, type, index) => {
        let tmpData = data ? data : [];
        tmpData?.splice(index, 1);
        let i = 0;
        tmpData?.length > 0 &&
            tmpData.map((itm) => {
                itm.position = i;
                i = i + 1;
            });
        setFeedbackForm((prevState) => ({
            ...prevState,
            [type]: tmpData,
            codeUpdated: true,
        }));
        setIsUpdate(!isUpdate);
    };

    const handleAdd = (data, type, mode) => {
        let tmpData = data ? data : [];
        if (type === FEEDBACK.SKILLS.TYPES.SCREENING_QUESTIONS) {
            if (question === '') {
                ToastManager.addToast({
                    title: "Question can't be empty.",
                });
            } else if (question.length < 5) {
                ToastManager.addToast({
                    title: `Question can't be less than 5 characters.`,
                });
            } else if (
                feedbackForm?.screeningQuestions.length >=
                feedbackDetails?.interviewId?.screeningQuestionsSummary?.QuestionCount?.max
            ) {
                ToastManager.addToast({
                    title: `No Of Questions Cannot Exceed More Than ${feedbackDetails?.interviewId?.screeningQuestionsSummary?.QuestionCount?.max}`,
                });
            } else {
                tmpData.push({
                    question,
                    comment: '',
                    commentRequired: false,
                    commentLength: 0,
                    position: tmpData.length,
                    delete: true,
                    type: CUSTOM,
                    commentBoxVisible: false,
                });
            }
            setQuestion('');
        } else {
            let skillCount = tmpData.filter((itm) => itm.skill === '');
            let mandatorySkills = feedbackForm?.skills.filter(
                (itm) => itm.skillType === FEEDBACK.SKILLS.TYPES.MANDATORY
            );
            let optionalSkills = feedbackForm?.skills.filter((itm) => itm.skillType === FEEDBACK.SKILLS.TYPES.OPTIONAL);
            if (skillCount && skillCount.length > 0) {
                ToastManager.addToast({
                    title: "Skill name can't be empty.",
                });
            } else if (
                mode === FEEDBACK.SKILLS.TYPES.MANDATORY &&
                mandatorySkills.length >= feedbackDetails?.interviewId?.skillsSummary?.mandatorySkillCount?.max
            ) {
                ToastManager.addToast({
                    title: `Mandatory Skills Cannot Not Be More Than ${feedbackDetails?.interviewId?.skillsSummary?.mandatorySkillCount?.max}`,
                });
            } else if (
                mode === FEEDBACK.SKILLS.TYPES.OPTIONAL &&
                optionalSkills.length >= feedbackDetails?.interviewId?.skillsSummary?.optionalSkillCount?.max
            ) {
                ToastManager.addToast({
                    title: `Optional Skills Cannot Not Be More Than ${feedbackDetails?.interviewId?.skillsSummary?.optionalSkillCount?.max}`,
                });
            } else {
                tmpData.push({
                    skill: '',
                    rating: '',
                    comment: '',
                    commentRequired: false,
                    commentLength: 0,
                    position: tmpData.length,
                    skillType: mode,
                    delete: true,
                    type: CUSTOM,
                    commentBoxVisible: false,
                });
            }
        }
        setFeedbackForm((prevState) => ({
            ...prevState,
            [type === FEEDBACK.SKILLS.TEXT ? FEEDBACK.SKILLS.TEXT : FEEDBACK.SKILLS.TYPES.SCREENING_QUESTIONS]: tmpData,
            codeUpdated: true,
        }));
        setIsUpdate(false);
    };

    const handleChangeSkill = (data, value, index) => {
        let tmpData = data ?? [];
        tmpData[index]['skill'] = value;
        setFeedbackForm((prevState) => ({
            ...prevState,
            skills: tmpData,
            codeUpdated: true,
        }));
        setIsUpdate(!isUpdate);
    };

    const handleCommentBoxVisible = (data, value, index, type) => {
        let tmpData = data ?? [];
        tmpData[index]['commentBoxVisible'] = value;
        setFeedbackForm((prevState) => ({
            ...prevState,
            [type === FEEDBACK.SKILLS.TEXT ? FEEDBACK.SKILLS.TEXT : FEEDBACK.SKILLS.TYPES.SCREENING_QUESTIONS]: tmpData,
            codeUpdated: true,
        }));
        setIsUpdate(!isUpdate);
    };

    return (
        <Flex css={{ size: '100%' }} direction="column">
            <Box css={{ h: '$18', '@md': { h: '$17' } }} data-testid="header">
                <Flex justify="center" align="center" css={{ marginTop: '2.5vh' }}>
                    <LogoImg src={logo} alt="InCruiter Logo" width={102} height={42} />
                </Flex>
                {loading ? (
                    <Loader />
                ) : (
                    <div
                        className="part-2 h-100 feedback-container-v2"
                        style={{ width: screenWidth > 768 ? '30%' : '100%' }}
                    >
                        <FeedbackHeader interviewDetails={feedbackDetails?.interviewId} form={feedbackForm} />
                        <FeedbackContainerV2
                            form={feedbackForm}
                            MasterSkills={MasterSkills}
                            handleRatingComment={handleRatingComment}
                            handleRemarks={handleRemarks}
                            handleDelete={handleDelete}
                            handleAdd={handleAdd}
                            handleChangeSkill={handleChangeSkill}
                            handleCommentBoxVisible={handleCommentBoxVisible}
                            setForm={setFeedbackForm}
                            userType="Interviewer"
                            role={rolePage}
                            roomId={roomId}
                            allScreenshots={allScreenshots}
                            interviewDetails={feedbackDetails?.interviewId}
                            setPage={setPage}
                            page={page}
                            setQuestion={setQuestion}
                            question={question}
                        />
                    </div>
                )}
            </Box>
        </Flex>
    );
}

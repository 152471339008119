import React from 'react';
import './TranscriptModal.css';

const TranscriptModal = ({ img, imgClass, text, btnText, btnClick }) => {
    return (
        <div className="transcript-wrapper">
            <div className="d-center flex-column">
                <p className="transcript-popup-title">Generating Transcript</p>
                <div className="transcript_popup_div"></div>
                <div className="transcript_img_container">
                    <img src={img} alt="" className={imgClass} />
                </div>
                <p className="transcript_text">{text}</p>
                {btnText && (
                    <button className="common-btn transcript_popup_btn mt-20" onClick={btnClick}>
                        {btnText}
                    </button>
                )}
            </div>
        </div>
    );
};

export default TranscriptModal;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import DeletePopUp from './FeedbackComponents/DeletePopUp';
import FinalRemarksV2 from './FeedbackComponents/FinalRemarksV2';
import Instructions from './FeedbackComponents/Instructions';
import OtherSkills from './FeedbackComponents/OtherSkills';
import ScreeningQuestions from './FeedbackComponents/ScreeningQuestions';
import Skills from './FeedbackComponents/Skills';
import TranscriptForMobileScreen from './TranscriptForMobileScreen/TranscriptForMobileScreen';
import finalremarksWhite from '../../images/fr-logo-White.svg';
import informationWhite from '../../images/information-img-White.svg';
import otherskillWhite from '../../images/os-logo-White.svg';
import screeningquestionWhite from '../../images/qs-logo-White.svg';
import skillWhite from '../../images/skill-img-White.svg';
import Tab3White from '../../images/tabImg3-white.svg';
import { postFeedbackDetails } from '../../redux/action';
import { isMobileDevice } from '../../common/utils';

export const FeedbackContainerV2 = ({
    form,
    MasterSkills,
    handleRatingComment,
    handleRemarks,
    handleDelete,
    handleAdd,
    handleChangeSkill,
    handleCommentBoxVisible,
    setForm,
    userType,
    role,
    roomId,
    interviewDetails,
    allScreenshots,
    setPage,
    page,
    setQuestion,
    question,
}) => {
    const handlePage = (page) => {
        setPage(page);
    };
    const [deleteObject, setDeleteObject] = useState(null);
    const [submitDisability, setSubmitDisability] = useState(false);

    useEffect(() => {
        setSubmitDisability(false);
    }, [form]);

    const SwitchPage = (page) => {
        switch (page) {
            case 0:
                return <Instructions interviewDetails={interviewDetails} />;
            case 1:
                return (
                    <ScreeningQuestions
                        data={form.screeningQuestions}
                        setQuestion={setQuestion}
                        question={question}
                        handleAdd={handleAdd}
                        handleRatingComment={handleRatingComment}
                        setDeleteObject={setDeleteObject}
                        interviewDetails={interviewDetails}
                        role={role}
                    />
                );
            case 2:
                return (
                    <>
                        <Skills
                            title="Mandatory Skills"
                            MasterSkills={MasterSkills}
                            type="mandatory"
                            skillLists={form?.skills}
                            handleChangeSkill={handleChangeSkill}
                            handleAdd={handleAdd}
                            handleRatingComment={handleRatingComment}
                            handleCommentBoxVisible={handleCommentBoxVisible}
                            role={role}
                            setDeleteObject={setDeleteObject}
                        />
                        <Skills
                            title="Optional Skills"
                            MasterSkills={MasterSkills}
                            type="optional"
                            skillLists={form?.skills}
                            handleChangeSkill={handleChangeSkill}
                            handleCommentBoxVisible={handleCommentBoxVisible}
                            handleAdd={handleAdd}
                            handleRatingComment={handleRatingComment}
                            role={role}
                            setDeleteObject={setDeleteObject}
                        />
                    </>
                );
            case 3:
                return (
                    <OtherSkills
                        skillLists={form?.skills}
                        handleRatingComment={handleRatingComment}
                        experience={interviewDetails?.candidate?.experience}
                        ratingType={interviewDetails?.ratingType}
                    />
                );
            case 4:
                return (
                    <TranscriptForMobileScreen transcript={form.transcript} interviewSummary={form.interviewSummary} />
                );
            case 5:
                return (
                    <FinalRemarksV2
                        form={form}
                        setForm={setForm}
                        handleRemarks={handleRemarks}
                        userType={userType}
                        role={role}
                        roomId={roomId}
                        allScreenshots={allScreenshots}
                        interviewDetails={interviewDetails}
                        submitDisability={submitDisability}
                        setSubmitDisability={setSubmitDisability}
                    />
                );
            default:
                return <Instructions interviewDetails={interviewDetails} />;
        }
    };
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    // updating screenWidth whenever window is resized
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const dispatch = useDispatch();

    const debouncedDispatch = debounce((formData) => {
        if (role === 'interviewer' && interviewDetails && form.interviewId && form.codeUpdated) {
            dispatch(
                postFeedbackDetails({
                    type: 1,
                    interviewId: form.interviewId,
                    data: {
                        ...formData,
                        code: [formData?.codeEditor],
                        roomId: roomId,
                        type: 'autosave',
                    },
                })
            );
        }
    }, 300);

    useEffect(() => {
        debouncedDispatch(form);
        return () => debouncedDispatch.cancel();
    }, [form, debouncedDispatch]);

    return (
        <div className="update-feedback-container feedback-container" style={{ height: screenWidth < 768 && '100%' }}>
            <div className="feedback-btn-container">
                <div
                    className={`feedback-btn ${page === 0 ? 'theme-peacock-bg' : 'theme-gray-bg'}`}
                    title="Information"
                    onClick={() => handlePage(0)}
                >
                    <img src={informationWhite} alt="mandatory skills logo" className="feedback-img" />
                </div>
                <div
                    className={`feedback-btn ${page === 1 ? 'theme-peacock-bg' : 'theme-gray-bg'}`}
                    title="Screening Questions"
                    onClick={() => handlePage(1)}
                >
                    <img src={screeningquestionWhite} alt="questions logo" className="feedback-img" />
                </div>
                <div
                    className={`feedback-btn ${page === 2 ? 'theme-peacock-bg' : 'theme-gray-bg'}`}
                    title="Technical Skills"
                    onClick={() => handlePage(2)}
                >
                    <img src={skillWhite} alt="Skills" className="feedback-img" />
                </div>
                <div
                    className={`feedback-btn ${page === 3 ? 'theme-peacock-bg' : 'theme-gray-bg'}`}
                    title="Interpersonal Skills"
                    onClick={() => handlePage(3)}
                >
                    <img src={otherskillWhite} alt="other skills logo" className="feedback-img" />
                </div>
                {isMobileDevice() && (
                    <div
                        className={`feedback-btn ${page === 4 ? 'theme-peacock-bg' : 'theme-gray-bg'}`}
                        title="Interpersonal Skills"
                        onClick={() => handlePage(4)}
                    >
                        <img
                            src={Tab3White}
                            alt="Transcript Logo"
                            className="tab-logo"
                            style={{ width: '20px', height: '20px' }}
                        />
                    </div>
                )}
                <div
                    className={`feedback-btn ${page === 5 ? 'theme-peacock-bg' : 'theme-gray-bg'}`}
                    title="Feedback"
                    onClick={() => handlePage(5)}
                >
                    <img src={finalremarksWhite} alt="final remarks logo" className="feedback-img" />
                </div>
            </div>
            <div className="feedback-page-container" style={page === 4 ? { overflowY: 'hidden' } : null}>
                {SwitchPage(page)}
            </div>
            {deleteObject && (
                <DeletePopUp
                    deleteObject={deleteObject}
                    setDeleteObject={setDeleteObject}
                    handleDelete={handleDelete}
                />
            )}
        </div>
    );
};

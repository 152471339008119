import React from 'react';
import { GlobalSkill, OverallSkill } from '../OtherComponents';
export const OverallSkills = ({ data }) => {
    return (
        <div className="overall-skills-div p-5-print">
            <GlobalSkill data={data} />
            <OverallSkill data={data} />
        </div>
    );
};

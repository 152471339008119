import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastManager } from '../../components/Toast/ToastManager';
import file_uploaded from '../../assets/images/file_uploaded.svg';
import file_upload from '../../assets/images/fileUpload.svg';
import { videoUpload } from '../../redux/action';

const UploadVideo = ({ roomId }) => {
    const dispatch = useDispatch();
    const isUploadingVideo = useSelector((state) => state.Interview.isUploadingVideo);
    const handleVideoUpload = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        if (file) {
            const fileType = file.type;
            const fileSize = file.size / (1024 * 1024 * 1024);
            if (fileType !== 'video/mp4') {
                ToastManager.addToast({
                    title: 'Please upload a file with .mp4 filetype.',
                    autoClose: 5000,
                });
            } else if (fileSize > 1) {
                ToastManager.addToast({
                    title: 'Please upload a file less than 1 GB.',
                    autoClose: 5000,
                });
            } else {
                formData.append('videoRecording', file);
                formData.append('roomId', roomId);
                dispatch(videoUpload(formData));
            }
        }
    };

    useEffect(() => {
        if (isUploadingVideo === 'FAILURE') {
            ToastManager.addToast({
                title: 'Something went wrong. Please try again.',
                autoClose: 5000,
            });
        }
    }, [isUploadingVideo]);

    return (
        <>
            <div className="d-center gap-10 w-100 position-relative">
                <label className="common-btn upload_btn">
                    <input
                        id="video-upload"
                        type="file"
                        accept="video/mp4"
                        onChange={(e) => handleVideoUpload(e)}
                        disabled={isUploadingVideo === 'SUCCESS' || isUploadingVideo === 'YES'}
                        className="upload-video"
                    />
                    <img
                        src={isUploadingVideo === 'SUCCESS' ? file_uploaded : file_upload}
                        alt="upload"
                        className="me-1 upload-img-size"
                    />
                    {isUploadingVideo === 'YES'
                        ? 'Uploading'
                        : isUploadingVideo === 'SUCCESS'
                          ? 'Uploaded'
                          : 'Upload Video'}
                </label>
                {isUploadingVideo === 'YES' && (
                    <div className="position-absolute video-uploading">
                        *Video is uploading. Please do not close this window.
                    </div>
                )}
            </div>
        </>
    );
};

export default UploadVideo;

/* eslint-disable no-unused-vars */
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Avatar, StyledVideoTile, useBorderAudioLevel, Video, VideoTileStats } from '@100mslive/roomkit-react';
import {
    selectAudioTrackByPeerID,
    selectIsPeerAudioEnabled,
    selectLocalPeerID,
    selectPeerMetadata,
    selectPeerNameByID,
    selectVideoTrackByID,
    selectVideoTrackByPeerID,
    useHMSStore,
} from '@100mslive/react-sdk';
import { BrbIcon, HandRaiseFilledIcon, MicOffIcon } from '@100mslive/react-icons';
import TileConnection from '../../../components/Connection/TileConnection';
import { getVideoTileLabel } from '../../../components/peerTileUtils';
import pinTile from '../../../assets/images/pinTile.svg';
import TileMenu from './TileMenu';
import { useAppConfig } from '../../../components/AppData/useAppConfig';
import { useIsHeadless, useSetAppDataByKey, useUISettings } from '../../../components/AppData/useUISettings';
import { APP_DATA, UI_SETTINGS } from '../../../common/constants';
const metaStyles = { top: '$4', left: '$4' };

const PeerMetadata = ({ peerId }) => {
    const metaData = useHMSStore(selectPeerMetadata(peerId));
    const isHandRaised = metaData?.isHandRaised || false;
    const isBRB = metaData?.isBRBOn || false;

    return (
        <Fragment>
            {isHandRaised ? (
                <StyledVideoTile.AttributeBox css={metaStyles} data-testid="raiseHand_icon_onTile">
                    <HandRaiseFilledIcon width={40} height={40} />
                </StyledVideoTile.AttributeBox>
            ) : null}
            {isBRB ? (
                <StyledVideoTile.AttributeBox css={metaStyles} data-testid="brb_icon_onTile">
                    <BrbIcon width={40} height={40} />
                </StyledVideoTile.AttributeBox>
            ) : null}
        </Fragment>
    );
};

const MeetVideoTile = React.memo(({ peerId, trackId, width, height, visible = true, setTab, tab }) => {
    const trackSelector = trackId ? selectVideoTrackByID(trackId) : selectVideoTrackByPeerID(peerId);
    const track = useHMSStore(trackSelector);
    const peerName = useHMSStore(selectPeerNameByID(peerId));
    const audioTrack = useHMSStore(selectAudioTrackByPeerID(peerId));
    const localPeerID = useHMSStore(selectLocalPeerID);
    const isAudioOnly = useUISettings(UI_SETTINGS.isAudioOnly);
    const showStatsOnTiles = useUISettings(UI_SETTINGS.showStatsOnTiles);
    const isHeadless = useIsHeadless();
    const isAudioMuted = !useHMSStore(selectIsPeerAudioEnabled(peerId));
    const isVideoMuted = !track?.enabled;
    const borderAudioRef = useBorderAudioLevel(audioTrack?.id);
    const isVideoDegraded = track?.degraded;
    const isLocal = localPeerID === peerId;
    const label = getVideoTileLabel({
        peerName,
        track,
        isLocal,
    });
    const headlessConfig = useAppConfig('headlessConfig');
    const hideLabel = isHeadless && headlessConfig?.hideTileName;
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const [pinnedTrackId, setPinnedTrackId] = useSetAppDataByKey(APP_DATA.pinnedTrackId);
    const isTilePinned =
        pinnedTrackId &&
        ((track?.id && track?.id === pinnedTrackId) || (audioTrack?.id && audioTrack?.id === pinnedTrackId));
    return (
        <StyledVideoTile.Root
            css={{
                width,
                height: height,
                visibility: 'visible',
                padding: '10px !important',
            }}
            data-testid={`participant_tile_${peerName}`}
        >
            {peerName !== undefined ? (
                <StyledVideoTile.Container
                    ref={isHeadless && headlessConfig?.hideAudioLevel ? undefined : borderAudioRef}
                >
                    {showStatsOnTiles ? (
                        <VideoTileStats
                            audioTrackID={audioTrack?.id}
                            videoTrackID={track?.id}
                            peerID={peerId}
                            isLocal={isLocal}
                        />
                    ) : null}

                    {isTilePinned && <img src={pinTile} className="pinned-tile" alt="Tile Pinned" />}

                    {track ? (
                        <div style={{ width: '100%', height: '100%' }}>
                            <Video
                                trackId={track?.id}
                                attach={isLocal ? undefined : !isAudioOnly}
                                mirror={false}
                                degraded={isVideoDegraded}
                                data-testid="participant_video_tile"
                            />
                        </div>
                    ) : null}
                    {isVideoMuted || isVideoDegraded || (!isLocal && isAudioOnly) ? (
                        <StyledVideoTile.AvatarContainer css={{ position: 'static', transform: 'unset' }}>
                            <Avatar
                                name={peerName || ''}
                                data-testid="participant_avatar_icon"
                                css={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%,-50%)',
                                }}
                            />
                        </StyledVideoTile.AvatarContainer>
                    ) : null}

                    {showAudioMuted({
                        hideTileAudioMute: headlessConfig?.hideTileAudioMute,
                        isHeadless,
                        isAudioMuted,
                    }) ? (
                        <StyledVideoTile.AudioIndicator
                            data-testid="participant_audio_mute_icon"
                            css={{ background: '#f2464b' }}
                        >
                            <MicOffIcon />
                        </StyledVideoTile.AudioIndicator>
                    ) : null}
                    {!isHeadless ? (
                        <TileMenu
                            peerID={peerId}
                            audioTrackID={audioTrack?.id}
                            videoTrackID={track?.id}
                            setTab={setTab}
                            tab={tab}
                        />
                    ) : null}
                    <PeerMetadata peerId={peerId} />
                    <TileConnection hideLabel={hideLabel} name={label} isTile peerId={peerId} width={width} />
                </StyledVideoTile.Container>
            ) : null}
        </StyledVideoTile.Root>
    );
});

const showAudioMuted = ({ hideTileAudioMute, isHeadless, isAudioMuted }) => {
    if (!isHeadless) {
        return isAudioMuted;
    }
    return isAudioMuted && !hideTileAudioMute;
};
export default MeetVideoTile;

import React, { useEffect, memo } from 'react';
import { Flex, styled, Text, Box } from '@100mslive/roomkit-react';
import { useDispatch, useSelector } from 'react-redux';
import { getRecordings } from '../redux/action';
import { useParams } from 'react-router-dom';
import logo from '../images/incruiter-logo.svg';
const RecordingPage = () => {
    const dispatch = useDispatch();
    const { roomId } = useParams();
    const loading = useSelector((state) => state.Interview.loading);
    const recordingLists = useSelector((state) => state.Interview.recordingLists);
    useEffect(() => {
        if (roomId) {
            dispatch(getRecordings({ roomId: roomId }));
        }
    }, [dispatch, roomId]);
    return (
        <Flex css={{ size: '100%' }} direction="column">
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Box css={{ h: '$18', '@md': { h: '$17' } }} data-testid="header">
                        <Flex justify="between" align="center" css={{ position: 'relative', height: '100%' }}>
                            <Flex align="center" css={{ position: 'absolute', left: '$10' }}>
                                <LogoImg src={logo} alt="InCruiter Logo" width={103} height={42} />
                            </Flex>
                        </Flex>
                    </Box>
                    <Flex direction="column" css={{ width: '100vw', height: '94vh' }}>
                        <Flex
                            justify="center"
                            direction="column"
                            align="center"
                            className="recordingDiv"
                            css={{
                                bg: '#2E2E41',
                                flex: '1 1 0',
                                position: 'relative',
                            }}
                        >
                            {recordingLists && recordingLists?.recording && recordingLists?.recording?.length > 0 ? (
                                recordingLists?.recording?.map((itm) => (
                                    <video width="320" height="240" controls autoplay name="media">
                                        <source
                                            src={`${itm?.url ? itm?.url.split('?')[0] : itm?.url}`}
                                            type="video/mp4"
                                        />
                                    </video>
                                ))
                            ) : (
                                <Text
                                    variant="body1"
                                    css={{
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        fontSize: '40px',
                                        lineHeight: '55px',
                                        color: '#9396A0',
                                        textAlign: 'center',
                                    }}
                                >
                                    OOPS!
                                    <br />
                                    No Recording Found
                                </Text>
                            )}
                        </Flex>
                    </Flex>
                </>
            )}
        </Flex>
    );
};
const LogoImg = styled('img', {
    maxHeight: '$17',
    p: '$2',
    w: 'auto',
    '@md': {
        maxHeight: '$15',
    },
});
const Loader = memo(() => {
    return (
        <div className="wrapper">
            <div className="loader"></div>
        </div>
    );
});

export default RecordingPage;

import React from 'react';
import Select from 'react-select';
const CustomSelect = ({ name, className, value, onChange, inputClass }) => {
    const ratingList = [
        { label: 0, value: 0, text: 'Very Poor' },
        { label: 1, value: 1, text: 'Very Poor' },
        { label: 2, value: 2, text: 'Very Poor' },
        { label: 3, value: 3, text: 'Poor' },
        { label: 4, value: 4, text: 'Poor' },
        { label: 5, value: 5, text: 'Average' },
        { label: 6, value: 6, text: 'Above Average' },
        { label: 7, value: 7, text: 'Good' },
        { label: 8, value: 8, text: 'Very Good' },
        { label: 9, value: 9, text: 'Excellent' },
        { label: 10, value: 10, text: 'Excellent' },
    ];
    const options = [
        {
            value: 0,
            label: (
                <span>
                    <span className="select-status-rating">0</span>
                    <br />
                    <span className="select-status-text select-status-red">Very Poor</span>
                </span>
            ),
        },
        {
            value: 1,
            label: (
                <span>
                    <span className="select-status-rating">1</span>
                    <br />
                    <span className="select-status-text select-status-red">Very Poor</span>
                </span>
            ),
        },
        {
            value: 2,
            label: (
                <span>
                    <span className="select-status-rating">2</span>
                    <br />
                    <span className="select-status-text select-status-red">Very Poor</span>
                </span>
            ),
        },
        {
            value: 3,
            label: (
                <span>
                    <span className="select-status-rating">3</span>
                    <br />
                    <span className="select-status-text select-status-red">Poor</span>
                </span>
            ),
        },
        {
            value: 4,
            label: (
                <span>
                    <span className="select-status-rating">4</span>
                    <br />
                    <span className="select-status-text select-status-red">Poor</span>
                </span>
            ),
        },
        {
            value: 5,
            label: (
                <span>
                    <span className="select-status-rating">5</span>
                    <br />
                    <span className="select-status-text select-status-orange">Average</span>
                </span>
            ),
        },
        {
            value: 6,
            label: (
                <span>
                    <span className="select-status-rating">6</span>
                    <br />
                    <span className="select-status-text select-status-orange">Above Average</span>
                </span>
            ),
        },
        {
            value: 7,
            label: (
                <span>
                    <span className="select-status-rating">7</span>
                    <br />
                    <span className="select-status-text select-status-green">Good</span>
                </span>
            ),
        },
        {
            value: 8,
            label: (
                <span>
                    <span className="select-status-rating">8</span>
                    <br />
                    <span className="select-status-text select-status-green">Very Good</span>
                </span>
            ),
        },
        {
            value: 9,
            label: (
                <span>
                    <span className="select-status-rating">9</span>
                    <br />
                    <span className="select-status-text select-status-green">Excellent</span>
                </span>
            ),
        },
        {
            value: 10,
            label: (
                <span>
                    <span className="select-status-rating">10</span>
                    <br />
                    <span className="select-status-text select-status-green">Excellent</span>
                </span>
            ),
        },
    ];

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: '#2e2e41',
            color: '#ffffff',
            cursor: 'pointer',
            textAlign: 'center',
            borderBottom: '1px solid #ffffff',
            padding: 0, // Remove padding
            margin: 0, // Remove margin
        }),
        singleValue: (provided, state) => ({
            ...provided,
            backgroundColor: '#2e2e41',
            color: '#ffffff',
        }),
        control: (provided) => ({
            ...provided,
            borderColor: '#4287f5',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#4287f5',
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#2e2e41',
            border: '1px solid #ffffff',
            padding: 0, // Remove padding
            margin: 0, // Remove margin
        }),
    };

    const formatOptionLabel = ({ label }) => <div className="option-label">{label}</div>;

    return (
        <Select
            isSearchable={false}
            options={options}
            styles={customStyles}
            menuPlacement="auto"
            formatOptionLabel={formatOptionLabel}
            name={name}
            className={className}
            value={value}
            onChange={onChange}
            inputClass={inputClass}
        />
    );
};

export default CustomSelect;

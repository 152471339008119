import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import _debounce from 'lodash/debounce';
import CustomSelect from '../../components/Feedback/FeedbackComponents/CustomSelect';
import DeletePopUp from '../../components/Feedback/FeedbackComponents/DeletePopUp';
import Instructions from '../../components/Feedback/FeedbackComponents/Instructions';
import OtherSkills from '../../components/Feedback/FeedbackComponents/OtherSkills';
import { ToastManager } from '../../components/Toast/ToastManager';
import finalRemarksIcon from '../../images/fr-logo-White.svg';
import informationIcon from '../../images/information-img-White.svg';
import otherSkillsIcon from '../../images/os-logo-White.svg';
import screeningQuestionsIcon from '../../images/qs-logo-White.svg';
import skillsIcon from '../../images/skill-img-White.svg';
import trashIcon from '../../images/trash.svg';
import { getSkills } from '../../redux/action';
import FinalFeedRemarks from './FinalFeedRemarks';

const FeedbackFeedContainer = ({ form, setForm, screenWidth }) => {
    const dispatch = useDispatch();
    const [question, setQuestion] = useState('');
    const [page, setPage] = useState(0);
    const [deleteObject, setDeleteObject] = useState(null);
    const [submitDisability, setSubmitDisability] = useState(false);
    const skills = useSelector((state) => state.Interview.skills);

    useEffect(() => {
        if (!skills) dispatch(getSkills());
    }, [dispatch, skills]);

    useEffect(() => {
        setSubmitDisability(false);
    }, [form]);

    const MasterSkills = useMemo(() => {
        const updatedMasterSkills = [];
        if (skills && skills.length > 0)
            skills.forEach((itm) => {
                updatedMasterSkills.push({
                    value: itm.name,
                    label: itm.name,
                });
            });
        return updatedMasterSkills;
    }, [skills]);

    const handlePageChange = (page) => setPage(page);

    const handleInputChange = (type, mode, value, index) => {
        if (mode === 'comment' && value.length > 500) {
            ToastManager.addToast({
                title: "Comments can't exceed 500 characters.",
            });
            return;
        }
        const updatedData = [...form[type]];
        updatedData[index][mode] = ['comment', 'skill'].includes(mode) ? (value ? value.trim() : '') : value;
        setForm((prevState) => ({ ...prevState, [type]: updatedData }));
    };

    const handleDelete = (data = [], type, index) => {
        const updatedData = data
            .filter((_, i) => i !== index)
            .map((item, i) => ({
                ...item,
                position: i,
            }));
        setForm((prevState) => ({
            ...prevState,
            [type]: updatedData,
        }));
    };

    const handleAdd = (data = [], type, mode) => {
        const tmpData = [...data];
        const showToast = (message) => ToastManager.addToast({ title: message });
        if (type === 'screeningQuestions') {
            if (!question) return showToast("Question can't be empty.");
            if (question.length < 5) return showToast("Question can't be less than 5 characters.");
            if (form?.screeningQuestions?.length >= 20)
                return showToast('Number of Questions Cannot Exceed More Than 20.');
            tmpData.push({
                question,
                comment: '',
                commentRequired: false,
                commentLength: 0,
                position: tmpData.length,
                delete: true,
                type: 'custom',
                commentBoxVisible: false,
            });
            setQuestion('');
        } else if (type === 'skills') {
            const hasEmptySkill = tmpData.some((item) => !item.skill);
            const mandatorySkills = form?.skills?.filter((item) => item.skillType === 'mandatory') || [];
            const optionalSkills = form?.skills?.filter((item) => item.skillType === 'optional') || [];
            if (hasEmptySkill) return showToast("Skill name can't be empty.");
            if (mode === 'mandatory' && mandatorySkills.length >= 6)
                return showToast('Mandatory Skills Cannot Be More Than 6.');
            if (mode === 'optional' && optionalSkills.length >= 6)
                return showToast('Optional Skills Cannot Be More Than 6.');
            tmpData.push({
                skill: '',
                rating: '',
                comment: '',
                commentRequired: false,
                commentLength: 0,
                position: tmpData.length,
                skillType: mode,
                delete: true,
                type: 'custom',
                commentBoxVisible: false,
            });
        }
        setForm((prevState) => ({
            ...prevState,
            [type === 'skills' ? 'skills' : 'screeningQuestions']: tmpData,
        }));
    };

    const renderPageContent = () => {
        switch (page) {
            case 1:
                return (
                    <ScreeningQuestions
                        data={form.screeningQuestions}
                        setQuestion={setQuestion}
                        question={question}
                        handleAdd={handleAdd}
                        handleInputChange={handleInputChange}
                        setDeleteObject={setDeleteObject}
                    />
                );
            case 2:
                return (
                    <>
                        <Skills
                            title="Mandatory Skills"
                            MasterSkills={MasterSkills}
                            type="mandatory"
                            skillLists={form.skills}
                            handleAdd={handleAdd}
                            handleInputChange={handleInputChange}
                            setDeleteObject={setDeleteObject}
                        />
                        <Skills
                            title="Optional Skills"
                            MasterSkills={MasterSkills}
                            type="optional"
                            skillLists={form.skills}
                            handleAdd={handleAdd}
                            handleInputChange={handleInputChange}
                            setDeleteObject={setDeleteObject}
                        />
                    </>
                );
            case 3:
                return (
                    <OtherSkills
                        skillLists={form.skills}
                        handleRatingComment={handleInputChange}
                        experience={form.candidate.experience || 0}
                        ratingType={1}
                    />
                );
            case 4:
                return (
                    <FinalFeedRemarks
                        form={form}
                        setForm={setForm}
                        submitDisability={submitDisability}
                        setSubmitDisability={setSubmitDisability}
                    />
                );
            default:
                return <Instructions interviewDetails={form} />;
        }
    };

    const feedbackButtons = [
        { title: 'Information', icon: informationIcon, page: 0 },
        { title: 'Screening Questions', icon: screeningQuestionsIcon, page: 1 },
        { title: 'Technical Skills', icon: skillsIcon, page: 2 },
        { title: 'Interpersonal Skills', icon: otherSkillsIcon, page: 3 },
        { title: 'Feedback', icon: finalRemarksIcon, page: 4 },
    ];

    return (
        <div className="update-feedback-container feedback-container" style={{ height: screenWidth < 768 && '100%' }}>
            <div className="feedback-btn-container">
                {feedbackButtons.map((btn) => (
                    <div
                        key={btn.page}
                        className={`feedback-btn ${page === btn.page ? 'theme-peacock-bg' : 'theme-gray-bg'}`}
                        title={btn.title}
                        onClick={() => handlePageChange(btn.page)}
                    >
                        <img src={btn.icon} alt={`${btn.title} logo`} className="feedback-img" />
                    </div>
                ))}
            </div>
            <div className="feedback-page-container-feed">{renderPageContent()}</div>
            {deleteObject && (
                <DeletePopUp
                    deleteObject={deleteObject}
                    setDeleteObject={setDeleteObject}
                    handleDelete={handleDelete}
                />
            )}
        </div>
    );
};

const ScreeningQuestions = ({ data, setQuestion, question, handleAdd, handleInputChange, setDeleteObject }) => {
    return (
        <div className="sq-container mr-10 ml-10">
            <div className="fp-title f-sem-bol">Screening Questions</div>
            <div className="sqc-container">
                {data?.map((item, i) => (
                    <ScreeningQuestionComponent
                        key={i}
                        data={item}
                        list={data}
                        index={i + 1}
                        handleInputChange={handleInputChange}
                        setDeleteObject={setDeleteObject}
                    />
                ))}
                <NewQuestionInput setQuestion={setQuestion} question={question} />
                <div
                    className="text-align-right"
                    onClick={_debounce(() => {
                        handleAdd(data, 'screeningQuestions', 'screeningQuestions');
                    }, 300)}
                >
                    <span className="f-12 f-sem-bol add-text-option cursor-pointer">+ Add Question</span>
                </div>
            </div>
        </div>
    );
};

const NewQuestionInput = ({ setQuestion, question }) => {
    const handleQuestionChange = (e) => {
        const { value } = e.target;
        const maxLength = 200;
        if (value.length > maxLength) {
            ToastManager.addToast({
                title: `Question Length Cannot Be More Than ${maxLength} Characters.`,
            });
        } else {
            setQuestion(value);
        }
    };
    return (
        <div className="nsq-component">
            <input
                className="question-input2 f-12 f-reg"
                placeholder="Enter New Question Here..."
                type="text"
                value={question}
                onChange={handleQuestionChange}
            />
        </div>
    );
};
const ScreeningQuestionComponent = ({ data, index, handleInputChange, setDeleteObject, list }) => {
    return (
        <div className="text-left mb-10 pos-rel">
            <div className="f-12 f-med text-theme-white mb-10 wth-94">
                <pre className="code-content-pre">
                    <code>{`${index}. ${data?.question}`}</code>
                </pre>
                {data?.delete && (
                    <button
                        className="delete-btn-v2"
                        onClick={() =>
                            setDeleteObject({
                                list,
                                mode: 'question',
                                title: data?.question,
                                type: 'screeningQuestions',
                                position: data?.position,
                            })
                        }
                    >
                        <img src={trashIcon} className="delete-img-v2" alt="Delete" />
                    </button>
                )}
            </div>
            <div className="input-textarea">
                <textarea
                    value={data?.comment || ''}
                    className="w-100 final-remarks f-12 f-reg mt-2"
                    placeholder="Enter Your Answer Here..."
                    style={{ height: '60px' }}
                    onChange={(e) => handleInputChange('screeningQuestions', 'comment', e.target.value, data?.position)}
                ></textarea>
                <span
                    className="clear-input"
                    onClick={() => handleInputChange('screeningQuestions', 'comment', '', data?.position)}
                >
                    x
                </span>
                <span className="length-count2">{data?.comment?.length || 0}</span>
            </div>
        </div>
    );
};

const Skills = ({ title, MasterSkills, type, skillLists, handleAdd, handleInputChange, setDeleteObject }) => {
    const details = skillLists.filter((itm) => itm.skillType === type);
    const addSkillHandler = _debounce(() => handleAdd(skillLists, 'skills', type), 300);
    return (
        <div className="feedback-page h-100">
            <div className="fp-title f-sem-bol">{title}</div>
            <div className="fp-content-container">
                {details.length > 0 ? (
                    details.map((item, i) => (
                        <SkillInput
                            list={skillLists}
                            data={item}
                            MasterSkills={MasterSkills}
                            setDeleteObject={setDeleteObject}
                            handleInputChange={handleInputChange}
                            key={i}
                            type={type}
                            handleAdd={handleAdd}
                            len={i === details.length - 1}
                        />
                    ))
                ) : (
                    <div className="d-flex text-right" onClick={addSkillHandler}>
                        <span className="f-12 f-sem-bol add-text-option">+ Add Skills</span>
                    </div>
                )}
            </div>
        </div>
    );
};

const SkillInput = ({ list, data, MasterSkills, setDeleteObject, handleInputChange, type, handleAdd, len }) => {
    const styles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
        }),
        option: (provided) => ({
            ...provided,
            color: '#2e2e41',
        }),
    };
    const addSkillHandler = _debounce(() => handleAdd(list, 'skills', type), 300);
    const toggleCommentBox = () =>
        handleInputChange('skills', 'commentBoxVisible', !data.commentBoxVisible, data.position);
    const remainingChars =
        data.commentLength?.min > 0 ? Math.max(0, data.commentLength.min - (data.comment?.length || 0)) : 0;

    return (
        <div className="skill-input">
            <div className="si-row-1 d-flex align-items-center justify-content-between">
                <div className="skill-input-field">
                    <CreatableSelect
                        className={`w-100 f-reg select-input-container${data?.delete ? '' : ' input-disabled'}`}
                        value={{ label: data.skill, value: data.skill }}
                        onChange={(e) => handleInputChange('skills', 'skill', e.value, data.position)}
                        options={MasterSkills}
                        menuPlacement="auto"
                        styles={styles}
                        isDisabled={!data?.delete}
                    />
                    {data?.delete && (
                        <button
                            className="delete-skill"
                            onClick={() =>
                                setDeleteObject({
                                    mode: 'skill',
                                    title: data.skill,
                                    list,
                                    type: 'skills',
                                    position: data.position,
                                })
                            }
                        >
                            <img src={trashIcon} className="delete-img" alt="Delete" />
                        </button>
                    )}
                </div>
                <CustomSelect
                    name="skill-rating"
                    className="w-35 f-reg select-input-container"
                    value={{
                        label: data.rating !== '' ? parseInt(data.rating) : 'Rating',
                        value: data.rating !== '' ? parseInt(data.rating) : '',
                    }}
                    onChange={(e) => handleInputChange('skills', 'rating', e.value, data.position)}
                />
            </div>
            {data.commentBoxVisible ? (
                <>
                    <div className="si-row-2">
                        <textarea
                            className="w-100 skill-remarks"
                            placeholder="Add Comments..."
                            value={data.comment}
                            onChange={(e) => handleInputChange('skills', 'comment', e.target.value, data.position)}
                        ></textarea>
                        <span
                            className="clear-input"
                            onClick={() => handleInputChange('skills', 'comment', '', data.position)}
                        >
                            x
                        </span>
                        <span className={`length-count${remainingChars === 0 ? '2' : ''}`}>{remainingChars}</span>
                    </div>
                    <div
                        className="d-flex"
                        style={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            float: 'right',
                        }}
                    >
                        {len && (
                            <span className="f-12 f-sem-bol add-text-option text-right" onClick={addSkillHandler}>
                                + Add Skills
                            </span>
                        )}
                    </div>
                </>
            ) : (
                <div
                    className="d-flex"
                    style={{
                        justifyContent: 'space-between',
                        display: 'flex',
                    }}
                >
                    <span className="f-12 f-sem-bol add-text-option text-left" onClick={toggleCommentBox}>
                        {data.comment ? 'Show Comment' : '+ Add Comment'}
                    </span>
                    {len && (
                        <span className="f-12 f-sem-bol add-text-option text-right" onClick={addSkillHandler}>
                            + Add Skills
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};

export default FeedbackFeedContainer;

import React from 'react';
import screenShotWhite from '../../../images/screenShotWhite.svg';
import Tab3White from '../../../images/tabImg3-white.svg';
const TabV2 = ({ form, setForm, role, isSideOpen = true, carSystem = false }) => {
    const handleTab = (selectTab) => {
        if (form.activeTab === selectTab) {
            selectTab = 1;
        }
        setForm((prevState) => ({
            ...prevState,
            activeTab: selectTab,
            codeUpdated: false,
        }));
    };
    return (
        <div className="d-flex meeting-tab w-md-100" style={{ width: !isSideOpen && '98%' }}>
            {form?.transcript?.length > 0 && (
                <div
                    className={`admin_tab activity-tab ${form?.activeTab === 3 ? 'active-tab' : 'inactive-tab'}`}
                    onClick={() => handleTab(3)}
                >
                    <img src={Tab3White} alt="Transcript Logo" className="tab-logo" />
                    <span className="tab-text">Transcript</span>
                </div>
            )}
            {!carSystem && role === 'admin' && (
                <div
                    className={`admin_tab activity-tab ${form?.activeTab === 2 ? 'active-tab' : 'inactive-tab'}`}
                    onClick={() => handleTab(2)}
                >
                    <img src={screenShotWhite} alt="Screenshots Logo" className="tab-logo" />
                    <span className="tab-text">Screenshot</span>
                </div>
            )}
        </div>
    );
};

export default TabV2;

import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Flex, styled, Text } from '@100mslive/roomkit-react';
import rightTick from '../../assets/images/right-tick.svg';
import updateFeedbackIcon from '../../assets/images/updateFeedbackIcon.svg';
import logo from '../../images/incruiter-logo.svg';
import FeedbackFeedContainer from './FeedbackFeedContainer';
import { FeedbackFeedHeader } from './FeedbackFeedHeader';

const CommonFeedbackFeedRoom = ({ form, setForm }) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const loading = useSelector((state) => state.Interview.loading);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const renderContent = () => (
        <Flex css={{ size: '100%' }} direction="column">
            <ContentWrapper screenWidth={screenWidth}>
                <ContentBox>
                    <img src={updateFeedbackIcon} alt="Interview Completed" />
                    <Message>
                        <img src={rightTick} alt="Success" />
                        <span>Update Feedback</span>
                    </Message>
                    <SubMessage>Please provide feedback using the form on the right panel.</SubMessage>
                </ContentBox>
            </ContentWrapper>
        </Flex>
    );

    const renderMain = () => (
        <Flex css={{ size: '100%' }} direction="column">
            <Header>
                <Logo src={logo} alt="InCruiter Logo" />
            </Header>
            <MainContainer>
                <ContentArea screenWidth={screenWidth}>{renderContent()}</ContentArea>
            </MainContainer>
            {!loading ? (
                <FeedbackContainer className="part-2 h-100 feedback-container-v2" screenWidth={screenWidth}>
                    <FeedbackFeedHeader form={form} />
                    <FeedbackFeedContainer form={form} setForm={setForm} screenWidth={screenWidth} />
                </FeedbackContainer>
            ) : (
                <Loader />
            )}
        </Flex>
    );

    return renderMain();
};

// Styled Components
const ContentWrapper = styled(Flex, {
    height: '100%',
    justifyContent: 'center',
    '@media (max-width: 768px)': {
        justifyContent: 'flex-start',
        height: '550px',
    },
});

const ContentBox = styled(Flex, {
    background: '#191924',
    color: '#FFFFFF',
    width: '98%',
    margin: '1%',
    height: '98%',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    '@media (max-width: 768px)': {
        height: '100%',
    },
});

const Message = styled(Text, {
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '25px',
    letterSpacing: '0.04em',
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
});

const SubMessage = styled(Text, {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '25px',
    marginTop: '10px',
});

const Header = styled(Box, {
    height: '$18',
    '@media (max-width: 768px)': {
        height: '$17',
    },
    padding: '11px 24px',
});

const Logo = styled('img', {
    width: 'auto',
    maxHeight: '42px',
    '@media (max-width: 768px)': {
        maxHeight: '36px',
    },
});

const MainContainer = styled(Box, {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    minHeight: 0,
});

const ContentArea = styled(Box, {
    flex: 1,
    width: '70%',
    height: '100%',
    background: '#2E2E41',
    borderRadius: '8px',
    '@media (max-width: 768px)': {
        width: '98%',
    },
});

const FeedbackContainer = styled('div', {
    top: '0',
    '@media (max-width: 768px)': {
        width: '100%',
    },
});

const Loader = memo(() => (
    <div className="wrapper">
        <div className="loader" />
    </div>
));

export default CommonFeedbackFeedRoom;

import React, { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Flex, styled, Text } from '@100mslive/roomkit-react';
import axios from 'axios';
import jwtEncode from 'jwt-encode';
import { Header } from '../../components/Header';
import FOFBanner from '../../assets/images/404-banner.svg';
import CommonFeedbackFeedRoom from './CommonFeedbackFeedRoom';
import FeedInterviewComplete from './FeedInterviewComplete';
import FeedLinkExpired from './FeedLinkExpired';
import { generateUrl, otherSkillsData } from '../../utils/helpers';
const FeedbackUpdateFeed = () => {
    const { interviewId, interviewKey } = useParams();
    const [form, setForm] = useState(null);
    const [error, setError] = useState(false);
    const [jwtToken, setJwtToken] = useState('');
    const [linkExpired, setLinkExpired] = useState(false);

    const processFinalData = (data) => {
        if (data?.feedbackId) {
            const feedback = data?.feedbackId;
            const skills = [],
                screeningQuestions = [];
            feedback?.skills?.map((itm, index) =>
                skills.push({
                    skill: itm.name,
                    rating: itm?.rating ?? '',
                    comment: itm?.comment ?? '',
                    commentRequired: false,
                    commentLength: ['mandatory', 'optional'].includes(itm?.skillType)
                        ? { min: 2, max: 500 }
                        : { min: 0, max: 500 },
                    position: index,
                    skillType: itm?.skillType,
                    delete: false,
                    type: 'default',
                    commentBoxVisible: itm?.comment ? true : false,
                })
            );
            feedback?.screeningQuestions?.map((questions, index) =>
                screeningQuestions.push({
                    question: questions?.question || '',
                    comment: questions?.comment || '',
                    commentRequired: false,
                    commentLength: { min: 2, max: 500 },
                    position: index,
                    type: 'default',
                    delete: false,
                    commentBoxVisible: questions?.comment ? true : false,
                })
            );
            return {
                feedbackId: feedback?._id,
                finalRemark: feedback?.finalRemark,
                code: feedback?.code[0],
                finalRating: feedback.finalRating,
                status: feedback?.status,
                skills: skills,
                screeningQuestions: screeningQuestions,
                suspectedProxy: feedback?.suspectedProxy || false,
            };
        } else {
            const skills = [],
                screeningQuestions = [];
            // eslint-disable-next-line array-callback-return
            data?.mandatorySkills?.map((itm) => {
                skills.push({
                    skill: itm.name,
                    rating: '',
                    comment: '',
                    commentRequired: false,
                    commentLength: { min: 2, max: 500 },
                    position: skills.length,
                    skillType: 'mandatory',
                    delete: false,
                    type: 'default',
                    commentBoxVisible: false,
                });
            });
            // eslint-disable-next-line array-callback-return
            data?.optionalSkills?.map((itm) => {
                skills.push({
                    skill: itm.name,
                    rating: '',
                    comment: '',
                    commentRequired: false,
                    commentLength: { min: 2, max: 500 },
                    position: skills.length,
                    skillType: 'optional',
                    delete: false,
                    type: 'default',
                    commentBoxVisible: false,
                });
            });
            otherSkillsData?.map((itm) =>
                // eslint-disable-next-line array-callback-return
                itm?.skills.map((item) => {
                    skills.push({
                        skill: item.name,
                        rating: '',
                        comment: '',
                        commentRequired: false,
                        commentLength: { min: 0, max: 500 },
                        position: skills.length,
                        skillType: item.skillType,
                        delete: false,
                        type: 'default',
                        commentBoxVisible: false,
                    });
                })
            );
            data?.screeningQuestions?.map((questions, index) =>
                screeningQuestions.push({
                    question: questions.question,
                    comment: '',
                    commentRequired: false,
                    commentLength: { min: 2, max: 500 },
                    position: index,
                    type: 'default',
                    delete: false,
                    commentBoxVisible: false,
                })
            );
            return {
                finalRemark: '',
                code: '',
                finalRating: null,
                status: '',
                skills,
                screeningQuestions,
                suspectedProxy: false,
            };
        }
    };

    const [deviceInfo, setDeviceInfo] = useState({
        browser: '',
        os: '',
        ip: '',
    });

    useEffect(() => {
        // Function to fetch IP address
        const fetchIP = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                const data = await response.json();
                setDeviceInfo((prevInfo) => ({
                    ...prevInfo,
                    ip: data.ip,
                }));
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }
        };

        // Get browser and OS info
        const browser = navigator.userAgent;
        const os = navigator.platform;

        // Update state with browser and OS info
        setDeviceInfo((prevInfo) => ({
            ...prevInfo,
            browser,
            os,
        }));

        // Fetch IP address after updating browser and OS info
        fetchIP();
    }, []);

    useEffect(() => {
        // Generate JWT using deviceInfo as payload and stringify as secret
        if (deviceInfo.ip) {
            const secret = JSON.stringify(deviceInfo);
            const token = jwtEncode(
                {
                    ...deviceInfo,
                    exp: Math.floor(Date.now() / 1000) + 60 * 60 * 24 * 365 * 20,
                },
                secret
            );
            setJwtToken(token);
        }
    }, [deviceInfo, setJwtToken]);

    useEffect(() => {
        if (!form?.token && interviewKey && interviewId && jwtToken) {
            const url = `${generateUrl('feed_backend')}/validateInterviewerKey/${interviewId}/${interviewKey}`;
            axios
                .get(url, {
                    headers: {
                        'session-token': jwtToken, // Add the JWT token in session-token header
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        const data = res.data.result;
                        if (data?.linkExpired) {
                            setLinkExpired(true);
                        } else if (data && data.interviewId === interviewId) {
                            if (data?.token) {
                                const config = {
                                    headers: { Authorization: `Bearer ${data.token}` },
                                };
                                axios
                                    .get(`${generateUrl('feed_backend')}/interviewDetails/${interviewId}`, config)
                                    .then((response) => {
                                        if (response.status === 200) {
                                            const newData = response.data.result;
                                            const processData = processFinalData(newData);
                                            setForm({
                                                ...processData,
                                                interviewKey: interviewKey,
                                                interviewId: interviewId,
                                                token: data?.token || '',
                                                product: data.productId || '',
                                                domain: data.domain || '',
                                                candidate: newData?.candidate,
                                                jobDescription: newData?.jobDescription,
                                                jobTitle: newData?.position?.title,
                                                remarks: newData?.remarks ?? '',
                                                interviewStatus: newData?.status ?? '',
                                                customField: newData?.customField ?? [],
                                            });
                                        } else {
                                            setError(true);
                                        }
                                    })
                                    .catch(() => {
                                        setError(true);
                                    });
                            } else {
                                setError(true);
                            }
                        } else {
                            setError(true);
                        }
                    }
                })
                .catch((error) => {
                    setError(true);
                });
        }
    }, [interviewKey, interviewId, form?.token, jwtToken]);

    if (error) return <ErrorPage />;

    if (linkExpired) return <FeedLinkExpired />;

    if (!form) return <Loader />;

    if (form?.interviewStatus !== 'scheduled') {
        return <FeedInterviewComplete />;
    }

    return form?.token ? <CommonFeedbackFeedRoom form={form} setForm={setForm} /> : <></>;
};

const Loader = memo(() => (
    <div className="wrapper">
        <div className="loader" />
    </div>
));

const ErrorPage = () => {
    return (
        <Flex css={{ height: '100vh', width: '100vw' }} direction="column">
            {/* Header Section */}
            <Box
                css={{
                    height: '$18',
                    backgroundColor: '#191924',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                }}
                data-testid="header"
            >
                <Header />
            </Box>

            {/* Main Content */}
            <Flex
                direction="column"
                css={{
                    flex: 1,
                    width: '100%',
                    backgroundColor: '#2E2E41',
                    position: 'relative',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px', // Add padding for smaller screens
                }}
            >
                <Text
                    variant="body1"
                    css={{
                        fontWeight: 700,
                        fontSize: '40px',
                        lineHeight: '55px',
                        color: '#9396A0',
                        textAlign: 'center',
                        '@media (max-width: 768px)': {
                            fontSize: '20px',
                            lineHeight: '30px',
                        },
                    }}
                >
                    OOPS!
                    <br />
                    Error 404 - Page not found
                </Text>
                <Text
                    variant="body1"
                    css={{
                        fontWeight: 600,
                        fontSize: '18px',
                        lineHeight: '25px',
                        color: '#FFFFFF',
                        marginTop: '10px',
                        textAlign: 'center',
                        '@media (max-width: 768px)': {
                            fontSize: '16px',
                            lineHeight: '22px',
                        },
                    }}
                >
                    We could not find what you were looking for
                </Text>

                <LogoImg
                    src={FOFBanner}
                    alt="InCruiter Logo"
                    width={615}
                    height={354}
                    css={{
                        marginTop: '20px',
                        maxWidth: '90%', // Ensure the image scales down for smaller screens
                        height: 'auto',
                        '@media (max-width: 768px)': {
                            width: '100%',
                        },
                    }}
                />
            </Flex>
        </Flex>
    );
};

const LogoImg = styled('img', {
    p: '$2',
});
export default FeedbackUpdateFeed;

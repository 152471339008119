import React from 'react';
import { SkillComment } from '../OtherComponents';
import { FinalRemarks } from './FinalRemarks';
import { ScreeningQuestions } from './ScreeningQuestions';
export const SkillsComment = ({
    feedbackData,
    feedbackStatus,
    data,
    ScreeningQuestionsStatus,
    screeningQuestionsData,
}) => {
    const mandatory = data.filter((itm) => itm.type === 'mandatory');
    const optional = data.filter((itm) => itm.type === 'optional');
    const soft = data.filter((itm) => itm.type === 'soft');
    const analytics = data.filter((itm) => itm.type === 'analytics');
    const leadership = data.filter((itm) => itm.type === 'leadership');
    return (
        <div className="detailed-feedback">
            <div className="detailed-feedback-list break-stop">
                <div className="detailed-feedback-title">Detailed Feedback</div>
                {feedbackStatus && <FinalRemarks data={feedbackData} />}
                {mandatory && mandatory.length > 0 && (
                    <SkillComment title="Mandatory Skills" skills={mandatory} position="left" breakPage={false} />
                )}
            </div>
            <div className="detailed-feedback-list break-stop">
                {optional && optional.length > 0 && (
                    <SkillComment title="Optional Skills" skills={optional} position="left" breakPage={false} />
                )}
            </div>
            {ScreeningQuestionsStatus && <ScreeningQuestions data={screeningQuestionsData} />}
            <div className="detailed-feedback-list">
                {soft && soft.length > 0 && (
                    <SkillComment title="Soft Skills" skills={soft} breakPage={true} position="left" />
                )}
                {analytics && analytics.length > 0 && (
                    <SkillComment title="Analytical Skills" skills={analytics} position="left" breakPage={true} />
                )}
                {leadership && leadership.length > 0 && (
                    <SkillComment title="Leadership Skills" skills={leadership} position="left" breakPage={true} />
                )}
            </div>
        </div>
    );
};

import axios from 'axios';
import { generateUrl } from '../helpers';

const fileSaver = axios.create({
    baseURL: generateUrl('file-saver-system'),
});

fileSaver.interceptors.request.use(
    function (config) {
        let token = localStorage.getItem('token');
        if (token && token !== null) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

fileSaver.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default fileSaver;

import React, { Fragment } from 'react';
import { Tooltip } from '@100mslive/roomkit-react';
import { parsedUserAgent, useAVToggle } from '@100mslive/react-sdk';
import { MicOffIcon, MicOnIcon, VideoOffIcon, VideoOnIcon } from '@100mslive/react-icons';
import IconButton from '../IconButton';
const isMacOS = parsedUserAgent.getOS().name.toLowerCase() === 'mac os';

export const AudioVideoToggle = () => {
    const { isLocalVideoEnabled, isLocalAudioEnabled, toggleAudio, toggleVideo } = useAVToggle();
    return (
        <Fragment>
            {toggleAudio ? (
                <Tooltip title={`Turn ${isLocalAudioEnabled ? 'off' : 'on'} audio (${isMacOS ? '⌘' : 'ctrl'} + d)`}>
                    <IconButton
                        active={isLocalAudioEnabled}
                        onClick={toggleAudio}
                        key="toggleAudio"
                        data-testid="audio_btn"
                        className={`preview-btn${isLocalAudioEnabled ? '' : ' meet-disable-btn'}`}
                    >
                        {!isLocalAudioEnabled ? (
                            <MicOffIcon data-testid="audio_off_btn" />
                        ) : (
                            <MicOnIcon data-testid="audio_on_btn" />
                        )}
                    </IconButton>
                </Tooltip>
            ) : null}
            {toggleVideo ? (
                <Tooltip title={`Turn ${isLocalVideoEnabled ? 'off' : 'on'} video (${isMacOS ? '⌘' : 'ctrl'} + e)`}>
                    <IconButton
                        key="toggleVideo"
                        active={isLocalVideoEnabled}
                        onClick={toggleVideo}
                        data-testid="video_btn"
                        className={`preview-btn${isLocalVideoEnabled ? '' : ' meet-disable-btn'}`}
                    >
                        {!isLocalVideoEnabled ? (
                            <VideoOffIcon data-testid="video_off_btn" />
                        ) : (
                            <VideoOnIcon data-testid="video_on_btn" />
                        )}
                    </IconButton>
                </Tooltip>
            ) : null}
        </Fragment>
    );
};

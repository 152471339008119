import React from 'react';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { Box, config as cssConfig, Flex } from '@100mslive/roomkit-react';
import {
    selectLocalPeerID,
    selectPeerScreenSharing,
    selectPeerSharingVideoPlaylist,
    selectScreenShareByPeerID,
    useHMSStore,
} from '@100mslive/react-sdk';
import { VideoPlayer } from '../../../components/Playlist/VideoPlayer';
import { ScreenshareDisplay } from '../../../components/ScreenshareDisplay';
import ScreenShareTile from './ScreenShareTile';
const ScreenShareView = ({ setTab, tab }) => {
    const mediaQueryLg = cssConfig.media.lg;
    const showSidebarInBottom = useMedia(mediaQueryLg);
    const localPeerID = useHMSStore(selectLocalPeerID);
    const peerPresenting = useHMSStore(selectPeerScreenSharing);
    const peerSharingPlaylist = useHMSStore(selectPeerSharingVideoPlaylist);
    const amIPresenting = localPeerID === peerPresenting?.id;
    const type = 'nonSpeaker';

    return (
        <Flex
            css={{
                size: '100%',
            }}
            direction={showSidebarInBottom ? 'column' : 'row'}
        >
            <ScreenShareComponent
                amIPresenting={amIPresenting}
                peerPresenting={peerPresenting}
                peerSharingPlaylist={peerSharingPlaylist}
                type={type}
                setTab={setTab}
                tab={tab}
            />
        </Flex>
    );
};

const ScreenShareComponent = ({ amIPresenting, peerPresenting, peerSharingPlaylist, type, setTab, tab }) => {
    const { role } = useParams();
    const screenShareTrack = useHMSStore(selectScreenShareByPeerID(peerPresenting?.id));

    const containerStyles = {
        width: role === 'beam' && type === 'activeSpeaker' ? '78vw' : '100%',
        height: role === 'beam' && type === 'activeSpeaker' ? '100vh' : '100%',
    };

    if (peerSharingPlaylist) {
        return (
            <Box css={containerStyles}>
                <VideoPlayer peerId={peerSharingPlaylist.id} />
            </Box>
        );
    }

    return (
        <Box css={containerStyles}>
            {peerPresenting &&
                (amIPresenting && !['browser', 'window', 'application'].includes(screenShareTrack?.displaySurface) ? (
                    <Box css={{ objectFit: 'contain', h: '100%' }}>
                        <ScreenshareDisplay />
                    </Box>
                ) : (
                    <ScreenShareTile peerId={peerPresenting?.id} setTab={setTab} tab={tab} />
                ))}
        </Box>
    );
};

export default ScreenShareView;

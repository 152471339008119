import React from 'react';
import { ToastManager } from '../../Toast/ToastManager';
const NewQuestionInput = ({ setQuestion, question, interviewDetails }) => {
    const handleQuestionChange = (e) => {
        const { value } = e.target;
        const maxLength = interviewDetails?.screeningQuestionsSummary?.questionLength?.max;
        if (value.length > maxLength) {
            ToastManager.addToast({
                title: `Question Length Cannot Be More Than ${maxLength} Characters.`,
            });
        } else {
            setQuestion(value);
        }
    };
    return (
        <div className="nsq-component">
            <input
                className="question-input2 f-12 f-reg"
                placeholder="Enter New Question Here..."
                type="text"
                value={question}
                onPaste={(e) => e.stopPropagation()}
                onCut={(e) => e.stopPropagation()}
                onCopy={(e) => e.stopPropagation()}
                onChange={handleQuestionChange}
            />
        </div>
    );
};
export default NewQuestionInput;

import { selectDominantSpeaker, useHMSStore } from '@100mslive/react-sdk';
import { VolumeOneIcon } from '@100mslive/react-icons';
import {
    // eslint-disable-next-line no-unused-vars
    config as cssConfig,
    Flex,
    styled,
    Text,
    textEllipsis,
} from '@100mslive/roomkit-react';
import logo from '../../images/incruiter-logo.svg';

export const SpeakerTag = () => {
    const dominantSpeaker = useHMSStore(selectDominantSpeaker);
    return dominantSpeaker && dominantSpeaker.name ? (
        <Flex
            align="center"
            justify="center"
            css={{
                flex: '1 1 0',
                color: '$textPrimary',
                '@md': { display: 'none' },
            }}
        >
            <VolumeOneIcon />
            <Text variant="md" css={{ ...textEllipsis(200), ml: '$2' }} title={dominantSpeaker.name}>
                {dominantSpeaker.name}
            </Text>
        </Flex>
    ) : (
        <></>
    );
};

const LogoImg = styled('img', {
    maxHeight: '$17',
    p: '$2',
    w: 'auto',
    '@md': {
        maxHeight: '$15',
    },
});

export const Logo = () => {
    return <LogoImg src={logo} alt="InCruiter Logo" width={103} height={42} />;
};

import {
    GET_EXIT_FEEDBACK,
    GET_EXIT_FEEDBACK_FAILURE,
    GET_EXIT_FEEDBACK_SUCCESS,
    GET_EXIT_INTERVIEW,
    GET_EXIT_INTERVIEW_FAILURE,
    GET_EXIT_INTERVIEW_SUCCESS,
    GET_EXIT_RECORDING,
    GET_EXIT_RECORDING_FAILURE,
    GET_EXIT_RECORDING_SUCCESS,
    GET_EXIT_TOKEN,
    GET_EXIT_TOKEN_FAILURE,
    GET_EXIT_TOKEN_SUCCESS,
    POST_EXIT_FEEDBACK,
    POST_EXIT_FEEDBACK_FAILURE,
    POST_EXIT_FEEDBACK_SUCCESS,
    POST_EXIT_NOSHOW_FEEDBACK,
    POST_EXIT_NOSHOW_FEEDBACK_FAILURE,
    POST_EXIT_NOSHOW_FEEDBACK_SUCCESS,
} from './types';

export const getExitFeedback = (payload) => ({
    type: GET_EXIT_FEEDBACK,
    payload,
});
export const getExitFeedbackSuccess = (payload) => ({
    type: GET_EXIT_FEEDBACK_SUCCESS,
    payload,
});
export const getExitFeedbackFailure = () => ({
    type: GET_EXIT_FEEDBACK_FAILURE,
});

export const getExitRecording = (payload) => ({
    type: GET_EXIT_RECORDING,
    payload,
});
export const getExitRecordingSuccess = (payload) => ({
    type: GET_EXIT_RECORDING_SUCCESS,
    payload,
});
export const getExitRecordingFailure = () => ({
    type: GET_EXIT_RECORDING_FAILURE,
});

export const getExitInterview = (payload) => ({
    type: GET_EXIT_INTERVIEW,
    payload,
});
export const getExitInterviewSuccess = (payload) => ({
    type: GET_EXIT_INTERVIEW_SUCCESS,
    payload,
});
export const getExitInterviewFailure = () => ({
    type: GET_EXIT_INTERVIEW_FAILURE,
});

export const getExitToken = (payload) => ({
    type: GET_EXIT_TOKEN,
    payload,
});
export const getExitTokenSuccess = (payload) => ({
    type: GET_EXIT_TOKEN_SUCCESS,
    payload,
});
export const getExitTokenFailure = () => ({
    type: GET_EXIT_TOKEN_FAILURE,
});

export const postExitFeedback = (payload) => ({
    type: POST_EXIT_FEEDBACK,
    payload,
});
export const postExitFeedbackSuccess = () => ({
    type: POST_EXIT_FEEDBACK_SUCCESS,
});
export const postExitFeedbackFailure = () => ({
    type: POST_EXIT_FEEDBACK_FAILURE,
});

export const postExitNoShowFeedback = (payload) => ({
    type: POST_EXIT_NOSHOW_FEEDBACK,
    payload,
});
export const postExitNoShowFeedbackSuccess = () => ({
    type: POST_EXIT_NOSHOW_FEEDBACK_SUCCESS,
});
export const postExitNoShowFeedbackFailure = () => ({
    type: POST_EXIT_NOSHOW_FEEDBACK_FAILURE,
});

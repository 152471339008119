/* eslint-disable no-unused-vars */
import React, { Suspense, useEffect } from 'react';
import { useMedia } from 'react-use';
import { config as cssConfig, Flex } from '@100mslive/roomkit-react';
import {
    selectIsConnectedToRoom,
    selectLocalPeerID,
    selectLocalPeerRoleName,
    selectPeers,
    selectPeerScreenSharing,
    selectPeerSharingAudio,
    selectPeerSharingVideoPlaylist,
    useHMSActions,
    useHMSStore,
} from '@100mslive/react-sdk';
import FullPageProgress from '../../components/FullPageProgress';
import { MeetGridView } from './MeetPageView/MeetGridView';
import { MeetSidePanel } from './MeetPageView/MeetSidePanel';
import SidePane from '../../layouts/SidePane';
import { usePinnedTrack, useUISettings } from '../../components/AppData/useUISettings';
import { useRefreshSessionMetadata } from '../../components/hooks/useRefreshSessionMetadata';
const MeetWhiteboardView = React.lazy(() => import('./MeetPageView/MeetWhiteboardView'));
const ScreenShareView = React.lazy(() => import('./MeetPageView/ScreenShareView'));
const PinnedTrackView = React.lazy(() => import('./MeetPageView/PinnedTrackView'));
const MeetChatView = React.lazy(() => import('./MeetPageView/MeetChatView'));

export const MeetPageView = ({ setTab, tab, name }) => {
    const localPeerRole = useHMSStore(selectLocalPeerRoleName);
    const pinnedTrack = usePinnedTrack();
    const peers = useHMSStore(selectPeers);
    const mediaQueryLg = cssConfig.media.lg;
    const localPeerId = useHMSStore(selectLocalPeerID);
    const showSidebarInBottom = useMedia(mediaQueryLg);
    const peerSharing = useHMSStore(selectPeerScreenSharing);
    const peerSharingAudio = useHMSStore(selectPeerSharingAudio);
    const peerSharingPlaylist = useHMSStore(selectPeerSharingVideoPlaylist);
    const isConnected = useHMSStore(selectIsConnectedToRoom);
    useRefreshSessionMetadata();
    const hmsActions = useHMSActions();
    const { isAudioOnly } = useUISettings();
    useEffect(() => {
        if (!isConnected) {
            return;
        }
        const audioPlaylist = JSON.parse(process.env.REACT_APP_AUDIO_PLAYLIST || '[]');
        const videoPlaylist = JSON.parse(process.env.REACT_APP_VIDEO_PLAYLIST || '[]');
        if (videoPlaylist.length > 0) {
            hmsActions.videoPlaylist.setList(videoPlaylist);
        }
        if (audioPlaylist.length > 0) {
            hmsActions.audioPlaylist.setList(audioPlaylist);
        }
    }, [isConnected, hmsActions]);

    if (!localPeerRole) {
        return null;
    }

    let ViewComponent;
    if (
        ((peerSharing && peerSharing.id !== peerSharingAudio?.id) || peerSharingPlaylist) &&
        !isAudioOnly &&
        tab === 1
    ) {
        ViewComponent = ScreenShareView;
    } else if (pinnedTrack && tab === 1) {
        ViewComponent = PinnedTrackView;
    } else if (tab === 2) {
        ViewComponent = MeetChatView;
    } else if (tab === 3) {
        ViewComponent = MeetWhiteboardView;
    } else {
        ViewComponent = MeetGridView;
    }

    const centerPeers = peers.length > 1 ? peers.filter((peer) => peer.id !== localPeerId) : peers;
    const sideBarPeers = tab === 1 ? peers.filter((peer) => peer.id !== centerPeers?.[0].id) : peers;
    const sideBarStatus = tab === 1 ? peers?.length > 1 : true;

    return (
        <Suspense fallback={<FullPageProgress />}>
            <Flex
                css={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    display: 'flex',
                    background: 'rgb(25 25 36)',
                }}
            >
                <ViewComponent tab={tab} setTab={setTab} name={name} />
                {sideBarStatus && (
                    <Flex
                        direction={{ '@initial': 'column', '@lg': 'row' }}
                        css={{
                            overflow: 'auto',
                            width: '25%',
                            height: '100%',
                            '@md': { display: 'none' },
                        }}
                    >
                        <MeetSidePanel
                            showSidebarInBottom={showSidebarInBottom}
                            smallTilePeers={sideBarPeers}
                            totalPeers={sideBarPeers.length}
                            setTab={setTab}
                            tab={tab}
                        />
                    </Flex>
                )}
            </Flex>
            <SidePane />
        </Suspense>
    );
};

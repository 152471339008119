import React from 'react';
import { getSkill, getStatusV } from './Helpers';
export const Skill = ({ title, skills }) => {
    return (
        <div className="break-stop">
            <div className="skills-div">
                <span className="soft-skills-span">{title}</span>
            </div>
            <div className="mandatory-skills-graph-wrapper">
                {skills &&
                    skills.length > 0 &&
                    skills.map((item, key) => (
                        <div className="individual-graph-wrapper break-stop" key={key}>
                            <img className="graph-img" src={getSkill(item.rating)} alt={item.rating * 10} />
                            <p className="graph-title" style={{ margin: '0px' }} title={item.name}>
                                {item.name.length > 21 ? item.name.substring(0, 21) + '...' : item.name}
                            </p>
                            <p style={{ margin: '0px', 'line-height': '0px' }}>
                                <span className={`graph-descp-comment ${getStatusV(item.rating)?.color}`}>
                                    {getStatusV(item.rating)?.title.substring(0, 21)}
                                </span>
                            </p>
                        </div>
                    ))}
            </div>
        </div>
    );
};

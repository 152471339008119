import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

export function RadarChart({ data }) {
    let dataDetails = [...data?.SkillsData];
    let dataRatingSet = dataDetails.sort((a, b) => b.rating - a.rating).slice(0, 3);
    let labels = [],
        result = [];
    dataRatingSet &&
        dataRatingSet.length > 0 &&
        dataRatingSet.map((itm) => {
            labels.push(itm?.name);
            result.push(itm?.rating);
        });

    const dataSet = {
        labels: labels,
        datasets: [
            // {
            //   label: "Global",
            //   data: [10, 8, 9, 8, 6],
            //   backgroundColor: "rgba(17, 178, 184, 0.4)",
            //   borderColor: "rgba(255, 99, 132, 1)",
            //   borderWidth: 1,
            // },
            {
                label: 'Candidate',
                data: result,
                backgroundColor: 'rgba(19, 63, 125, 0.4)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
                strokeColor: '#000000',
                pointColor: '#000000',
                pointHighlightStroke: '#000000',
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            },
        },
        tooltips: {
            enabled: false,
        },
        scales: {
            r: {
                max: 10,
                min: 0,
                ticks: {
                    stepSize: 1,
                    textStrokeColor: 'black',
                    color: 'black',
                    display: true,
                },
                angleLines: {
                    color: ' rgba(155, 155, 155, 0.1)',
                },
                pointLabels: {
                    color: '#000000',
                    callback: (value) => {
                        // Split the label into two lines
                        const words = value.split(' ');
                        const line1 = words.slice(0, Math.floor(words.length / 2)).join(' ');
                        const line2 = words.slice(Math.floor(words.length / 2)).join(' ');
                        return [line1, line2];
                    },
                    font: {
                        size: 12,
                        family: 'Poppins',
                        style: 'normal',
                        weight: 400,
                    },
                },
                grid: {
                    color: '#9B9B9B',
                    circular: true,
                },
                gridLines: {
                    color: '#9B9B9B',
                },
            },
        },
    };
    return <Radar data={dataSet} options={options} width={'100%'} height={'100%'} />;
}

import {
    GET_EXIT_FEEDBACK,
    GET_EXIT_FEEDBACK_FAILURE,
    GET_EXIT_FEEDBACK_SUCCESS,
    GET_EXIT_INTERVIEW,
    GET_EXIT_INTERVIEW_FAILURE,
    GET_EXIT_INTERVIEW_SUCCESS,
    GET_EXIT_RECORDING,
    GET_EXIT_RECORDING_FAILURE,
    GET_EXIT_RECORDING_SUCCESS,
    GET_EXIT_TOKEN,
    GET_EXIT_TOKEN_FAILURE,
    GET_EXIT_TOKEN_SUCCESS,
    POST_EXIT_FEEDBACK,
    POST_EXIT_FEEDBACK_FAILURE,
    POST_EXIT_FEEDBACK_SUCCESS,
    POST_EXIT_NOSHOW_FEEDBACK,
    POST_EXIT_NOSHOW_FEEDBACK_FAILURE,
    POST_EXIT_NOSHOW_FEEDBACK_SUCCESS,
} from '../action/types';

const INIT_STATE = {
    loading: false,
    interviewDetails: null,
    verifyKey: null,
    feedbackDetails: null,
    recordingDetails: null,
};

const ExitInterview = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_EXIT_FEEDBACK:
            return { ...state, loading: true };
        case GET_EXIT_FEEDBACK_SUCCESS:
            return {
                ...state,
                loading: false,
                feedbackDetails: action.payload,
            };
        case GET_EXIT_FEEDBACK_FAILURE:
            return { ...state, loading: false };

        case GET_EXIT_RECORDING:
            return { ...state, loading: true };
        case GET_EXIT_RECORDING_SUCCESS:
            return {
                ...state,
                loading: false,
                recordingDetails: action.payload,
            };
        case GET_EXIT_RECORDING_FAILURE:
            return { ...state, loading: false };

        case GET_EXIT_INTERVIEW:
            return { ...state, loading: true };
        case GET_EXIT_INTERVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                interviewDetails: action.payload,
            };
        case GET_EXIT_INTERVIEW_FAILURE:
            return { ...state, loading: false };

        case GET_EXIT_TOKEN:
            return { ...state, loading: true };
        case GET_EXIT_TOKEN_SUCCESS:
            return { ...state, loading: false, verifyKey: action.payload };
        case GET_EXIT_TOKEN_FAILURE:
            return { ...state, loading: false };

        case POST_EXIT_NOSHOW_FEEDBACK:
            return { ...state, loading: true };
        case POST_EXIT_NOSHOW_FEEDBACK_SUCCESS:
            return { ...state, loading: false };
        case POST_EXIT_NOSHOW_FEEDBACK_FAILURE:
            return { ...state, loading: false };

        case POST_EXIT_FEEDBACK:
            return { ...state, loading: true };
        case POST_EXIT_FEEDBACK_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_EXIT_FEEDBACK_FAILURE:
            return { ...state, loading: false };

        default:
            return state;
    }
};
export default ExitInterview;

import React, { memo } from 'react';
import Delete_Icon from '../../../assets/images/deleteIconNew.svg';
const DeletePopUp = memo(({ deleteObject, setDeleteObject, handleDelete }) => {
    return (
        <div className="finish-interview-popup">
            <div className="finish-interview-popup-content rounded d-flex flex-column p-4">
                <center>
                    <img src={Delete_Icon} alt="delete" className="finish-logo img-fluid" />
                </center>
                <div className="finish-subtitle">
                    <pre className="code-content-pre">
                        <code style={{ fontFamily: 'Poppins' }}>
                            Are you sure you want to delete the {deleteObject?.mode}
                            {deleteObject?.title ? ` '${deleteObject?.title}'` : ''}?
                        </code>
                    </pre>
                </div>
                <div className="d-center gap-10 w-100 delete_popUp_btn_container">
                    <button className="common-btn cancel" onClick={() => setDeleteObject(null)}>
                        Cancel
                    </button>
                    <button
                        className="common-btn delete-final-btn"
                        onClick={() => {
                            handleDelete(deleteObject.list, deleteObject.type, deleteObject.position);
                            setDeleteObject(null);
                        }}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );
});

export default DeletePopUp;

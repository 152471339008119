import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import _debounce from 'lodash/debounce';
import cross from '../../images/cross.svg';
import emptyStar from '../../images/emptyStar.svg';
import filledStar from '../../images/filledStar.svg';
import { postInterviewFeedback } from '../../redux/action';
import { ToastManager } from '../Toast/ToastManager';
const InterviewFeedback = ({ setShowFeedback, role, roomId }) => {
    const dispatch = useDispatch();
    const arr = ['1', '2', '3', '4', '5'];
    const [feedbackInput, setFeedbackInput] = useState('');
    const [coordinationStar, setCoordinationStar] = useState(0);
    const [platformStar, setPlatformStar] = useState(0);
    const handleClose = () => {
        setShowFeedback(true);
    };
    const handleSubmit = _debounce(() => {
        if (platformStar || coordinationStar) {
            let data = {
                interviewId: '',
                comment: feedbackInput,
                role: role,
                coordination: coordinationStar,
                platform: platformStar,
                roomId: roomId,
            };
            dispatch(
                postInterviewFeedback({
                    data: data,
                })
            );
            setShowFeedback(true);
            ToastManager.addToast({
                title: 'Feedback Submitted successfully.',
            });
        } else {
            ToastManager.addToast({
                title: 'Please provide the ratings.',
            });
        }
    }, 300);
    return (
        <div className="feedback-wrapper-new">
            <div className="feeback-collection">
                <div className="feddback-header-wrapper">
                    <p className="feeback-collection-header f-bol f-18">Interview Feedback</p>
                    <img src={cross} alt="cut" className="feedback-close-btn" onClick={() => handleClose()}></img>
                </div>
                <div className="feedback-inner-div">
                    <p className="feedback-inner-head f-bol">Please Share your valuable feedback to help us improve!</p>
                    {role === 'candidate' && (
                        <>
                            <p className="feedback-inner-div-p f-reg f-14">Overall Interview Rating</p>
                            <div className="star-container">
                                {arr.map((ele, i) => {
                                    return (
                                        <button key={i} className="star-btn" onClick={() => setCoordinationStar(ele)}>
                                            <img
                                                className="star-img"
                                                alt="star"
                                                src={ele <= coordinationStar ? filledStar : emptyStar}
                                            ></img>
                                        </button>
                                    );
                                })}
                            </div>
                        </>
                    )}
                    {(role === 'interviewer' || role === 'admin' || role === 'guest') && (
                        <>
                            <p className="feedback-inner-div-p f-reg">Interview Coordination</p>
                            <div className="star-container">
                                {arr.map((ele, i) => {
                                    return (
                                        <button key={i} className="star-btn" onClick={() => setCoordinationStar(ele)}>
                                            <img
                                                className="star-img"
                                                alt="star"
                                                src={ele <= coordinationStar ? filledStar : emptyStar}
                                            />
                                        </button>
                                    );
                                })}
                            </div>
                        </>
                    )}
                    <p className="feedback-inner-div-p f-reg f-14">
                        {role === 'candidate' ? 'Interviewer Rating' : 'Interview Platform'}
                    </p>
                    <div className="star-container">
                        {arr.map((ele, i) => {
                            return (
                                <button key={i} className="star-btn" onClick={() => setPlatformStar(ele)}>
                                    <img
                                        className="star-img"
                                        alt="star"
                                        src={ele <= platformStar ? filledStar : emptyStar}
                                    ></img>
                                </button>
                            );
                        })}
                    </div>
                    <p className="feedback-inner-div-p f-reg f-14">Platform Feedback</p>
                    <textarea
                        className="feedback-input"
                        value={feedbackInput ? feedbackInput : ''}
                        onChange={(e) => setFeedbackInput(e.target.value)}
                    ></textarea>
                    <button className="feedback-submit" onClick={() => handleSubmit()}>
                        <span>Submit</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default InterviewFeedback;

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import { v4 as uuid } from 'uuid';
import { useHMSActions } from '@100mslive/react-sdk';
import Loader from '../../components/Loader/Loader';
import MeetContainer from './MeetPreview/MeetContainer';
import { useSetUiSettings, useTokenEndpoint } from '../../components/AppData/useUISettings';
import getToken from '../../services/tokenService';
import {
    QUERY_PARAM_AUTH_TOKEN,
    QUERY_PARAM_NAME,
    QUERY_PARAM_SKIP_PREVIEW_HEADFUL,
    UI_SETTINGS,
} from '../../common/constants';
import '../../base.css';
import '../../index.css';
const env = 'prod';
const MeetPreview = React.memo(({ endPoint }) => {
    const navigate = useNavigate();
    const hmsActions = useHMSActions();
    const { roomId: urlRoomId, role: userRole } = useParams();
    const tokenEndpoint = useTokenEndpoint();
    const [, setIsHeadless] = useSetUiSettings(UI_SETTINGS.isHeadless);
    const [token, setToken] = useState(null);
    let skipPreview = userRole === 'beam';
    const directJoinHeadfulFromEnv = false;
    const directJoinHeadful = useSearchParam(QUERY_PARAM_SKIP_PREVIEW_HEADFUL) === 'true' || directJoinHeadfulFromEnv;
    const initialName = useSearchParam(QUERY_PARAM_NAME) || (skipPreview ? 'Beam' : '');
    let authToken = useSearchParam(QUERY_PARAM_AUTH_TOKEN);

    useEffect(() => {
        if (authToken) {
            setToken(authToken);
            return;
        }
        if (!tokenEndpoint || !urlRoomId) {
            return;
        }
        const roomCode = !userRole && urlRoomId;
        const getTokenFn = roomCode
            ? () => hmsActions.getAuthTokenByRoomCode({ roomCode }, { endpoint: endPoint })
            : () => getToken(tokenEndpoint, uuid(), userRole, urlRoomId);
        getTokenFn()
            .then((token) => {
                setToken(token);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [hmsActions, tokenEndpoint, urlRoomId, userRole, authToken, endPoint]);

    const onJoin = () => {
        const meetingURL = `/meet/meeting/${userRole}/${urlRoomId}`;
        !directJoinHeadful && setIsHeadless(skipPreview);
        navigate(meetingURL, { state: { name: initialName } });
    };

    return token ? (
        <MeetContainer initialName={initialName} skipPreview={skipPreview} env={env} onJoin={onJoin} token={token} />
    ) : (
        <Loader />
    );
});

export default MeetPreview;

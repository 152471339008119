import React from 'react';
import { RadarChart } from './RadarChart';
export const GlobalSkill = ({ data }) => {
    const splittedArray = data?.resumeSummary ? data?.resumeSummary.split(/\d+\. /) : [];
    return (
        <div className="candidate-skills-wrapper">
            <span className="candidate-skills-heading">
                {data?.resumeSummary ? 'Resume Summary' : 'Candidate Skills'}
            </span>
            {data?.resumeSummary?.length ? (
                <div className="font-style-soft-skill resume-summary">
                    <ul class="bullet-list">{splittedArray.map((itm) => itm !== '' && <li key={itm}>{itm}</li>)}</ul>
                </div>
            ) : (
                <div className="candidate-skills">
                    <RadarChart data={data} />
                </div>
            )}
        </div>
    );
};

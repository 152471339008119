export { default as Skill0 } from '../../../assets/images/0.svg';
export { default as Skill1 } from '../../../assets/images/1.svg';
export { default as Skill2 } from '../../../assets/images/2.svg';
export { default as Skill3 } from '../../../assets/images/3.svg';
export { default as Skill4 } from '../../../assets/images/4.svg';
export { default as Skill5 } from '../../../assets/images/5.svg';
export { default as Skill6 } from '../../../assets/images/6.svg';
export { default as Skill7 } from '../../../assets/images/7.svg';
export { default as Skill8 } from '../../../assets/images/8.svg';
export { default as Skill9 } from '../../../assets/images/9.svg';
export { default as Skill10 } from '../../../assets/images/10.svg';

import React from 'react';
import { useParams } from 'react-router-dom';
const Instructions = ({ interviewDetails }) => {
    const { role } = useParams();
    let customField = interviewDetails?.customField
        ? interviewDetails?.customField.length > 0
            ? interviewDetails?.customField.filter(
                  (itm) => itm.fieldValue !== '' && (itm.interviewerVisibility || role === 'admin')
              )
            : []
        : [];
    return (
        <div className="feedback-page h-100">
            {customField ? (
                customField.length ? (
                    <div className="instruction-div">
                        <div className="fp-title f-sem-bol">Additional Information</div>
                        <div className="instruction-text f-med">
                            <ul className="work-experiance-info f-l f-med">
                                {customField.map((data, index) => (
                                    <li key={index}>
                                        {index + 1}
                                        {'. '}
                                        {data?.fieldName}:{' '}
                                        {data?.fieldType === 'file' ? (
                                            <a
                                                href={
                                                    'https://incruiter-media.s3.amazonaws.com/client1/custom_field_files/' +
                                                    data?.fieldValue
                                                }
                                                className="text-primary cursor-pointer"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <span className="hyperlink">View File</span>
                                            </a>
                                        ) : (
                                            <>{data?.fieldValue}</>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ) : (
                    ''
                )
            ) : (
                ''
            )}
            {interviewDetails?.remarks && (
                <div className="instruction-div">
                    <div className="fp-title f-sem-bol">Basic Instructions</div>
                    <div className="instruction-text f-med">
                        <pre style={{ whiteSpace: 'pre-wrap' }} className="f-med">
                            {interviewDetails?.remarks}
                        </pre>
                        <br />
                        <br />
                    </div>
                </div>
            )}
            <div className="instruction-div">
                <div className="fp-title f-sem-bol">Interview Instructions</div>
                <div className="instruction-text f-med">
                    1. Use your laptop and not a smartphone for the Interview.
                    <br />
                    2. Check for good internet connectivity to avoid glitches and lag during the Interview.
                    <br />
                    3. Please ensure your free from a noisy background or surroundings.
                    <br /> 4. Login 5 mins early to ensure all of the above are in place.
                    <br />
                    <br />
                    Feel free to connect in case of any further clarifications are required.
                </div>
            </div>
            {interviewDetails?.summary && interviewDetails?.summary.length > 0 && (
                <>
                    <br />
                    <div className="instruction-div">
                        <div className="fp-title f-sem-bol">Interview Summary</div>
                        <div className="instruction-text f-med">
                            {interviewDetails?.summary.map((itm, key) => (
                                <span key={key}>{itm}</span>
                            ))}
                        </div>
                    </div>
                    <br />
                </>
            )}
        </div>
    );
};

export default Instructions;

import React from 'react';
import trash from '../../../images/trash.svg';

const ScreeningQuestionComponent = ({ data, index, handleRatingComment, role, setDeleteObject, list }) => {
    const deleteOption = data?.delete ? (data?.type === 'default' ? role === 'admin' : true) : data?.delete;
    return (
        <div className="text-left mb-10 pos-rel">
            <div className="f-12 f-med text-theme-white mb-10 wth-94">
                <pre className="code-content-pre">
                    <code style={{ fontFamily: 'Poppins' }}>
                        {index}. {data?.question}
                    </code>
                </pre>
                {deleteOption && (
                    <button
                        className="delete-btn-v2"
                        onClick={() => {
                            setDeleteObject({
                                list: list,
                                mode: 'question',
                                title: data?.question,
                                type: 'screeningQuestions',
                                position: data?.position,
                            });
                        }}
                    >
                        <img src={trash} className="delete-img-v2" alt="trash" />
                    </button>
                )}
            </div>
            <div className="input-textarea">
                <textarea
                    value={data?.comment}
                    className="w-100 final-remarks f-12 f-reg mt-2"
                    placeholder="Enter Your Answer Here..."
                    style={{ height: '60px' }}
                    onPaste={(e) => e.stopPropagation()}
                    onCut={(e) => e.stopPropagation()}
                    onCopy={(e) => e.stopPropagation()}
                    onChange={(e) =>
                        handleRatingComment('screeningQuestions', 'comment', e.target.value, data?.position)
                    }
                ></textarea>
                <span
                    className="clear-input"
                    onClick={() => handleRatingComment('screeningQuestions', 'comment', '', data?.position)}
                >
                    x
                </span>
                {data?.comment ? (
                    <span className="length-count2">{data?.comment.length}</span>
                ) : (
                    <span className="length-count2">0</span>
                )}
            </div>
        </div>
    );
};

export default ScreeningQuestionComponent;

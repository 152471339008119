import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    CodeSnippet,
    CodeSnippetImages,
    Footer,
    Header,
    OtherSkills,
    OverallSkills,
    Proctoring,
    Profile,
    Screenshots,
    Skills,
    SkillsComment,
} from '../components/MainComponents';
import { getSoftRating, getStatusV } from '../components/OtherComponents/Helpers';
import { getFeedback } from '../redux/action';
import { capitalizeAll } from '../utils/helpers';
import '../assets/css/index.css';
import '../assets/fonts/stylesheet.css';
const Other = React.lazy(() => import('../components/Pages/Other'));
function FeedbackRoom() {
    const { roomId, interviewId } = useParams();
    const [profileForm, setProfileForm] = useState({
        image: '',
        intId: '',
        name: '',
        position: '',
        company: '',
        experience: '',
        email: '',
        phone: '',
        suspectedProxy: false,
        candidateDrop: false,
        recommendedMode: 1,
        link: '',
    });
    const [feedbackForm, setFeedbackForm] = useState({
        proctoringScreenShots: [],
        proctoringMessage: [],
        FinalRemarksStatus: false,
        FinalRemarksData: '',
        ScreenshotsStatus: false,
        ScreenshotsData: [],
        ScreeningQuestionsStatus: false,
        ScreeningQuestionsData: [],
        CodeSnippetStatus: false,
        CodeSnippetData: '',
        SkillsCommentStatus: false,
        SkillsCommentData: [],
        SkillsStatus: false,
        SkillsData: [],
        OtherSkillsType: 1,
        OtherSkillsList: [],
        GlobalSkillRating: [],
        GlobalSkillName: [],
        proctoringLogs: {
            status: false,
            messages: [],
            screenshots: [],
        },
        skillOverAll: [
            {
                name: 'Mandatory Skills',
                type: 'mandatory',
                total: 0,
                length: 0,
            },
            { name: 'Optional Skills', type: 'optional', total: 0, length: 0 },
            { name: 'Soft Skills', type: 'soft', total: 0, length: 0 },
        ],
    });
    useEffect(() => {
        if (profileForm?.name) {
            document.title = `${capitalizeAll(profileForm?.name)} Feedback Report`;
        }
    }, [profileForm]);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.Interview.loading);
    const feedbackDetails = useSelector((state) => state.Interview.feedbackDetails);
    useEffect(() => {
        if (roomId && interviewId) {
            if (!feedbackDetails) {
                dispatch(getFeedback({ roomId, interviewId }));
            } else {
                const { interviewId: interview } = feedbackDetails;
                const { feedback } = interview;

                if (interview?.candidate) {
                    const { name, experience, email, phone } = interview.candidate;
                    const { jobTitle: position, scheduledDate: interviewDate } = interview;
                    const { employerName: company = 'InCruiter', logo: link = '' } = interview?.employer || {};

                    setProfileForm((prevState) => ({
                        ...prevState,
                        code: interview?.code,
                        name,
                        experience,
                        email,
                        phone,
                        position,
                        rating: feedback?.finalRating || 0,
                        company,
                        interviewDate,
                        status: feedback?.status,
                        link: interview?.employer?.status ? link : '',
                    }));
                }

                if (interview?.reference_id) {
                    setProfileForm((prevState) => ({
                        ...prevState,
                        intId: interview?.reference_id?.code,
                    }));
                }

                if (feedback) {
                    // Extract relevant feedback fields
                    const {
                        suspectedProxy,
                        candidateDrop = false,
                        finalRating = 0,
                        candidateImage: image,
                        resumeSummary = '',
                        finalRemark,
                        screenshots = [],
                        proctoring = {},
                        code = [],
                        codeImages = [],
                        skills = [],
                        screeningQuestions = [],
                    } = feedback;

                    const formattedResumeSummary = resumeSummary
                        .replace(/\\r/g, '')
                        .replace(/\\n/g, '')
                        .replace(/\\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
                        .replace(/\\s/g, '&nbsp;')
                        .replace('<br /><br />', '');

                    let skillRatingTmp = [];
                    let skillCommentTmp = [];
                    let screeningQuestionRatingTmp = [];
                    let otherSkillTmp = [];
                    let OtherSkillsTypeTmp = 1;
                    let [mc, mt, oc, ot, sc, st] = [0, 0, 0, 0, 0, 0];

                    skills.forEach((itm) => {
                        const { skillType, skill, rating, comment } = itm;
                        const parsedRating = parseInt(rating) || 0;

                        if (['mandatory', 'optional'].includes(skillType)) {
                            if (rating) {
                                skillRatingTmp.push({ name: skill, type: skillType, rating: parsedRating });
                                if (skillType === 'mandatory') {
                                    mc++;
                                    mt += parsedRating;
                                } else if (skillType === 'optional') {
                                    oc++;
                                    ot += parsedRating;
                                }
                            }
                            if (comment) {
                                skillCommentTmp.push({
                                    name: skill,
                                    type: skillType,
                                    color: getStatusV(parsedRating)?.color,
                                    rating: getStatusV(parsedRating)?.title,
                                    comment,
                                });
                            }
                        } else {
                            otherSkillTmp.push({ name: skill, type: skillType, rating: parsedRating });
                            if (skillType === 'leadership' && rating) OtherSkillsTypeTmp = 2;
                            if (rating) {
                                skillCommentTmp.push({
                                    name: skill,
                                    type: skillType,
                                    color: getSoftRating(skill, parsedRating)?.color,
                                    rating: getSoftRating(skill, parsedRating)?.value,
                                    comment: getSoftRating(skill, parsedRating)?.comment,
                                });
                                sc++;
                                st += parsedRating;
                            }
                        }
                    });

                    screeningQuestions.forEach((itm) => {
                        if (itm?.comment) {
                            screeningQuestionRatingTmp.push({
                                question: itm.question,
                                comment: itm.comment,
                            });
                        }
                    });

                    setProfileForm((prevState) => ({
                        ...prevState,
                        suspectedProxy,
                        candidateDrop,
                        recommendedMode: finalRating > 6 ? 1 : finalRating > 4 ? 2 : 3,
                        image,
                    }));

                    setFeedbackForm((prevState) => ({
                        ...prevState,
                        resumeSummary: formattedResumeSummary,
                        FinalRemarksStatus: Boolean(finalRemark),
                        FinalRemarksData: finalRemark,
                        ScreenshotsStatus: screenshots.length > 0,
                        proctoringLogs: {
                            status: Boolean(proctoring?.messages),
                            messages: proctoring?.messages || [],
                            screenshots: proctoring?.screenshots || [],
                        },
                        ScreenshotsData: screenshots,
                        ScreeningQuestionsStatus: screeningQuestionRatingTmp.length > 0,
                        ScreeningQuestionsData: screeningQuestionRatingTmp,
                        CodeSnippetStatus: Boolean(code.length && code[0]),
                        CodeSnippetData: code[0] || '',
                        codeImagesStatus: codeImages.length > 0,
                        codeImagesData: codeImages,
                        SkillsCommentStatus: skillCommentTmp.length > 0,
                        SkillsCommentData: skillCommentTmp,
                        SkillsStatus: skillRatingTmp.length > 0,
                        SkillsData: skillRatingTmp,
                        OtherSkillsType: OtherSkillsTypeTmp,
                        OtherSkillsList: otherSkillTmp,
                        skillOverAll: [
                            { name: 'Mandatory Skills', type: 'mandatory', total: mt, length: mc },
                            { name: 'Optional Skills', type: 'optional', total: ot, length: oc },
                            { name: 'Soft Skills', type: 'soft', total: st, length: sc },
                        ],
                    }));
                }
            }
        }
    }, [dispatch, roomId, interviewId, feedbackDetails]);

    const [, setWidth] = useState(window.innerWidth);
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return (
        <div className="App">
            {loading ? (
                <Loader />
            ) : feedbackDetails && feedbackDetails?.interviewId?.feedback ? (
                <>
                    <Header data={profileForm} />
                    <Profile data={profileForm} />
                    <OverallSkills data={feedbackForm} />
                    <OtherSkills data={feedbackForm} />
                    {feedbackForm?.SkillsStatus && <Skills data={feedbackForm?.SkillsData} />}
                    <SkillsComment
                        feedbackData={feedbackForm?.FinalRemarksData}
                        feedbackStatus={feedbackForm?.FinalRemarksStatus}
                        data={feedbackForm?.SkillsCommentData}
                        ScreeningQuestionsStatus={feedbackForm?.ScreeningQuestionsStatus}
                        screeningQuestionsData={feedbackForm?.ScreeningQuestionsData}
                    />
                    {feedbackForm?.CodeSnippetStatus && <CodeSnippet data={feedbackForm?.CodeSnippetData} />}
                    {feedbackForm?.codeImagesStatus && <CodeSnippetImages data={feedbackForm?.codeImagesData} />}
                    {feedbackForm?.ScreenshotsStatus && <Screenshots data={feedbackForm?.ScreenshotsData} />}
                    {feedbackForm?.proctoringLogs?.status && (
                        <Proctoring
                            message={feedbackForm?.proctoringLogs?.messages}
                            screenshots={feedbackForm?.proctoringLogs?.screenshots}
                        />
                    )}
                    <Footer data={profileForm} />
                </>
            ) : (
                <Other page="404Page" />
            )}
        </div>
    );
}

const Loader = memo(() => {
    return (
        <div className="wrapper">
            <div className="loader"></div>
        </div>
    );
});

export default FeedbackRoom;

import React from 'react';
import { toast } from 'react-toastify';
import { all, put, takeEvery } from 'redux-saga/effects';
import { ToastManager } from '../../components/Toast/ToastManager';
import {
    getExitFeedbackFailure,
    getExitFeedbackSuccess,
    getExitInterviewFailure,
    getExitInterviewSuccess,
    getExitRecordingFailure,
    getExitRecordingSuccess,
    getExitTokenFailure,
    getExitTokenSuccess,
    postExitFeedbackFailure,
    postExitFeedbackSuccess,
    postExitNoShowFeedbackFailure,
    postExitNoShowFeedbackSuccess,
} from '../action';
import {
    GET_EXIT_FEEDBACK,
    GET_EXIT_INTERVIEW,
    GET_EXIT_RECORDING,
    GET_EXIT_TOKEN,
    POST_EXIT_FEEDBACK,
    POST_EXIT_NOSHOW_FEEDBACK,
} from '../action/types';
import VIDEO from '../../utils/apiUrl/video';
import { Toast } from '../../utils/helpers';

function* getExitFeedbackRequest(action) {
    try {
        let res = yield VIDEO.get(`getInCExitInterview/${action.payload.interviewId}/${action.payload.roomId}`);
        if (res.status === 200 || res.status === 201) {
            yield put(getExitFeedbackSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getExitFeedbackFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

function* getExitRecordingRequest(action) {
    try {
        let res = yield VIDEO.get(`getInCExitRecording/${action.payload.interviewId}/${action.payload.roomId}`);
        if (res.status === 200 || res.status === 201) {
            yield put(getExitRecordingSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getExitRecordingFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

function* getExitTokenRequest(action) {
    try {
        let res = yield VIDEO.get(`getInterviewToken/${action.payload.roomId}`);
        if (res.status === 200) {
            yield put(getExitTokenSuccess(res.data.result));
            localStorage.setItem('token', res.data.result?.token);
        }
    } catch (e) {
        yield put(getExitTokenFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

function* getExitInterviewRequest(action) {
    try {
        const res = yield VIDEO.get(`getInCExitInterviewDetails/${action.payload.interviewId}`);
        if (res.status === 200) {
            yield put(getExitInterviewSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getExitInterviewFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

function* postExitFeedbackRequest(action) {
    try {
        let res = yield VIDEO.post(`addInCExitFeedback/${action.payload.interviewId}`, action.payload.data);
        if (res.status === 201) {
            yield put(postExitFeedbackSuccess(res.data));
            if (action.payload.data?.type !== 'autosave') {
                ToastManager.addToast({
                    title: 'Feedback Submitted successfully.',
                });
            }
        }
    } catch (e) {
        yield put(postExitFeedbackFailure());
        yield e?.response?.data?.message;
    }
}

function* postExitNoShowFeedbackRequest(action) {
    try {
        let res = yield VIDEO.post(`noshowInCExit/${action.payload.interviewId}`, action.payload.data);
        if (res.status === 201) {
            yield put(postExitNoShowFeedbackSuccess(res.data));
            if (action.payload.data?.type !== 'autosave') {
                ToastManager.addToast({
                    title: 'Feedback Submitted successfully.',
                });
            }
        }
    } catch (e) {
        yield put(postExitNoShowFeedbackFailure());
        yield e?.response?.data?.message;
    }
}

export function* watchGetExitFeedbackRequestAPI() {
    yield takeEvery(GET_EXIT_FEEDBACK, getExitFeedbackRequest);
}
export function* watchGetExitRecordingRequestAPI() {
    yield takeEvery(GET_EXIT_RECORDING, getExitRecordingRequest);
}
export function* watchGetExitInterviewRequestAPI() {
    yield takeEvery(GET_EXIT_INTERVIEW, getExitInterviewRequest);
}
export function* watchGetExitTokenRequestAPI() {
    yield takeEvery(GET_EXIT_TOKEN, getExitTokenRequest);
}
export function* watchPostExitFeedbackRequestAPI() {
    yield takeEvery(POST_EXIT_FEEDBACK, postExitFeedbackRequest);
}
export function* watchPostExitNoShowFeedbackRequestAPI() {
    yield takeEvery(POST_EXIT_NOSHOW_FEEDBACK, postExitNoShowFeedbackRequest);
}

export default function* rootSaga() {
    yield all([
        watchGetExitFeedbackRequestAPI(),
        watchGetExitRecordingRequestAPI(),
        watchGetExitInterviewRequestAPI(),
        watchGetExitTokenRequestAPI(),
        watchPostExitFeedbackRequestAPI(),
        watchPostExitNoShowFeedbackRequestAPI(),
    ]);
}

import React from 'react';
import _debounce from 'lodash/debounce';
import NewQuestionInput from './NewQuestionInput';
import ScreeningQuestionComponent from './ScreeningQuestionComponent';

const ScreeningQuestions = ({
    data,
    setQuestion,
    question,
    handleAdd,
    handleRatingComment,
    setDeleteObject,
    interviewDetails,
    role,
}) => {
    return (
        <div className="sq-container mr-10 ml-10">
            <div className="fp-title f-sem-bol">Screening Questions</div>
            <div className="sqc-container pr-10 pl-10">
                {data &&
                    data.length > 0 &&
                    data.map((item, i) => (
                        <ScreeningQuestionComponent
                            key={i}
                            data={item}
                            list={data}
                            index={i + 1}
                            handleRatingComment={handleRatingComment}
                            role={role}
                            setDeleteObject={setDeleteObject}
                        />
                    ))}
                <NewQuestionInput setQuestion={setQuestion} question={question} interviewDetails={interviewDetails} />
                <div
                    className="text-align-right"
                    onClick={_debounce(() => {
                        handleAdd(data, 'screeningQuestions', 'screeningQuestions');
                    }, 300)}
                >
                    <span className="f-12 f-sem-bol add-text-option cursor-pointer">+ Add Question</span>
                </div>
            </div>
        </div>
    );
};

export default ScreeningQuestions;

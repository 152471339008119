import {
    GET_MEET_INFO,
    GET_MEET_INFO_FAILURE,
    GET_MEET_INFO_SUCCESS,
    GET_REGENERATED_SUMMARY,
    GET_REGENERATED_SUMMARY_FAILURE,
    GET_REGENERATED_SUMMARY_SUCCESS,
    GET_TRANSCRIPT,
    GET_TRANSCRIPT_FAILURE,
    GET_TRANSCRIPT_SUCCESS,
} from './types';

export const getMeetInfo = (payload) => ({
    type: GET_MEET_INFO,
    payload,
});
export const getMeetInfoSuccess = (payload) => ({
    type: GET_MEET_INFO_SUCCESS,
    payload,
});
export const getMeetInfoFailure = () => ({
    type: GET_MEET_INFO_FAILURE,
});

export const getRegeneratedSummary = (payload) => ({
    type: GET_REGENERATED_SUMMARY,
    payload,
});
export const getRegeneratedSummarySuccess = () => ({
    type: GET_REGENERATED_SUMMARY_SUCCESS,
});
export const getRegeneratedSummaryFailure = () => ({
    type: GET_REGENERATED_SUMMARY_FAILURE,
});

export const getTranscript = (payload) => ({
    type: GET_TRANSCRIPT,
    payload,
});
export const getTranscriptSuccess = () => ({
    type: GET_TRANSCRIPT_SUCCESS,
});
export const getTranscriptFailure = () => ({
    type: GET_TRANSCRIPT_FAILURE,
});

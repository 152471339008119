import React, { useEffect, useState } from 'react';
import { Box, Flex, styled, Text } from '@100mslive/roomkit-react';
import interviewCompleted from '../../assets/images/interview-completed.svg';
import rightTick from '../../assets/images/right-tick.svg';
import logo from '../../images/incruiter-logo.svg';

const FeedInterviewComplete = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const renderContent = () => (
        <Flex css={{ size: '100%' }} direction="column">
            <ContentWrapper screenWidth={screenWidth}>
                <ContentBox>
                    <img src={interviewCompleted} alt="Feedback submitted successfully" />
                    <Message>
                        <img src={rightTick} alt="Success" />
                        <span>Feedback submitted successfully!</span>
                    </Message>
                </ContentBox>
            </ContentWrapper>
        </Flex>
    );

    const renderMain = () => (
        <Flex css={{ size: '100%' }} direction="column">
            <Header>
                <Logo src={logo} alt="InCruiter Logo" />
            </Header>
            <MainContainer>
                <ContentArea screenWidth={screenWidth}>{renderContent()}</ContentArea>
            </MainContainer>
        </Flex>
    );

    return renderMain();
};

// Styled Components
const ContentWrapper = styled(Flex, {
    height: '100%',
    justifyContent: 'center',
    '@media (max-width: 768px)': {
        justifyContent: 'flex-start',
        height: '98.5%',
    },
});

const ContentBox = styled(Flex, {
    background: '#191924',
    color: '#FFFFFF',
    width: '98%',
    margin: '1%',
    height: '98%',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
    '@media (max-width: 768px)': {
        height: '100%',
    },
});

const Message = styled(Text, {
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '25px',
    letterSpacing: '0.04em',
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
});

const Header = styled(Box, {
    height: '$18',
    '@media (max-width: 768px)': {
        height: '$17',
    },
    padding: '11px 24px',
});

const Logo = styled('img', {
    width: 'auto',
    maxHeight: '42px',
    '@media (max-width: 768px)': {
        maxHeight: '36px',
    },
});

const MainContainer = styled(Box, {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    minHeight: 0,
});

const ContentArea = styled(Box, {
    flex: 1,
    width: '100%',
    height: '100%',
    background: '#2E2E41',
    borderRadius: '8px',
    '@media (max-width: 768px)': {
        width: '100%',
    },
});

export default FeedInterviewComplete;

import {
    ADD_SCREENSHOT,
    ADD_SCREENSHOT_FAILURE,
    ADD_SCREENSHOT_SUCCESS,
    CANDIDATE_CALL_END,
    CANDIDATE_CALL_END_FAILURE,
    CANDIDATE_CALL_END_SUCCESS,
    CODE_EDITOR_LANGUAGE,
    CODE_EDITOR_LANGUAGE_FAILURE,
    CODE_EDITOR_LANGUAGE_SUCCESS,
    CONTACT_ADMIN,
    CONTACT_ADMIN_FAILURE,
    CONTACT_ADMIN_SUCCESS,
    CREATE_AND_UPDATE_SCREENSHOT,
    CREATE_AND_UPDATE_SCREENSHOT_FAILURE,
    CREATE_AND_UPDATE_SCREENSHOT_SUCCESS,
    CREATE_CODE_SUBMISSION,
    CREATE_CODE_SUBMISSION_FAILURE,
    CREATE_CODE_SUBMISSION_SUCCESS,
    DELETE_RECORDING,
    DELETE_RECORDING_FAILURE,
    DELETE_RECORDING_SUCCESS,
    FETCH_SCREENSHOT_IMAGE,
    FETCH_SCREENSHOT_IMAGE_FAILURE,
    FETCH_SCREENSHOT_IMAGE_TYPE1_SUCCESS,
    FETCH_SCREENSHOT_IMAGE_TYPE2_SUCCESS,
    FETCH_SCREENSHOT_IMAGE_TYPE3_SUCCESS,
    FETCH_SCREENSHOT_IMAGE_TYPE4_SUCCESS,
    FETCH_SCREENSHOT_IMAGE_TYPE5_SUCCESS,
    FETCH_SCREENSHOT_IMAGEV2,
    FETCH_SCREENSHOT_IMAGEV2_FAILURE,
    FETCH_SCREENSHOT_IMAGEV2_SUCCESS,
    FETCH_SCREENSHOT_IMAGEV3,
    FETCH_SCREENSHOT_IMAGEV3_FAILURE,
    FETCH_SCREENSHOT_IMAGEV3_SUCCESS,
    FETCH_TRANSCRIPT,
    FETCH_TRANSCRIPT_FAILURE,
    FETCH_TRANSCRIPT_SUCCESS,
    GET_ALL_SCREENSHOT,
    GET_ALL_SCREENSHOT_FAILURE,
    GET_ALL_SCREENSHOT_SUCCESS,
    GET_CANDIDATE_INTERVIEW_DETAILS,
    GET_CANDIDATE_INTERVIEW_DETAILS_FAILURE,
    GET_CANDIDATE_INTERVIEW_DETAILS_SUCCESS,
    GET_CODE_SUBMISSION,
    GET_CODE_SUBMISSION_FAILURE,
    GET_CODE_SUBMISSION_SUCCESS,
    GET_CROP_IMAGE,
    GET_CROP_IMAGE_FAILURE,
    GET_CROP_IMAGE_SUCCESS,
    GET_FEEDBACK,
    GET_FEEDBACK_FAILURE,
    GET_FEEDBACK_SUCCESS,
    GET_INTERVIEW_DETAILS,
    GET_INTERVIEW_DETAILS_FAILURE,
    GET_INTERVIEW_DETAILS_SUCCESS,
    GET_INTERVIEW_TOKEN,
    GET_INTERVIEW_TOKEN_FAILURE,
    GET_INTERVIEW_TOKEN_SUCCESS,
    GET_INTERVIEWER_INTERVIEW_DETAILS,
    GET_INTERVIEWER_INTERVIEW_DETAILS_FAILURE,
    GET_INTERVIEWER_INTERVIEW_DETAILS_SUCCESS,
    GET_RECORDING_LIST,
    GET_RECORDING_LIST_FAILURE,
    GET_RECORDING_LIST_SUCCESS,
    GET_REGENERATED_AI_SUMMARY,
    GET_REGENERATED_AI_SUMMARY_FAILURE,
    GET_REGENERATED_AI_SUMMARY_SUCCESS,
    GET_SCREENSHOT,
    GET_SCREENSHOT_FAILURE,
    GET_SCREENSHOT_SUCCESS,
    GET_SKILLS,
    GET_SKILLS_FAILURE,
    GET_SKILLS_SUCCESS,
    GET_URL_GENERATED_ID,
    GET_URL_GENERATED_ID_FAILURE,
    GET_URL_GENERATED_ID_SUCCESS,
    INTERVIEWER_CALL_END,
    INTERVIEWER_CALL_END_FAILURE,
    INTERVIEWER_CALL_END_SUCCESS,
    MOVEMENT,
    MOVEMENT_FAILURE,
    MOVEMENT_SUCCESS,
    NOTIFY_INTERVIEWER,
    NOTIFY_INTERVIEWER_FAILURE,
    NOTIFY_INTERVIEWER_SUCCESS,
    POST_CAR_FEEDBACK_DETAILS,
    POST_CAR_FEEDBACK_DETAILS_FAILURE,
    POST_CAR_FEEDBACK_DETAILS_SUCCESS,
    POST_FEEDBACK_DETAILS,
    POST_FEEDBACK_DETAILS_FAILURE,
    POST_FEEDBACK_DETAILS_SUCCESS,
    POST_INTERVIEW_FEEDBACK,
    POST_INTERVIEW_FEEDBACK_FAILURE,
    POST_INTERVIEW_FEEDBACK_SUCCESS,
    POST_NOSHOW_FEEDBACK_DETAILS,
    POST_NOSHOW_FEEDBACK_DETAILS_FAILURE,
    POST_NOSHOW_FEEDBACK_DETAILS_SUCCESS,
    QUESTION_BANK,
    QUESTION_BANK_FAILURE,
    QUESTION_BANK_LIST,
    QUESTION_BANK_LIST_FAILURE,
    QUESTION_BANK_LIST_SUCCESS,
    QUESTION_BANK_SUCCESS,
    REMOVE_SCREENSHOT_IMAGE,
    ROOM_UPDATE,
    ROOM_UPDATE_FAILURE,
    ROOM_UPDATE_SUCCESS,
    SET_SHOW_FEEDBACK_PORTAL,
    SINGLE_CODE_EDITOR_LANGUAGE,
    SINGLE_CODE_EDITOR_LANGUAGE_FAILURE,
    SINGLE_CODE_EDITOR_LANGUAGE_SUCCESS,
    START_MEETING,
    START_MEETING_FAILURE,
    START_MEETING_SUCCESS,
    SWITCH_TAB,
    SWITCH_TAB_FAILURE,
    SWITCH_TAB_SUCCESS,
    VERIFY_INTERVIEW_KEY,
    VERIFY_INTERVIEW_KEY_FAILURE,
    VERIFY_INTERVIEW_KEY_SUCCESS,
    VIDEO_UPLOAD,
    VIDEO_UPLOAD_FAILURE,
    VIDEO_UPLOAD_SUCCESS,
} from '../action/types';

const INIT_STATE = {
    loading: false,
    submitLoading: false,
    skills: null,
    interviewDetails: null,
    codeEditorLanguageList: null,
    codeEditorLangDetails: null,
    codeSubmissionOutput: null,
    interviewTokenDetails: null,
    showFeedback: true,
    questionBank: null,
    languageList: null,
    verifyKey: null,
    fetchImage1: null,
    fetchImage2: null,
    fetchImage3: null,
    fetchImage4: null,
    fetchImage5: null,
    fetchImagev2: null,
    fetchImagev3: null,
    feedbackDetails: null,
    recordingLists: null,
    screenshotLists: null,
    allScreenshots: null,
    cropImage: null,
    meetingInfo: null,
    screenshotsList: null,
    urlGeneratedId: null,
    urlGeneratedIdLoader: null,
    regeneratedAiSummary: null,
    regeneratedAiSummaryLoader: false,
    fetchLoading: false,
    fetchLoadingFail: false,
    isUploadingVideo: 'NO',
};

const Interview = (state = INIT_STATE, action) => {
    switch (action.type) {
        case POST_CAR_FEEDBACK_DETAILS:
            return { ...state, loading: true };
        case POST_CAR_FEEDBACK_DETAILS_SUCCESS:
            return { ...state, loading: false };
        case POST_CAR_FEEDBACK_DETAILS_FAILURE:
            return { ...state, loading: false };

        case ROOM_UPDATE:
            return { ...state, loading: false };
        case ROOM_UPDATE_SUCCESS:
            return { ...state, loading: false };
        case ROOM_UPDATE_FAILURE:
            return { ...state, loading: false };

        case POST_NOSHOW_FEEDBACK_DETAILS:
            return { ...state, loading: true };
        case POST_NOSHOW_FEEDBACK_DETAILS_SUCCESS:
            return { ...state, loading: false };
        case POST_NOSHOW_FEEDBACK_DETAILS_FAILURE:
            return { ...state, loading: false };

        case NOTIFY_INTERVIEWER:
            return { ...state, loading: true };
        case NOTIFY_INTERVIEWER_SUCCESS:
            return { ...state, loading: false };
        case NOTIFY_INTERVIEWER_FAILURE:
            return { ...state, loading: false };

        case SWITCH_TAB:
            return { ...state, loading: false };
        case SWITCH_TAB_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case SWITCH_TAB_FAILURE:
            return { ...state, loading: false };

        case MOVEMENT:
            return { ...state, loading: true };
        case MOVEMENT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case MOVEMENT_FAILURE:
            return { ...state, loading: false };

        case GET_CROP_IMAGE:
            return { ...state, loading: true };
        case GET_CROP_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                cropImage: action.payload,
            };
        case GET_CROP_IMAGE_FAILURE:
            return { ...state, loading: false };

        case GET_SCREENSHOT:
            return { ...state, loading: true };
        case GET_SCREENSHOT_SUCCESS:
            return {
                ...state,
                loading: false,
                screenshotLists: action.payload,
            };
        case GET_SCREENSHOT_FAILURE:
            return { ...state, loading: false };

        case GET_ALL_SCREENSHOT:
            return { ...state, loading: true };
        case GET_ALL_SCREENSHOT_SUCCESS:
            return {
                ...state,
                loading: false,
                allScreenshots: action.payload,
            };
        case GET_ALL_SCREENSHOT_FAILURE:
            return { ...state, loading: false };

        case CREATE_AND_UPDATE_SCREENSHOT:
            return { ...state, loading: true };
        case CREATE_AND_UPDATE_SCREENSHOT_SUCCESS:
            return {
                ...state,
                loading: false,
                screenshotLists: action.payload,
            };
        case CREATE_AND_UPDATE_SCREENSHOT_FAILURE:
            return { ...state, loading: false };

        case ADD_SCREENSHOT:
            return { ...state, loading: true };
        case ADD_SCREENSHOT_SUCCESS:
            return {
                ...state,
                loading: false,
                screenshotsList: action.payload,
            };
        case ADD_SCREENSHOT_FAILURE:
            return { ...state, loading: false };

        case GET_RECORDING_LIST:
            return { ...state, loading: true };
        case GET_RECORDING_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                recordingLists: action.payload,
            };
        case GET_RECORDING_LIST_FAILURE:
            return { ...state, loading: false };

        case DELETE_RECORDING:
            return { ...state, loading: true };
        case DELETE_RECORDING_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case DELETE_RECORDING_FAILURE:
            return { ...state, loading: false };

        case CANDIDATE_CALL_END:
            return { ...state, loading: true };
        case CANDIDATE_CALL_END_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case CANDIDATE_CALL_END_FAILURE:
            return { ...state, loading: false };

        case INTERVIEWER_CALL_END:
            return { ...state, loading: true };
        case INTERVIEWER_CALL_END_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case INTERVIEWER_CALL_END_FAILURE:
            return { ...state, loading: false };

        case VERIFY_INTERVIEW_KEY:
            return { ...state, loading: true };
        case VERIFY_INTERVIEW_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                verifyKey: action.payload,
            };
        case VERIFY_INTERVIEW_KEY_FAILURE:
            return { ...state, loading: false };

        case REMOVE_SCREENSHOT_IMAGE:
            return {
                ...state,
                loading: false,
                fetchImage3: null,
            };

        case FETCH_SCREENSHOT_IMAGE:
            return {
                ...state,
                loading: false,
                fetchImage1: null,
                fetchImage2: null,
                fetchImage3: null,
                fetchImage4: null,
                fetchImage5: null,
            };
        case FETCH_SCREENSHOT_IMAGE_TYPE1_SUCCESS:
            return {
                ...state,
                loading: false,
                fetchImage1: action.payload,
            };
        case FETCH_SCREENSHOT_IMAGE_TYPE2_SUCCESS:
            return {
                ...state,
                loading: false,
                fetchImage2: action.payload,
            };
        case FETCH_SCREENSHOT_IMAGE_TYPE3_SUCCESS:
            return {
                ...state,
                loading: false,
                fetchImage3: action.payload,
            };
        case FETCH_SCREENSHOT_IMAGE_TYPE4_SUCCESS:
            return {
                ...state,
                loading: false,
                fetchImage4: action.payload,
            };
        case FETCH_SCREENSHOT_IMAGE_TYPE5_SUCCESS:
            return {
                ...state,
                loading: false,
                fetchImage5: action.payload,
            };
        case FETCH_SCREENSHOT_IMAGE_FAILURE:
            return { ...state, loading: false };

        case FETCH_SCREENSHOT_IMAGEV2:
            return { ...state, loading: false };
        case FETCH_SCREENSHOT_IMAGEV2_SUCCESS:
            return {
                ...state,
                loading: false,
                fetchImagev2: action.payload,
            };
        case FETCH_SCREENSHOT_IMAGEV2_FAILURE:
            return { ...state, loading: false };

        case FETCH_SCREENSHOT_IMAGEV3:
            return { ...state, loading: false };
        case FETCH_SCREENSHOT_IMAGEV3_SUCCESS:
            return {
                ...state,
                loading: false,
                fetchImagev3: action.payload,
            };
        case FETCH_SCREENSHOT_IMAGEV3_FAILURE:
            return { ...state, loading: false };

        case QUESTION_BANK:
            return { ...state, loading: true };
        case QUESTION_BANK_SUCCESS:
            return {
                ...state,
                loading: false,
                questionBank: action.payload,
            };
        case QUESTION_BANK_FAILURE:
            return { ...state, loading: false };

        case QUESTION_BANK_LIST:
            return { ...state, loading: true };
        case QUESTION_BANK_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                languageList: action.payload,
            };
        case QUESTION_BANK_LIST_FAILURE:
            return { ...state, loading: false };

        case GET_INTERVIEW_TOKEN:
            return { ...state, loading: true };
        case GET_INTERVIEW_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                interviewTokenDetails: action.payload,
            };
        case GET_INTERVIEW_TOKEN_FAILURE:
            return { ...state, loading: false };

        case GET_SKILLS:
            return { ...state, loading: true };
        case GET_SKILLS_SUCCESS:
            return { ...state, loading: false, skills: action.payload };
        case GET_SKILLS_FAILURE:
            return { ...state, loading: false };

        case GET_FEEDBACK:
            return { ...state, loading: true };
        case GET_FEEDBACK_SUCCESS:
            return {
                ...state,
                loading: false,
                feedbackDetails: action.payload,
            };
        case GET_FEEDBACK_FAILURE:
            return { ...state, loading: false };

        case GET_CODE_SUBMISSION:
            return { ...state, loading: true };
        case GET_CODE_SUBMISSION_SUCCESS:
            return { ...state, loading: false, codeToSubmit: action.payload };
        case GET_CODE_SUBMISSION_FAILURE:
            return { ...state, loading: false };

        case CREATE_CODE_SUBMISSION:
            return { ...state, submitLoading: true };
        case CREATE_CODE_SUBMISSION_FAILURE:
            return { ...state, submitLoading: false };
        case CREATE_CODE_SUBMISSION_SUCCESS:
            return {
                ...state,
                submitLoading: false,
                codeSubmissionOutput: action.payload,
            };

        case SINGLE_CODE_EDITOR_LANGUAGE:
            return { ...state, loading: true };
        case SINGLE_CODE_EDITOR_LANGUAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                codeEditorLangDetails: action.payload,
            };
        case SINGLE_CODE_EDITOR_LANGUAGE_FAILURE:
            return { ...state, loading: false };

        case CODE_EDITOR_LANGUAGE:
            return { ...state, loading: true };
        case CODE_EDITOR_LANGUAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                codeEditorLanguageList: action.payload,
            };
        case CODE_EDITOR_LANGUAGE_FAILURE:
            return { ...state, loading: false };

        case POST_INTERVIEW_FEEDBACK:
            return { ...state, loading: true };
        case POST_INTERVIEW_FEEDBACK_SUCCESS:
            return { ...state, loading: false };
        case POST_INTERVIEW_FEEDBACK_FAILURE:
            return { ...state, loading: false };

        case CONTACT_ADMIN:
            return { ...state, loading: true };
        case CONTACT_ADMIN_SUCCESS:
            return { ...state, loading: false };
        case CONTACT_ADMIN_FAILURE:
            return { ...state, loading: false };

        case POST_FEEDBACK_DETAILS:
            return { ...state, loading: false };
        case POST_FEEDBACK_DETAILS_SUCCESS:
            return { ...state, loading: false };
        case POST_FEEDBACK_DETAILS_FAILURE:
            return { ...state, loading: false };

        case GET_INTERVIEW_DETAILS:
            return { ...state, loading: true };
        case GET_INTERVIEW_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                interviewDetails: action.payload,
            };
        case GET_INTERVIEW_DETAILS_FAILURE:
            return { ...state, loading: false };

        case GET_CANDIDATE_INTERVIEW_DETAILS:
            return { ...state, loading: true };
        case GET_CANDIDATE_INTERVIEW_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                interviewDetails: action.payload,
            };
        case GET_CANDIDATE_INTERVIEW_DETAILS_FAILURE:
            return { ...state, loading: false };

        case GET_INTERVIEWER_INTERVIEW_DETAILS:
            return { ...state, loading: true };
        case GET_INTERVIEWER_INTERVIEW_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                interviewDetails: action.payload,
            };
        case GET_INTERVIEWER_INTERVIEW_DETAILS_FAILURE:
            return { ...state, loading: false };

        case SET_SHOW_FEEDBACK_PORTAL:
            return { ...state, showFeedback: action.payload };

        case START_MEETING:
            return {
                ...state,
            };
        case START_MEETING_SUCCESS:
            return {
                ...state,
                meetingInfo: action.payload,
            };
        case START_MEETING_FAILURE:
            return {
                ...state,
            };
        case GET_URL_GENERATED_ID:
            return { ...state, urlGeneratedIdLoader: true };
        case GET_URL_GENERATED_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                urlGeneratedId: action.payload,
                urlGeneratedIdLoader: false,
            };
        case GET_URL_GENERATED_ID_FAILURE:
            return { ...state, urlGeneratedIdLoader: false };
        case GET_REGENERATED_AI_SUMMARY:
            return { ...state, regeneratedAiSummaryLoader: true };
        case GET_REGENERATED_AI_SUMMARY_SUCCESS:
            return {
                ...state,
                regeneratedAiSummaryLoader: false,
                regeneratedAiSummary: action.payload,
            };
        case GET_REGENERATED_AI_SUMMARY_FAILURE:
            return { ...state, regeneratedAiSummaryLoader: false };
        case FETCH_TRANSCRIPT:
            return { ...state, fetchLoading: true, fetchLoadingFail: false };
        case FETCH_TRANSCRIPT_SUCCESS:
            return {
                ...state,
                fetchLoading: false,
                fetchLoadingFail: false,
            };
        case FETCH_TRANSCRIPT_FAILURE:
            return {
                ...state,
                fetchLoading: false,
                fetchLoadingFail: true,
            };
        case VIDEO_UPLOAD:
            return {
                ...state,
                isUploadingVideo: 'YES',
            };
        case VIDEO_UPLOAD_SUCCESS:
            return {
                ...state,
                isUploadingVideo: 'SUCCESS',
            };
        case VIDEO_UPLOAD_FAILURE:
            return {
                ...state,
                isUploadingVideo: 'FAILURE',
            };
        default:
            return state;
    }
};
export default Interview;

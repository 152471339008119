import React from 'react';
const ToolTip = ({ title, message, className }) => {
    return (
        <div className={`tool-tip-div ${className}`}>
            <p className="tool-tip-title">{title}</p>
            <p className="tool-tip-message">{message}</p>
            <div className="tool-tip-arrow"></div>
        </div>
    );
};
export default ToolTip;

import React from 'react';
export const FinalRemarks = ({ data }) => {
    let finalData = data?.replace(/\*\*/g, '');
    return (
        <div className="detailed-feedback-list break-stop">
            <div className="soft-skill-wrapperv2">
                <div className="font-style-header-soft-skill soft-skill-header-600">Final Remarks</div>
                <div className="soft-skill-header-outer">
                    <div className="soft-skill-header-type-1 font-style-header-soft-skill">Final Remarks</div>
                </div>
                <div className="soft-skill-content">
                    <pre className="font-style-soft-skill" style={{ whiteSpace: 'pre-wrap' }}>
                        <code
                            style={{
                                display: 'block',
                                width: '100%',
                                fontFamily: 'Poppins',
                            }}
                            dangerouslySetInnerHTML={{
                                __html: `${finalData?.replace('**', '')}`,
                            }}
                        />
                    </pre>
                </div>
            </div>
        </div>
    );
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Tooltip } from '@100mslive/roomkit-react';
import Transcript from '../../components/Pages/FeedbackComponent/Transcript';
import TranscriptModal from '../../components/TranscriptModal/TranscriptModal';
import transcriptFail from '../../assets/images/transcript_fail.svg';
import transcriptLoader from '../../assets/images/transcript_loader.gif';
import { getTranscript } from '../../redux/action';
const MeetTranscript = ({ roomId, meetInfo }) => {
    const dispatch = useDispatch();
    const fetchLoading = useSelector((state) => state.Meet.fetchLoading);
    const fetchLoadingFail = useSelector((state) => state.Meet.fetchLoadingFail);
    const [fetchBtnClicked, setFetchBtnClicked] = useState(false);
    const [feedbackForm, setFeedbackForm] = useState({});
    const [fetchTranscriptFail, setFetchTranscriptFail] = useState(fetchLoadingFail);

    useEffect(() => {
        if (meetInfo) setFeedbackForm(meetInfo);
    }, [meetInfo]);

    useEffect(() => {
        setFetchTranscriptFail(fetchLoadingFail);
    }, [fetchLoadingFail]);

    const handleFetchTranscript = () => {
        setFetchBtnClicked(true);
        dispatch(getTranscript({ roomId }));
    };

    if (!feedbackForm?.transcript?.length) {
        return (
            <div className="w-100" style={{ backgroundColor: 'rgb(46, 46, 65)', height: '100%' }}>
                <div className="no-transcript-parent">
                    <div className="no-transcript-div">
                        <Text
                            variant="body1"
                            css={{
                                fontStyle: 'normal',
                                fontWeight: '300',
                                fontSize: '12px',
                                lineHeight: '18px',
                                letterSpacing: '5%',
                                color: '#FFFFFF',
                                textAlign: 'left',
                                fontFamily: 'Poppins !important',
                                '@md': { fontSize: '12px' },
                            }}
                        >
                            Transcription will be available after 30 minutes of meeting completion.
                        </Text>
                        <Tooltip
                            disabled={!fetchBtnClicked}
                            title={fetchBtnClicked ? 'Transcript can be generated once per session' : ''}
                        >
                            <button
                                className={`generate-transcript-btn ${fetchBtnClicked && 'disable-transcript-btn'}`}
                                disabled={fetchBtnClicked}
                                onClick={handleFetchTranscript}
                            >
                                Generate Transcript
                            </button>
                        </Tooltip>
                    </div>
                    {fetchLoading && (
                        <TranscriptModal
                            img={transcriptLoader}
                            text="We are generating transcript. Please wait a moment!"
                        />
                    )}
                    {fetchTranscriptFail && (
                        <TranscriptModal
                            img={transcriptFail}
                            imgClass="transcriptFail_img"
                            text="We are unable to generate transcript now. Please try again later."
                            btnText="Ok"
                            btnClick={() => setFetchTranscriptFail(false)}
                        />
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className="transcript-main-container">
            <div className="tandScontentContainer">
                <Tooltip
                    disabled={!fetchBtnClicked}
                    title={fetchBtnClicked ? 'Transcript can be generated once per session' : ''}
                >
                    <button
                        className={`generate-transcript-btn ${fetchBtnClicked && 'disable-transcript-btn'}`}
                        disabled={fetchBtnClicked}
                        onClick={handleFetchTranscript}
                        style={{ float: 'right' }}
                    >
                        Generate Transcript
                    </button>
                </Tooltip>
                <Transcript
                    urls={feedbackForm?.transcript}
                    status={!!feedbackForm?.transcript.length}
                    fromMain={false}
                />
            </div>
        </div>
    );
};

export default MeetTranscript;

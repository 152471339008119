import React from 'react';
import CreatableSelect from 'react-select/creatable';
import _debounce from 'lodash/debounce';
import trash from '../../../images/trash.svg';
import CustomSelect from './CustomSelect';
const SkillInput = ({
    list,
    data,
    MasterSkills,
    setDeleteObject,
    handleChangeSkill,
    handleRatingComment,
    handleCommentBoxVisible,
    type,
    handleAdd,
    len,
    role,
}) => {
    const styles = {
        menu: (provided, state) => ({
            ...provided,
            zIndex: 9999,
        }),
        option: (provided) => ({
            ...provided,
            color: '#2e2e41',
        }),
    };
    let deleteOption = data?.delete ? (data?.type === 'default' ? role === 'admin' : true) : data?.delete;
    return (
        <div className="skill-input">
            <div className="si-row-1 d-flex align-items-center justify-content-between">
                <div
                    style={{
                        position: 'relative',
                        width: '64%',
                        display: 'inline-block',
                        height: '38px',
                        float: 'left',
                    }}
                >
                    <CreatableSelect
                        className={`w-100 f-reg select-input-container${deleteOption ? '' : ' input-disabled'}`}
                        value={{ label: data.skill, value: data.skill }}
                        onChange={(e) => handleChangeSkill(list, e.value, data?.position)}
                        options={MasterSkills}
                        menuPlacement="auto"
                        styles={styles}
                        inputClass="f-l f-reg input-shadow"
                        isDisabled={!deleteOption}
                    />
                    {}
                    {deleteOption && (
                        <button
                            className="delete-skill"
                            onClick={() =>
                                setDeleteObject({
                                    mode: 'skill',
                                    title: data?.skill,
                                    list: list,
                                    type: 'skills',
                                    position: data?.position,
                                })
                            }
                        >
                            <img src={trash} className="delete-img" alt="Delete" />
                        </button>
                    )}
                </div>
                <CustomSelect
                    name="skill-rating"
                    className="w-35 f-reg select-input-container"
                    value={{
                        label: data?.rating !== '' ? parseInt(data?.rating) : 'Rating',
                        value: data?.rating !== '' ? parseInt(data?.rating) : '',
                    }}
                    onChange={(e) => handleRatingComment('skills', 'rating', e.value, data?.position)}
                    inputClass="f-l f-reg input-shadow"
                />
            </div>
            {data.commentBoxVisible ? (
                <>
                    <div className="si-row-2">
                        <textarea
                            className="w-100 skill-remarks"
                            placeholder="Add Comments..."
                            value={data?.comment}
                            onPaste={(e) => e.stopPropagation()}
                            onCut={(e) => e.stopPropagation()}
                            onCopy={(e) => e.stopPropagation()}
                            onChange={(e) => handleRatingComment('skills', 'comment', e.target.value, data?.position)}
                        ></textarea>
                        <span
                            className="clear-input"
                            onClick={(e) => handleRatingComment('skills', 'comment', '', data?.position)}
                        >
                            x
                        </span>

                        {data?.commentLength?.min > 0 ? (
                            data?.comment ? (
                                data?.commentLength?.min - data?.comment.length > 0 ? (
                                    <span className="length-count">
                                        {data?.commentLength?.min - data?.comment.length}
                                    </span>
                                ) : (
                                    <span className="length-count2">{data?.comment.length}</span>
                                )
                            ) : (
                                <span className="length-count">{data?.commentLength?.min}</span>
                            )
                        ) : data?.comment ? (
                            <span className="length-count2">{data?.comment.length}</span>
                        ) : (
                            <span className="length-count2">0</span>
                        )}
                    </div>
                    <div
                        className="d-flex"
                        style={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            float: 'right',
                        }}
                    >
                        {len && (
                            <span
                                className="f-12 f-sem-bol add-text-option text-right"
                                onClick={_debounce(() => handleAdd(list, 'skills', type), 300)}
                            >
                                + Add Skills
                            </span>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <div
                        className="d-flex"
                        style={{
                            justifyContent: 'space-between',
                            display: 'flex',
                        }}
                    >
                        <span
                            className="f-12 f-sem-bol add-text-option text-left"
                            onClick={() =>
                                handleCommentBoxVisible(list, !data?.commentBoxVisible, data?.position, 'skills')
                            }
                        >
                            {data?.comment ? 'Show Comment' : '+ Add Comment'}
                        </span>
                        {len && (
                            <span
                                className="f-12 f-sem-bol add-text-option text-right"
                                onClick={_debounce(() => handleAdd(list, 'skills', type), 300)}
                            >
                                + Add Skills
                            </span>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default SkillInput;

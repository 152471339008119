import React from 'react';
import _debounce from 'lodash/debounce';
import trash from '../../../../images/trash.svg';
import { ToastManager } from '../../../Toast/ToastManager';
const ScreeningQuestions = ({
    title,
    type,
    data,
    handleAdd,
    handleDelete,
    handleComment,
    interviewDetails,
    role,
    addQuestion,
    setAddQuestion,
}) => {
    return (
        <div className="sq-container mr-10 ml-10">
            <div className="fp-title f-sem-bol">{title}</div>
            <div className="sqc-container pr-10 pl-10">
                {data &&
                    data.length > 0 &&
                    data.map((item, i) => (
                        <ScreeningQuestionComponent
                            list={data}
                            data={item}
                            index={i + 1}
                            handleDelete={handleDelete}
                            handleComment={handleComment}
                            key={i}
                            role={role}
                            type={type}
                        />
                    ))}
                <NewQuestionInput
                    type={type}
                    setQuestion={setAddQuestion}
                    question={addQuestion}
                    interviewDetails={interviewDetails}
                />
                <div
                    className="text-align-right"
                    onClick={_debounce(() => {
                        handleAdd(type, addQuestion);
                    }, 300)}
                >
                    <span className="f-12 f-sem-bol add-text-option cursor-pointer">+ Add Question</span>
                </div>
            </div>
        </div>
    );
};

export default ScreeningQuestions;

const ScreeningQuestionComponent = ({ list, data, index, handleDelete, handleComment, role, type }) => {
    let deleteOption = data?.delete ? (data?.type === 'default' ? role === 'admin' : true) : data?.delete;
    return (
        <div className="text-left mb-10 pos-rel">
            <div className="f-12 f-med text-theme-white mb-10 wth-94">
                {index}
                {'. '}
                {data?.question}
                {deleteOption && (
                    <button className="delete-btn-v2" onClick={(e) => handleDelete(data?.question)}>
                        <img src={trash} className="delete-img-v2" alt="trash" />
                    </button>
                )}
            </div>
            <div className="input-textarea">
                <textarea
                    value={data?.comment}
                    className="w-100 final-remarks f-12 f-reg mt-2"
                    placeholder="Enter Your Answer Here..."
                    style={{ height: '60px' }}
                    onPaste={(e) => e.stopPropagation()}
                    onCut={(e) => e.stopPropagation()}
                    onCopy={(e) => e.stopPropagation()}
                    onChange={(e) => handleComment(data?.question, e.target.value)}
                ></textarea>
                <span className="clear-input" onClick={(e) => handleComment(data?.question, '')}>
                    x
                </span>
                {data?.comment ? (
                    <span className="length-count2">{data?.comment.length}</span>
                ) : (
                    <span className="length-count2">0</span>
                )}
            </div>
        </div>
    );
};

const NewQuestionInput = ({ setQuestion, question, interviewDetails, type }) => {
    return (
        <div className="nsq-component">
            <input
                className="question-input2 f-12 f-reg`"
                placeholder="Enter New Question Here..."
                type="text"
                value={question}
                onPaste={(e) => e.stopPropagation()}
                onCut={(e) => e.stopPropagation()}
                onCopy={(e) => e.stopPropagation()}
                onChange={(e) => {
                    if (e.target.value.length > interviewDetails?.screeningQuestionsSummary?.questionLength?.max) {
                        ToastManager.addToast({
                            title: `Question Length Cannot Be More Than ${interviewDetails?.screeningQuestionsSummary?.questionLength?.max} Characters.`,
                        });
                    } else {
                        setQuestion((prevState) => ({
                            ...prevState,
                            [type]: e.target.value,
                        }));
                    }
                }}
            />
        </div>
    );
};

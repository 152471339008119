import React from 'react';
import { styled, Text, textEllipsis } from '@100mslive/roomkit-react';
import { ConnectionIndicator } from './ConnectionIndicator';

const TileConnection = ({ name, peerId, hideLabel, width }) => {
    return (
        <Wrapper css={{ color: '#fff', backgroundColor: 'inherit', maxWidth: '80%' }}>
            {!hideLabel ? (
                <Text
                    css={{
                        c: '$textHighEmp',
                        ...textEllipsis(width - 60),
                    }}
                    variant="xs"
                >
                    {name}
                </Text>
            ) : null}
            <ConnectionIndicator isTile peerId={peerId} />
        </Wrapper>
    );
};

const Wrapper = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: '$2',
    left: '$2',
    backgroundColor: '$backgroundDark',
    borderRadius: '$1',
    maxWidth: '80%',
    zIndex: 1,
    '& p,span': {
        p: '$2 $3',
    },
});

export default TileConnection;

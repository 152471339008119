import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { BeamSpeakerLabelsLogging } from '../AudioLevel/BeamSpeakerLabelsLogging';
import BackSwipe from '../BackSwipe/BackSwipe';
import { KeyboardHandler } from '../Input/KeyboardInputManager';
import { Notifications } from '../Notifications';
import RouteList from '../RouteList/RouteList';
import { ToastContainer } from '../Toast/ToastContainer';
import { Confetti } from '../../plugins/confetti';
import { RemoteStopScreenshare } from '../../plugins/RemoteStopScreenshare';

export default function AppRoutes({ getDetails, authTokenByRoomCodeEndpoint }) {
    return (
        <Router>
            <ToastContainer />
            <Notifications />
            <BackSwipe />
            <Confetti />
            <RemoteStopScreenshare />
            <KeyboardHandler />
            <BeamSpeakerLabelsLogging />
            <Routes>
                <Route
                    path="/*"
                    element={
                        <RouteList getDetails={getDetails} authTokenByRoomCodeEndpoint={authTokenByRoomCodeEndpoint} />
                    }
                />
            </Routes>
        </Router>
    );
}

import React from 'react';
import EdtechComponent from './components/EdtechComponent/EdtechComponent';

let appName;
if (window.location.host.includes('localhost')) {
    appName = 'localhost';
} else {
    appName = window.location.host.split('.')[0];
}

document.title = 'InCruiter - Video Interview Platform' || `${appName}'s ${document.title}`;

export default function App() {
    return (
        <EdtechComponent
            themeConfig={{
                aspectRatio: '1-1',
                theme: 'dark',
                color: '#2F80FF',
                logo: '',
                font: 'Roboto',
                metadata: '', // A stringified object in env
            }}
        />
    );
}

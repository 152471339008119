import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import _debounce from 'lodash/debounce';
import downloadTemplate from '../../../assets/images/download-img.svg';
import fileUploadImg from '../../../assets/images/file-upload-img.svg';
import { postCarFeedbackDetails } from '../../../redux/action';
import { ToastManager } from '../../Toast/ToastManager';
const CarFinalRemarks = ({ form, handleRemarks, submitDisability, setSubmitDisability }) => {
    const dispatch = useDispatch();
    const fileInputRef = useRef();
    const handleSubmit = _debounce(() => {
        const { finalRating, status, codeEditor, carFeedbackFileName } = form || {};
        let message = '';
        if (carFeedbackFileName === '') {
            message = 'Please upload CAR file to continue.';
        } else if (finalRating === '' && !['shortlisted', 'rejected', 'hold'].includes(status)) {
            message = "Overall rating can't be empty.";
        } else if (status === 'pending' || status === '') {
            message = 'Please select feedback status to continue';
        }
        if (status !== 'noshow' && message) {
            ToastManager.addToast({ title: message });
        } else {
            dispatch(
                postCarFeedbackDetails({
                    data: {
                        ...form,
                        code: codeEditor,
                        type: 'final',
                        isUpdated: true,
                    },
                    callback: () => setSubmitDisability(true),
                })
            );
        }
    }, 300);

    const styles = {
        option: (provided) => ({
            ...provided,
            color: '#2e2e41',
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999, // Adjust the z-index value as needed
        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '180px', // Height for 4 options, adjust if necessary
            overflowY: 'auto',
        }),
    };

    const ratingList = Array.from({ length: 11 }, (_, index) => ({
        label: index,
        value: index,
    }));

    const statusList = [
        { title: 'No Show', code: 'noshow' },
        { title: 'Very Poor', code: 'very_poor' },
        { title: 'Poor', code: 'poor' },
        { title: 'Average', code: 'average' },
        { title: 'Above Avg', code: 'above_average' },
        { title: 'Good', code: 'good' },
        { title: 'Very Good', code: 'very_good' },
        { title: 'Excellent', code: 'excellent' },
    ];

    const anotherStatusList = [
        { title: 'Shortlisted', code: 'shortlisted' },
        { title: 'Rejected', code: 'rejected' },
        // { title: 'On Hold', code: 'hold' },
    ];

    return (
        <div className="final-remarks-page h-100">
            <div className="fp-content-container">
                <div>
                    <div className="final-remark-label-box">
                        <div className="final-remark-label">
                            Upload CAR<span style={{ color: 'red' }}>*</span>
                        </div>
                        <a href={`${form?.carSampleLink}/${form?.roomId}`} className="final-remark-file-template f-med">
                            <img src={downloadTemplate} alt="Download Template" /> Download Template
                        </a>
                    </div>
                    <div
                        className="final-remarks-file-input"
                        onClick={() => {
                            if (fileInputRef?.current) fileInputRef.current.click();
                        }}
                    >
                        <input
                            id="carFeedback"
                            name="carFeedback"
                            type="file"
                            className="file-input"
                            ref={fileInputRef}
                            onChange={(e) => handleRemarks('carFeedback', e)}
                        />
                        <div className="final-remark-input-name">{form?.carFeedbackFileName || 'Upload file'}</div>
                        <img src={fileUploadImg} alt="file-logo" />
                    </div>
                </div>
                <div className="f-14 f-sem-bol text-theme-white mt-2 text-left code-title">Paste Code (if any)</div>
                <textarea
                    className="w-100 final-remarks f-14 f-reg mt-2"
                    placeholder="Add Comments..."
                    value={form?.codeEditor || ''}
                    onChange={(e) => handleRemarks('codeEditor', e.target.value)}
                    disabled={form?.role === 'guest'}
                    onPaste={(e) => e.stopPropagation()}
                    onCut={(e) => e.stopPropagation()}
                    onCopy={(e) => e.stopPropagation()}
                ></textarea>
                <div className="f-14 f-sem-bol text-theme-white mt-2 text-left code-title">Feedback</div>
                <div className="fr-checkbox-container3 text-theme-white">
                    {anotherStatusList?.length > 0 &&
                        anotherStatusList.map((list, key) => (
                            <label
                                className={`checkbox-container f-reg f-12${
                                    [
                                        'noshow',
                                        'very_poor',
                                        'poor',
                                        'average',
                                        'above_average',
                                        'good',
                                        'very_good',
                                        'excellent',
                                    ].includes(form?.status)
                                        ? ' checkbox-container-disabled'
                                        : ''
                                }`}
                                key={key}
                            >
                                {list?.title}
                                <input
                                    type="checkbox"
                                    onChange={(e) => handleRemarks('status', e.target.checked ? list?.code : '')}
                                    checked={form?.status === list?.code}
                                    disabled={[
                                        'noshow',
                                        'very_poor',
                                        'poor',
                                        'average',
                                        'above_average',
                                        'good',
                                        'very_good',
                                        'excellent',
                                    ].includes(form?.status)}
                                />
                                <span className="checkmark"></span>
                            </label>
                        ))}
                </div>
                <div className="overall-height mt-2 w-100 text-end">
                    <span className="f-14 f-sem-bol text-theme-white text-left code-title">Overall Rating</span>
                    <Select
                        name="finalRating"
                        menuPlacement="auto"
                        isSearchable={false}
                        className={`f-reg select-container-rating${
                            ['shortlisted', 'rejected', 'hold'].includes(form?.status)
                                ? ' select-container-rating-disabled'
                                : ''
                        }`}
                        value={
                            form?.finalRating !== ''
                                ? { label: parseInt(form?.finalRating), value: parseInt(form?.finalRating) }
                                : { label: 'Rating', value: '' }
                        }
                        onChange={(e) =>
                            !['shortlisted', 'rejected', 'hold'].includes(form?.status) &&
                            handleRemarks('finalRating', e.value)
                        }
                        options={ratingList}
                        styles={{
                            ...styles,
                            control: (base, state) => ({
                                ...base,
                                backgroundColor: '#2e2e41',
                                background: '#2e2e41',
                                pointerEvents: ['shortlisted', 'rejected', 'hold'].includes(form?.status)
                                    ? 'none'
                                    : 'auto',
                            }),
                            singleValue: (base) => ({
                                ...base,
                                color: '#ffffff',
                            }),
                        }}
                        inputClass="f-l f-reg input-shadow"
                    />
                </div>
                <div className="fr-checkbox-container3 text-theme-white">
                    {statusList?.length > 0 &&
                        statusList.map((list, key) => (
                            <label
                                className={`checkbox-container f-reg f-12${
                                    ['shortlisted', 'rejected', 'hold'].includes(form?.status)
                                        ? ' checkbox-container-disabled'
                                        : ''
                                }`}
                                key={key}
                            >
                                {list?.title}
                                <input
                                    type="checkbox"
                                    onChange={(e) => handleRemarks('status', e.target.checked ? list?.code : '')}
                                    checked={form?.status === list?.code}
                                    disabled={
                                        ['shortlisted', 'rejected', 'hold'].includes(form?.status) ||
                                        (!(form?.status === list?.code) && form?.role === 'interviewer')
                                    }
                                />
                                <span className="checkmark"></span>
                            </label>
                        ))}
                </div>
                <div className="d-center fr-submit">
                    <button
                        className="fr-submit-btn text-theme-white f-sem-bol"
                        onClick={() => handleSubmit()}
                        disabled={submitDisability}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CarFinalRemarks;

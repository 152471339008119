import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import CarFinalRemarks from './FeedbackComponents/CarFinalRemarks';
import Instructions from './FeedbackComponents/Instructions';
import finalRemarksWhite from '../../images/fr-logo-White.svg';
import informationWhite from '../../images/information-img-White.svg';
import { postCarFeedbackDetails } from '../../redux/action';

const CarFeedbackContainer = ({ form, setForm, interviewDetails, handleRemarks, isSideOpen }) => {
    const dispatch = useDispatch();
    const [submitDisability, setSubmitDisability] = useState(false);
    const handlePage = (page) => {
        setForm((prev) => ({
            ...prev,
            tabPage: page,
            codeUpdated: false,
        }));
    };
    useEffect(() => {
        setSubmitDisability(false);
    }, [form]);

    const debouncedDispatch = debounce((formData) => {
        if (formData?.role === 'interviewer' && interviewDetails && formData?.interviewId && formData?.codeUpdated) {
            dispatch(
                postCarFeedbackDetails({
                    data: {
                        ...formData,
                        code: formData?.codeEditor,
                        type: 'auto',
                    },
                })
            );
            setForm({
                ...formData,
                codeUpdated: false,
            });
        }
    }, 300);

    useEffect(() => {
        debouncedDispatch(form);
        return () => debouncedDispatch.cancel();
    }, [form?.status, form?.finalRating, form?.code, form?.carFeedback, debouncedDispatch]);

    const switchPage = (page) => {
        switch (page) {
            case 0:
                return <Instructions interviewDetails={interviewDetails} />;
            case 1:
                return (
                    <CarFinalRemarks
                        form={form}
                        handleRemarks={handleRemarks}
                        submitDisability={submitDisability}
                        setSubmitDisability={setSubmitDisability}
                    />
                );
            default:
                return <Instructions interviewDetails={interviewDetails} />;
        }
    };

    const [, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={`feedback-container ${isSideOpen ? '' : 'd-none'}`}>
            <div className="feedback-btn-container">
                <div
                    className={`feedback-btn ${form?.tabPage === 0 ? 'theme-peacock-bg' : 'theme-gray-bg'}`}
                    title="Information"
                    onClick={() => handlePage(0)}
                >
                    <img src={informationWhite} alt="mandatory skills logo" className="feedback-img" />
                </div>
                <div
                    className={`feedback-btn ${form?.tabPage === 1 ? 'theme-peacock-bg' : 'theme-gray-bg'}`}
                    title="Feedback"
                    onClick={() => handlePage(1)}
                >
                    <img src={finalRemarksWhite} alt="final remarks logo" className="feedback-img" />
                </div>
            </div>
            <div className="feedback-page-container">{switchPage(form?.tabPage)}</div>
        </div>
    );
};

export default CarFeedbackContainer;

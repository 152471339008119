import React from 'react';
import Select from 'react-select';
import axios from 'axios';
import _debounce from 'lodash/debounce';
import { getStatusList } from '../../components/OtherComponents/Helpers';
import { ToastManager } from '../../components/Toast/ToastManager';
import { generateUrl } from '../../utils/helpers';

const FinalFeedRemarks = ({ form, setForm, submitDisability, setSubmitDisability }) => {
    const validateComments = (items, type) => {
        for (const item of items) {
            if (item?.comment && item?.comment.length < item?.commentLength?.min) {
                return `${type} "${item.skill || item.question}" should have at least ${item?.commentLength?.min} characters.`;
            }
            if (item?.comment && item?.comment.length > item?.commentLength?.max) {
                return `${type} "${item.skill || item.question}" should not exceed ${item?.commentLength?.max} characters.`;
            }
            if (['mandatory'].includes(item.skillType) && item.rating === '') {
                return `Mandatory skill "${item.skill || 'Unnamed'}" skill rating can't be empty.`;
            }
            if (['mandatory', 'optional'].includes(item.skillType) && !item?.skill) {
                return `${item.skillType === 'optional' ? 'Optional' : 'Mandatory'} skill "Unnamed" requires a name.`;
            }
        }
        return '';
    };

    const validateRating = (items) => {
        for (const item of items) {
            if (item.rating === '') return `"${item.skill}" skill rating can't be empty.`;
        }
        return '';
    };

    const handleRemarks = (type, value) => {
        const fValue = ['finalRemark'].includes(type)
            ? value
                ? value.replace(/^\s+/, '').replace(/\s{2,}/g, ' ')
                : ''
            : value;
        setForm((prevState) => ({
            ...prevState,
            [type]: fValue,
        }));
    };

    const handleSubmit = _debounce(() => {
        const config = {
            headers: { Authorization: `Bearer ${form?.token}` },
        };
        if (form?.status === 'noshow') {
            axios
                .post(`${generateUrl('feed_backend')}/no-show-interview/${form?.interviewKey}/noshow`, {}, config)
                .then((res) => {
                    if (res.status === 201) setForm(null);
                });
            return;
        }
        let message =
            form?.screeningQuestions?.length > 0 && validateComments(form.screeningQuestions, 'Screening Question');
        if (!message && form?.skills?.length > 0) {
            message = validateComments(
                form.skills.filter((skill) => ['mandatory', 'optional'].includes(skill.skillType)),
                'Skill'
            );
            if (!message) {
                message = validateRating(
                    form.skills.filter((skill) => ['soft', 'analytics'].includes(skill.skillType)),
                    'Skill'
                );
            }
        }
        if (!message && (!form?.finalRemark || form?.finalRemark.length < 2)) {
            message = 'Final remarks should have at least 2 characters.';
        }
        if (!message && form?.finalRemark.length > 5000) {
            message = 'Final remarks cannot exceed 5000 characters.';
        }
        if (!message && !form?.finalRating) {
            message = 'Overall rating is required.';
        }
        if (!message && !form?.status) {
            message = 'Candidate status must be selected.';
        }
        if (message) {
            ToastManager.addToast({ title: message });
            return;
        }
        axios
            .post(
                `${generateUrl('feed_backend')}/addFeedback/${form?.interviewId}`,
                {
                    ...form,
                    code: [form?.code],
                },
                config
            )
            .then((res) => {
                if (res.status === 201) {
                    setForm(null);
                    setSubmitDisability(true);
                }
            });
    }, 300);
    const ratingList = Array.from({ length: 11 }, (_, i) => ({ label: i, value: i }));
    const statusList = getStatusList(1, 'admin');
    const customStyles = {
        option: (provided) => ({
            ...provided,
            color: '#2e2e41',
        }),
    };
    return (
        <div className="final-remarks-page h-100">
            <div className="fp-content-container">
                <SectionHeader title="Final Remarks" />
                <TextArea
                    name="finalRemark"
                    value={form?.finalRemark ?? ''}
                    placeholder="Add Comments..."
                    onChange={(e) => handleRemarks('finalRemark', e.target.value)}
                />
                {form?.finalRemark.length < 2 && <ValidationMessage message="*Min. 2 char." />}
                <br />
                <SectionHeader title="Paste Code (if any)" />
                <TextArea
                    name="code"
                    value={form?.code || ''}
                    placeholder="Add Comments..."
                    onChange={(e) => handleRemarks('code', e.target.value)}
                />
                <ToggleSwitch form={form} setForm={setForm} />
                <div className="overall-height mt-2 w-100 text-end">
                    <SectionHeader className=" code-title" title="Overall Rating" />
                    <RatingDropdown
                        value={form?.finalRating}
                        options={ratingList}
                        onChange={(value) => handleRemarks('finalRating', value)}
                        styles={customStyles}
                    />
                </div>
                <br />
                <StatusCheckboxes statusList={statusList} form={form} handleRemarks={handleRemarks} />
                <SubmitButton disabled={submitDisability} onClick={handleSubmit} label="Submit" />
            </div>
        </div>
    );
};
const SectionHeader = ({ className = '', title }) => (
    <div className={`f-14 f-sem-bol text-theme-white mt-2 text-left${className}`}>{title}</div>
);
const TextArea = ({ name, value, placeholder, onChange }) => (
    <textarea
        name={name}
        className="w-100 final-remarks f-reg mt-2"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
    />
);
const ValidationMessage = ({ message }) => <span className="f-bol text-theme-red text-right f-10">{message}</span>;
const RatingDropdown = ({ value, options, onChange, styles }) => (
    <Select
        name="finalRating"
        menuPlacement="auto"
        isSearchable={false}
        value={{ label: value !== '' ? value : 'Select', value }}
        onChange={(e) => onChange(e.value)}
        options={options}
        styles={styles}
        className="f-reg select-container-rating"
        style={{ float: 'right' }}
    />
);
const StatusCheckboxes = ({ statusList, form, handleRemarks }) => (
    <div className="fr-checkbox-container text-theme-white">
        {statusList?.map((list, key) => (
            <label className="checkbox-container f-reg f-12" key={key}>
                {list?.title}
                <input
                    type="checkbox"
                    onChange={(e) => handleRemarks('status', e.target.checked ? list?.code : '')}
                    checked={form?.status === list?.code}
                    disabled={list?.disable}
                />
                <span className="checkmark"></span>
            </label>
        ))}
    </div>
);
const SubmitButton = ({ disabled, onClick, label }) => (
    <div className="d-center fr-submit">
        <button className="fr-submit-btn text-theme-white f-sem-bol" onClick={onClick} disabled={disabled}>
            {label}
        </button>
    </div>
);
const ToggleSwitch = ({ form, setForm }) => (
    <div className="suspected-div">
        <SectionHeader title="Suspected Proxy :" />
        <label className="toggle-switch">
            <input
                type="checkbox"
                checked={form.suspectedProxy}
                onChange={() =>
                    setForm((prev) => ({
                        ...prev,
                        suspectedProxy: !prev.suspectedProxy,
                    }))
                }
            />
            <span className="switch" />
        </label>
    </div>
);

export default FinalFeedRemarks;

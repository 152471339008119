import Zero from '../../assets/images/0by10.svg';
import One from '../../assets/images/1by10.svg';
import Two from '../../assets/images/2by10.svg';
import Three from '../../assets/images/3by10.svg';
import Four from '../../assets/images/4by10.svg';
import Five from '../../assets/images/5by10.svg';
import Six from '../../assets/images/6by10.svg';
import Seven from '../../assets/images/7by10.svg';
import Eight from '../../assets/images/8by10.svg';
import Nine from '../../assets/images/9by10.svg';
import Ten from '../../assets/images/10by10.svg';

const images = [Zero, One, Two, Three, Four, Five, Six, Seven, Eight, Nine, Ten];

export function CircularProgressBarPage({ rating, candidateDrop }) {
    return (
        <div className="CircularProgressbarDiv">
            <img src={images[rating]} alt={`Rating: ${rating}`} />
            {candidateDrop && <p className="feedback-status">Drop-off</p>}
        </div>
    );
}

import React from 'react';
import { ChevronLeftIcon } from '@100mslive/react-icons';
import { Box, Text } from '@100mslive/roomkit-react';

const SettingsContentHeader = ({ children, isMobile, onBack }) => {
    return (
        <Text variant="h6" css={{ mb: '$12', display: 'flex', alignItems: 'center' }}>
            {isMobile && (
                <Box
                    as="span"
                    css={{
                        bg: '$surfaceLight',
                        mr: '$4',
                        r: '$round',
                        p: '$2',
                    }}
                    onClick={onBack}
                >
                    <ChevronLeftIcon />
                </Box>
            )}
            {children}
        </Text>
    );
};

export default SettingsContentHeader;

import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    CodeSnippet,
    CodeSnippetImages,
    FinalRemarks,
    Footer,
    Header,
    OtherSkills,
    OverallSkills,
    Profile,
    ScreeningQuestions,
    Screenshots,
    Skills,
    SkillsComment,
} from '../components/MainComponents';
import { getSoftRating } from '../components/OtherComponents/Helpers';
import { getFeedback } from '../redux/action';
import { capitalizeAll } from '../utils/helpers';
import '../assets/css/index.css';
import '../assets/fonts/stylesheet.css';
function FeedbackPage() {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.Interview.loading);
    const feedbackDetails = useSelector((state) => state.Interview.feedbackDetails);
    const { roomId } = useParams();
    const [profileForm, setProfileForm] = useState({
        image: '',
        intId: '',
        name: '',
        position: '',
        company: '',
        experience: '',
        email: '',
        phone: '',
        suspectedProxy: false,
        candidateDrop: false,
        recommendedMode: 1,
    });
    const [feedbackForm, setFeedbackForm] = useState({
        FinalRemarksStatus: false,
        FinalRemarksData: '',
        ScreenshotsStatus: false,
        ScreenshotsData: [],
        ScreeningQuestionsStatus: false,
        ScreeningQuestionsData: [],
        CodeSnippetStatus: false,
        CodeSnippetData: '',
        SkillsCommentStatus: false,
        SkillsCommentData: [],
        SkillsStatus: false,
        SkillsData: [],
        OtherSkillsType: 1,
        OtherSkillsList: [],
        GlobalSkillRating: [],
        GlobalSkillName: [],
        skillOverAll: [
            {
                name: 'Mandatory Skills',
                type: 'mandatory',
                total: 0,
                length: 0,
            },
            { name: 'Optional Skills', type: 'optional', total: 0, length: 0 },
            { name: 'Soft Skills', type: 'soft', total: 0, length: 0 },
        ],
    });
    useEffect(() => {
        if (profileForm.name) {
            document.title = `${capitalizeAll(profileForm.name)} Feedback Report`;
        }
    }, [profileForm?.name]);
    useEffect(() => {
        if (!roomId) return;

        if (!feedbackDetails) {
            dispatch(getFeedback({ roomId }));
            return;
        }

        const { interview } = feedbackDetails;
        const { candidate, feedback, jobTitle, employer, reference_id } = interview || {};

        if (candidate) {
            setProfileForm((prevState) => ({
                ...prevState,
                name: candidate.name,
                experience: candidate.experience,
                email: candidate.email,
                phone: candidate.phone,
                position: jobTitle,
                rating: feedback?.finalRating || 0,
                company: employer?.employerName || 'InCruiter',
            }));
        }

        if (feedback) {
            const skillRatingTmp = [];
            const skillCommentTmp = [];
            const screeningQuestionRatingTmp = [];
            const otherSkillTmp = [];
            let OtherSkillsTypeTmp = 1;

            let [mc, mt, oc, ot, sc, st] = [0, 0, 0, 0, 0, 0];

            feedback.skills?.forEach((itm) => {
                const { skill, skillType, rating, comment } = itm || {};

                if (skillType === 'mandatory' || skillType === 'optional') {
                    if (rating) {
                        skillRatingTmp.push({ name: skill, type: skillType, rating: parseInt(rating) });
                        if (skillType === 'mandatory') {
                            mc += 1;
                            mt += parseInt(rating);
                        } else {
                            oc += 1;
                            ot += parseInt(rating);
                        }
                    }
                    if (comment) {
                        skillCommentTmp.push({ name: skill, type: skillType, rating, comment });
                    }
                } else {
                    otherSkillTmp.push({ name: skill, type: skillType, rating: rating ? parseInt(rating) : 0 });
                    if (skillType === 'leadership' && rating) {
                        OtherSkillsTypeTmp = 2;
                    }
                    if (rating) {
                        skillCommentTmp.push({
                            name: skill,
                            type: skillType,
                            rating: getSoftRating(skill, parseInt(rating))?.value,
                            comment: getSoftRating(skill, parseInt(rating))?.comment,
                        });
                        sc += 1;
                        st += parseInt(rating);
                    }
                }
            });

            feedback.screeningQuestions?.forEach(({ question, comment }) => {
                if (comment) {
                    screeningQuestionRatingTmp.push({ question, comment });
                }
            });

            setProfileForm((prevState) => ({
                ...prevState,
                intId: reference_id?.code,
                suspectedProxy: feedback.suspectedProxy,
                candidateDrop: feedback.candidateDrop || false,
                recommendedMode: feedback.finalRating > 7 ? 1 : feedback.finalRating > 5 ? 2 : 3,
                image: feedback.candidateImage,
            }));

            setFeedbackForm((prevState) => ({
                ...prevState,
                FinalRemarksStatus: !!feedback.finalRemark,
                FinalRemarksData: feedback.finalRemark,
                ScreenshotsStatus: !!feedback.screenshots?.length,
                ScreenshotsData: feedback.screenshots,
                ScreeningQuestionsStatus: !!screeningQuestionRatingTmp.length,
                ScreeningQuestionsData: screeningQuestionRatingTmp,
                CodeSnippetStatus: !!feedback.code?.length,
                CodeSnippetData: feedback.code?.[0] || '',
                codeImagesStatus: !!feedback.codeImages?.length,
                codeImagesData: feedback.codeImages || [],
                SkillsCommentStatus: !!skillCommentTmp.length,
                SkillsCommentData: skillCommentTmp,
                SkillsStatus: !!skillRatingTmp.length,
                SkillsData: skillRatingTmp,
                OtherSkillsType: OtherSkillsTypeTmp,
                OtherSkillsList: otherSkillTmp,
                skillOverAll: [
                    { name: 'Mandatory Skills', type: 'mandatory', total: mt, length: mc },
                    { name: 'Optional Skills', type: 'optional', total: ot, length: oc },
                    { name: 'Soft Skills', type: 'soft', total: st, length: sc },
                ],
            }));
        }
    }, [dispatch, roomId, feedbackDetails]);

    const [, setWidth] = useState(window.innerWidth);
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return (
        <div className="App">
            {loading && <Loader />}
            {feedbackDetails && (
                <>
                    <Header />
                    <Profile data={profileForm} />
                    <OverallSkills data={feedbackForm} />
                    <OtherSkills data={feedbackForm} />
                    {feedbackForm?.SkillsStatus && <Skills data={feedbackForm?.SkillsData} />}
                    {feedbackForm?.SkillsCommentStatus && (
                        <>
                            <div className="pagebreak">
                                <SkillsComment data={feedbackForm?.SkillsCommentData} />
                            </div>
                        </>
                    )}
                    {feedbackForm?.ScreeningQuestionsStatus && (
                        <ScreeningQuestions data={feedbackForm?.ScreeningQuestionsData} />
                    )}
                    {feedbackForm?.CodeSnippetStatus && <CodeSnippet data={feedbackForm?.CodeSnippetData} />}
                    {feedbackForm?.FinalRemarksStatus && <FinalRemarks data={feedbackForm?.FinalRemarksData} />}
                    {feedbackForm?.codeImagesStatus && (
                        <>
                            <div className="pagebreak">
                                <CodeSnippetImages data={feedbackForm?.codeImagesData} />
                            </div>
                        </>
                    )}
                    {feedbackForm?.ScreenshotsStatus && (
                        <>
                            <div className="pagebreak">
                                <Screenshots data={feedbackForm?.ScreenshotsData} />
                            </div>
                        </>
                    )}
                    <Footer />
                </>
            )}
        </div>
    );
}

const Loader = memo(() => {
    return (
        <div className="wrapper">
            <div className="loader"></div>
        </div>
    );
});

export default FeedbackPage;

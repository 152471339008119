/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { getLeft, StyledVideoList } from '@100mslive/roomkit-react';
import {
    selectPeerScreenSharing,
    selectPeerSharingAudio,
    selectPeerSharingVideoPlaylist,
    useHMSStore,
    useVideoList,
} from '@100mslive/react-sdk';
import ScreenshareTile from '../../../components/ScreenshareTile';
import MeetVideoTile from './MeetVideoTile';
import ScreenShareView from './ScreenShareView';
import { useAppConfig } from '../../../components/AppData/useAppConfig';
import { useIsHeadless, useUISettings } from '../../../components/AppData/useUISettings';

const List = ({ maxTileCount, peers, maxColCount, maxRowCount, includeScreenShareForPeer, size, tab, setTab }) => {
    const tileOffset = useAppConfig('headlessConfig', 'tileOffset');
    const isHeadless = useIsHeadless();
    const { ref, pagesWithTiles } = useVideoList({
        peers,
        maxTileCount: maxTileCount || 10,
        maxColCount: maxColCount || 10,
        maxRowCount: maxRowCount || 10,
        includeScreenShareForPeer,
        offsetY: getOffset({ isHeadless, tileOffset }),
    });
    const [page, setPage] = useState(0);

    useEffect(() => {
        if (page >= pagesWithTiles.length) {
            setPage(0);
        }
    }, [pagesWithTiles.length, page]);

    const peerSharing = useHMSStore(selectPeerScreenSharing);
    const peerSharingAudio = useHMSStore(selectPeerSharingAudio);
    const peerSharingPlaylist = useHMSStore(selectPeerSharingVideoPlaylist);
    const { isAudioOnly } = useUISettings();

    return (
        <StyledVideoList.Root ref={ref}>
            <StyledVideoList.Container css={{ width: 'auto' }}>
                <StyledVideoList.View
                    key={0}
                    css={{
                        left: getLeft(0, page),
                        transition: 'left 0.3s ease-in-out',
                        position: 'relative !important',
                        'place-content': 'start !important',
                    }}
                >
                    {pagesWithTiles.map((tiles, pageNo) => (
                        <>
                            {tiles.map((tile) =>
                                tile.track?.source === 'screen' ? (
                                    <ScreenshareTile
                                        key={tile.track.id}
                                        width={tile.width}
                                        height={tile.height}
                                        peerId={tile.peer.id}
                                    />
                                ) : (
                                    <MeetVideoTile
                                        key={tile.track?.id || tile.peer.id}
                                        width="100%"
                                        height={size === 0 ? '250px' : '100%'}
                                        peerId={tile.peer?.id}
                                        trackId={tile.track?.id}
                                        visible={pageNo === page}
                                        setTab={setTab}
                                        tab={tab}
                                    />
                                )
                            )}
                            {((peerSharing && peerSharing.id !== peerSharingAudio?.id) || peerSharingPlaylist) &&
                                !isAudioOnly &&
                                tab !== 1 && (
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '250px',
                                            padding: '10px',
                                            background: '#15171c',
                                        }}
                                    >
                                        <ScreenShareView setTab={setTab} />
                                    </div>
                                )}
                        </>
                    ))}
                </StyledVideoList.View>
            </StyledVideoList.Container>
        </StyledVideoList.Root>
    );
};

const MeetVideoList = React.memo(List);

const getOffset = ({ offset, isHeadless }) => {
    return !isHeadless || typeof offset !== 'number' ? 32 : offset;
};

export default MeetVideoList;

import React from 'react';
export const FinalRemarks = ({ data }) => {
    return (
        <div className="detailed-feedback-list break-stop">
            <div className="soft-skill-wrapperv2">
                <div className="font-style-header-soft-skill soft-skill-header-600">Final Remarks</div>
                <div className="soft-skill-header-outer">
                    <div className="soft-skill-header-type-1 font-style-header-soft-skill">Final Remarks</div>
                </div>
                <div className="soft-skill-content">
                    <p className="font-style-soft-skill">{data}</p>
                </div>
            </div>
        </div>
    );
};

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Candidate_Icon from '../../assets/images/candidate_icon.svg';
import Group_Avtar from '../../assets/images/group_avtar.svg';
import Guest_Icon from '../../assets/images/guest_icon.svg';
import Incruiter_Logo from '../../assets/images/incruiter_logo.svg';
import Interviewer_Icon from '../../assets/images/interviewer_icon.svg';
import Joining_Avtar from '../../assets/images/joining_avtar.svg';
import Joining_Avtar_Mob from '../../assets/images/joining_avtar_mob.svg';
import JoiningRoleBox from './JoiningRoleBox';
import './joiningScreen.css';
const Other = React.lazy(() => import('../Pages/Other'));
const JoiningScreen = () => {
    const { roomId } = useParams();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    // updating screenWidth whenever window is resized
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return roomId ? (
        <div className="joining_screen_container">
            <img src={Incruiter_Logo} alt="logo" className="logo" />
            <h1 className="joining_title">Join Interview</h1>
            <div className="avtar_container">
                <div className="joining_role_box_container">
                    <JoiningRoleBox
                        role="candidate"
                        role_icon={Candidate_Icon}
                        role_text="I am Candidate"
                        roomId={roomId}
                    />
                    <JoiningRoleBox
                        role="interviewer"
                        role_icon={Interviewer_Icon}
                        role_text="I am Interviewer"
                        roomId={roomId}
                    />
                    <JoiningRoleBox role="guest" role_icon={Guest_Icon} role_text="I am Guest" roomId={roomId} />
                </div>
            </div>
            <div className="single_avtar_container">
                <img src={screenWidth > 967 ? Joining_Avtar : Joining_Avtar_Mob} alt="avtar" className="avtar" />
            </div>
            <div className="group_avtar_container">
                <img src={Group_Avtar} alt="group_avtar" className="group_avtar" />
            </div>
        </div>
    ) : (
        <Other page="404Page" />
    );
};

export default JoiningScreen;

import React, { cloneElement, useCallback, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { CrossIcon, NotificationsIcon, SettingsIcon } from '@100mslive/react-icons';
import { config as cssConfig, Dialog, Flex, IconButton, Tabs, Text } from '@100mslive/roomkit-react';
import DeviceSettings from './DeviceSettings';
import { NotificationSettings } from './NotificationSettings';
import { settingContent } from './common.js';
import SettingsContentHeader from './SettingsContentHeader.jsx';

const tabTriggerStyles = {
    gap: '$8',
    mt: '$10',
    mb: '$4',
    display: 'flex',
    alignItems: 'center',
};

const iconStyles = {
    width: 24,
    height: 24,
    marginRight: '$2',
};

const CustomTabTrigger = ({ value, icon, label }) => (
    <Tabs.Trigger value={value} css={tabTriggerStyles}>
        {icon && cloneElement(icon, { css: iconStyles })}
        {label}
    </Tabs.Trigger>
);

const SettingsModal = ({ open, onOpenChange, children }) => {
    const mediaQueryLg = cssConfig.media.md;
    const isMobile = useMedia(mediaQueryLg);
    const [selection, setSelection] = useState('');
    const resetSelection = useCallback(() => {
        setSelection('');
    }, []);

    useEffect(() => {
        setSelection(isMobile ? '' : 'devices');
    }, [isMobile]);

    return (
        <Dialog.Root open={open} onOpenChange={onOpenChange}>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay />
                <Dialog.Content
                    css={{
                        w: 'min(800px, 90%)',
                        height: 'min(656px, 90%)',
                        p: 0,
                        r: '$4',
                    }}
                >
                    <Tabs.Root
                        value={selection}
                        activationMode={isMobile ? 'manual' : 'automatic'}
                        onValueChange={setSelection}
                        css={{ size: '100%', position: 'relative' }}
                    >
                        <Tabs.List
                            css={{
                                w: isMobile ? '100%' : '18.625rem',
                                flexDirection: 'column',
                                bg: '$backgroundDefault',
                                p: '$14 $10',
                                borderTopLeftRadius: '$4',
                                borderBottomLeftRadius: '$4',
                            }}
                        >
                            <Text variant="h5">Settings </Text>
                            <Flex
                                direction="column"
                                css={{
                                    mx: isMobile ? '-$8' : 0,
                                    overflowY: 'auto',
                                }}
                            >
                                <CustomTabTrigger value="devices" icon={<SettingsIcon />} label="Device Settings" />
                                <CustomTabTrigger
                                    value="notifications"
                                    icon={<NotificationsIcon />}
                                    label="Notifications"
                                />
                            </Flex>
                        </Tabs.List>
                        {selection && (
                            <Flex
                                direction="column"
                                css={{
                                    flex: '1 1 0',
                                    minWidth: 0,
                                    mr: '$4',
                                    ...(isMobile
                                        ? {
                                              position: 'absolute',
                                              left: 0,
                                              right: 0,
                                              bg: '$surfaceDefault',
                                              width: '100%',
                                              height: '100%',
                                          }
                                        : {}),
                                }}
                            >
                                <Tabs.Content value="devices" className={settingContent()}>
                                    <SettingsContentHeader onBack={resetSelection} isMobile={isMobile}>
                                        Device Settings
                                    </SettingsContentHeader>
                                    <DeviceSettings />
                                </Tabs.Content>
                                <Tabs.Content value="notifications" className={settingContent()}>
                                    <SettingsContentHeader onBack={resetSelection} isMobile={isMobile}>
                                        Notifications
                                    </SettingsContentHeader>
                                    <NotificationSettings />
                                </Tabs.Content>
                            </Flex>
                        )}
                    </Tabs.Root>
                    <Dialog.Close css={{ position: 'absolute', right: '$10', top: '$10' }}>
                        <IconButton as="div" data-testid="dialog_cross_icon">
                            <CrossIcon />
                        </IconButton>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
};

export default SettingsModal;

import { useEffect } from 'react';
import { selectIsConnectedToRoom, useHMSActions, useHMSStore } from '@100mslive/react-sdk';

const BackSwipe = () => {
    const isConnectedToRoom = useHMSStore(selectIsConnectedToRoom);
    const hmsActions = useHMSActions();
    useEffect(() => {
        const onRouteLeave = async () => {
            if (isConnectedToRoom) {
                await hmsActions.leave();
            }
        };
        window.addEventListener('popstate', onRouteLeave);
        return () => {
            window.removeEventListener('popstate', onRouteLeave);
        };
    }, [hmsActions, isConnectedToRoom]);
    return null;
};

export default BackSwipe;

import React from 'react';
import { Box, Flex, styled, Text } from '@100mslive/roomkit-react';
import { Header } from '../../components/Header';
import FOFBanner from '../../assets/images/404-banner.svg';

const FeedLinkExpired = () => {
    const renderMain = () => (
        <Flex css={{ height: '100vh', width: '100vw' }} direction="column">
            <Box
                css={{
                    height: '$18',
                    backgroundColor: '#191924',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                }}
                data-testid="header"
            >
                <Header />
            </Box>
            <Flex
                direction="column"
                css={{
                    flex: 1,
                    width: '100%',
                    backgroundColor: '#2E2E41',
                    position: 'relative',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px', // Add padding for smaller screens
                }}
            >
                <Text
                    variant="body1"
                    css={{
                        fontWeight: 700,
                        fontSize: '40px',
                        lineHeight: '55px',
                        color: '#9396A0',
                        textAlign: 'center',
                        '@media (max-width: 768px)': {
                            fontSize: '20px',
                            lineHeight: '30px',
                        },
                    }}
                >
                    The link has been expired.
                </Text>
                <LogoImg
                    src={FOFBanner}
                    alt="InCruiter Logo"
                    width={615}
                    height={354}
                    css={{
                        marginTop: '20px',
                        maxWidth: '90%', // Ensure the image scales down for smaller screens
                        height: 'auto',
                        '@media (max-width: 768px)': {
                            width: '100%',
                        },
                    }}
                />
            </Flex>
        </Flex>
    );

    return renderMain();
};

const LogoImg = styled('img', {
    p: '$2',
});

export default FeedLinkExpired;

import React from 'react';
import Select from 'react-select';
import { getSoftRating } from '../../OtherComponents/Helpers';
const OtherSkills = ({ skillLists, handleRatingComment, experience, ratingType }) => {
    let options;
    const styles = {
        option: (provided) => ({
            ...provided,
            color: '#2e2e41',
        }),
    };
    options = {
        Confidence: [
            { value: 2, label: 'Inadequate Confidence' },
            { value: 4, label: 'Low Self-Assurance' },
            { value: 6, label: 'Mildly Confident' },
            { value: 8, label: 'Bold & Confident' },
            { value: 10, label: 'Extremely Confident' },
        ],
        Communication: [
            { value: 2, label: 'Inadequate' },
            { value: 4, label: 'Poor' },
            { value: 6, label: 'Avg. Communication' },
            { value: 8, label: 'Decent Communication' },
            { value: 10, label: 'Excellent Communication' },
        ],
        'Pressure Handling': [
            { value: 2, label: 'Inefficient' },
            { value: 4, label: 'Vulnerable' },
            { value: 6, label: 'Moderate' },
            { value: 8, label: 'Capable' },
            { value: 10, label: 'Competent' },
        ],
        'Approach & Attitude': [
            { value: 2, label: 'Extremely Low' },
            { value: 4, label: 'Poor' },
            { value: 6, label: 'Moderate' },
            { value: 8, label: 'Positive' },
            { value: 10, label: 'Extremely Positive' },
        ],
        'Problem Solving': [
            { value: 2, label: 'Extremely Low' },
            { value: 4, label: 'Substandard' },
            { value: 6, label: 'Moderate' },
            { value: 8, label: 'Problem Solver' },
            { value: 10, label: 'Excellent' },
        ],
        'Result Oriented': [
            { value: 2, label: 'Extremely Low' },
            { value: 4, label: 'Lacks Focus' },
            { value: 6, label: 'Passable' },
            { value: 8, label: 'Highly Enthusiastic' },
            { value: 10, label: 'Goal Driven' },
        ],
        'Decision Making': [
            { value: 2, label: 'Bad Decision Maker' },
            { value: 4, label: 'Dysfunctional' },
            { value: 6, label: 'Moderate' },
            { value: 8, label: 'Quite Decisive' },
            { value: 10, label: 'Extremely Decisive' },
        ],
        'Team Management': [
            { value: 2, label: 'Extremely Lousy' },
            { value: 4, label: 'Poor Admin. & Control' },
            { value: 6, label: 'Decent Synergism' },
            { value: 8, label: 'People Manager' },
            { value: 10, label: 'Highly Efficient' },
        ],
        Accountability: [
            { value: 2, label: 'Extremely Unaccountable' },
            { value: 4, label: 'Low Accountability' },
            { value: 6, label: 'Somewhat Accountable' },
            { value: 8, label: 'Answerable' },
            { value: 10, label: 'Extremely Reliable' },
        ],
    };
    return (
        <div className="feedback-page h-100">
            <div className="fp-title f-sem-bol">Soft Skills</div>
            <div className="fp-content-container">
                <div className="si-row-4 d-flex align-items-center justify-content-between">
                    <span className="title-softskillsv">Skills</span>
                    <span className="title-softskillsv">Rating</span>
                </div>
                {skillLists &&
                    skillLists.length > 0 &&
                    skillLists
                        .filter((itm) => itm.skillType === 'soft')
                        .map((itm, nkey) => (
                            <div key={nkey}>
                                <div className="si-row-1 d-flex align-items-center justify-content-between">
                                    <input
                                        type="text"
                                        id="fname"
                                        name="fname"
                                        value={itm.skill}
                                        title={itm.skill}
                                        className="other-skills-input"
                                        disabled={true}
                                    />
                                    <Select
                                        isSearchable={false}
                                        menuPlacement="auto"
                                        name="skill-rating"
                                        className="w-58 f-reg select-input-container select-input-other"
                                        value={{
                                            label: itm?.rating
                                                ? getSoftRating(itm.skill, parseInt(itm?.rating)).value
                                                : 'Select',
                                            value: itm?.rating ? parseInt(itm?.rating) : '',
                                        }}
                                        onChange={(e) =>
                                            handleRatingComment('skills', 'rating', e.value, itm?.position)
                                        }
                                        options={options[itm.skill]}
                                        styles={styles}
                                        inputClass="f-l f-reg input-shadow"
                                    />
                                </div>
                            </div>
                        ))}
            </div>
            <br />
            <div className="fp-title f-sem-bol">Analytical Skills</div>
            <div className="fp-content-container">
                <div className="si-row-4 d-flex align-items-center justify-content-between">
                    <span className="title-softskillsv">Skills</span>
                    <span className="title-softskillsv">Rating</span>
                </div>
                {skillLists &&
                    skillLists.length > 0 &&
                    skillLists
                        .filter((itm) => itm.skillType === 'analytics')
                        .map((itm, nkey) => (
                            <div key={nkey}>
                                <div className="si-row-1 d-flex align-items-center justify-content-between">
                                    <input
                                        type="text"
                                        id="fname"
                                        name="fname"
                                        value={itm.skill}
                                        title={itm.skill}
                                        className="other-skills-input"
                                        disabled={true}
                                    />
                                    <Select
                                        isSearchable={false}
                                        name="skill-rating"
                                        menuPlacement="auto"
                                        className="w-58 f-reg select-input-container select-input-other"
                                        value={{
                                            label: itm?.rating
                                                ? getSoftRating(itm.skill, parseInt(itm?.rating)).value
                                                : 'Select',
                                            value: itm?.rating ? parseInt(itm?.rating) : '',
                                        }}
                                        onChange={(e) =>
                                            handleRatingComment('skills', 'rating', e.value, itm?.position)
                                        }
                                        options={options[itm.skill]}
                                        styles={styles}
                                        inputClass="f-l f-reg input-shadow"
                                    />
                                </div>
                            </div>
                        ))}
            </div>
            <br />
            {ratingType === 2 ? (
                experience > 9.9 && (
                    <>
                        <div className="fp-title f-sem-bol">Leadership Skills</div>
                        <div className="fp-content-container">
                            <div className="si-row-4 d-flex align-items-center justify-content-between">
                                <span className="title-softskillsv">Skills</span>
                                <span className="title-softskillsv">Rating</span>
                            </div>
                            {skillLists &&
                                skillLists.length > 0 &&
                                skillLists
                                    .filter((itm) => itm.skillType === 'leadership')
                                    .map((itm, nkey) => (
                                        <div key={nkey}>
                                            <div className="si-row-1 d-flex align-items-center justify-content-between">
                                                <input
                                                    type="text"
                                                    id="fname"
                                                    name="fname"
                                                    value={itm.skill}
                                                    title={itm.skill}
                                                    className="other-skills-input"
                                                    disabled={true}
                                                />
                                                <Select
                                                    isSearchable={false}
                                                    name="skill-rating"
                                                    menuPlacement="auto"
                                                    className="w-58 f-reg select-input-container select-input-other"
                                                    value={{
                                                        label: itm?.rating
                                                            ? getSoftRating(itm.skill, parseInt(itm?.rating)).value
                                                            : 'Select',
                                                        value: itm?.rating ? parseInt(itm?.rating) : '',
                                                    }}
                                                    onChange={(e) =>
                                                        handleRatingComment('skills', 'rating', e.value, itm?.position)
                                                    }
                                                    options={options[itm.skill]}
                                                    styles={styles}
                                                    inputClass="f-l f-reg input-shadow"
                                                />
                                            </div>
                                        </div>
                                    ))}
                        </div>
                    </>
                )
            ) : (
                <>
                    <div className="fp-title f-sem-bol">
                        Leadership Skills
                        <br />
                        (Optional)
                    </div>
                    <div className="fp-content-container">
                        <div className="si-row-4 d-flex align-items-center justify-content-between">
                            <span className="title-softskillsv">Skills</span>
                            <span className="title-softskillsv">Rating</span>
                        </div>
                        {skillLists &&
                            skillLists.length > 0 &&
                            skillLists
                                .filter((itm) => itm.skillType === 'leadership')
                                .map((itm, nkey) => (
                                    <div key={nkey}>
                                        <div className="si-row-1 d-flex align-items-center justify-content-between">
                                            <input
                                                type="text"
                                                id="fname"
                                                name="fname"
                                                value={itm.skill}
                                                title={itm.skill}
                                                className="other-skills-input"
                                                disabled={true}
                                            />
                                            <Select
                                                isSearchable={false}
                                                name="skill-rating"
                                                menuPlacement="auto"
                                                className="w-58 f-reg select-input-container select-input-other"
                                                value={{
                                                    label: itm?.rating
                                                        ? getSoftRating(itm.skill, parseInt(itm?.rating)).value
                                                        : 'Select',
                                                    value: itm?.rating ? parseInt(itm?.rating) : '',
                                                }}
                                                onChange={(e) =>
                                                    handleRatingComment('skills', 'rating', e.value, itm?.position)
                                                }
                                                options={options[itm.skill]}
                                                styles={styles}
                                                inputClass="f-l f-reg input-shadow"
                                            />
                                        </div>
                                    </div>
                                ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default OtherSkills;

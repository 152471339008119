/* eslint-disable no-constant-condition */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { flexCenter, styled } from '@100mslive/roomkit-react';
import { getInterviewToken } from '../../../redux/action';
import MeetJoin from './MeetJoin';
import { ROLES } from '../../../common/constants';
const MeetContainer = ({ token, onJoin, env, skipPreview, initialName }) => {
    const { role, roomId } = useParams();
    const dispatch = useDispatch();
    const interviewTokenDetails = useSelector((state) => state.Interview.interviewTokenDetails);
    useEffect(() => {
        if (!interviewTokenDetails) {
            dispatch(getInterviewToken({ roomId }));
        }
    }, [dispatch, roomId, interviewTokenDetails]);

    return (
        <Container style={{ height: '100%' }}>
            <MeetJoin
                initialName={role === ROLES.BEAM ? 'Beam' : initialName}
                skipPreview={skipPreview}
                env={env}
                onJoin={onJoin}
                token={token}
                role={role}
                interviewTokenDetails={interviewTokenDetails}
            />
        </Container>
    );
};

const Container = styled('div', {
    width: '100%',
    ...flexCenter,
    flexDirection: 'column',
});

export default MeetContainer;

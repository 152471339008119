import React from 'react';
const Instructions = ({ interviewDetails }) => {
    return (
        <div className="feedback-page h-100">
            {interviewDetails?.remarks && (
                <div className="instruction-div">
                    <div className="fp-title f-sem-bol">Basic Instructions</div>
                    <div className="instruction-text f-med">
                        <pre style={{ whiteSpace: 'pre-wrap' }} className="f-med">
                            {interviewDetails?.remarks}
                        </pre>
                        <br />
                        <br />
                    </div>
                </div>
            )}
            <div className="instruction-div">
                <div className="fp-title f-sem-bol">Interview Instructions</div>
                <div className="instruction-text f-med">
                    1. Use your laptop and not a smartphone for the Interview.
                    <br />
                    2. Check for good internet connectivity to avoid glitches and lag during the Interview.
                    <br />
                    3. Please ensure your free from a noisy background or surroundings.
                    <br /> 4. Login 5 mins early to ensure all of the above are in place.
                    <br />
                    <br />
                    Feel free to connect in case of any further clarifications are required.
                </div>
            </div>
        </div>
    );
};

export default Instructions;

import React from 'react';
import { SoftSkills, AnalyticsSkills, LeadershipSkills } from '../OtherComponents';
export const OtherSkills = ({ data }) => {
    const softSkills = data?.OtherSkillsList.filter((itm) => itm.type === 'soft');
    const analyticsSkills = data?.OtherSkillsList.filter((itm) => itm.type === 'analytics');
    const leadershipSkills = data?.OtherSkillsList.filter((itm) => itm.type === 'leadership');
    const type = data?.OtherSkillsType ? data?.OtherSkillsType : 1;
    return (
        <div className={type === 2 ? 'other-skillsv2 break-stop' : 'other-skills break-stop'}>
            <SoftSkills data={softSkills} type={type} />
            <AnalyticsSkills data={analyticsSkills} type={type} />
            {type === 2 && <LeadershipSkills data={leadershipSkills} type={type} />}
        </div>
    );
};

import React, { useEffect } from 'react';
import { Box, Flex, styled, Text } from '@100mslive/roomkit-react';
import FOFBanner from '../assets/images/404-banner.svg';
import { Header } from './Header';
function ErrorPage({ error }) {
    useEffect(() => {
        if (error) console.error(error);
    }, [error]);

    return (
        <Flex css={{ height: '100vh', width: '100vw' }} direction="column">
            <Box
                css={{
                    h: '$18',
                    background: '#191924',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                }}
                data-testid="header"
            >
                <Header />
            </Box>
            <Flex direction="column" css={{ size: '100%' }}>
                <Flex
                    justify="center"
                    direction="column"
                    align="center"
                    css={{ bg: '#2E2E41', flex: '1 1 0', position: 'relative' }}
                >
                    <Text
                        variant="body1"
                        css={{
                            fontStyle: 'normal',
                            fontWeight: '700',
                            fontSize: '40px',
                            lineHeight: '55px',
                            color: '#9396A0',
                            textAlign: 'center',
                        }}
                    >
                        OOPS!
                        <br />
                        Error 404 - Page not found
                    </Text>
                    <Text
                        variant="body1"
                        css={{
                            fontStyle: 'normal',
                            fontWeight: '600',
                            fontSize: '18px',
                            lineHeight: '25px',
                            color: '#FFFFFF',
                        }}
                    >
                        We could not find what you were looking for
                    </Text>
                    <br />
                    <LogoImg src={FOFBanner} alt="InCruiter Logo" width={615} height={354} />
                </Flex>
            </Flex>
        </Flex>
    );
}
const LogoImg = styled('img', {
    p: '$2',
});
ErrorPage.displayName = 'ErrorPage';

export default ErrorPage;

import React from 'react';
import MaleAvatar from '../../assets/images/MaleAvatar.svg';
import feedbackNo from '../../assets/images/feedbackNo.svg';
import feedbackYes from '../../assets/images/feedbackYes.svg';
import { capitalizeAll } from '../../utils/helpers';
import moment from 'moment';
export const Profile = ({ data }) => {
    return (
        <>
            <div className="profile-div-t2">
                <div className="profile-img-div">
                    <img className="profile-img-logo" src={data?.image ? data?.image : MaleAvatar} alt="Logo" />
                </div>
                <div className="profile-details-div desktop-mode">
                    <span className="common-profile-text profile-intId">{data?.intId}</span>
                    <span className="common-profile-text profile-details-text">
                        {capitalizeAll(data?.name)} | {capitalizeAll(data?.designation)}
                    </span>
                    <span className="common-profile-text profile-details-text">Company: {data?.company}</span>
                    <span className="common-profile-text profile-contact">
                        {data?.email} | {data?.phone}
                    </span>
                    <span className="common-profile-text profile-details-text">
                        DOB: {moment.utc(data?.dateOfBirth).format('ll')}
                    </span>
                    <span className="common-profile-text profile-details-text">
                        Joining Date: {moment.utc(data?.joiningDate).format('ll')}
                    </span>
                    <span className="common-profile-text profile-details-text">
                        Relieving Date: {moment.utc(data?.relievingDate).format('ll')}
                    </span>
                    <span className="common-profile-text profile-contact">
                        Date of Interview: {moment.utc(data?.scheduledDate).format('llll')}
                    </span>
                </div>
                <div className="profile-details-status-t2">
                    <br />
                    <center>
                        <span className="profile-join-title">Will Re-join?</span>
                    </center>
                    <img
                        className="profile-recommended-img-t2"
                        src={data?.reJoin === 'Yes' ? feedbackYes : feedbackNo}
                        alt="Logo"
                    />
                    {data?.newEmployer && <span className="profile-add-info1">New Employer: {data?.newEmployer}</span>}
                    {data?.industry && <span className="profile-add-info2">Industry: {data?.industry}</span>}
                </div>
            </div>
            <div className="profile-details-div mobile-mode">
                <div className="profile-details-div-1">
                    <span className="common-profile-text profile-intId">{data?.intId}</span>
                    <span className="common-profile-text profile-details-text">
                        {capitalizeAll(data?.name)} | {capitalizeAll(data?.designation)}
                    </span>
                    <span className="common-profile-text profile-details-text">Company: {data?.company}</span>
                    <span className="common-profile-text profile-contact">
                        {data?.email} | {data?.phone}
                    </span>
                    <span className="common-profile-text profile-details-text">
                        DOB: {moment.utc(data?.dateOfBirth).format('ll')}
                    </span>
                    <span className="common-profile-text profile-details-text">
                        Joining Date: {moment.utc(data?.joiningDate).format('ll')}
                    </span>
                    <span className="common-profile-text profile-details-text">
                        Relieving Date: {moment.utc(data?.relievingDate).format('ll')}
                    </span>
                    <span className="common-profile-text profile-contact">
                        Date of Interview: {moment(data?.interviewDate).format('llll')}
                    </span>
                </div>
            </div>
        </>
    );
};

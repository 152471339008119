import React, { useEffect } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { getRoutePrefix } from '../../common/utils';

const RedirectToPreview = ({ getDetails }) => {
    const { roomId, role } = useParams();
    const location = useLocation();
    useEffect(() => {
        getDetails();
    }, [roomId]); //eslint-disable-line

    if (!roomId && !role) {
        return <Navigate to="/" />;
    }
    if (!roomId) {
        return <Navigate to="/" />;
    }
    if (['streaming', 'preview', 'meeting', 'leave'].includes(roomId) && !role) {
        return <Navigate to="/" />;
    }
    const value = queryString.parse(location?.search);
    const key = value?.key;
    const name = value?.name;
    return (
        <Navigate
            to={`${getRoutePrefix()}/preview/${role}/${roomId}${key ? `?key=${key}` : ''}${
                name ? `&name=${name}` : ''
            }`}
        />
    );
};

export default RedirectToPreview;

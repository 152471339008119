/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Text } from '@100mslive/roomkit-react';
const TranscriptText = ({ url }) => {
    const [textData, setTextData] = useState('');
    useEffect(() => {
        fetch(url)
            .then((response) => response.text())
            .then((data) => {
                try {
                    const jsonData = JSON.parse(data);
                    setTextData(jsonData);
                } catch (parseError) {
                    console.error(`Error parsing JSON data from ${url}:`, parseError);
                }
            })
            .catch((error) => {
                console.error(`Error fetching data from ${url}:`, error);
            });
    }, [url]);
    const sections = textData?.sentences
        ?.filter((section) => section?.text && section?.text !== '')
        .map((section, index) => (
            <span key={index}>
                {section?.text}
                <br />
            </span>
        ));
    const sentences = textData?.sentences
        ?.filter((section) => section?.sentence && section?.sentence !== '')
        .map((section, index) => (
            <span key={index}>
                {section?.name ? `${section?.name}: ` : ''}
                {section?.sentence}
                <br />
            </span>
        ));
    return (
        textData.sentences && (
            <div
            // style={{
            //   backgroundColor: "#191924",
            //   padding: "10px",
            //   marginTop: "10px",
            //   overflowY: "auto",
            //   height: "calc(100vh - 120px)",
            // }}
            >
                <Text
                    variant="body1"
                    css={{
                        fontStyle: 'normal',
                        fontWeight: '300',
                        fontSize: '12px',
                        lineHeight: '18px',
                        letterSpacing: '5%',
                        color: '#FFFFFF',
                        textAlign: 'left',
                        fontFamily: 'Poppins !important',
                        '@md': { fontSize: '10px' },
                    }}
                >
                    {sentences}
                    {sections}
                </Text>
            </div>
        )
    );
};

export default TranscriptText;

import {
    ADD_SCREENSHOT,
    ADD_SCREENSHOT_FAILURE,
    ADD_SCREENSHOT_SUCCESS,
    CANDIDATE_CALL_END,
    CANDIDATE_CALL_END_FAILURE,
    CANDIDATE_CALL_END_SUCCESS,
    CODE_EDITOR_LANGUAGE,
    CODE_EDITOR_LANGUAGE_FAILURE,
    CODE_EDITOR_LANGUAGE_SUCCESS,
    CONTACT_ADMIN,
    CONTACT_ADMIN_FAILURE,
    CONTACT_ADMIN_SUCCESS,
    CREATE_AND_UPDATE_SCREENSHOT,
    CREATE_AND_UPDATE_SCREENSHOT_FAILURE,
    CREATE_AND_UPDATE_SCREENSHOT_SUCCESS,
    CREATE_CODE_SUBMISSION,
    CREATE_CODE_SUBMISSION_FAILURE,
    CREATE_CODE_SUBMISSION_SUCCESS,
    DELETE_RECORDING,
    DELETE_RECORDING_FAILURE,
    DELETE_RECORDING_SUCCESS,
    FETCH_SCREENSHOT_IMAGE,
    FETCH_SCREENSHOT_IMAGE_FAILURE,
    FETCH_SCREENSHOT_IMAGE_TYPE1_SUCCESS,
    FETCH_SCREENSHOT_IMAGE_TYPE2_SUCCESS,
    FETCH_SCREENSHOT_IMAGE_TYPE3_SUCCESS,
    FETCH_SCREENSHOT_IMAGE_TYPE4_SUCCESS,
    FETCH_SCREENSHOT_IMAGE_TYPE5_SUCCESS,
    FETCH_SCREENSHOT_IMAGEV2,
    FETCH_SCREENSHOT_IMAGEV2_FAILURE,
    FETCH_SCREENSHOT_IMAGEV2_SUCCESS,
    FETCH_SCREENSHOT_IMAGEV3,
    FETCH_SCREENSHOT_IMAGEV3_FAILURE,
    FETCH_SCREENSHOT_IMAGEV3_SUCCESS,
    FETCH_TRANSCRIPT,
    FETCH_TRANSCRIPT_FAILURE,
    FETCH_TRANSCRIPT_SUCCESS,
    GET_ALL_SCREENSHOT,
    GET_ALL_SCREENSHOT_FAILURE,
    GET_ALL_SCREENSHOT_SUCCESS,
    GET_CANDIDATE_INTERVIEW_DETAILS,
    GET_CANDIDATE_INTERVIEW_DETAILS_FAILURE,
    GET_CANDIDATE_INTERVIEW_DETAILS_SUCCESS,
    GET_CODE_SUBMISSION,
    GET_CODE_SUBMISSION_FAILURE,
    GET_CODE_SUBMISSION_SUCCESS,
    GET_CROP_IMAGE,
    GET_CROP_IMAGE_FAILURE,
    GET_CROP_IMAGE_SUCCESS,
    GET_FEEDBACK,
    GET_FEEDBACK_FAILURE,
    GET_FEEDBACK_SUCCESS,
    GET_INTERVIEW_DETAILS,
    GET_INTERVIEW_DETAILS_FAILURE,
    GET_INTERVIEW_DETAILS_SUCCESS,
    GET_INTERVIEW_TOKEN,
    GET_INTERVIEW_TOKEN_FAILURE,
    GET_INTERVIEW_TOKEN_SUCCESS,
    GET_INTERVIEWER_INTERVIEW_DETAILS,
    GET_INTERVIEWER_INTERVIEW_DETAILS_FAILURE,
    GET_INTERVIEWER_INTERVIEW_DETAILS_SUCCESS,
    GET_RECORDING_LIST,
    GET_RECORDING_LIST_FAILURE,
    GET_RECORDING_LIST_SUCCESS,
    GET_REGENERATED_AI_SUMMARY,
    GET_REGENERATED_AI_SUMMARY_FAILURE,
    GET_REGENERATED_AI_SUMMARY_SUCCESS,
    GET_SCREENSHOT,
    GET_SCREENSHOT_FAILURE,
    GET_SCREENSHOT_SUCCESS,
    GET_SKILLS,
    GET_SKILLS_FAILURE,
    GET_SKILLS_SUCCESS,
    GET_URL_GENERATED_ID,
    GET_URL_GENERATED_ID_FAILURE,
    GET_URL_GENERATED_ID_SUCCESS,
    INTERVIEWER_CALL_END,
    INTERVIEWER_CALL_END_FAILURE,
    INTERVIEWER_CALL_END_SUCCESS,
    MOVEMENT,
    MOVEMENT_FAILURE,
    MOVEMENT_SUCCESS,
    NOTIFY_INTERVIEWER,
    NOTIFY_INTERVIEWER_FAILURE,
    NOTIFY_INTERVIEWER_SUCCESS,
    POST_CAR_FEEDBACK_DETAILS,
    POST_CAR_FEEDBACK_DETAILS_FAILURE,
    POST_CAR_FEEDBACK_DETAILS_SUCCESS,
    POST_FEEDBACK_DETAILS,
    POST_FEEDBACK_DETAILS_FAILURE,
    POST_FEEDBACK_DETAILS_SUCCESS,
    POST_INTERVIEW_FEEDBACK,
    POST_INTERVIEW_FEEDBACK_FAILURE,
    POST_INTERVIEW_FEEDBACK_SUCCESS,
    POST_NOSHOW_FEEDBACK_DETAILS,
    POST_NOSHOW_FEEDBACK_DETAILS_FAILURE,
    POST_NOSHOW_FEEDBACK_DETAILS_SUCCESS,
    QUESTION_BANK,
    QUESTION_BANK_FAILURE,
    QUESTION_BANK_LIST,
    QUESTION_BANK_LIST_FAILURE,
    QUESTION_BANK_LIST_SUCCESS,
    QUESTION_BANK_SUCCESS,
    REMOVE_SCREENSHOT_IMAGE,
    ROOM_UPDATE,
    ROOM_UPDATE_FAILURE,
    ROOM_UPDATE_SUCCESS,
    SET_SHOW_FEEDBACK_PORTAL,
    SINGLE_CODE_EDITOR_LANGUAGE,
    SINGLE_CODE_EDITOR_LANGUAGE_FAILURE,
    SINGLE_CODE_EDITOR_LANGUAGE_SUCCESS,
    START_MEETING,
    START_MEETING_FAILURE,
    START_MEETING_SUCCESS,
    SWITCH_TAB,
    SWITCH_TAB_FAILURE,
    SWITCH_TAB_SUCCESS,
    VERIFY_INTERVIEW_KEY,
    VERIFY_INTERVIEW_KEY_FAILURE,
    VERIFY_INTERVIEW_KEY_SUCCESS,
    VIDEO_UPLOAD,
    VIDEO_UPLOAD_FAILURE,
    VIDEO_UPLOAD_SUCCESS,
} from './types';

export const postCarFeedbackDetails = (payload) => ({
    type: POST_CAR_FEEDBACK_DETAILS,
    payload,
});
export const postCarFeedbackDetailsSuccess = () => ({
    type: POST_CAR_FEEDBACK_DETAILS_SUCCESS,
});
export const postCarFeedbackDetailsFailure = () => ({
    type: POST_CAR_FEEDBACK_DETAILS_FAILURE,
});

export const roomUpdate = (payload) => ({
    type: ROOM_UPDATE,
    payload,
});
export const roomUpdateSuccess = () => ({
    type: ROOM_UPDATE_SUCCESS,
});
export const roomUpdateFailure = () => ({
    type: ROOM_UPDATE_FAILURE,
});

export const movementRequest = (payload) => ({
    type: MOVEMENT,
    payload,
});
export const movementSuccess = () => ({
    type: MOVEMENT_SUCCESS,
});
export const movementFailure = () => ({
    type: MOVEMENT_FAILURE,
});

export const switchTab = (payload) => ({
    type: SWITCH_TAB,
    payload,
});
export const switchTabSuccess = () => ({
    type: SWITCH_TAB_SUCCESS,
});
export const switchTabFailure = () => ({
    type: SWITCH_TAB_FAILURE,
});

export const postNoShowFeedbackDetails = (payload) => ({
    type: POST_NOSHOW_FEEDBACK_DETAILS,
    payload,
});
export const postNoShowFeedbackDetailsSuccess = () => ({
    type: POST_NOSHOW_FEEDBACK_DETAILS_SUCCESS,
});
export const postNoShowFeedbackDetailsFailure = () => ({
    type: POST_NOSHOW_FEEDBACK_DETAILS_FAILURE,
});

export const notifyInterviewer = (payload) => ({
    type: NOTIFY_INTERVIEWER,
    payload,
});
export const notifyInterviewerSuccess = () => ({
    type: NOTIFY_INTERVIEWER_SUCCESS,
});
export const notifyInterviewerFailure = () => ({
    type: NOTIFY_INTERVIEWER_FAILURE,
});

export const getCropUrl = (payload) => ({
    type: GET_CROP_IMAGE,
    payload,
});
export const getCropUrlSuccess = (payload) => ({
    type: GET_CROP_IMAGE_SUCCESS,
    payload,
});
export const getCropUrlFailure = () => ({
    type: GET_CROP_IMAGE_FAILURE,
});

export const getAllScreenshots = (payload) => ({
    type: GET_ALL_SCREENSHOT,
    payload,
});
export const getAllScreenshotsSuccess = (payload) => ({
    type: GET_ALL_SCREENSHOT_SUCCESS,
    payload,
});
export const getAllScreenshotsFailure = () => ({
    type: GET_ALL_SCREENSHOT_FAILURE,
});

export const getScreenshots = (payload) => ({
    type: GET_SCREENSHOT,
    payload,
});
export const getScreenshotsSuccess = (payload) => ({
    type: GET_SCREENSHOT_SUCCESS,
    payload,
});
export const getScreenshotsFailure = () => ({
    type: GET_SCREENSHOT_FAILURE,
});

export const createAndUpdateScreenshots = (payload) => ({
    type: CREATE_AND_UPDATE_SCREENSHOT,
    payload,
});
export const createAndUpdateScreenshotsSuccess = (payload) => ({
    type: CREATE_AND_UPDATE_SCREENSHOT_SUCCESS,
    payload,
});
export const createAndUpdateScreenshotsFailure = () => ({
    type: CREATE_AND_UPDATE_SCREENSHOT_FAILURE,
});

export const addScreenshot = (payload) => ({
    type: ADD_SCREENSHOT,
    payload,
});
export const addScreenshotSuccess = (payload) => ({
    type: ADD_SCREENSHOT_SUCCESS,
    payload,
});
export const addScreenshotFailure = () => ({
    type: ADD_SCREENSHOT_FAILURE,
});

export const getRecordings = (payload) => ({
    type: GET_RECORDING_LIST,
    payload,
});
export const getRecordingsSuccess = (payload) => ({
    type: GET_RECORDING_LIST_SUCCESS,
    payload,
});
export const getRecordingsFailure = () => ({
    type: GET_RECORDING_LIST_FAILURE,
});

export const deleteRecording = (payload) => ({
    type: DELETE_RECORDING,
    payload,
});

export const deleteRecordingSuccess = (payload) => ({
    type: DELETE_RECORDING_SUCCESS,
    payload,
});
export const deleteRecordingFailure = () => ({
    type: DELETE_RECORDING_FAILURE,
});

export const candidateCallEnd = (payload) => ({
    type: CANDIDATE_CALL_END,
    payload,
});
export const candidateCallEndSuccess = (payload) => ({
    type: CANDIDATE_CALL_END_SUCCESS,
    payload,
});
export const candidateCallEndFailure = () => ({
    type: CANDIDATE_CALL_END_FAILURE,
});

export const interviewerCallEnd = (payload) => ({
    type: INTERVIEWER_CALL_END,
    payload,
});
export const interviewerCallEndSuccess = (payload) => ({
    type: INTERVIEWER_CALL_END_SUCCESS,
    payload,
});
export const interviewerCallEndFailure = () => ({
    type: INTERVIEWER_CALL_END_FAILURE,
});

export const getSkills = () => ({
    type: GET_SKILLS,
});
export const getSkillsSuccess = (payload) => ({
    type: GET_SKILLS_SUCCESS,
    payload,
});
export const getSkillsFailure = () => ({
    type: GET_SKILLS_FAILURE,
});

export const getFeedback = (payload) => ({
    type: GET_FEEDBACK,
    payload,
});
export const getFeedbackSuccess = (payload) => ({
    type: GET_FEEDBACK_SUCCESS,
    payload,
});
export const getFeedbackFailure = () => ({
    type: GET_FEEDBACK_FAILURE,
});

export const verifyInterviewKey = (payload) => ({
    type: VERIFY_INTERVIEW_KEY,
    payload,
});
export const verifyInterviewKeySuccess = (payload) => ({
    type: VERIFY_INTERVIEW_KEY_SUCCESS,
    payload,
});
export const verifyInterviewKeyFailure = () => ({
    type: VERIFY_INTERVIEW_KEY_FAILURE,
});

export const removeScreenShotImage = () => ({
    type: REMOVE_SCREENSHOT_IMAGE,
});
export const fetchScreenShotImage = (payload) => ({
    type: FETCH_SCREENSHOT_IMAGE,
    payload,
});
export const fetchScreenShotImageType1Success = (payload) => ({
    type: FETCH_SCREENSHOT_IMAGE_TYPE1_SUCCESS,
    payload,
});
export const fetchScreenShotImageType2Success = (payload) => ({
    type: FETCH_SCREENSHOT_IMAGE_TYPE2_SUCCESS,
    payload,
});
export const fetchScreenShotImageType3Success = (payload) => ({
    type: FETCH_SCREENSHOT_IMAGE_TYPE3_SUCCESS,
    payload,
});
export const fetchScreenShotImageType4Success = (payload) => ({
    type: FETCH_SCREENSHOT_IMAGE_TYPE4_SUCCESS,
    payload,
});
export const fetchScreenShotImageType5Success = (payload) => ({
    type: FETCH_SCREENSHOT_IMAGE_TYPE5_SUCCESS,
    payload,
});
export const fetchScreenShotImageFailure = () => ({
    type: FETCH_SCREENSHOT_IMAGE_FAILURE,
});

export const fetchScreenShotImagev2 = (payload) => ({
    type: FETCH_SCREENSHOT_IMAGEV2,
    payload,
});
export const fetchScreenShotImagev2Success = (payload) => ({
    type: FETCH_SCREENSHOT_IMAGEV2_SUCCESS,
    payload,
});
export const fetchScreenShotImagev2Failure = () => ({
    type: FETCH_SCREENSHOT_IMAGEV2_FAILURE,
});

export const fetchScreenShotImagev3 = (payload) => ({
    type: FETCH_SCREENSHOT_IMAGEV3,
    payload,
});
export const fetchScreenShotImagev3Success = (payload) => ({
    type: FETCH_SCREENSHOT_IMAGEV3_SUCCESS,
    payload,
});
export const fetchScreenShotImagev3Failure = () => ({
    type: FETCH_SCREENSHOT_IMAGEV3_FAILURE,
});

export const getQuestionBank = (payload) => ({
    type: QUESTION_BANK,
    payload,
});
export const getQuestionBankSuccess = (payload) => ({
    type: QUESTION_BANK_SUCCESS,
    payload,
});
export const getQuestionBankFailure = () => ({
    type: QUESTION_BANK_FAILURE,
});

export const getQuestionBankLanguageList = () => ({
    type: QUESTION_BANK_LIST,
});
export const getQuestionBankListSuccess = (payload) => ({
    type: QUESTION_BANK_LIST_SUCCESS,
    payload,
});
export const getQuestionBankListFailure = () => ({
    type: QUESTION_BANK_LIST_FAILURE,
});

export const getInterviewDetails = (payload) => ({
    type: GET_INTERVIEW_DETAILS,
    payload,
});
export const getInterviewDetailsSuccess = (payload) => ({
    type: GET_INTERVIEW_DETAILS_SUCCESS,
    payload,
});
export const getInterviewDetailsFailure = () => ({
    type: GET_INTERVIEW_DETAILS_FAILURE,
});

export const getCandidateInterviewDetails = () => ({
    type: GET_CANDIDATE_INTERVIEW_DETAILS,
});
export const getCandidateInterviewDetailsSuccess = (payload) => ({
    type: GET_CANDIDATE_INTERVIEW_DETAILS_SUCCESS,
    payload,
});
export const getCandidateInterviewDetailsFailure = () => ({
    type: GET_CANDIDATE_INTERVIEW_DETAILS_FAILURE,
});

export const getInterviewerInterviewDetails = (payload) => ({
    type: GET_INTERVIEWER_INTERVIEW_DETAILS,
    payload,
});
export const getInterviewerInterviewDetailsSuccess = (payload) => ({
    type: GET_INTERVIEWER_INTERVIEW_DETAILS_SUCCESS,
    payload,
});
export const getInterviewerInterviewDetailsFailure = () => ({
    type: GET_INTERVIEWER_INTERVIEW_DETAILS_FAILURE,
});

export const getInterviewToken = (payload) => ({
    type: GET_INTERVIEW_TOKEN,
    payload,
});
export const getInterviewTokenSuccess = (payload) => ({
    type: GET_INTERVIEW_TOKEN_SUCCESS,
    payload,
});
export const getInterviewTokenFailure = () => ({
    type: GET_INTERVIEW_TOKEN_FAILURE,
});

export const postFeedbackDetails = (payload) => ({
    type: POST_FEEDBACK_DETAILS,
    payload,
});
export const postFeedbackDetailsSuccess = () => ({
    type: POST_FEEDBACK_DETAILS_SUCCESS,
});
export const postFeedbackDetailsFailure = () => ({
    type: POST_FEEDBACK_DETAILS_FAILURE,
});

export const postInterviewFeedback = (payload) => ({
    type: POST_INTERVIEW_FEEDBACK,
    payload,
});
export const postInterviewFeedbackSuccess = () => ({
    type: POST_INTERVIEW_FEEDBACK_SUCCESS,
});
export const postInterviewFeedbackFailure = () => ({
    type: POST_INTERVIEW_FEEDBACK_FAILURE,
});

export const contactAdmin = (payload) => ({
    type: CONTACT_ADMIN,
    payload,
});
export const contactAdminSuccess = () => ({
    type: CONTACT_ADMIN_SUCCESS,
});
export const contactAdminFailure = () => ({
    type: CONTACT_ADMIN_FAILURE,
});

export const getCodeEditorLanguages = () => ({
    type: CODE_EDITOR_LANGUAGE,
});
export const getCodeEditorLanguagesSuccess = (payload) => ({
    type: CODE_EDITOR_LANGUAGE_SUCCESS,
    payload,
});
export const getCodeEditorLanguagesFailure = () => ({
    type: CODE_EDITOR_LANGUAGE_FAILURE,
});

export const getSingleCodeEditorLanguages = (payload) => ({
    type: SINGLE_CODE_EDITOR_LANGUAGE,
    payload,
});
export const getSingleCodeEditorLanguagesSuccess = (payload) => ({
    type: SINGLE_CODE_EDITOR_LANGUAGE_SUCCESS,
    payload,
});
export const getSingleCodeEditorLanguagesFailure = () => ({
    type: SINGLE_CODE_EDITOR_LANGUAGE_FAILURE,
});

export const getCodeSubmission = (payload) => ({
    type: GET_CODE_SUBMISSION,
    payload,
});
export const getCodeSubmissionSuccess = (payload) => ({
    type: GET_CODE_SUBMISSION_SUCCESS,
    payload,
});
export const getCodeSubmissionFailure = () => ({
    type: GET_CODE_SUBMISSION_FAILURE,
});

export const createCodeSubmission = (payload) => ({
    type: CREATE_CODE_SUBMISSION,
    payload,
});
export const createCodeSubmissionSuccess = (payload) => ({
    type: CREATE_CODE_SUBMISSION_SUCCESS,
    payload,
});
export const createCodeSubmissionFailure = () => ({
    type: CREATE_CODE_SUBMISSION_FAILURE,
});

export const setShowFeedbackPortal = (payload) => ({
    type: SET_SHOW_FEEDBACK_PORTAL,
    payload,
});

// zoom sdk
export const startMeeting = (payload) => ({
    type: START_MEETING,
    payload,
});

export const startMeetingSuccess = (payload) => ({
    type: START_MEETING_SUCCESS,
    payload,
});

export const startMeetingFailure = () => ({
    type: START_MEETING_FAILURE,
});

// url generated id
export const getUrlGeneratedId = (payload) => ({
    type: GET_URL_GENERATED_ID,
    payload,
});

export const getUrlGeneratedIdSuccess = (payload) => ({
    type: GET_URL_GENERATED_ID_SUCCESS,
    payload,
});

export const getUrlGeneratedIdFailure = () => ({
    type: GET_URL_GENERATED_ID_FAILURE,
});
// Regenerate AI summary
export const getRegeneratedAiSummary = (payload) => ({
    type: GET_REGENERATED_AI_SUMMARY,
    payload,
});

export const getRegeneratedAiSummarySuccess = (payload) => ({
    type: GET_REGENERATED_AI_SUMMARY_SUCCESS,
    payload,
});

export const getRegeneratedAiSummaryFailure = () => ({
    type: GET_REGENERATED_AI_SUMMARY_FAILURE,
});
export const fetchTranscript = (payload) => ({
    type: FETCH_TRANSCRIPT,
    payload,
});
export const fetchTranscriptSuccess = () => ({
    type: FETCH_TRANSCRIPT_SUCCESS,
});
export const fetchTranscriptFailure = () => ({
    type: FETCH_TRANSCRIPT_FAILURE,
});
// Video Upload
export const videoUpload = (payload) => ({
    type: VIDEO_UPLOAD,
    payload,
});
export const videoUploadSuccess = () => ({
    type: VIDEO_UPLOAD_SUCCESS,
});
export const videoUploadFailure = () => ({
    type: VIDEO_UPLOAD_FAILURE,
});

import {
    GET_MEET_INFO,
    GET_MEET_INFO_FAILURE,
    GET_MEET_INFO_SUCCESS,
    GET_REGENERATED_SUMMARY,
    GET_REGENERATED_SUMMARY_FAILURE,
    GET_REGENERATED_SUMMARY_SUCCESS,
    GET_TRANSCRIPT,
    GET_TRANSCRIPT_FAILURE,
    GET_TRANSCRIPT_SUCCESS,
} from '../action/types';

const INIT_STATE = {
    loading: false,
    meetInfo: null,
    fetchLoading: false,
    fetchLoadingFail: false,
};

const Meet = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_MEET_INFO:
            return { ...state, loading: true };
        case GET_MEET_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                meetInfo: action.payload,
            };
        case GET_MEET_INFO_FAILURE:
            return { ...state, loading: false };

        case GET_REGENERATED_SUMMARY:
            return { ...state, loading: true };
        case GET_REGENERATED_SUMMARY_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case GET_REGENERATED_SUMMARY_FAILURE:
            return { ...state, loading: false };

        case GET_TRANSCRIPT:
            return { ...state, fetchLoading: true, fetchLoadingFail: false };
        case GET_TRANSCRIPT_SUCCESS:
            return {
                ...state,
                fetchLoading: false,
                fetchLoadingFail: false,
            };
        case GET_TRANSCRIPT_FAILURE:
            return {
                ...state,
                fetchLoading: false,
                fetchLoadingFail: true,
            };

        default:
            return state;
    }
};
export default Meet;

import React from 'react';
import { Flex } from '@100mslive/roomkit-react';
import { Logo } from './HeaderComponents';

export const ConferencingHeader = () => {
    return (
        <Flex justify="between" align="center" css={{ position: 'relative', height: '100%' }}>
            <Flex align="center" css={{ position: 'absolute', left: '$10' }}>
                <Logo />
            </Flex>
        </Flex>
    );
};

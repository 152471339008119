import { v4 } from 'uuid';

export const ToastManager = {
    toasts: new Map(),
    listeners: new Map(),
    currentToastId: null,
    addToast(toast) {
        if (this.currentToastId) {
            // Close the previous toast
            this.removeToast(this.currentToastId);
        }
        const id = toast.id ? toast.id : v4();
        this.toasts.set(id, { ...toast, id });
        this.currentToastId = id; // Update current toast ID
        this.onChange();
        return id;
    },

    clearAllToast() {
        this.toasts.clear();
        this.currentToastId = null; // Reset current toast ID
        this.onChange();
    },

    removeToast(id) {
        this.toasts.delete(id);
        if (this.currentToastId === id) {
            this.currentToastId = null; // Reset current toast ID
        }
        this.onChange();
    },
    replaceToast(id, toast) {
        if (this.isActive(id)) {
            this.toasts.set(id, { ...this.toasts.get(id), ...toast });
            this.onChange();
            return id;
        } else {
            return this.addToast(toast);
        }
    },
    addListener(cb) {
        this.listeners.set(cb, cb);
    },
    removeListener(cb) {
        this.listeners.delete(cb);
    },
    isActive(id) {
        return this.toasts.has(id);
    },
    onChange() {
        const toasts = Array.from(this.toasts.values());
        this.listeners.forEach((listener) => listener(toasts));
    },
};

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Footer as AppFooter, IconButton, Tooltip } from '@100mslive/roomkit-react';
import {
    parsedUserAgent,
    selectIsAllowedToPublish,
    selectIsConnectedToRoom,
    selectUnreadHMSMessagesCount,
    useAVToggle,
    useHMSActions,
    useHMSStore,
    useRecordingStreaming,
    useScreenShare,
} from '@100mslive/react-sdk';
import { ChatIcon, ChatUnreadIcon, HangUpIcon, ShareScreenIcon } from '@100mslive/react-icons';
import { AudioVideoToggle } from '../../components/AudioVideoToggle';
import { ParticipantCount } from '../../components/Footer/ParticipantList';
import MetaActions from '../../components/MetaActions';
import { MoreSettings } from '../../components/MoreSettings/MoreSettings';
import { ToastManager } from '../../components/Toast/ToastManager';
import recordingImage from '../../assets/images/recording.svg';
import whiteboardIcon from '../../assets/images/whiteboardIcon.svg';
import TimerIcon from '../../images/TIMERICON.svg';
import { VirtualBackground } from '../../plugins/VirtualBackground/VirtualBackground';
import { useSidepaneToggle } from '../../components/AppData/useSidepane';
import { useSetAppDataByKey, useUISettings } from '../../components/AppData/useUISettings';
import { useNavigation } from '../../components/hooks/useNavigation';
import { isScreenshareSupported } from '../../common/utils';
import { generateUrl } from '../../utils/helpers';
import { APP_DATA, RTMP_RECORD_DEFAULT_RESOLUTION, SIDE_PANE_OPTIONS } from '../../common/constants';
const isMacOS = parsedUserAgent.getOS().name.toLowerCase() === 'mac os';
export const MeetFooter = ({ setTab, tab }) => {
    const { isBrowserRecordingOn } = useRecordingStreaming();
    const [, setRecordingState] = useSetAppDataByKey(APP_DATA.recordingStarted);
    const { role, roomId } = useParams();
    const hmsActions = useHMSActions();
    const { isLocalVideoEnabled } = useAVToggle();
    const isAllowedToPublish = useHMSStore(selectIsAllowedToPublish);
    const { isAudioOnly } = useUISettings();
    const { amIScreenSharing, screenShareVideoTrackId: video, toggleScreenShare } = useScreenShare();
    const isVideoScreenShare = amIScreenSharing && !!video;
    const navigate = useNavigation();
    const isConnected = useHMSStore(selectIsConnectedToRoom);

    const [timer, setTimer] = useState(0);
    const [start, setStart] = useState(false);
    const [resolution] = useState(RTMP_RECORD_DEFAULT_RESOLUTION);

    useEffect(() => {
        setStart(isBrowserRecordingOn);
    }, [isBrowserRecordingOn]);

    useEffect(() => {
        let interval;
        if (isBrowserRecordingOn || start) {
            interval = setInterval(() => setTimer((prev) => prev + 1), 1000);
        } else {
            setTimer(0);
        }
        return () => clearInterval(interval);
    }, [start, isBrowserRecordingOn]);

    const displaySecondsAsMins = (seconds) => new Date(seconds * 1000).toISOString().substr(11, 8);

    const handleRecordingOff = async () => {
        try {
            setStart(false);
            if (isBrowserRecordingOn) {
                await hmsActions.stopRTMPAndRecording();
            }
            setRecordingState(false);
            ToastManager.addToast({
                title: 'Recording has been stopped successfully.',
                variant: 'success',
            });
        } catch (error) {
            console.error(error.message);
        }
    };

    const handleRecordingOn = async () => {
        try {
            setStart(true);
            setRecordingState(true);
            const recordingUrl = `${generateUrl('meeting')}/meet/preview/beam/${roomId}?skip_preview=true`;
            if (!isBrowserRecordingOn) {
                await hmsActions.startRTMPOrRecording({
                    meetingURL: recordingUrl,
                    resolution,
                    record: true,
                });
            }
            ToastManager.addToast({
                title: 'Recording has been started successfully.',
                variant: 'success',
            });
        } catch (error) {
            console.error(error.message);
        }
    };

    const handleClick = async () => {
        if (isBrowserRecordingOn || start) {
            await handleRecordingOff();
        } else {
            await handleRecordingOn();
        }
    };

    const leaveRoom = async () => {
        await hmsActions.leave();
        if (isBrowserRecordingOn) {
            await handleRecordingOff();
        }
        navigate(`/meet/leave/${role}/${roomId}`);
    };

    if (!isConnected || !isAllowedToPublish.screen || !isScreenshareSupported()) {
        return null;
    }

    return (
        <AppFooter.Root className="video-footer-btn">
            <AppFooter.Center className="video-footer preview-meet">
                <AudioVideoToggle />
                <Tooltip
                    title={`Turn ${isBrowserRecordingOn || start ? 'off' : 'on'} recording (${isMacOS ? '⌘' : 'ctrl'} + e)`}
                >
                    <IconButton
                        onClick={handleClick}
                        data-testid="video_btn"
                        className={`preview-btn${!(isBrowserRecordingOn || start) ? '' : ' meet-disable-btn'}`}
                    >
                        <img src={recordingImage} alt="Recording" />
                    </IconButton>
                </Tooltip>
                {(isBrowserRecordingOn || start) && (
                    <button data-testid="video_btn" className="video-timer" style={{ cursor: 'auto' }}>
                        <img src={TimerIcon} style={{ width: '10px', margin: '5px' }} alt="timer" />{' '}
                        {displaySecondsAsMins(timer)}
                    </button>
                )}
                <Tooltip title={`${!isVideoScreenShare ? 'Start' : 'Stop'} screen sharing`}>
                    <IconButton
                        active={!isVideoScreenShare}
                        disabled={isAudioOnly}
                        onClick={toggleScreenShare}
                        data-testid="screen_share_btn"
                        className={`preview-btn${!isVideoScreenShare ? '' : ' meet-disable-btn'}`}
                    >
                        <ShareScreenIcon />
                    </IconButton>
                </Tooltip>
                {isLocalVideoEnabled && <VirtualBackground />}
                <MetaActions />
                <Tooltip title="Participant List">
                    <ParticipantCount />
                </Tooltip>
                <Tooltip title="More Settings">
                    <MoreSettings />
                </Tooltip>
                <Flex align="center" css={{ '@md': { display: 'flex' } }}>
                    <ChatToggle setTab={setTab} tab={tab} />
                </Flex>
                <WhiteToggle setTab={setTab} tab={tab} />
                <Tooltip title="Leave Room">
                    <IconButton
                        onClick={leaveRoom}
                        variant="danger"
                        data-testid="leave_room_btn"
                        className="preview-btn meet-disable-btn"
                    >
                        <HangUpIcon />
                    </IconButton>
                </Tooltip>
            </AppFooter.Center>
        </AppFooter.Root>
    );
};

const ChatToggle = ({ setTab, tab }) => {
    const countUnreadMessages = useHMSStore(selectUnreadHMSMessagesCount);
    const isChatOpen = tab === 2;
    const toggleChat = useSidepaneToggle(SIDE_PANE_OPTIONS.CHAT);

    return (
        <Tooltip key="chat" title={`${isChatOpen ? 'Close' : 'Open'} chat`}>
            <IconButton
                onClick={() => {
                    toggleChat();
                    setTab(tab === 2 ? 1 : 2);
                }}
                active={!isChatOpen}
                data-testid="chat_btn"
                className={`preview-btn${!isChatOpen ? '' : ' meet-disable-btn'}`}
            >
                {countUnreadMessages === 0 ? <ChatIcon /> : <ChatUnreadIcon data-testid="chat_unread_btn" />}
            </IconButton>
        </Tooltip>
    );
};
const WhiteToggle = ({ setTab, tab }) => {
    const isWhiteBoardOpen = tab === 3;
    return (
        <Tooltip key="whiteboard" title={`${isWhiteBoardOpen ? 'Close' : 'Open'} whiteboard`}>
            <IconButton
                onClick={() => {
                    setTab(tab === 3 ? 1 : 3);
                }}
                active={!isWhiteBoardOpen}
                data-testid="whiteboard_btn"
                className={`preview-btn${!isWhiteBoardOpen ? '' : ' meet-disable-btn'}`}
            >
                <img src={whiteboardIcon} style={{ width: '15px' }} />
            </IconButton>
        </Tooltip>
    );
};

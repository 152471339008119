import React, { useState } from 'react';
import _debounce from 'lodash/debounce';
import FinalRemarks from './Components/FinalRemarks';
import Instructions from './Components/Instructions';
import ScreeningQuestions from './Components/ScreeningQuestions';
import departmentalQuestion from '../../../images/departmentalQuestion.svg';
import finalRemarksWhite from '../../../images/fr-logo-White.svg';
import informationWhite from '../../../images/information-img-White.svg';
import otherSkillWhite from '../../../images/os-logo-White.svg';
import positionQuestion from '../../../images/positionQuestion.svg';
import screeningQuestionWhite from '../../../images/qs-logo-White.svg';
import { ToastManager } from '../../Toast/ToastManager';
export const Body = ({ form, interviewDetails, setForm, socket, setSeconds, seconds }) => {
    const [addQuestion, setAddQuestion] = useState({
        organizationEvaQues: '',
        departmentalEvaQues: '',
        positionEvaQues: '',
        screeningQuestions: '',
    });
    const handleAddQuestion = _debounce((questionType, question) => {
        let tmpData = form?.screeningQuestions ?? [];
        if (question === '') {
            ToastManager.addToast({
                title: "Question can't be empty.",
            });
        } else if (question.length < 5) {
            ToastManager.addToast({
                title: `Question can't be less than 5 characters.`,
            });
        } else if (form?.screeningQuestions.length >= form?.questionCount?.max) {
            ToastManager.addToast({
                title: `No Of Questions Cannot Exceed More Than ${form?.questionCount?.max}`,
            });
        } else {
            tmpData.push({
                question: question,
                comment: '',
                commentRequired: false,
                commentLength: { min: 0, max: 1000 },
                position: tmpData.length,
                delete: true,
                type: 'custom',
                commentBoxVisible: false,
                questionType: questionType,
            });
        }
        setForm((prevState) => ({
            ...prevState,
            screeningQuestions: tmpData,
            codeUpdated: true,
        }));
        let nData = {
            screeningQuestions: tmpData,
            codeUpdated: true,
            roleName: form?.role,
        };
        socket?.emit('codeWritten', nData, form?.roomId);
    }, 300);

    const handleDeleteQuestion = (question) => {
        let tmpData = form?.screeningQuestions ?? [];
        tmpData = tmpData.filter((que) => que.question !== question);
        setForm((prevState) => ({
            ...prevState,
            screeningQuestions: tmpData,
            codeUpdated: true,
        }));
    };

    const handleCommentUpdate = (question, comment) => {
        let tmpData = form?.screeningQuestions ?? [];
        tmpData = tmpData.map((item) => {
            if (item.question === question) {
                return { ...item, comment: comment };
            }
            return item;
        });
        setForm((prevState) => ({
            ...prevState,
            screeningQuestions: tmpData,
            codeUpdated: true,
        }));
    };

    const [page, setPage] = useState(0);

    const SwitchPage = (page) => {
        switch (page) {
            case 0:
                return <Instructions interviewDetails={interviewDetails} />;
            case 1:
                return (
                    <ScreeningQuestions
                        title="Position Evaluation"
                        type="positionEvaQues"
                        data={form?.screeningQuestions.filter((item) => item?.questionType === 'positionEvaQues')}
                        handleAdd={handleAddQuestion}
                        handleDelete={handleDeleteQuestion}
                        handleComment={handleCommentUpdate}
                        interviewDetails={interviewDetails}
                        role={form?.role}
                        addQuestion={addQuestion?.positionEvaQues}
                        setAddQuestion={setAddQuestion}
                    />
                );
            case 2:
                return (
                    <ScreeningQuestions
                        title="Departmental Evaluation"
                        type="departmentalEvaQues"
                        data={form?.screeningQuestions.filter((item) => item?.questionType === 'departmentalEvaQues')}
                        handleAdd={handleAddQuestion}
                        handleDelete={handleDeleteQuestion}
                        handleComment={handleCommentUpdate}
                        interviewDetails={interviewDetails}
                        role={form?.role}
                        addQuestion={addQuestion?.departmentalEvaQues}
                        setAddQuestion={setAddQuestion}
                    />
                );
            case 3:
                return (
                    <ScreeningQuestions
                        title="Organization Evaluation"
                        type="organizationEvaQues"
                        data={form?.screeningQuestions.filter((item) => item?.questionType === 'organizationEvaQues')}
                        handleAdd={handleAddQuestion}
                        handleDelete={handleDeleteQuestion}
                        handleComment={handleCommentUpdate}
                        interviewDetails={interviewDetails}
                        role={form?.role}
                        addQuestion={addQuestion?.organizationEvaQues}
                        setAddQuestion={setAddQuestion}
                    />
                );
            case 4:
                return (
                    <ScreeningQuestions
                        title="Other Questions"
                        type="screeningQuestions"
                        data={form?.screeningQuestions.filter((item) => item?.questionType === 'screeningQuestions')}
                        handleAdd={handleAddQuestion}
                        handleDelete={handleDeleteQuestion}
                        handleComment={handleCommentUpdate}
                        interviewDetails={interviewDetails}
                        role={form?.role}
                        addQuestion={addQuestion?.screeningQuestions}
                        setAddQuestion={setAddQuestion}
                    />
                );
            case 5:
                return (
                    <FinalRemarks form={form} setForm={setForm} interviewDetails={interviewDetails} socket={socket} />
                );
            default:
                return <Instructions interviewDetails={interviewDetails} />;
        }
    };
    return (
        <div className="feedback-container">
            <div className="feedback-btn-container">
                <div
                    className={`feedback-btn ${page === 0 ? 'theme-peacock-bg' : 'theme-gray-bg'}`}
                    title="Information"
                    onClick={() => setPage(0)}
                >
                    <img src={informationWhite} alt="mandatory skills logo" className="feedback-img" />
                </div>
                <div
                    className={`feedback-btn ${page === 1 ? 'theme-peacock-bg' : 'theme-gray-bg'}`}
                    title="Position Evaluation"
                    onClick={() => setPage(1)}
                >
                    <img src={positionQuestion} alt="Position Evaluation" className="feedback-img" />
                </div>
                <div
                    className={`feedback-btn ${page === 2 ? 'theme-peacock-bg' : 'theme-gray-bg'}`}
                    title="Departmental Evaluation"
                    onClick={() => setPage(2)}
                >
                    <img src={departmentalQuestion} alt="Departmental Evaluation" className="feedback-img" />
                </div>
                <div
                    className={`feedback-btn ${page === 3 ? 'theme-peacock-bg' : 'theme-gray-bg'}`}
                    title="Organization Evaluation"
                    onClick={() => setPage(3)}
                >
                    <img src={otherSkillWhite} alt="Organization Evaluation" className="feedback-img" />
                </div>
                <div
                    className={`feedback-btn ${page === 4 ? 'theme-peacock-bg' : 'theme-gray-bg'}`}
                    title="Other Questions"
                    onClick={() => setPage(4)}
                >
                    <img src={screeningQuestionWhite} alt="Other Questions" className="feedback-img" />
                </div>
                <div
                    className={`feedback-btn ${page === 5 ? 'theme-peacock-bg' : 'theme-gray-bg'}`}
                    title="Final Submission"
                    onClick={() => setPage(5)}
                >
                    <img src={finalRemarksWhite} alt="Final Submission" className="feedback-img" />
                </div>
            </div>
            <div className="feedback-page-container">{SwitchPage(page)}</div>
        </div>
    );
};

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import _debounce from 'lodash/debounce';
import { postExitFeedback, postExitNoShowFeedback } from '../../../../redux/action';
import { ToastManager } from '../../../Toast/ToastManager';
const FinalRemarks = ({ form, setForm, interviewDetails, socket }) => {
    const dispatch = useDispatch();
    const { roomId } = useParams();
    const [selectedPositiveNotes, setSelectedPositiveNotes] = useState([]);
    const [selectedExitReasons, setSelectedExitReasons] = useState([]);

    useEffect(() => {
        let tmpList1 = [],
            tmpList2 = [];
        form.positionNotes &&
            form.positionNotes.length > 0 &&
            form.positionNotes.map((itm) => {
                tmpList1.push({ label: itm, value: itm });
            });
        setSelectedPositiveNotes(tmpList1);
        form.exitReasons &&
            form.exitReasons.length > 0 &&
            form.exitReasons.map((itm) => {
                tmpList2.push({ label: itm, value: itm });
            });
        setSelectedExitReasons(tmpList2);
    }, [form?.positionNotes, form?.exitReasons]);

    const handleRemarks = (type, value) => {
        setForm((prevState) => ({
            ...prevState,
            [type]: value,
            codeUpdated: true,
        }));
    };

    const handleSelectChange = (data, type) => {
        let tmplist = [];
        data && data.length > 0 && data.map((itm) => tmplist.push(itm.value));
        setForm((prevState) => ({
            ...prevState,
            [type?.name]: tmplist,
            codeUpdated: true,
        }));
    };

    const handleSubmit = _debounce(() => {
        let submitStatus = true;
        if (form?.status === 'noshow') {
            dispatch(postExitNoShowFeedback({ interviewId: form.interviewId }));
        } else {
            if (form?.screeningQuestions && form?.screeningQuestions.length > 0) {
                form?.screeningQuestions.forEach((qs, index) => {
                    if (qs?.commentRequired && qs?.comment === '') {
                        ToastManager.addToast({
                            title: `Screening Question Number ${index} Is Required.`,
                        });
                        submitStatus = false;
                    } else if (qs?.commentRequired && qs?.comment.length < qs?.commentLength?.min) {
                        ToastManager.addToast({
                            title: `Screening Question ${index} Comments Requires A Minimum Of ${qs?.commentLength?.min} Characters`,
                        });
                        submitStatus = false;
                    }
                });
            }
            if (interviewDetails?.finalRemarks?.commentRequired && (form?.finalRemarks === '' || !form?.finalRemarks)) {
                ToastManager.addToast({
                    title: "Final remarks can't be empty.",
                });
            } else if (
                interviewDetails?.finalRemarks?.commentRequired &&
                form?.finalRemarks.length < interviewDetails?.finalRemarks?.commentLength.min
            ) {
                ToastManager.addToast({
                    title: `Final remarks can't be less than ${interviewDetails?.finalRemarks?.commentLength.min} characters`,
                });
            } else if (form.positionNotes.length === 0) {
                ToastManager.addToast({
                    title: "Re - Join can't be empty.",
                });
            } else if (form.exitReasons.length === 0) {
                ToastManager.addToast({
                    title: "Re - Join can't be empty.",
                });
                console.log(form, 'exitReasons');
            } else if (form.reJoin === '') {
                ToastManager.addToast({
                    title: "Re - Join can't be empty.",
                });
                console.log(form, 'reJoin');
            } else if (form.retentionEfforts === '') {
                ToastManager.addToast({
                    title: "Retention Efforts can't be empty.",
                });
                console.log(form, 'retentionEfforts');
            } else if (form.newEmployer === '') {
                ToastManager.addToast({
                    title: "New Employer can't be empty.",
                });
                console.log(form, 'newEmployer');
            } else if (form.industry === '') {
                ToastManager.addToast({
                    title: "Industry can't be empty.",
                });
                console.log(form, 'industry');
            } else if (form.overallRating === '') {
                ToastManager.addToast({
                    title: "Overall rating can't be empty.",
                });
                console.log(form, 'overallRating');
            } else if (form.recommendFriendsFamily === '') {
                ToastManager.addToast({
                    title: "Recommend Friends Family rating can't be empty.",
                });
                console.log(form, 'recommendFriendsFamily');
            } else if (form.transparency === '') {
                ToastManager.addToast({
                    title: "Transparency rating can't be empty.",
                });
                console.log(form, 'transparency');
            } else if (form.workLifeBalance === '') {
                ToastManager.addToast({
                    title: "Work Life Balance rating can't be empty.",
                });
                console.log(form, 'workLifeBalance');
            } else {
                let status = '';
                if ('finalRating' && form.overallRating > -1 && form.overallRating < 3) {
                    status = 'very_poor';
                } else if ('finalRating' && form.overallRating > 2 && form.overallRating < 5) {
                    status = 'poor';
                } else if ('finalRating' && form.overallRating === 5) {
                    status = 'average';
                } else if ('finalRating' && form.overallRating === 6) {
                    status = 'above_average';
                } else if ('finalRating' && form.overallRating === 7) {
                    status = 'good';
                } else if ('finalRating' && form.overallRating === 8) {
                    status = 'very_good';
                } else if ('finalRating' && form.overallRating > 8 && form.overallRating < 11) {
                    status = 'excellent';
                }
                if (submitStatus) {
                    dispatch(
                        postExitFeedback({
                            type: 1,
                            interviewId: form.interviewId,
                            data: {
                                ...form,
                                status: status,
                                roomId: roomId,
                                type: 'finalsave',
                                isUpdated: true,
                            },
                        })
                    );
                }
            }
        }
    }, 300);
    const styles2 = {
        control: (provided, state) => ({
            ...provided,
            color: '#ffffff',
            backgroundColor: '#2e2e41',
            borderColor: state.isFocused ? '#80bdff' : provided.borderColor,
            boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : provided.boxShadow,
        }),
        menu: (provided) => ({
            ...provided,
            color: '#ffffff',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '275',
            lineHeight: 'normal',
            letterSpacing: '0.6px',
            backgroundColor: '#2e2e41',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#ffffff' : '#2e2e41',
            color: state.isFocused ? '#2e2e41' : '#ffffff',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#ffffff',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '275',
            lineHeight: 'normal',
            letterSpacing: '0.6px',
            backgroundColor: '#2e2e41',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#ffffff',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            letterSpacing: '0.6px',
            backgroundColor: '#2e2e41',
        }),
        valueContainer: (provided) => ({
            ...provided,
            backgroundColor: '#2e2e41',
            color: '#ffffff',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#12B6BC',
            borderRadius: '5px',
            color: '#fff',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: '#fff',
            fontWeight: 'bold',
        }),
    };
    const styles = {
        control: (provided, state) => ({
            ...provided,
            color: '#ffffff',
            backgroundColor: '#2e2e41',
            borderColor: state.isFocused ? '#80bdff' : provided.borderColor,
            boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : provided.boxShadow,
        }),
        menu: (provided) => ({
            ...provided,
            color: '#ffffff',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '275',
            lineHeight: 'normal',
            letterSpacing: '0.6px',
            backgroundColor: '#2e2e41',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#ffffff' : '#2e2e41',
            color: state.isFocused ? '#2e2e41' : '#ffffff',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#ffffff',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '275',
            lineHeight: 'normal',
            letterSpacing: '0.6px',
            backgroundColor: '#2e2e41',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#ffffff',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            letterSpacing: '0.6px',
            backgroundColor: '#2e2e41',
        }),
        valueContainer: (provided) => ({
            ...provided,
            backgroundColor: '#2e2e41',
            color: '#ffffff',
        }),
    };
    const ratingList = [
        { label: 0, value: 0 },
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
        { label: 6, value: 6 },
        { label: 7, value: 7 },
        { label: 8, value: 8 },
        { label: 9, value: 9 },
        { label: 10, value: 10 },
    ];
    return (
        <div className="final-remarks-page h-100">
            <div className="fp-content-container">
                <div className="fp-title f-sem-bol">Final Submission</div>
                <div className="mt-2 w-100 text-end">
                    <span className="f-14 f-sem-bol text-theme-white text-left exit-feedback-title">
                        Positive notes about the company
                    </span>
                    <Select
                        name="positionNotes"
                        isMulti={true}
                        menuPlacement="auto"
                        isSearchable={false}
                        className="f-reg exit-feedback-select"
                        value={selectedPositiveNotes}
                        onChange={handleSelectChange}
                        options={[
                            { label: 'Select Options', value: '' },
                            {
                                label: 'Company Policy',
                                value: 'Company Policy',
                            },
                            { label: 'Compensation', value: 'Compensation' },
                            {
                                label: 'Other benefits like health incentives etc. ',
                                value: 'Other benefits like health incentives etc. ',
                            },
                            {
                                label: 'Positive Atmosphere',
                                value: 'Positive Atmosphere',
                            },
                            {
                                label: 'Training & Development',
                                value: 'Training & Development',
                            },
                            {
                                label: 'Open & Honest Communication',
                                value: 'Open & Honest Communication',
                            },
                            { label: 'Flexibility', value: 'Flexibility' },
                            {
                                label: 'Cooperation from Teammates',
                                value: 'Cooperation from Teammates',
                            },
                            {
                                label: 'Office Facilities',
                                value: 'Office Facilities',
                            },
                            {
                                label: 'Task Assigned & Management',
                                value: 'Task Assigned & Management',
                            },
                            {
                                label: 'Competitive Atmosphere',
                                value: 'Competitive Atmosphere',
                            },
                            {
                                label: 'Others',
                                value: 'Others',
                            },
                        ]}
                        styles={styles2}
                        inputClass="f-l f-reg input-shadow"
                    />
                    {form?.positionNotes.includes('Others') && (
                        <textarea
                            name="positionNotesComment"
                            className="w-100 final-remarks f-reg"
                            placeholder="Add Comments..."
                            value={form?.positionNotesComment ? form?.positionNotesComment : ''}
                            onChange={(e) => handleRemarks('positionNotesComment', e.target.value)}
                            disabled={false}
                            onPaste={(e) => e.stopPropagation()}
                            onCut={(e) => e.stopPropagation()}
                            onCopy={(e) => e.stopPropagation()}
                        ></textarea>
                    )}
                </div>
                <div className="mt-2 w-100 text-end">
                    <span className="f-14 f-sem-bol text-theme-white text-left exit-feedback-title">
                        Reason For Exit
                    </span>
                    <Select
                        name="exitReasons"
                        isMulti={true}
                        menuPlacement="auto"
                        isSearchable={false}
                        className="f-reg exit-feedback-select"
                        value={selectedExitReasons}
                        onChange={handleSelectChange}
                        options={[
                            { label: 'Select Options', value: '' },
                            {
                                label: 'Better Opportunity',
                                value: 'Better Opportunity',
                            },
                            {
                                label: 'Lack of Career Growth',
                                value: 'Lack of Career Growth',
                            },
                            {
                                label: 'Work Life Balance',
                                value: 'Work Life Balance',
                            },
                            {
                                label: 'Travel difficulties',
                                value: 'Travel difficulties',
                            },
                            {
                                label: 'Health',
                                value: 'Health',
                            },
                            {
                                label: 'Poor Work Environment',
                                value: 'Poor Work Environment',
                            },
                            {
                                label: 'Relocation',
                                value: 'Relocation',
                            },
                            {
                                label: 'Family/Personal Reasons',
                                value: 'Family/Personal Reasons',
                            },
                            {
                                label: 'Higher Education',
                                value: 'Higher Education',
                            },
                            {
                                label: 'Layoff/Retirement',
                                value: 'Layoff/Retirement',
                            },
                            {
                                label: 'HR Policies',
                                value: 'HR Policies',
                            },
                            {
                                label: 'Company Culture',
                                value: 'Company Culture',
                            },

                            {
                                label: 'Conflict',
                                value: 'Conflict',
                            },
                            {
                                label: 'Supervisor/Manager',
                                value: 'Supervisor/Manager',
                            },
                            {
                                label: 'Role Clarity',
                                value: 'Role Clarity',
                            },
                            {
                                label: 'Others',
                                value: 'Others',
                            },
                        ]}
                        styles={styles2}
                        inputClass="f-l f-reg input-shadow"
                    />
                    {form?.exitReasons.includes('Others') && (
                        <textarea
                            name="exitReasonsComment"
                            className="w-100 final-remarks f-reg"
                            placeholder="Add Comments..."
                            value={form?.exitReasonsComment ? form?.exitReasonsComment : ''}
                            onChange={(e) => handleRemarks('exitReasonsComment', e.target.value)}
                            disabled={false}
                            onPaste={(e) => e.stopPropagation()}
                            onCut={(e) => e.stopPropagation()}
                            onCopy={(e) => e.stopPropagation()}
                        ></textarea>
                    )}
                </div>
                <div className="mt-2 w-100 text-end">
                    <span className="f-14 f-sem-bol text-theme-white text-left exit-feedback-title">
                        Rejoin Company?
                    </span>
                    <Select
                        name="reJoin"
                        menuPlacement="auto"
                        isSearchable={false}
                        className="f-reg exit-feedback-select"
                        value={{
                            label: form?.reJoin ?? 'Select Options',
                            value: form?.reJoin ?? '',
                        }}
                        onChange={(e) => handleRemarks('reJoin', e.value)}
                        options={[
                            { label: 'Select Options', value: '' },
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                        ]}
                        styles={styles}
                        inputClass="f-l f-reg input-shadow"
                    />
                </div>
                <div className="mt-2 w-100 text-end">
                    <span className="f-14 f-sem-bol text-theme-white text-left exit-feedback-title">
                        Retention Efforts
                    </span>
                    <Select
                        name="retentionEfforts"
                        menuPlacement="auto"
                        isSearchable={false}
                        className="f-reg exit-feedback-select"
                        value={{
                            label: form?.retentionEfforts ?? 'Select Options',
                            value: form?.retentionEfforts ?? '',
                        }}
                        onChange={(e) => handleRemarks('retentionEfforts', e.value)}
                        options={[
                            { label: 'Select Options', value: '' },
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                        ]}
                        styles={styles}
                        inputClass="f-l f-reg input-shadow"
                    />
                </div>
                <div className="mt-2 w-100 text-end">
                    <span className="f-14 f-sem-bol text-theme-white text-left exit-feedback-title">New Employer</span>
                    <input
                        type="text"
                        id="newEmployer"
                        name="newEmployer"
                        className="exit-input-field"
                        value={form?.newEmployer}
                        placeholder="Enter new organization name"
                        onChange={(e) => handleRemarks('newEmployer', e.target.value)}
                    />
                </div>
                <div className="mt-2 w-100 text-end">
                    <span className="f-14 f-sem-bol text-theme-white text-left exit-feedback-title">Industry</span>
                    <Select
                        name="industry"
                        menuPlacement="auto"
                        isSearchable={false}
                        className="f-reg exit-feedback-select"
                        value={{
                            label: form?.industry ?? 'Select Options',
                            value: form?.industry ?? '',
                        }}
                        onChange={(e) => handleRemarks('industry', e.value)}
                        options={[
                            { label: 'Select Options', value: '' },
                            { label: 'Same', value: 'Same' },
                            { label: 'Not same', value: 'Not same' },
                        ]}
                        styles={styles}
                        inputClass="f-l f-reg input-shadow"
                    />
                </div>
                <div>
                    <div className="fp-title f-sem-bol">Rating Scales</div>
                    <div className="fp-content-container">
                        <div className="si-row-1 d-flex align-items-center justify-content-between">
                            <div className="exit-input-disabled">Work Life Balance</div>
                            <Select
                                name="workLifeBalance"
                                menuPlacement="auto"
                                isSearchable={false}
                                className="f-reg select-container-rating f-right"
                                value={{
                                    label: form?.workLifeBalance !== '' ? parseInt(form?.workLifeBalance) : 'Select',
                                    value: form?.workLifeBalance !== '' ? parseInt(form?.workLifeBalance) : '',
                                }}
                                onChange={(e) => handleRemarks('workLifeBalance', e.value)}
                                options={ratingList}
                                styles={styles}
                                inputClass="f-l f-reg input-shadow"
                            />
                        </div>
                        <div className="si-row-1 d-flex align-items-center justify-content-between">
                            <div className="exit-input-disabled">Transparency (Promotion and Appraisal)</div>
                            <Select
                                name="transparency"
                                menuPlacement="auto"
                                isSearchable={false}
                                className="f-reg select-container-rating f-right"
                                value={{
                                    label: form?.transparency !== '' ? parseInt(form?.transparency) : 'Select',
                                    value: form?.transparency !== '' ? parseInt(form?.transparency) : '',
                                }}
                                onChange={(e) => handleRemarks('transparency', e.value)}
                                options={ratingList}
                                styles={styles}
                                inputClass="f-l f-reg input-shadow"
                            />
                        </div>
                        <div className="si-row-1 d-flex align-items-center justify-content-between">
                            <div className="exit-input-disabled">Recommend Friends Family</div>
                            <Select
                                name="recommendFriendsFamily"
                                menuPlacement="auto"
                                isSearchable={false}
                                className="f-reg select-container-rating f-right"
                                value={{
                                    label:
                                        form?.recommendFriendsFamily !== ''
                                            ? parseInt(form?.recommendFriendsFamily)
                                            : 'Select',
                                    value:
                                        form?.recommendFriendsFamily !== ''
                                            ? parseInt(form?.recommendFriendsFamily)
                                            : '',
                                }}
                                onChange={(e) => handleRemarks('recommendFriendsFamily', e.value)}
                                options={ratingList}
                                styles={styles}
                                inputClass="f-l f-reg input-shadow"
                            />
                        </div>
                        <div className="si-row-1 d-flex align-items-center justify-content-between">
                            <div className="exit-input-disabled">Overall Rating</div>
                            <Select
                                name="overallRating"
                                menuPlacement="auto"
                                isSearchable={false}
                                className="f-reg select-container-rating f-right"
                                value={{
                                    label: form?.overallRating !== '' ? parseInt(form?.overallRating) : 'Select',
                                    value: form?.overallRating !== '' ? parseInt(form?.overallRating) : '',
                                }}
                                onChange={(e) => handleRemarks('overallRating', e.value)}
                                options={ratingList}
                                styles={styles}
                                inputClass="f-l f-reg input-shadow"
                            />
                        </div>
                        <textarea
                            name="finalRemarks"
                            className="w-100 final-remarks f-reg"
                            placeholder="Add Comments..."
                            value={form?.finalRemarks ? form?.finalRemarks : ''}
                            onChange={(e) => handleRemarks('finalRemarks', e.target.value)}
                            disabled={false}
                            onPaste={(e) => e.stopPropagation()}
                            onCut={(e) => e.stopPropagation()}
                            onCopy={(e) => e.stopPropagation()}
                        ></textarea>
                        <div className="f-11 text-limit">
                            <span className="f-reg text-theme-gray">
                                ({form?.finalRemarks && form?.finalRemarks !== null ? form?.finalRemarks.length : 0}/
                                {interviewDetails?.finalRemarks?.commentLength?.max})
                            </span>
                            {interviewDetails?.finalRemarks?.commentRequired ? (
                                interviewDetails?.finalRemarks?.commentLength.min > 0 && form?.finalRemarks ? (
                                    form?.finalRemarks.length < 175 && (
                                        <span className="f-bol text-theme-red text-right" style={{ float: 'right' }}>
                                            Minimum {interviewDetails?.finalRemarks?.commentLength.min} Characters
                                        </span>
                                    )
                                ) : (
                                    <span className="f-bol text-theme-red text-right" style={{ float: 'right' }}>
                                        Minimum {interviewDetails?.finalRemarks?.commentLength.min} Characters
                                    </span>
                                )
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
                <div className="d-center fr-submit">
                    <button className="fr-submit-btn text-theme-white f-sem-bol" onClick={() => handleSubmit()}>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FinalRemarks;

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDebounce, useMeasure } from 'react-use';
import { FixedSizeList } from 'react-window';
import { Avatar, Box, Flex, Input, Text } from '@100mslive/roomkit-react';
import { selectPeers, useHMSStore, useParticipants } from '@100mslive/react-sdk';
import { CrossIcon, PeopleIcon, SearchIcon } from '@100mslive/react-icons';
import IconButton from '../../IconButton';
import { useIsSidepaneTypeOpen, useSidepaneToggle } from '../AppData/useSidepane';
import { SIDE_PANE_OPTIONS } from '../../common/constants';

export const ParticipantList = () => {
    const [filter, setFilter] = useState();
    const { participants, isConnected, peerCount } = useParticipants(filter);
    const [, setSelectedPeerId] = useState(null);
    const toggleSidepane = useSidepaneToggle(SIDE_PANE_OPTIONS.PARTICIPANTS);
    const onSearch = useCallback((value) => {
        setFilter((filterValue) => {
            if (!filterValue) {
                filterValue = {};
            }
            filterValue.search = value;
            return { ...filterValue };
        });
    }, []);
    if (peerCount === 0) {
        return null;
    }

    return (
        <Fragment>
            <Flex direction="column" css={{ size: '100%' }}>
                <Flex align="center" css={{ w: '100%', mb: '$10' }}>
                    <Text css={{ fontWeight: '$semiBold', mr: '$4' }}>Participants</Text>
                    <IconButton onClick={toggleSidepane} css={{ w: '$11', h: '$11', ml: 'auto' }}>
                        <CrossIcon />
                    </IconButton>
                </Flex>
                {!filter?.search && participants.length === 0 ? null : <ParticipantSearch onSearch={onSearch} />}
                {participants.length === 0 && (
                    <Flex align="center" justify="center" css={{ w: '100%', p: '$8 0' }}>
                        <Text variant="sm">{!filter ? 'No participants' : 'No matching participants'}</Text>
                    </Flex>
                )}
                <VirtualizedParticipants
                    participants={participants}
                    isConnected={isConnected}
                    setSelectedPeerId={setSelectedPeerId}
                />
            </Flex>
        </Fragment>
    );
};

export const ParticipantCount = () => {
    let peers = useHMSStore(selectPeers);
    const peerCount = peers.filter((itm) => itm?.roleName !== 'beam').length;
    const toggleSidepane = useSidepaneToggle(SIDE_PANE_OPTIONS.PARTICIPANTS);
    const isParticipantsOpen = useIsSidepaneTypeOpen(SIDE_PANE_OPTIONS.PARTICIPANTS);
    useEffect(() => {
        if (isParticipantsOpen && peerCount === 0) {
            toggleSidepane();
        }
    }, [isParticipantsOpen, peerCount, toggleSidepane]);

    if (peerCount === 0) {
        return null;
    }
    return (
        <IconButton
            css={{
                w: 'auto',
                p: '$4',
                h: 'auto',
            }}
            onClick={() => {
                if (peerCount > 0) {
                    toggleSidepane();
                }
            }}
            active={!isParticipantsOpen}
            data-testid="participant_list"
            className="participant_list"
        >
            <PeopleIcon />
            <Text variant="sm" css={{ mx: '$4', c: 'inherit' }}>
                {peerCount}
            </Text>
        </IconButton>
    );
};

function itemKey(index, data) {
    return data.participants[index].id;
}

const VirtualizedParticipants = ({ participants, isConnected, setSelectedPeerId }) => {
    const [ref, { width, height }] = useMeasure();
    return (
        <Box
            ref={ref}
            css={{
                flex: '1 1 0',
                mr: '-$10',
            }}
        >
            <FixedSizeList
                itemSize={68}
                itemData={{ participants, isConnected, setSelectedPeerId }}
                itemKey={itemKey}
                itemCount={participants.length}
                width={width}
                height={height}
            >
                {VirtualisedParticipantListItem}
            </FixedSizeList>
        </Box>
    );
};

const VirtualisedParticipantListItem = React.memo(({ style, index, data }) => {
    return (
        data.participants[index].name !== 'Beam' && (
            <div key={data.participants[index].id}>
                <Participant peer={data.participants[index]} />
            </div>
        )
    );
});

const Participant = ({ peer }) => {
    return (
        <Fragment>
            <Flex
                key={peer.id}
                css={{ w: '100%', py: '$4', pr: '$10' }}
                align="center"
                data-testid={'participant_' + peer.name}
            >
                <Avatar
                    name={peer.name}
                    css={{
                        position: 'unset',
                        transform: 'unset',
                        mr: '$8',
                        fontSize: '$sm',
                        size: '$12',
                        p: '$4',
                    }}
                />
                <Flex direction="column" css={{ flex: '1 1 0' }}>
                    <Text variant="md" css={{ fontWeight: '$semiBold' }}>
                        {peer.name} ({peer?.roleName})
                    </Text>
                </Flex>
            </Flex>
        </Fragment>
    );
};

export const ParticipantSearch = ({ onSearch, placeholder }) => {
    const [value, setValue] = React.useState('');
    useDebounce(
        () => {
            onSearch(value);
        },
        300,
        [value, onSearch]
    );
    return (
        <Box css={{ p: '$4 0', my: '$8', position: 'relative' }}>
            <Box
                css={{
                    position: 'absolute',
                    left: '$4',
                    top: '$2',
                    transform: 'translateY(50%)',
                    color: '#ffffff',
                }}
            >
                <SearchIcon />
            </Box>
            <Input
                type="text"
                placeholder={placeholder || 'Find what you are looking for'}
                css={{ w: '100%', pl: '$14' }}
                value={value}
                onKeyDown={(event) => {
                    event.stopPropagation();
                }}
                onChange={(event) => {
                    setValue(event.currentTarget.value);
                }}
                autoComplete="off"
                aria-autocomplete="none"
            />
        </Box>
    );
};

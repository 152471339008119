import { combineReducers } from 'redux';
import ExitInterview from './exitInterviewReducer';
import Interview from './interviewReducer';
import Meet from './meetReducer';
const appReducer = combineReducers({ Interview, ExitInterview, Meet });

const reducers = (state, action) => {
    return appReducer(state, action);
};

export default reducers;

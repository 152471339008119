import React, { useEffect, useState } from 'react';
import { Flex, Text } from '@100mslive/roomkit-react';

const MeetRecording = ({ meetInfo }) => {
    const [recordedVideoList, setRecordedVideoList] = useState([]);
    const [recordingStatus, setRecordingStatus] = useState(false);

    useEffect(() => {
        setRecordingStatus(meetInfo?.recording?.length > 0 || 0);
        setRecordedVideoList(meetInfo?.recording || []);
    }, [meetInfo]);

    return (
        <Flex
            justify="center"
            direction="column"
            align="center"
            className="recordingDiv"
            css={{
                bg: '#2E2E41',
                flex: '1 1 0',
                position: 'relative',
            }}
        >
            {recordingStatus ? (
                recordedVideoList.map((itm, index) => (
                    <div key={index} className="video_container">
                        <video width="320" height="240" controls name="media">
                            <source src={itm} type="video/mp4" />
                        </video>
                    </div>
                ))
            ) : (
                <Text
                    variant="body1"
                    css={{
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '40px',
                        lineHeight: '55px',
                        color: '#9396A0',
                        textAlign: 'center',
                    }}
                >
                    OOPS!
                    <br />
                    No Recording Found
                </Text>
            )}
        </Flex>
    );
};
export default MeetRecording;

import React from 'react';
export const OverallSkills = ({ data }) => {
    return (
        <div className="other-skills break-stop">
            <div className="soft-skills-wrapper">
                <div className="soft-skills-div">
                    <span className="soft-skills-span">{data?.positiveNotes?.title}</span>
                </div>
                <div className="soft-new-wrapper">
                    {data?.positiveNotes?.values && data?.positiveNotes?.values.length > 0
                        ? data?.positiveNotes?.values.map((itm) => (
                              <span className="feedback-note feedback-green">{itm}</span>
                          ))
                        : 'Not Available'}
                </div>
            </div>
            <div className="phychological-skills-wrapper">
                <div className="phychological-skills-div">
                    <span className="phychological-skills-span">{data?.exitReasons?.title}</span>
                </div>
                <div className="soft-new-wrapper">
                    {data?.exitReasons?.values && data?.exitReasons?.values.length > 0
                        ? data?.exitReasons?.values.map((itm) => (
                              <span className="feedback-note feedback-red">{itm}</span>
                          ))
                        : 'Not Available'}
                </div>
            </div>
        </div>
    );
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getInterviewToken } from '../../redux/action';
import CarFeedbackRoom from './CarFeedbackRoom';
import CommonFeedbackRoom from './CommonFeedbackRoom';

const UpdateFeedbackRoom = () => {
    const dispatch = useDispatch();
    const { interviewId, roomId } = useParams();
    const interviewTokenDetails = useSelector((state) => state.Interview.interviewTokenDetails);
    useEffect(() => {
        if (!interviewTokenDetails && roomId && interviewId) {
            dispatch(getInterviewToken({ roomId: roomId, interviewId: interviewId }));
        }
    }, [dispatch, roomId, interviewId]);
    return interviewTokenDetails ? (
        interviewTokenDetails?.carSystem ? (
            <CarFeedbackRoom />
        ) : (
            <CommonFeedbackRoom />
        )
    ) : (
        <></>
    );
};
export default UpdateFeedbackRoom;

import React from 'react';
export const SkillComment = ({ title, skills, position }) => {
    return (
        <div className="soft-skill-wrapper break-stop">
            <div className="font-style-header-soft-skill soft-skill-header-600">{title}</div>
            {position === 'left' && (
                <>
                    <div className="soft-skill-header-outer">
                        <div className="soft-skill-header-type-1 font-style-header-soft-skill">{title}</div>
                    </div>
                </>
            )}
            <div className="soft-skill-content">
                {skills &&
                    skills.length > 0 &&
                    skills.map((item, key) => (
                        <p className="font-style-soft-skill break-stop" key={key}>
                            <strong>{item.name} </strong>
                            <span className={`${item?.color}`}>
                                <strong>({item?.rating})</strong>
                            </span>
                            <br /> {item.comment}
                        </p>
                    ))}
            </div>
            {position === 'right' && (
                <>
                    <div className="soft-skill-header-outer">
                        <div className="soft-skill-header-type-2 font-style-header-soft-skill">{title}</div>
                    </div>
                </>
            )}
        </div>
    );
};

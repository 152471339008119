import React from 'react';
import { Skill } from '../OtherComponents';
export const Skills = ({ data }) => {
    let MandatorySkills = data.filter((itm) => itm.type === 'mandatory');
    let OptionalSkills = data.filter((itm) => itm.type === 'optional');
    let MandatorySkillsLength = MandatorySkills.length;
    let OptionalSkillsLength = OptionalSkills.length;
    return (
        <div className="skill-rating-div mb-0">
            {MandatorySkillsLength > 0 && <Skill title="Mandatory Skills" skills={MandatorySkills} />}
            {OptionalSkillsLength > 0 && <Skill title="Optional Skills" skills={OptionalSkills} />}
        </div>
    );
};

/**
 * reset hlsStarted, rtmpStarted values when streaming starts
 */

import { useEffect } from 'react';
import {
    HMSRoomState,
    selectHLSState,
    selectRoomState,
    selectRTMPState,
    useHMSStore,
    useRecordingStreaming,
} from '@100mslive/react-sdk';
import { useSetAppDataByKey } from '../useUISettings';
import { APP_DATA, SIDE_PANE_OPTIONS } from '../../../common/constants';
import { useIsSidepaneTypeOpen, useSidepaneToggle } from '../useSidepane';

/**
 * reset hlsStarted, rtmpStarted values when streaming starts
 */
const ResetStreamingStart = () => {
    const { isHLSRunning, isRTMPRunning, isBrowserRecordingOn } = useRecordingStreaming();
    const hlsError = useHMSStore(selectHLSState).error;
    const rtmpError = useHMSStore(selectRTMPState).error;
    const roomState = useHMSStore(selectRoomState);
    const [hlsStarted, setHLSStarted] = useSetAppDataByKey(APP_DATA.hlsStarted);
    const [recordingStarted, setRecordingStarted] = useSetAppDataByKey(APP_DATA.recordingStarted);
    const [rtmpStarted, setRTMPStarted] = useSetAppDataByKey(APP_DATA.rtmpStarted);
    const toggleStreaming = useSidepaneToggle(SIDE_PANE_OPTIONS.STREAMING);
    const isStreamingOpen = useIsSidepaneTypeOpen(SIDE_PANE_OPTIONS.STREAMING);

    useEffect(() => {
        if (isBrowserRecordingOn && recordingStarted) {
            setRecordingStarted(false);
        }
    }, [isBrowserRecordingOn, recordingStarted, setRecordingStarted]);
    /**
     * Reset on leave
     */
    useEffect(() => {
        if (roomState === HMSRoomState.Disconnected) {
            setHLSStarted(false);
            setRecordingStarted(false);
            setRTMPStarted(false);
        }
    }, [roomState, setHLSStarted, setRTMPStarted, setRecordingStarted]);
    useEffect(() => {
        if (isHLSRunning || hlsError) {
            if (hlsStarted) {
                setHLSStarted(false);
                if (isStreamingOpen) {
                    toggleStreaming();
                }
            }
        }
    }, [isHLSRunning, hlsStarted, setHLSStarted, hlsError, isStreamingOpen, toggleStreaming]);
    useEffect(() => {
        if (isRTMPRunning || rtmpError || isBrowserRecordingOn) {
            if (rtmpStarted) {
                setRTMPStarted(false);
                if (isStreamingOpen) {
                    toggleStreaming();
                }
            }
        }
    }, [isRTMPRunning, setRTMPStarted, rtmpStarted, rtmpError, isBrowserRecordingOn, isStreamingOpen, toggleStreaming]);
    return null;
};

export default ResetStreamingStart;

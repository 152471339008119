import React from 'react';
import { Box, Flex } from '@100mslive/roomkit-react';
import { selectLocalPeerID, selectPeers, useHMSStore } from '@100mslive/react-sdk';
import MeetVideoList from './MeetVideoList';

export const MeetGridView = ({ setTab, tab }) => {
    const peers = useHMSStore(selectPeers);
    const localPeerId = useHMSStore(selectLocalPeerID);
    const centerPeers = peers.length > 1 ? peers.filter((peer) => peer.id !== localPeerId) : peers;

    return (
        <Flex
            css={{
                size: '100%',
                display: 'flex',
            }}
            direction={{
                '@initial': 'row',
                '@md': 'column',
            }}
        >
            <Flex
                css={{
                    width: '100%',
                    height: '100%',
                }}
            >
                <Box
                    css={{
                        flex: '1 1 0',
                        height: '100%',
                    }}
                >
                    {peers.length > 0 && (
                        <MeetVideoList peers={[centerPeers[0]]} size={1} setTab={setTab} tab={tab} maxTileCount={1} />
                    )}
                </Box>
            </Flex>
        </Flex>
    );
};

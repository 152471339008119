import React from 'react';
import logoImg from '../../assets/images/incruiter-main-logo.svg';
export const Header = ({ data }) => {
    return (
        <div className="header">
            <div className="img-div">
                <img className="img-logo" src={data?.link ? data?.link : logoImg} alt="Logo" />
            </div>
            <div className="header-content-wrapper">
                <button className="btn-g" onClick={() => window.print()}>
                    Print
                </button>
            </div>
        </div>
    );
};

import React from 'react';
import { toast } from 'react-toastify';
import { all, put, takeEvery } from 'redux-saga/effects';
import {
    getMeetInfoFailure,
    getMeetInfoSuccess,
    getRegeneratedSummaryFailure,
    getRegeneratedSummarySuccess,
    getTranscriptFailure,
    getTranscriptSuccess,
} from '../action';
import { GET_MEET_INFO, GET_REGENERATED_SUMMARY, GET_TRANSCRIPT } from '../action/types';
import MEET from '../../utils/apiUrl/meet';
import { Toast } from '../../utils/helpers';

function* getMeetInfoRequest(action) {
    try {
        let res = yield MEET.get(`getMeetInfo/${action.payload.roomId}`);
        if (res.status === 200 || res.status === 201) {
            yield put(getMeetInfoSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getMeetInfoFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

function* getRegeneratedSummaryRequest(action) {
    try {
        let res = yield MEET.get(`getGenerateSummary/${action.payload.roomId}`);
        if (res.status === 200 || res.status === 201) {
            yield put(getRegeneratedSummarySuccess(res.data.result));
        }
    } catch (e) {
        yield put(getRegeneratedSummaryFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

function* getTranscriptRequest(action) {
    try {
        let res = yield MEET.get(`getTranscript/${action.payload.roomId}`);
        if (res.status === 200) {
            yield put(getTranscriptSuccess(res.data.result));
        } else {
            yield put(getTranscriptFailure());
        }
    } catch (e) {
        yield put(getTranscriptFailure());
    }
}

export function* watchGetMeetInfoRequestAPI() {
    yield takeEvery(GET_MEET_INFO, getMeetInfoRequest);
}
export function* watchGetRegeneratedSummaryRequestAPI() {
    yield takeEvery(GET_REGENERATED_SUMMARY, getRegeneratedSummaryRequest);
}
export function* watchGetTranscriptRequestAPI() {
    yield takeEvery(GET_TRANSCRIPT, getTranscriptRequest);
}
export default function* rootSaga() {
    yield all([watchGetMeetInfoRequestAPI(), watchGetRegeneratedSummaryRequestAPI(), watchGetTranscriptRequestAPI()]);
}

import React from 'react';
import logoImg from '../../assets/images/incruiter-main-logo.svg';
export const Footer = ({ data }) => {
    return (
        <div>
            <div className="footer">
                <img className="footer-logo" src={data?.link ? data?.link : logoImg} alt="Logo" />
            </div>
            <div className="rating-system">
                <span>{'Very Poor : <=2'}</span>
                <span>Poor : 3 to 4</span>
                <span>Average : 5</span>
                <span>Above Average : 6</span>
                <span>Good : 7</span>
                <span>Very Good : 8</span>
                <span>{'Excellent >= 9'}</span>
            </div>
        </div>
    );
};

import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Button, Flex, styled, Text } from '@100mslive/roomkit-react';
import queryString from 'query-string';
import { ExitIcon } from '@100mslive/react-icons';
import InterviewFeedback from './PageComponent/InterviewFeedback';
import { ToastManager } from './Toast/ToastManager';
import logo from '../images/incruiter-logo.svg';
import { defaultPreviewPreference, UserPreferencesKeys, useUserPreferences } from './hooks/useUserPreferences';
import '../base.css';
import '../index.css';
import { generateUrl } from '../utils/helpers';

const PostLeave = () => {
    const { roomId, role } = useParams();
    const [newName, setNewName] = useState('');
    const [interviewKey, setInterviewKey] = useState('');
    const [showFeedback, setShowFeedback] = useState(false);
    const location = useLocation();
    useEffect(() => {
        if (role !== 'candidate') {
            // eslint-disable-next-line no-restricted-globals
            const value = queryString.parse(location?.search);
            const key = value?.key;
            const name = value?.name;
            if (key) {
                setInterviewKey(key);
            }
            if (name) {
                setNewName(name);
            }
        }
    }, [role, location?.search]);

    let previewUrl =
        generateUrl('meeting') +
        `/preview/${role}/${roomId}${interviewKey ? `?key=${interviewKey}` : ''}${newName ? `&name=${newName}` : ''}`;
    // eslint-disable-next-line no-unused-vars
    const [previewPreference] = useUserPreferences(UserPreferencesKeys.PREVIEW, defaultPreviewPreference);
    return (
        <Flex direction="column" css={{ height: '100vh', width: '100vw' }}>
            {!showFeedback && (
                <InterviewFeedback
                    setShowFeedback={setShowFeedback}
                    showFeedback={showFeedback}
                    role={role}
                    roomId={roomId}
                />
            )}
            <Flex
                justify="center"
                direction="column"
                align="center"
                css={{ bg: '#191924', flex: '1 1 0', position: 'relative' }}
            >
                <LogoImg src={logo} alt="InCruiter Logo" width={265} height={108} />
                <br />
                <Text
                    variant="body1"
                    css={{
                        color: '#ffffff',
                        fontWeight: '$regular',
                        'font-size': '1.6vw',
                        'line-height': '41px',
                        textAlign: 'center',
                        '@lg': { 'font-size': '2.6vw', 'line-height': '41px' },
                        '@md': { 'font-size': '3.6vw', 'line-height': '41px' },
                        '@sm': { 'font-size': '4.6vw', 'line-height': '30px' },
                    }}
                >
                    The meeting has been ended.
                </Text>
                <Flex css={{ mt: '$14', gap: '$10', alignItems: 'center' }}>
                    <Button
                        onClick={() => {
                            window.location.href = previewUrl;
                            ToastManager.clearAllToast();
                        }}
                        css={{
                            background: '#12B6BC',
                            'border-radius': '5px',
                            border: 'none',
                            width: '16vw',
                            height: '6vh',
                            '@lg': { width: '25vw', height: '7vh' },
                            '@md': { width: '36vw', height: '7vh' },
                            '@sm': { width: '45vw', height: '6vh' },
                        }}
                        data-testid="join_again_btn"
                    >
                        <ExitIcon className="exit-icon" />
                        <Text
                            css={{
                                ml: '$3',
                                color: 'inherit',
                                fontSize: '1.6vw',
                                padding: '10px',
                                '@lg': { 'font-size': '2.6vw' },
                                '@md': { 'font-size': '3.6vw' },
                                '@sm': { 'font-size': '4.6vw' },
                            }}
                        >
                            Rejoin Meeting
                        </Text>
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    );
};
const LogoImg = styled('img', {
    p: '$2',
});

export default PostLeave;

import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Flex, styled, Text } from '@100mslive/roomkit-react';
import deleteIcon from '../assets/images/delete-red-icon.svg';
import logo from '../images/incruiter-logo.svg';
import { deleteRecording, getRecordings } from '../redux/action';
const RecordingRoom = () => {
    const dispatch = useDispatch();
    const { roomId, interviewId, role } = useParams();
    const loading = useSelector((state) => state.Interview.loading);
    const recordingLists = useSelector((state) => state.Interview.recordingLists);
    const [recorderdVideoList, setRecordedVideoList] = useState(null);
    const [recordingStatus, setRecordingStatus] = useState(false);
    const userRole = role ?? 'interviewer';
    useEffect(() => {
        if (roomId && interviewId) {
            dispatch(getRecordings({ roomId: roomId, interviewId: interviewId }));
        }
    }, [dispatch, roomId, interviewId]);
    useEffect(() => {
        const recordingObject = recordingLists?.recording?.filter((item) => item.status === 'active');
        if (recordingObject && recordingObject.length > 0) {
            setRecordingStatus(true);
            setRecordedVideoList(recordingLists);
        } else {
            setRecordingStatus(false);
            setRecordedVideoList(null);
        }
    }, [recordingLists]);
    const handleDelete = (index) => {
        const updatedRecordings = [...recordingLists.recording];
        updatedRecordings[index].status = 'inactive';
        const updatedData = {
            roomId: recordingLists.roomId,
            recording: updatedRecordings,
        };
        dispatch(
            deleteRecording({
                updatedData,
                callback: () => {
                    dispatch(
                        getRecordings({
                            roomId: roomId,
                            interviewId: interviewId,
                        })
                    );
                },
            })
        );

        setRecordedVideoList(updatedData);
    };
    return (
        <Flex css={{ size: '100%' }} direction="column">
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Box css={{ h: '$18', '@md': { h: '$17' } }} data-testid="header">
                        <Flex justify="between" align="center" css={{ position: 'relative', height: '100%' }}>
                            <Flex align="center" css={{ position: 'absolute', left: '$10' }}>
                                <LogoImg src={logo} alt="InCruiter Logo" width={103} height={42} />
                            </Flex>
                        </Flex>
                    </Box>
                    <Flex direction="column" css={{ width: '100vw', height: '94vh' }}>
                        <Flex
                            justify="center"
                            direction="column"
                            align="center"
                            className="recordingDiv"
                            css={{
                                bg: '#2E2E41',
                                flex: '1 1 0',
                                position: 'relative',
                            }}
                        >
                            {recordingStatus ? (
                                recorderdVideoList?.recording.map(
                                    (itm, index) =>
                                        itm?.status === 'active' && (
                                            <div className="video_container">
                                                <video width="320" height="240" controls autoplay name="media">
                                                    <source
                                                        src={`${itm?.url ? itm?.url.split('?')[0] : itm?.url}`}
                                                        type="video/mp4"
                                                    />
                                                </video>
                                                {userRole === 'admin' && (
                                                    <img src={deleteIcon} alt="" onClick={() => handleDelete(index)} />
                                                )}
                                            </div>
                                        )
                                )
                            ) : (
                                <Text
                                    variant="body1"
                                    css={{
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        fontSize: '40px',
                                        lineHeight: '55px',
                                        color: '#9396A0',
                                        textAlign: 'center',
                                    }}
                                >
                                    OOPS!
                                    <br />
                                    No Recording Found
                                </Text>
                            )}
                        </Flex>
                    </Flex>
                </>
            )}
        </Flex>
    );
};
const LogoImg = styled('img', {
    maxHeight: '$17',
    p: '$2',
    w: 'auto',
    '@md': {
        maxHeight: '$15',
    },
});
const Loader = memo(() => {
    return (
        <div className="wrapper">
            <div className="loader"></div>
        </div>
    );
});

export default RecordingRoom;

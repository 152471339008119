import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend);
export const MultipleSeriesPieChart = ({ backGroundColor, dataNumbers }) => {
    const options = {
        plugins: {
            legend: {
                display: false,
            },
        },
        responsive: true,
        maintainAspectRatio: false,
    };
    var data = {
        labels: ['Skills in %', ''],
        datasets: [
            {
                data: dataNumbers[0],
                backgroundColor: backGroundColor,
            },
            {
                data: dataNumbers[1],
                backgroundColor: backGroundColor,
            },
            {
                data: dataNumbers[2],
                backgroundColor: backGroundColor,
            },
        ],
    };
    return <Doughnut data={data} options={options} />;
};

import { useEffect } from 'react';
import { selectRoomID, useHMSStore } from '@100mslive/react-sdk';

export class FeatureFlags {
    static enableTranscription = true;
    static enableStatsForNerds = false;
    static enableWhiteboard = true;
    static enableBeamSpeakersLogging = true;

    static init(roomId) {
        if (!window.HMS) {
            window.HMS = {};
        }
        // some extra config to hls js to bring down latency
        window.HMS.OPTIMISE_HLS_LATENCY = false;
        // ask permissions in preview even if role doesn't have it
        window.HMS.ALWAYS_REQUEST_PERMISSIONS = false;

        this.enableTranscription = true;
    }

    static optimiseHLSLatency() {
        return window.HMS.OPTIMISE_HLS_LATENCY;
    }

    static alwaysRequestPermissions() {
        return window.HMS.ALWAYS_REQUEST_PERMISSIONS;
    }
}

export function FeatureFlagsInit() {
    const roomId = useHMSStore(selectRoomID);
    useEffect(() => {
        if (roomId) {
            FeatureFlags.init(roomId);
        }
    }, [roomId]);
    return null;
}

import React from 'react';
import { Provider } from 'react-redux';
import { globalStyles, HMSThemeProvider } from '@100mslive/roomkit-react';
import { HMSRoomProvider } from '@100mslive/react-sdk';
import store from '../../redux/store';
import { AppData } from '../AppData/AppData';
import AppRoutes from '../AppRoutes/AppRoutes';
import { ErrorBoundary } from '../ErrorBoundary';
import { Init } from '../init/Init';
import { shadeColor } from '../../common/utils';
import { FeatureFlags } from '../../services/FeatureFlags';
import { generateUrl } from '../../utils/helpers';

const defaultTokenEndpoint = generateUrl('token_generation');
const envPolicyConfig = JSON.parse('' || '{}');

export default function EdtechComponent({
    tokenEndpoint = defaultTokenEndpoint,
    themeConfig: { aspectRatio = '1-1', font = 'Roboto', color = '#2F80FF', theme = 'dark', logo = '', metadata = '' },
    policyConfig = envPolicyConfig,
    getDetails = () => {},
    authTokenByRoomCodeEndpoint = '',
}) {
    const { 0: width, 1: height } = aspectRatio.split('-').map((el) => parseInt(el));
    globalStyles();

    // TODO: remove now that there are options to change to portrait
    const getAspectRatio = ({ width, height }) => {
        const host = process.env.REACT_APP_HOST_NAME || window.location.hostname;
        const portraitDomains = (process.env.REACT_APP_PORTRAIT_MODE_DOMAINS || '').split(',');
        if (portraitDomains.includes(host) && width > height) {
            return { width: height, height: width };
        }
        return { width, height };
    };

    return (
        <Provider store={store}>
            <ErrorBoundary>
                <HMSThemeProvider
                    themeType={theme}
                    aspectRatio={getAspectRatio({ width, height })}
                    theme={{
                        colors: {
                            brandDefault: color,
                            brandDark: shadeColor(color, -30),
                            brandLight: shadeColor(color, 30),
                            brandDisabled: shadeColor(color, 10),
                        },
                        fonts: {
                            sans: [font, 'Inter', 'sans-serif'],
                        },
                    }}
                >
                    <HMSRoomProvider isHMSStatsOn={FeatureFlags.enableStatsForNerds}>
                        <AppData
                            appDetails={metadata}
                            policyConfig={policyConfig}
                            logo={logo}
                            tokenEndpoint={tokenEndpoint}
                        />
                        <Init />
                        <AppRoutes getDetails={getDetails} authTokenByRoomCodeEndpoint={authTokenByRoomCodeEndpoint} />
                    </HMSRoomProvider>
                </HMSThemeProvider>
            </ErrorBoundary>
        </Provider>
    );
}

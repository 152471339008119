import React, { useCallback, useEffect, useState } from 'react';
import {
    Avatar,
    Button,
    Flex,
    Input,
    Loading,
    styled,
    StyledVideoTile,
    Text,
    textEllipsis,
    useBorderAudioLevel,
    Video,
} from '@100mslive/roomkit-react';
import _debounce from 'lodash/debounce';
import {
    selectIsLocalAudioEnabled,
    selectIsLocalVideoEnabled,
    selectLocalPeer,
    useAVToggle,
    useHMSStore,
    usePreviewJoin,
} from '@100mslive/react-sdk';
import { AudioVideoToggle } from '../../../components/AudioVideoToggle';
import TileConnection from '../../../components/Connection/TileConnection';
import PreviewSettings from '../../../components/Preview/components/PreviewControls/components/PreviewSettings/PreviewSettings';
import mute from '../../../images/mute.svg';
import { VirtualBackground } from '../../../plugins/VirtualBackground/VirtualBackground';
import {
    defaultPreviewPreference,
    UserPreferencesKeys,
    useUserPreferences,
} from '../../../components/hooks/useUserPreferences';
import { INCRUITER } from '../../../common/constants';
import '../../../../src/index.css';

const MeetJoin = ({ token, onJoin, env, skipPreview, initialName, role }) => {
    const isVideoOn = useHMSStore(selectIsLocalVideoEnabled);
    const [previewPreference, setPreviewPreference] = useUserPreferences(
        UserPreferencesKeys.PREVIEW,
        defaultPreviewPreference
    );
    const [name, setName] = useState(role === 'beam' ? 'Beam' : initialName || '');
    const { isLocalAudioEnabled, isLocalVideoEnabled } = useAVToggle();
    const [previewError, setPreviewError] = useState(false);
    const { enableJoin, preview, join } = usePreviewJoin({
        name,
        token,
        initEndpoint: env ? `https://${env}-init.100ms.live/init` : undefined,
        initialSettings: {
            isAudioMuted: skipPreview || previewPreference.isAudioMuted,
            isVideoMuted: skipPreview || previewPreference.isVideoMuted,
            speakerAutoSelectionBlacklist: ['Yeti Stereo Microphone'],
        },
        captureNetworkQualityInPreview: true,
        handleError: (_, method) => {
            if (method === 'preview') {
                setPreviewError(true);
            }
        },
    });

    const savePreferenceAndJoin = useCallback(() => {
        setPreviewPreference({
            name,
            isAudioMuted: !isLocalAudioEnabled,
            isVideoMuted: !isLocalVideoEnabled,
        });
        join();
        onJoin && onJoin();
    }, [join, isLocalAudioEnabled, isLocalVideoEnabled, name, setPreviewPreference, onJoin]);

    useEffect(() => {
        if (token) {
            if (skipPreview) savePreferenceAndJoin();
            else preview();
        }
    }, [token, skipPreview]);

    return (
        <Container style={{ width: 'auto' }}>
            <Text variant="h4" css={headingStyles}>
                Get Started
            </Text>
            <Text variant="body1" css={subheadingStyles}>
                Setup your audio and video before joining
            </Text>
            <Flex align="center" justify="center" css={videoContainerStyles(isVideoOn)}>
                <MeetPreviewTile name={name} error={previewError} />
                <MeetPreviewControls />
                <MeetName name={name} onChange={setName} enableJoin={enableJoin} onJoin={savePreferenceAndJoin} />
            </Flex>
        </Container>
    );
};

const MeetPreviewControls = () => {
    const isVideoOn = useHMSStore(selectIsLocalVideoEnabled);
    console.log(isVideoOn, 'isVideoOn');
    return (
        <Flex justify="between" css={previewControlsStyles} className="preview-meet">
            <Flex css={{ gap: '$4' }}>
                <AudioVideoToggle compact />
                {isVideoOn && <VirtualBackground />}
            </Flex>
            <PreviewSettings />
        </Flex>
    );
};

const MeetPreviewTile = ({ name, error }) => {
    const localPeer = useHMSStore(selectLocalPeer);
    const borderAudioRef = useBorderAudioLevel(localPeer?.audioTrack);
    const isVideoOn = useHMSStore(selectIsLocalVideoEnabled);
    const isAudioOn = useHMSStore(selectIsLocalAudioEnabled);

    return (
        <StyledVideoTile.Container css={videoTileContainerStyles} ref={borderAudioRef}>
            {localPeer ? (
                <>
                    <TileConnection name={name} peerId={localPeer.id} hideLabel />
                    {!isAudioOn && <MuteIcon />}
                    <Video trackId={localPeer.videoTrack} css={videoStyles} />
                    {!isVideoOn && (
                        <StyledVideoTile.AvatarContainer>
                            <Avatar name={name ?? INCRUITER} />
                            <Text css={textEllipsis('75%')} variant="body2">
                                {name ?? INCRUITER}
                            </Text>
                        </StyledVideoTile.AvatarContainer>
                    )}
                </>
            ) : !error ? (
                <Loading size={100} />
            ) : null}
        </StyledVideoTile.Container>
    );
};

const MeetName = ({ name, onChange, onJoin, enableJoin }) => {
    const joinMeeting = useCallback(
        _debounce(() => {
            onJoin();
        }, 300),
        [onJoin]
    );

    return (
        <Form>
            <Input
                required
                id="name"
                css={{
                    w: '100%',
                    borderRadius: '8px',
                    border: '1px #2D3440',
                    background: '#1E232A',
                }}
                value={name}
                onChange={(e) => onChange(e.target.value)}
                placeholder="Enter your name"
                autoFocus
                autoComplete="name"
            />
            <Button
                type="button"
                disabled={!name || !enableJoin}
                onClick={joinMeeting}
                css={{
                    borderRadius: '8px',
                    border: '1px solid #12B6BC !important',
                    backgroundColor: '#12B6BC !important',
                    color: '#fff !important',
                }}
                className="meet-join-btn"
            >
                Join Interview
            </Button>
        </Form>
    );
};

const MuteIcon = () => (
    <div className="muteLogo">
        <img src={mute} alt="Mute" style={{ width: '30px' }} />
    </div>
);

const Container = styled('div', {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0 $10',
});

const headingStyles = {
    wordBreak: 'break-word',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '2vw',
    lineHeight: '2.5vw',
    letterSpacing: '0.25px',
    color: '#f5f9ff',
};

const subheadingStyles = {
    color: 'rgba(224, 236, 255, 0.8)',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1vw',
    letterSpacing: '0.5px',
};

const videoContainerStyles = (isVideoOn) => ({
    flexDirection: 'column',
    justifyContent: 'center',
    height: '60vh',
    width: '400px',
});

const videoTileContainerStyles = {
    backgroundColor: '$surfaceDefault',
    width: '100%',
    marginTop: '10px',
};

const videoStyles = {
    width: '100%',
    height: '100%',
};

const previewControlsStyles = {
    width: '100%',
    marginTop: '$8',
};

const Form = styled('form', {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '$4',
    marginTop: '$10',
    marginBottom: '$10',
});

export default MeetJoin;

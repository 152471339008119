import React from 'react';
import pinImg from '../../../images/pinImg.svg';
export const Header = ({ interviewDetails }) => {
    return (
        <div className="part-header rounded">
            <div className="candi-details-box text-theme-white">
                <div className="candi-name-exp mx-1">
                    <div
                        className="f-12 f-bol fp-span feedback-header-title"
                        title={interviewDetails?.candidate?.name ?? 'InCruiter'}
                    >
                        {interviewDetails?.candidate?.name ?? 'InCruiter'}
                    </div>
                    <div className="d-center">
                        <a href={interviewDetails?.exitForm} target="_blank" rel="noreferrer">
                            <img src={pinImg} alt="document" className="candi-doc-img" />
                            <span className="f-10 f-reg cursor-pointer mx-1 fp-span ">Exit Form</span>
                        </a>
                    </div>
                </div>
                <div className="candi-name-exp mx-1">
                    <div
                        className="f-12 f-bol fp-span feedback-header-title"
                        title={interviewDetails?.designation ?? 'Designation'}
                    >
                        {interviewDetails?.designation ?? 'Designation'}
                    </div>
                    <div className="d-center"></div>
                </div>
                <div className="candi-name-exp mx-1">
                    <div className="f-12 f-bol fp-span">Tenure:</div>
                    <div className="d-center">
                        <span className="f-10 f-reg cursor-pointer mx-1 fp-span">{interviewDetails?.tenure ?? 0}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

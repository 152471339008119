import React from 'react';
export const ScreeningQuestions = ({ data }) => {
    return (
        <div className="screening-ques-wrapper break-stop">
            <div className="skills-div">
                <span className="soft-skills-span">Screening Questions</span>
            </div>
            <div className="ques-ans-wrapper">
                {data &&
                    data.length > 0 &&
                    data.map((itm, index) => (
                        <div className="break-stop" key={index}>
                            <div className="ques font-style-soft-skill w-100">
                                <pre className="code-content-pre">
                                    <code style={{ fontFamily: 'Poppins' }}>
                                        {index + 1}. {itm?.question}
                                    </code>
                                </pre>
                            </div>
                            <div className="ans font-style-soft-skill">{itm?.comment}</div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

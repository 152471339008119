import React from 'react';
import pinImg from '../../images/pinImg.svg';
import { INCRUITER } from '../../common/constants';
import { isMobileDevice } from '../../common/utils';
export const FeedbackHeader = ({ interviewDetails, form, isSideOpen }) => {
    const { jobDescription, candidate, jobTitle } = interviewDetails ?? {
        jobDescription: null,
        candidate: null,
        jobTitle: null,
    };
    const { resume, name, experience } = candidate ?? {
        resume: null,
        name: null,
        experience: null,
    };
    const shouldAppendJDRoomId = jobDescription ? jobDescription.includes('interview-requirements') : false;
    const resultDescription = shouldAppendJDRoomId ? `${jobDescription}/${form?.roomId}` : jobDescription;
    const shouldAppendResumeRoomId = resume ? resume.includes('interview-requirements') : false;
    const resultResume = shouldAppendResumeRoomId ? `${resume}/${form?.roomId}` : resume;

    const getMainContainerClassName = () => {
        let className = 'part-header rounded';
        if (!isMobileDevice()) {
            if (!isSideOpen) className += 'd-none';
        }
        return className;
    };

    return (
        <div className={getMainContainerClassName()}>
            <div className="candi-details-box text-theme-white">
                <div className="candi-name-exp mx-1">
                    <div className="f-12 f-bol fp-span feedback-header-title" title={name ?? INCRUITER}>
                        {name ?? INCRUITER}
                    </div>
                    <a href={resultResume} target="_blank" rel="noreferrer">
                        <div className="d-center">
                            <img src={pinImg} alt="document" className="candi-doc-img" />
                            <span className="f-10 f-reg cursor-pointer mx-1 fp-span" title="Resume">
                                Resume
                            </span>
                        </div>
                    </a>
                </div>
                <div className="candi-name-exp mx-1">
                    <div className="f-12 f-bol fp-span feedback-header-title" title={jobTitle ?? INCRUITER}>
                        {jobTitle ?? INCRUITER}
                    </div>
                    <a href={resultDescription} target="_blank" rel="noreferrer">
                        <div className="d-center">
                            <img src={pinImg} alt="document" className="candi-doc-img" />
                            <span className="f-10 f-reg cursor-pointer mx-1 fp-span" title="Job Description">
                                Job Description
                            </span>
                        </div>
                    </a>
                </div>
                <div className="candi-name-exp mx-1">
                    <div className="f-12 f-bol fp-span">Experience:</div>
                    <div className="d-center">
                        <span className="f-10 f-reg cursor-pointer mx-1 fp-span">
                            {experience ? Number(experience).toFixed(1) : 0}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Flex, styled } from '@100mslive/roomkit-react';
import logo from '../../images/incruiter-logo.svg';
import { getMeetInfo } from '../../redux/action';
import MeetMeeting from './MeetMeeting';
import MeetPreview from './MeetPreview';
import MeetRecording from './MeetRecording';
import MeetTranscript from './MeetTranscript';
const MeetRoom = ({ roomType, endPoint }) => {
    const dispatch = useDispatch();
    const { roomId } = useParams();
    const loading = useSelector((state) => state.Meet.loading);
    const meetInfo = useSelector((state) => state.Meet.meetInfo);
    console.log(roomId);
    useEffect(() => {
        if (roomId) {
            dispatch(getMeetInfo({ roomId }));
        }
    }, [dispatch, roomId]);

    const roomComponents = {
        transcript: MeetTranscript,
        recording: MeetRecording,
        preview: MeetPreview,
        meeting: MeetMeeting,
    };

    const RoomComponent = roomComponents[roomType];

    return (
        <Flex css={{ size: '100%' }} direction="column">
            {loading ? (
                <Loader />
            ) : (
                <>
                    <Box css={{ h: '$18', '@md': { h: '$17' } }} data-testid="header">
                        <Flex justify="between" align="center" css={{ position: 'relative', height: '100%' }}>
                            <Flex align="center" css={{ position: 'absolute', left: '$10' }}>
                                <LogoImg src={logo} alt="InCruiter Logo" />
                            </Flex>
                        </Flex>
                    </Box>
                    <Flex direction="column" css={{ width: '100%', height: 'calc(100% - 52px)' }}>
                        <RoomComponent roomId={roomId} endPoint={endPoint} meetInfo={meetInfo} />
                    </Flex>
                </>
            )}
        </Flex>
    );
};

const LogoImg = styled('img', {
    maxHeight: '$17',
    p: '$2',
    width: 'auto',
    '@md': {
        maxHeight: '$15',
    },
});

const Loader = memo(() => (
    <div className="wrapper">
        <div className="loader"></div>
    </div>
));

export default MeetRoom;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import TranscriptModal from '../../components/TranscriptModal/TranscriptModal';
import transcriptFail from '../../assets/images/transcript_fail.svg';
import transcriptLoader from '../../assets/images/transcript_loader.gif';
import { fetchTranscript, getFeedback } from '../../redux/action';

const RegenerateTranscript = ({
    regenerateTranscript,
    fetchLoading,
    roomId,
    interviewId,
    fetchTranscriptFail,
    setFetchTranscriptFail,
}) => {
    const dispatch = useDispatch();
    const [fetchBtnDisabled, setFetchBtnDisabled] = useState(false);

    const handleFetchTranscript = () => {
        setFetchBtnDisabled(true);
        dispatch(
            fetchTranscript({
                roomId: roomId,
                callback: () => {
                    dispatch(getFeedback({ roomId: roomId, interviewId: interviewId }));
                },
            })
        );
        setTimeout(() => {
            setFetchBtnDisabled(false);
        }, 300000);
    };
    return (
        <>
            <div className="d-center gap-10 w-100">
                <button
                    className={`common-btn upload_btn regenerate ${fetchBtnDisabled ? 'disabled' : ''}`}
                    onClick={handleFetchTranscript}
                    disabled={fetchBtnDisabled}
                >
                    <img src={regenerateTranscript} alt="edit" className="me-1" />
                    Regenerate Transcript
                </button>
            </div>

            {fetchLoading && (
                <TranscriptModal
                    img={transcriptLoader}
                    text={`We are generating transcript. \n Please wait a moment!`}
                />
            )}
            {fetchTranscriptFail && (
                <TranscriptModal
                    img={transcriptFail}
                    imgClass="transcriptFail_img"
                    text={`We are unable to generate transcript. \n Please contact technical team for further assistance `}
                    btnText="Ok"
                    btnClick={() => setFetchTranscriptFail(!fetchTranscriptFail)}
                />
            )}
        </>
    );
};

export default RegenerateTranscript;

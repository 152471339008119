/* eslint-disable no-case-declarations */
import { HMSVirtualBackgroundTypes } from '@100mslive/hms-virtual-background';
import { generateUrl } from '../../utils/helpers';
export function getRandomVirtualBackground() {
    const backgroundList = [
        {
            background: HMSVirtualBackgroundTypes.BLUR,
            backgroundType: HMSVirtualBackgroundTypes.BLUR,
        },
    ];
    const images = [
        generateUrl('meeting') + '/logo-1683535417285-954382121.png',
        generateUrl('meeting') + '/logo-1683535481376-465737953.png',
        generateUrl('meeting') + '/logo-1683535568174-401718843.png',
    ].map((url) => ({
        background: url,
        backgroundType: HMSVirtualBackgroundTypes.IMAGE,
    }));
    backgroundList.push(...images);
    const randomIdx = Math.floor(Math.random() * backgroundList.length);
    const virtualBackground = backgroundList[randomIdx];
    switch (virtualBackground.backgroundType) {
        case HMSVirtualBackgroundTypes.IMAGE:
            const img = document.createElement('img');
            img.alt = 'VB';
            img.src = backgroundList[randomIdx].background;
            virtualBackground.background = img;
            return virtualBackground;
        case HMSVirtualBackgroundTypes.VIDEO:
            const videoEl = document.createElement('video');
            videoEl.src = backgroundList[randomIdx].background;
            virtualBackground.background = videoEl;
            return virtualBackground;
        default:
            return virtualBackground;
    }
}

import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Pointing_Arrow from '../../assets/images/pointing_arrow.svg';
import { notifyInterviewer } from '../../redux/action';
import { useNavigation } from '../hooks/useNavigation';
import './joiningScreen.css';
const JoiningRoleBox = ({ role, role_icon, role_text, roomId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigation();
    const location = useLocation();
    const handleRoom = () => {
        if (roomId) {
            if (role === 'interviewer') {
                dispatch(notifyInterviewer({ roomId: roomId }));
            }
            const value = queryString.parse(location?.search);
            const key = value?.key;
            const name = value?.name;
            navigate(`/preview/${role}/${roomId}${key ? `?key=${key}` : ''}${name ? `&name=${name}` : ''}`);
        }
    };

    return (
        <div className="role_box_arrow_container">
            <img
                src={Pointing_Arrow}
                alt="arrow"
                className={`pointing_arrow ${role === 'candidate' && 'candidate_pointing_arrow'}`}
            />
            <div className={`joining_role_box ${role}`} onClick={() => handleRoom()}>
                <img src={role_icon} alt="icon" className="role_icon" />
                <p className="role_text">{role_text}</p>
            </div>
        </div>
    );
};

export default JoiningRoleBox;

import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Flex, styled, Text } from '@100mslive/roomkit-react';
import { ExitIcon } from '@100mslive/react-icons';
import { ToastManager } from '../../components/Toast/ToastManager';
import logo from '../../images/incruiter-logo.svg';
import { generateUrl } from '../../utils/helpers';
import '../../base.css';
import '../../index.css';

const MeetLeave = () => {
    const { roomId, role } = useParams();
    const previewUrl = generateUrl('meeting') + `/meet/preview/${role}/${roomId}`;
    return (
        <Flex direction="column" css={{ height: '100vh', width: '100vw' }}>
            <Flex
                justify="center"
                direction="column"
                align="center"
                css={{ bg: '#191924', flex: '1 1 0', position: 'relative' }}
            >
                <LogoImg src={logo} alt="InCruiter Logo" width={265} height={108} />
                <br />
                <Text
                    variant="body1"
                    css={{
                        color: '#ffffff',
                        fontWeight: '$regular',
                        'font-size': '1.6vw',
                        'line-height': '41px',
                        textAlign: 'center',
                        '@lg': { 'font-size': '2.6vw', 'line-height': '41px' },
                        '@md': { 'font-size': '3.6vw', 'line-height': '41px' },
                        '@sm': { 'font-size': '4.6vw', 'line-height': '30px' },
                    }}
                >
                    The meeting has been ended.
                </Text>
                <Flex css={{ mt: '$14', gap: '$10', alignItems: 'center' }}>
                    <Button
                        onClick={() => {
                            window.location.href = previewUrl;
                            ToastManager.clearAllToast();
                        }}
                        css={{
                            background: '#12B6BC',
                            'border-radius': '5px',
                            border: 'none',
                            width: '16vw',
                            height: '6vh',
                            '@lg': { width: '25vw', height: '7vh' },
                            '@md': { width: '36vw', height: '7vh' },
                            '@sm': { width: '45vw', height: '6vh' },
                        }}
                        data-testid="join_again_btn"
                    >
                        <ExitIcon className="exit-icon" />
                        <Text
                            css={{
                                ml: '$3',
                                color: 'inherit',
                                fontSize: '1.6vw',
                                padding: '10px',
                                '@lg': { 'font-size': '2.6vw' },
                                '@md': { 'font-size': '3.6vw' },
                                '@sm': { 'font-size': '4.6vw' },
                            }}
                        >
                            Rejoin Meeting
                        </Text>
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    );
};
const LogoImg = styled('img', {
    p: '$2',
});

export default MeetLeave;

import React, { Fragment, useCallback } from 'react';
import { Box, Flex } from '@100mslive/roomkit-react';
import MeetVideoList from './MeetVideoList';
export const MeetSidePanel = ({ peerScreenSharing, smallTilePeers, showSidebarInBottom, setTab, tab }) => {
    const shouldShowScreenFn = useCallback(
        (peer) => peerScreenSharing && peer.id !== peerScreenSharing.id,
        [peerScreenSharing]
    );
    return (
        <Fragment>
            <SmallTilePeersViewNew
                showSidebarInBottom={showSidebarInBottom}
                smallTilePeers={smallTilePeers}
                shouldShowScreenFn={shouldShowScreenFn}
                setTab={setTab}
                tab={tab}
            />
        </Fragment>
    );
};

const SmallTilePeersViewNew = ({ smallTilePeers, shouldShowScreenFn, setTab, tab }) => {
    return (
        <Flex
            css={{
                flex: '1 1 0',
            }}
        >
            <Box
                css={{
                    letterSpacing: '0.05em',
                    padding: '10px',
                    margin: '10px',
                    position: 'absolute',
                    top: '-60px',
                    width: '18%',
                    textAlign: 'center',
                    color: '#fff',
                    background: '#2E2E41',
                    borderRadius: '8px 8px 0px 0px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '18px',
                    '@lg': { display: 'none' },
                }}
            >
                Participants
            </Box>
            {smallTilePeers && smallTilePeers.length > 0 && (
                <MeetVideoList
                    peers={smallTilePeers}
                    size={0}
                    setTab={setTab}
                    tab={tab}
                    maxTileCount={1}
                    shouldShowScreenFn={shouldShowScreenFn}
                />
            )}
        </Flex>
    );
};

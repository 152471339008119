import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Text } from '@100mslive/roomkit-react';
import changeIcon from '../../assets/images/changeIcon.svg';
import close from '../../assets/images/crop-close.svg';
import tick from '../../assets/images/crop-tick.svg';
import deleteIcon from '../../assets/images/deleteIcon.svg';
import editIcon from '../../assets/images/editIcon.svg';
import remove from '../../assets/images/remove-file.svg';
import setMainIcon from '../../assets/images/setMainIcon.svg';
import file from '../../assets/images/sp-file.svg';
import info from '../../assets/images/sp-info.svg';
import upload from '../../assets/images/upload-icon.svg';
import {
    addScreenshot,
    createAndUpdateScreenshots,
    fetchScreenShotImagev3,
    getAllScreenshots,
    getCropUrl,
} from '../../redux/action';
import { ToastManager } from '../Toast/ToastManager';
import ToolTip from './ToolTip';
import 'react-image-crop/dist/ReactCrop.css';
const ScreenShotsTab = ({ images, roomId, feedbackId, interviewId }) => {
    const hiddenFileInput = React.useRef(null);
    const dispatch = useDispatch();
    const [changeToolTipShow, setChangeToolTipShow] = useState(false);
    const [setMainToolTipShow, setSetMainToolTipShow] = useState(false);
    const [deleteToolTipShow, setDeleteToolTipShow] = useState(false);
    const [editToolTipShow, setEditToolTipShow] = useState(false);
    const [toolTipPosition, setToolTipPosition] = useState(0);
    const [popup, setPopup] = useState('close');
    const [currentPosition, setCurrentPosition] = useState(0);
    const [selectedImages, setSelectedImages] = useState();

    const changeMouseOver = (position) => {
        setChangeToolTipShow(true);
        setToolTipPosition(position);
    };
    const changeMouseOut = (position) => {
        setChangeToolTipShow(false);
        setToolTipPosition(position);
    };
    const setMainMouseOver = (position) => {
        setSetMainToolTipShow(true);
        setToolTipPosition(position);
    };
    const setMainMouseOut = (position) => {
        setSetMainToolTipShow(false);
        setToolTipPosition(position);
    };
    const deleteMouseOver = (position) => {
        setDeleteToolTipShow(true);
        setToolTipPosition(position);
    };
    const deleteMouseOut = (position) => {
        setDeleteToolTipShow(false);
        setToolTipPosition(position);
    };
    const editMouseOver = (position) => {
        setEditToolTipShow(true);
        setToolTipPosition(position);
    };
    const editMouseOut = (position) => {
        setEditToolTipShow(false);
        setToolTipPosition(position);
    };
    const handlePopUp = (type, index) => {
        setPopup(type);
        setCurrentPosition(index);
    };

    const handleAddScreenshot = () => {
        hiddenFileInput.current.click();
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImages(file);
    };

    const handleUpload = () => {
        if (selectedImages) {
            dispatch(
                addScreenshot({
                    images: selectedImages,
                    roomId: roomId,
                    interviewId: interviewId,
                    callback: () => {
                        dispatch(
                            getAllScreenshots({
                                roomId: roomId,
                                interviewId: interviewId,
                            })
                        );
                    },
                })
            );
        }
    };

    useEffect(() => {
        handleUpload();
    }, [selectedImages]);

    return (
        <div className="screenshot-div-v2 position-relative">
            <button className="add_screenshot_btn feedback-btn theme-peacock-bg" onClick={handleAddScreenshot}>
                Add Screenshot
            </button>
            <input
                type="file"
                ref={hiddenFileInput}
                style={{ display: 'none' }}
                onChange={handleImageChange}
                accept="image/*"
            />
            {images ? (
                images.length > 0 ? (
                    <div className="miniScreenshotDiv_container">
                        {images.map((image, index) => (
                            <div className="MiniScreenShotDiv" key={index}>
                                {toolTipPosition === index &&
                                    (changeToolTipShow ||
                                        setMainToolTipShow ||
                                        deleteToolTipShow ||
                                        editToolTipShow) && (
                                        <ToolTip
                                            title={
                                                changeToolTipShow
                                                    ? 'Change'
                                                    : setMainToolTipShow
                                                    ? 'Set Main'
                                                    : deleteToolTipShow
                                                    ? 'Delete'
                                                    : editToolTipShow
                                                    ? 'Edit'
                                                    : ''
                                            }
                                            className={
                                                changeToolTipShow
                                                    ? 'tip-change'
                                                    : setMainToolTipShow
                                                    ? 'tip-set-main'
                                                    : deleteToolTipShow
                                                    ? 'tip-delete'
                                                    : editToolTipShow
                                                    ? 'tip-edit'
                                                    : ''
                                            }
                                            message={
                                                changeToolTipShow
                                                    ? 'Upload other screenshot'
                                                    : setMainToolTipShow
                                                    ? 'Use screenshot in Report'
                                                    : deleteToolTipShow
                                                    ? 'Remove the screenshot'
                                                    : editToolTipShow
                                                    ? 'Crop the screenshot'
                                                    : ''
                                            }
                                        />
                                    )}
                                <div className="ScreenShotHeader">
                                    <img
                                        src={editIcon}
                                        onMouseOver={() => editMouseOver(index)}
                                        onMouseOut={() => editMouseOut(index)}
                                        onClick={() => handlePopUp('edit', index)}
                                        alt=""
                                    ></img>
                                    <img
                                        src={deleteIcon}
                                        onMouseOver={() => deleteMouseOver(index)}
                                        onMouseOut={() => deleteMouseOut(index)}
                                        onClick={() => handlePopUp('delete', index)}
                                        alt=""
                                    ></img>

                                    <img
                                        src={setMainIcon}
                                        onMouseOver={() => setMainMouseOver(index)}
                                        onMouseOut={() => setMainMouseOut(index)}
                                        onClick={() => handlePopUp('setMain', index)}
                                        alt=""
                                    ></img>
                                    <img
                                        src={changeIcon}
                                        onMouseOver={() => changeMouseOver(index)}
                                        onMouseOut={() => changeMouseOut(index)}
                                        onClick={() => handlePopUp('change', index)}
                                        alt=""
                                    ></img>
                                </div>
                                <div className="ScreenShotBody">
                                    <img src={image?.url} alt=""></img>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <Flex css={{ size: '100%' }} direction="column">
                        <Flex direction="column" css={{ height: '94vh' }}>
                            <Flex
                                justify="center"
                                direction="column"
                                align="center"
                                className="recordingDiv"
                                css={{ flex: '1 1 0', position: 'relative' }}
                            >
                                <Text
                                    variant="body1"
                                    css={{
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        fontSize: '40px',
                                        lineHeight: '55px',
                                        color: '#9396A0',
                                        textAlign: 'center',
                                        '@md': { fontSize: '28px' },
                                    }}
                                >
                                    No Screenshots Found
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                )
            ) : (
                <Flex css={{ size: '100%' }} direction="column">
                    <Flex direction="column" css={{ height: '94vh' }}>
                        <Flex
                            justify="center"
                            direction="column"
                            align="center"
                            className="recordingDiv"
                            css={{ flex: '1 1 0', position: 'relative' }}
                        >
                            <Text
                                variant="body1"
                                css={{
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    fontSize: '40px',
                                    lineHeight: '55px',
                                    color: '#9396A0',
                                    textAlign: 'center',
                                    '@md': { fontSize: '28px' },
                                }}
                            >
                                No Screenshots Found
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            )}
            {popup === 'edit' && (
                <CropPopup
                    feedbackId={feedbackId}
                    images={images}
                    index={currentPosition}
                    setPopup={setPopup}
                    roomId={roomId}
                />
            )}
            {popup === 'delete' && (
                <SmallPopup
                    feedbackId={feedbackId}
                    img={info}
                    popup={popup}
                    setPopup={setPopup}
                    index={currentPosition}
                    title="Are You Sure ?"
                    images={images}
                    roomId={roomId}
                    subtitle="Do you really want to Delete this screenshot?"
                />
            )}
            {popup === 'setMain' && (
                <SmallPopup
                    feedbackId={feedbackId}
                    img={file}
                    popup={popup}
                    setPopup={setPopup}
                    index={currentPosition}
                    images={images}
                    roomId={roomId}
                    title="Use this Screenshot in Report ?"
                />
            )}
            {popup === 'change' && (
                <FileUploadPopup
                    feedbackId={feedbackId}
                    index={currentPosition}
                    setPopup={setPopup}
                    images={images}
                    roomId={roomId}
                />
            )}
        </div>
    );
};

export default ScreenShotsTab;

const CropPopup = ({ images, index, setPopup, roomId, feedbackId }) => {
    const [isImageCropped, setIsImageCropped] = useState(false);
    const [crop, setCrop] = useState({
        unit: '%',
        width: 100,
        height: 100,
        aspect: 16 / 16,
    });
    const dispatch = useDispatch();
    const cropImage = useSelector((state) => state.Interview.cropImage);
    useEffect(() => {
        if (isImageCropped && cropImage && cropImage?.url) {
            let tmpImages = images;
            tmpImages[index] = { url: cropImage?.url, type: 'default' };
            dispatch(
                createAndUpdateScreenshots({
                    roomId: roomId,
                    screenshots: tmpImages,
                    mode: 'update',
                    feedbackId: feedbackId,
                })
            );
            setPopup('close');
            ToastManager.addToast({
                title: 'Screenshot edited successfully.',
            });
            setIsImageCropped(false);
        }
    }, [cropImage]);
    const handleChange = () => {
        if (crop) {
            dispatch(getCropUrl({ url: images[index].url, coordinates: crop }));
            setIsImageCropped(true);
        }
    };
    return (
        <div className="main-popup-container">
            <div className="crop-popup-container">
                <div className="cp-header-container">
                    <div className="cp-header-title">Edit</div>
                    <div className="cp-header-img-container">
                        <img src={tick} alt="tick" className="cp-header-img-1" onClick={() => handleChange()} />
                        <img src={close} alt="close" className="cp-header-img-2" onClick={() => setPopup('close')} />
                    </div>
                </div>
                <div className="cp-crop-img-container">
                    <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
                        <img src={images[index].url} alt="crop-img" className="cp-crop-img" />
                    </ReactCrop>
                </div>
            </div>
        </div>
    );
};

const SmallPopup = ({ popup, img, setPopup, index, title, subtitle, images, roomId, feedbackId }) => {
    const dispatch = useDispatch();
    const handleChange = () => {
        let tmpImages = images;
        tmpImages.map((itm, ind) => {
            tmpImages[ind]['type'] = 'default';
        });
        tmpImages[index]['type'] = 'main';
        dispatch(
            createAndUpdateScreenshots({
                roomId: roomId,
                screenshots: tmpImages,
                mode: 'update',
                feedbackId: feedbackId,
            })
        );
        setPopup('close');
        ToastManager.addToast({
            title: 'Main screenshot changed successfully.',
        });
    };
    const handleDelete = () => {
        let tmpImages = images;
        tmpImages.splice(index, 1);
        dispatch(
            createAndUpdateScreenshots({
                roomId: roomId,
                screenshots: tmpImages,
                mode: 'update',
                feedbackId: feedbackId,
            })
        );
        setPopup('close');
        ToastManager.addToast({
            title: 'Image deleted successfully.',
        });
    };
    return (
        <div className="main-popup-container">
            <div className="small-popup">
                <img src={img} alt="small popup image" className="small-popup-img" />
                <div className="sp-title">{title}</div>
                <div className="sp-subtitle">{subtitle}</div>
                <div className="sp-btn-container">
                    {popup === 'setMain' ? (
                        <button className="sp-btn sp-yes" onClick={() => handleChange()}>
                            Yes
                        </button>
                    ) : (
                        <button className="sp-btn sp-yes" onClick={() => handleDelete()}>
                            Yes
                        </button>
                    )}
                    <button className="sp-btn sp-cancel" onClick={() => setPopup('close')}>
                        No
                    </button>
                </div>
            </div>
        </div>
    );
};

const FileUploadPopup = ({ index, setPopup, images, roomId, feedbackId }) => {
    const dispatch = useDispatch();
    const [imageUpdate, setImageUpdate] = useState(false);
    const fetchImagev3 = useSelector((state) => state.Interview.fetchImagev3);
    const fetchImageAndUpload = () => {
        if (myFiles[0].type.split('/')[0] === 'image') {
            if (myFiles[0]) {
                dispatch(fetchScreenShotImagev3({ image: myFiles[0] }));
            }
            setImageUpdate(true);
        } else {
            ToastManager.addToast({
                title: 'Uploaded file is not a valid image. Only JPG, JPEG, PNG and GIF files are allowed.',
            });
        }
    };
    useEffect(() => {
        if (imageUpdate && fetchImagev3 && fetchImagev3?.url) {
            let tmpImages = images;
            tmpImages[index] = { url: fetchImagev3?.url, type: 'default' };
            dispatch(
                createAndUpdateScreenshots({
                    roomId: roomId,
                    screenshots: tmpImages,
                    mode: 'update',
                    feedbackId: feedbackId,
                })
            );
            setPopup('close');
            ToastManager.addToast({
                title: 'Screenshot changed successfully.',
            });
        }
    }, [fetchImagev3]);
    const [myFiles, setMyFiles] = useState([]);
    const onDrop = useCallback(
        (acceptedFiles) => {
            setMyFiles([...myFiles, ...acceptedFiles]);
        },
        [myFiles]
    );
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: false,
    });
    const removeFile = (file) => {
        const newFiles = [...myFiles];
        newFiles.splice(newFiles.indexOf(file), 1);
        setMyFiles(newFiles);
    };
    const files = myFiles.map((file) => (
        <div key={file.path} className="fup-file">
            <div className="fup-file-name">{file.path}</div>
            <img src={remove} alt="remove file" className="remove-file-btn" onClick={() => removeFile(file)} />
        </div>
    ));
    return (
        <div className="main-popup-container">
            <div className="crop-popup-container-2">
                <div className="cp-header-container">
                    <div className="cp-header-title">Change Screenshot</div>
                    <div className="cp-header-img-container">
                        <img src={close} alt="close" className="cp-header-img-2" onClick={() => setPopup('close')} />
                    </div>
                </div>
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input className="input-zone" {...getInputProps()} />
                    <img src={upload} alt="upload icon" className="fup-img" />
                    <div className="fup-subtitle">
                        Drag & drop files or <span className="fup-text-theme-peacock">Browse</span>
                    </div>
                    <div className="fup-file-type">Supported formats: JPEG, PNG, JPG</div>
                </div>
                {files && files.length > 0 && (
                    <div className="fup-uploaded-container">
                        <div className="fup-uploaded-title">Uploaded</div>
                        {files}
                    </div>
                )}
                <button
                    className={`fup-btn ${myFiles && myFiles.length > 0 ? 'fup-btn-active' : 'fup-btn-inactive'}`}
                    onClick={() => fetchImageAndUpload()}
                    disabled={myFiles && myFiles.length > 0 ? false : true}
                >
                    Upload File
                </button>
            </div>
        </div>
    );
};

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Flex } from '@100mslive/roomkit-react';
import ErrorPageImg from '../../assets/images/ErrorPage.svg';
import LoadingImg from '../../assets/images/LoadingImg.svg';
import { contactAdmin } from '../../redux/action';
import { Header } from '../Header';
import { ToastManager } from '../Toast/ToastManager';
import { ROLES } from '../../common/constants';

const ErrorPage = ({ title, message }) => {
    const dispatch = useDispatch();
    const { roomId, role } = useParams();

    useEffect(() => {
        localStorage.clear();
    }, []);

    const handleSubmit = () => {
        const titleMsg = 'Our team has notified successfully. Our team will contact you soon.';
        if (roomId) {
            let data = {
                title: title,
                message: message,
                url: window.location.href,
                roomId: roomId,
                mode: role ?? ROLES.INTERVIEWER,
            };
            dispatch(contactAdmin(data));
            ToastManager.addToast({
                title: titleMsg,
            });
        } else {
            ToastManager.addToast({
                title: titleMsg,
            });
        }
    };

    return (
        <Flex css={{ size: '100%' }}>
            <Box css={{ h: '$18', '@md': { h: '$17' } }} data-testid="header">
                <Header />
            </Box>
            <div className="login-limit-div">
                <div className="error-page-new-div">
                    <div className="center-img-div d-center">
                        <img src={ErrorPageImg} alt="Error Page Image" />
                    </div>
                    <div className="error-text-main">
                        <p className="error-text1">Oops! Please refresh your page to continue as usual.</p>
                        <p className="error-text2">
                            <img src={LoadingImg} alt="Loading Image" />
                            Please refresh the page
                        </p>
                        <p className="error-text3">Refresh doesn’t work?</p>
                        <button className="error-btn" onClick={() => handleSubmit()}>
                            Contact Us
                        </button>
                    </div>
                </div>
            </div>
        </Flex>
    );
};
export default ErrorPage;

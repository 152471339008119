import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation, usePrevious } from 'react-use';
import { Box } from '@100mslive/roomkit-react';
import { HMSRoomState, selectIsConnectedToRoom, selectRoomState, useHMSStore } from '@100mslive/react-sdk';
import { MeetFooter } from './MeetFooter';
import { MeetPageView } from './MeetPageView';
import { useNavigation } from '../../components/hooks/useNavigation';
const MeetMeeting = ({ meetInfo }) => {
    const location = useLocation();
    const navigate = useNavigation();
    const roomState = useHMSStore(selectRoomState);
    const prevState = usePrevious(roomState);
    const isConnectedToRoom = useHMSStore(selectIsConnectedToRoom);
    const { roomId, role } = useParams();
    const name = location?.state?.name || 'InCruiter';
    const [tab, setTab] = useState(1);
    console.log(isConnectedToRoom);
    useEffect(() => {
        if (!roomId) {
            navigate(`/`);
            return;
        }
        if (
            !prevState &&
            !(roomState === HMSRoomState.Connecting || roomState === HMSRoomState.Reconnecting || isConnectedToRoom)
        ) {
            navigate(`/meet/preview/${role}/${roomId}`);
        }
    }, [isConnectedToRoom, prevState, roomState, navigate, role, roomId]);
    return (
        isConnectedToRoom && (
            <>
                <Box
                    css={{
                        width: '100%',
                        flex: '1 1 0',
                        minHeight: 0,
                    }}
                    data-testid="conferencing"
                >
                    <div
                        style={{
                            width: '100%',
                            flex: '1 1 0px',
                            minHeight: '0px',
                            height: '100%',
                        }}
                    >
                        <MeetPageView setTab={setTab} tab={tab} name={name} />
                    </div>
                </Box>
                <Box css={{ flexShrink: 0, zIndex: '2' }} data-testid="footer" className="meet-footer">
                    <div>
                        <MeetFooter setTab={setTab} tab={tab} />
                    </div>
                </Box>
            </>
        )
    );
};

export default MeetMeeting;

import React, { useEffect, useState } from 'react';
import InterviewSummaryText from '../../Pages/FeedbackComponent/InterviewSummaryText';
import TranscriptText from '../../Pages/FeedbackComponent/TranscriptText';
import './TranscriptForMobileScreen.css';

export default function TranscriptForMobileScreen({ transcript, interviewSummary }) {
    const TRANSCRIPT = 'Transcript';
    const INTERVIEW_SUMMARY = 'Interview Summary';
    const [activeTab, setActiveTab] = useState(TRANSCRIPT);
    const [remainingHeight, setRemainingHeight] = useState(0);
    const tabs = [
        {
            text: TRANSCRIPT,
            urls: transcript,
            Component: TranscriptText,
        },
        {
            text: INTERVIEW_SUMMARY,
            urls: interviewSummary,
            Component: InterviewSummaryText,
        },
    ];

    useEffect(() => {
        const updateRemainingHeight = () => {
            const pageHeader = document.querySelector(
                '.hms-ui-c-dhzjXW .hms-ui-c-dhzjXW-bICGYT-justify-center .hms-ui-c-dhzjXW-jroWjL-align-center .hms-ui-c-dhzjXW-iyaXAz-css'
            )?.offsetHeight;
            const feedbackTabsHeight = document.querySelector('.feedback-btn-container')?.offsetHeight;
            const candidateDetailsHeight = document.querySelector('.part-header .rounded')?.offsetHeight;
            const transcriptContainerHeight = document.querySelector('.transcript-container')?.offsetHeight;
            const computedStyle = window.getComputedStyle(document.querySelector('.feedback-container-v2'));
            const extraPadding = parseFloat(computedStyle?.paddingTop) + parseFloat(computedStyle?.paddingBottom);
            if (
                pageHeader &&
                feedbackTabsHeight &&
                candidateDetailsHeight &&
                transcriptContainerHeight &&
                extraPadding
            ) {
                const totalOccupiedHeight =
                    pageHeader + feedbackTabsHeight + candidateDetailsHeight + transcriptContainerHeight + extraPadding;

                const windowHeight = window.innerHeight;
                const calculatedRemainingHeight = windowHeight - totalOccupiedHeight;

                setRemainingHeight(calculatedRemainingHeight);
            }
        };

        window.addEventListener('resize', updateRemainingHeight);
        updateRemainingHeight();

        return () => {
            window.removeEventListener('resize', updateRemainingHeight);
        };
    }, []);

    const handleTabChange = (tab) => {
        if (tab !== activeTab) setActiveTab(tab);
    };

    const activeTabData = tabs.find((tab) => tab.text === activeTab);
    return (
        <div className="parent-container">
            <div className="transcript-container">
                {tabs.map((tab) => (
                    <div
                        key={tab.text}
                        className={`tab f-reg ${activeTab === tab.text ? 'active' : 'inactive'}`}
                        onClick={() => handleTabChange(tab.text)}
                    >
                        {tab.text}
                    </div>
                ))}
            </div>
            <div className="content-container" style={{ height: remainingHeight }}>
                {activeTabData?.urls.map((url, index) => (
                    <activeTabData.Component key={index} url={url} />
                ))}
            </div>
        </div>
    );
}

import React from 'react';
import moment from 'moment';
import MaleAvatar from '../../assets/images/MaleAvatar.svg';
import SuspectedProxy from '../../assets/images/SuspectedProxy.svg';
import { CircularProgressBarPage } from '../OtherComponents/CircularProgressBarPage';
import { capitalizeAll } from '../OtherComponents/Helpers';
export const Profile = ({ data }) => {
    return (
        <>
            <div className="profile-div">
                <div className="profile-img-div">
                    <img className="profile-img-logo" src={data?.image ? data?.image : MaleAvatar} alt="Logo" />
                </div>
                <div className="profile-details-div desktop-mode">
                    <span className="common-profile-text profile-intId">{data?.code}</span>
                    <span className="common-profile-text profile-details-text">
                        {capitalizeAll(data?.name)} | {capitalizeAll(data?.position)} |{' '}
                        {Number(data?.experience).toFixed(1)} years
                    </span>
                    <span className="common-profile-text profile-details-text">Company: {data?.company}</span>
                    <span className="common-profile-text profile-contact">
                        {data?.email} | {data?.phone}
                    </span>
                    <span className="common-profile-text profile-contact">
                        Date of Interview: {moment.utc(data?.interviewDate).format('llll')}
                    </span>
                </div>
                {data?.suspectedProxy ? (
                    <div className="profile-details-status">
                        <img className="profile-suspected" src={SuspectedProxy} alt="Logo" />
                        <CircularProgressBarPage rating={data?.rating} candidateDrop={data?.candidateDrop} />
                    </div>
                ) : (
                    <div className="profile-details-status-t3">
                        <CircularProgressBarPage rating={data?.rating} candidateDrop={data?.candidateDrop} />
                    </div>
                )}
            </div>
            <div className="profile-details-div mobile-mode">
                <div className="profile-details-div-1">
                    <span className="common-profile-text profile-intId">{data?.code}</span>
                    <span className="common-profile-text profile-details-text">
                        {capitalizeAll(data?.name)} | {capitalizeAll(data?.position)} |{' '}
                        {Number(data?.experience).toFixed(1)} years
                    </span>
                    <span className="common-profile-text profile-details-text">Company: {data?.company}</span>
                    <span className="common-profile-text profile-contact">
                        {data?.email} | {data?.phone}
                    </span>
                    <span className="common-profile-text profile-contact">
                        Date of Interview: {moment(data?.interviewDate).format('llll')}
                    </span>
                </div>
            </div>
        </>
    );
};

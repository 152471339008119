import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUrlGeneratedId } from '../../redux/action';
// import { FOFPage } from "../PageComponent/FOFPage";

const UrlGeneratedId = () => {
    const dispatch = useDispatch();
    const urlGeneratedIdValue = useSelector((state) => state.Interview.urlGeneratedId);
    const loading = useSelector((state) => state.Interview.urlGeneratedIdLoader);
    const { interviewId, urlGenerateId } = useParams();

    useEffect(() => {
        dispatch(
            getUrlGeneratedId({
                interviewId,
                urlGenerateId,
                callback: (res) => {
                    window.location.href = res?.data?.result?.url;
                },
            })
        );
    }, [dispatch, interviewId, urlGenerateId, urlGeneratedIdValue?.url]);

    return <div>{loading ? <Loader /> : ''}</div>;
};
const Loader = memo(() => {
    return (
        <div className="wrapper">
            <div className="loader"></div>
        </div>
    );
});

export default UrlGeneratedId;

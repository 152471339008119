import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Flex, styled, Text } from '@100mslive/roomkit-react';
import io from 'socket.io-client';
import { Body } from '../components/Feedback/InCExitFeedback/Body';
import { Header } from '../components/Feedback/InCExitFeedback/Header';
import interviewCompleted from '../assets/images/interview-completed.svg';
import RightTick from '../assets/images/right-tick.svg';
import logo from '../images/incruiter-logo.svg';
import { getExitFeedback, postExitFeedback } from '../redux/action';
import { generateUrl } from '../utils/helpers';
export var socket;
const UpdateFeedbackExitRoom = () => {
    const dispatch = useDispatch();
    const { interviewId, roomId, role } = useParams();
    const rolePage = role ? role : 'interviewer';
    const feedbackDetails = useSelector((state) => state.ExitInterview.feedbackDetails);
    const initialForm = {
        screeningQuestions: [],
        positionNotes: [],
        positionNotesComment: '',
        exitReasons: [],
        exitReasonsComment: '',
        reJoin: '',
        retentionEfforts: '',
        newEmployer: '',
        industry: '',
        workLifeBalance: '',
        transparency: '',
        recommendFriendsFamily: '',
        overallRating: '',
        finalRemarks: '',
        status: '',
        interviewId: interviewId,
        roomId: roomId,
        role: rolePage,
        codeUpdated: false,
    };
    const [seconds, setSeconds] = useState(0);
    const [feedbackForm, setFeedbackForm] = useState(initialForm);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    useEffect(() => {
        let tmpScreeningQues = [];
        if (!feedbackDetails && roomId && interviewId) {
            dispatch(
                getExitFeedback({
                    roomId: roomId,
                    interviewId: interviewId,
                })
            );
        }
        if (feedbackDetails) {
            if (feedbackDetails?.interviewId?.feedback) {
                let screeningQuestionSkills = feedbackDetails.interviewId?.screeningQuestions
                    ? feedbackDetails.interviewId?.screeningQuestions.length > 0
                        ? feedbackDetails.interviewId?.screeningQuestions
                        : ''
                    : '';
                feedbackDetails?.interviewId?.feedback?.screeningQuestions &&
                    feedbackDetails?.interviewId?.feedback?.screeningQuestions.length > 0 &&
                    feedbackDetails?.interviewId?.feedback?.screeningQuestions.map((screeningQuestionsList, index) => {
                        let object = {
                            question: screeningQuestionsList.question,
                            comment: screeningQuestionsList?.comment,
                            commentRequired: screeningQuestionSkills
                                ? screeningQuestionSkills.length > 0
                                    ? screeningQuestionSkills[0].commentRequired
                                    : false
                                : false,
                            commentLength: screeningQuestionSkills
                                ? screeningQuestionSkills.length > 0
                                    ? screeningQuestionSkills[0].commentLength
                                    : { min: 50, max: 500 }
                                : { min: 50, max: 500 },
                            position: index,
                            delete: rolePage === 'admin' ? true : false,
                            type: 'default',
                            questionType: screeningQuestionsList.questionType,
                            commentBoxVisible: feedbackDetails?.interviewId?.ratingType === 2 ? true : false,
                        };
                        tmpScreeningQues.push(object);
                    });
            } else {
                feedbackDetails?.interviewId?.screeningQuestions &&
                    feedbackDetails?.interviewId?.screeningQuestions.length > 0 &&
                    feedbackDetails?.interviewId?.screeningQuestions.map((screeningQuestionsList, index) => {
                        let object = {
                            question: screeningQuestionsList.question,
                            comment: '',
                            commentRequired: screeningQuestionsList.commentRequired,
                            commentLength: screeningQuestionsList.commentLength,
                            position: index,
                            type: 'default',
                            questionType: screeningQuestionsList.questionType,
                            delete: rolePage === 'admin' ? true : false,
                            commentBoxVisible: screeningQuestionsList.commentRequired
                                ? screeningQuestionsList.commentRequired
                                : false,
                        };
                        tmpScreeningQues.push(object);
                    });
            }
            setFeedbackForm((prevState) => ({
                ...prevState,
                screeningQuestions:
                    feedbackForm?.screeningQuestions.length > 0 ? feedbackForm?.screeningQuestions : tmpScreeningQues,
                finalRemarks: feedbackForm?.finalRemarks
                    ? feedbackForm?.finalRemarks
                    : feedbackDetails?.interviewId?.feedback?.finalRemarks ?? '',
                status: feedbackForm?.status
                    ? feedbackForm?.status
                    : feedbackDetails?.interviewId?.feedback?.status ?? '',
                overallRating: feedbackForm?.overallRating
                    ? feedbackForm?.overallRating
                    : feedbackDetails?.interviewId?.feedback?.overallRating ?? '',
                recommendFriendsFamily: feedbackForm?.recommendFriendsFamily
                    ? feedbackForm?.recommendFriendsFamily
                    : feedbackDetails?.interviewId?.feedback?.recommendFriendsFamily ?? '',
                transparency: feedbackForm?.transparency
                    ? feedbackForm?.transparency
                    : feedbackDetails?.interviewId?.feedback?.transparency ?? '',
                workLifeBalance: feedbackForm?.workLifeBalance
                    ? feedbackForm?.workLifeBalance
                    : feedbackDetails?.interviewId?.feedback?.workLifeBalance ?? '',
                positionNotes:
                    feedbackForm?.positionNotes.length > 0
                        ? feedbackForm?.positionNotes
                        : feedbackDetails?.interviewId?.feedback?.positionNotes,
                exitReasons:
                    feedbackForm?.exitReasons.length > 0
                        ? feedbackForm?.exitReasons
                        : feedbackDetails?.interviewId?.feedback?.exitReasons,
                positionNotesComment: feedbackForm?.positionNotesComment
                    ? feedbackForm?.positionNotesComment
                    : feedbackDetails?.interviewId?.feedback?.positionNotesComment ?? '',
                exitReasonsComment: feedbackForm?.exitReasonsComment
                    ? feedbackForm?.exitReasonsComment
                    : feedbackDetails?.interviewId?.feedback?.exitReasonsComment ?? '',
                reJoin: feedbackForm?.reJoin
                    ? feedbackForm?.reJoin
                    : feedbackDetails?.interviewId?.feedback?.reJoin ?? '',
                retentionEfforts: feedbackForm?.retentionEfforts
                    ? feedbackForm?.retentionEfforts
                    : feedbackDetails?.interviewId?.feedback?.retentionEfforts ?? '',
                newEmployer: feedbackForm?.newEmployer
                    ? feedbackForm?.newEmployer
                    : feedbackDetails?.interviewId?.feedback?.newEmployer ?? '',
                industry: feedbackForm?.industry
                    ? feedbackForm?.industry
                    : feedbackDetails?.interviewId?.feedback?.industry ?? '',
                questionCount: feedbackDetails?.interviewId?.screeningQuestionsSummary?.QuestionCount ?? {
                    min: 0,
                    max: 20,
                },
                questionLength: feedbackDetails?.interviewId?.screeningQuestionsSummary?.questionLength ?? {
                    min: 0,
                    max: 200,
                },
            }));
        }
    }, [dispatch, roomId, interviewId, feedbackDetails]);

    // updating screenWidth whenever window is resized
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (seconds > 0 && feedbackDetails && feedbackForm?.role === 'interviewer' && feedbackForm.interviewId) {
            dispatch(
                postExitFeedback({
                    type: 1,
                    interviewId: feedbackForm.interviewId,
                    data: {
                        ...feedbackForm,
                        type: 'autosave',
                    },
                })
            );
        }
    }, [seconds, feedbackDetails]);

    useEffect(() => {
        if (roomId && !socket) {
            socket = io(generateUrl('socket'), {
                transports: ['websocket'],
            });
            socket?.emit('joinRoom', { roomId, name: role });
        }
    }, [roomId]);

    socket?.on('receiveCode', (data) => {
        if (role !== data?.roleName) {
            setFeedbackForm((prevState) => ({
                ...prevState,
                ...data,
            }));
        }
    });
    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds + 1);
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Flex css={{ size: '100%' }} direction="column">
            <Box css={{ h: '$18', '@md': { h: '$17' } }} data-testid="header">
                <Flex justify="between" align="center" css={{ position: 'relative', height: '100%' }}>
                    <Flex align="center" css={{ position: 'absolute', left: '$10' }}>
                        <LogoImg src={logo} alt="InCruiter Logo" width={103} height={42} />
                    </Flex>
                </Flex>
                <Box
                    css={{
                        width: '100%',
                        flex: '1 1 0',
                        minHeight: 0,
                    }}
                    data-testid="conferencing"
                >
                    <div
                        style={{
                            width: screenWidth > 768 ? '70%' : '100%',
                            flex: '1 1 0px',
                            minHeight: '0px',
                            height: '100%',
                            background: '#2E2E41',
                            borderRadius: '0px',
                        }}
                    >
                        <Flex css={{ size: '100%' }} direction="column">
                            <Flex
                                direction="column"
                                css={{
                                    height: screenWidth > 768 ? '90vh' : '550px',
                                }}
                            >
                                <Flex
                                    // justify="center"
                                    direction="column"
                                    align="center"
                                    className="recordingDiv"
                                    css={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '100%',
                                        justifyContent: screenWidth > 768 ? 'center' : 'flex-start',
                                    }}
                                >
                                    <Flex
                                        css={{
                                            fontStyle: 'normal',
                                            fontWeight: '700',
                                            fontSize: '40px',
                                            lineHeight: '55px',
                                            color: '#9396A0',
                                            textAlign: 'center',
                                            background: '#191924',
                                            width: '98%',
                                            height: screenWidth > 768 ? '85%' : '100%',
                                            margin: '0 auto',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Text
                                            variant="body1"
                                            css={{
                                                color: '#ffffff',
                                                fontWeight: '$regular',
                                                'font-size': '30px',
                                                'line-height': '41px',
                                            }}
                                        >
                                            <img src={interviewCompleted} alt="Interview Completed" />
                                        </Text>
                                        <Text
                                            variant="body1"
                                            css={{
                                                color: '#ffffff',
                                                'font-size': '30px',
                                                'line-height': '41px',
                                                fontStyle: 'normal',
                                                fontWeight: '700',
                                                fontSize: '18px',
                                                lineHeight: '25px',
                                                letterSpacing: '0.04em',
                                            }}
                                        >
                                            <img src={RightTick} alt="download" className="ext-file-download" />
                                            <span
                                                style={{
                                                    padding: '10px',
                                                    display: 'inline-block',
                                                    paddingTop: '0px',
                                                }}
                                            >
                                                "Interview Completed"
                                            </span>
                                        </Text>
                                        <Text
                                            variant="body1"
                                            css={{
                                                color: '#ffffff',
                                                'font-size': '30px',
                                                'line-height': '41px',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                fontSize: '12px',
                                                lineHeight: '16px',
                                                letterSpacing: '0.04em',
                                            }}
                                        >
                                            Please provide feedback using the form on the right panel
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </div>
                </Box>
            </Box>
            <div
                className="part-2 h-100 feedback-container-v2"
                style={{
                    width: screenWidth > 768 ? '30%' : '100%',
                    top: screenWidth < 768 && '600px',
                }}
            >
                <Header interviewDetails={feedbackDetails?.interviewId} />
                <Body
                    form={feedbackForm}
                    socket={socket}
                    setForm={setFeedbackForm}
                    interviewDetails={feedbackDetails?.interviewId}
                    setSeconds={setSeconds}
                    seconds={seconds}
                />
            </div>
        </Flex>
    );
};
const LogoImg = styled('img', {
    maxHeight: '$17',
    p: '$2',
    w: 'auto',
    '@md': {
        maxHeight: '$15',
    },
});
export default UpdateFeedbackExitRoom;

import React from 'react';
import _debounce from 'lodash/debounce';
import SkillInput from './SkillInput';
const Skills = ({
    title,
    MasterSkills,
    skillLists,
    type,
    handleChangeSkill,
    handleAdd,
    handleRatingComment,
    handleCommentBoxVisible,
    role,
    setDeleteObject,
}) => {
    let details = skillLists.filter((itm) => itm.skillType === type);
    return (
        <div className="feedback-page h-100">
            <div className="fp-title f-sem-bol">{title}</div>
            <div className="fp-content-container">
                {skillLists &&
                    skillLists.length > 0 &&
                    skillLists
                        .filter((itm) => itm.skillType === type)
                        .map((item, i) => (
                            <SkillInput
                                list={skillLists}
                                data={item}
                                MasterSkills={MasterSkills}
                                setDeleteObject={setDeleteObject}
                                handleChangeSkill={handleChangeSkill}
                                handleRatingComment={handleRatingComment}
                                handleCommentBoxVisible={handleCommentBoxVisible}
                                key={i}
                                type={type}
                                handleAdd={handleAdd}
                                len={i === details.length - 1 ? true : false}
                                role={role}
                            />
                        ))}
                {skillLists && details.length === 0 && (
                    <div
                        className="d-flex text-right"
                        onClick={_debounce(() => handleAdd(skillLists, 'skills', type), 300)}
                    >
                        <span className="f-12 f-sem-bol add-text-option">+ Add Skills</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Skills;

import React from 'react';
import vectortl from '../../assets/images/Vectortl.svg';
import vectortr from '../../assets/images/Vectortr.svg';
import vectorb from '../../assets/images/Vectorb.svg';
import { getSkill } from './Helpers';
export const OverallSkill = ({ data }) => {
    return (
        <div className="overAll-skills">
            <div className="overAll-skills-header">
                <span className="overAll-skills-span font-style-header">Overall skills</span>
            </div>
            <div className="toplayer-ellip">
                <div>
                    <div>
                        <img
                            className="toplayer-ellip-img"
                            src={getSkill(Math.round(data?.skillOverAll[0]?.total / data?.skillOverAll[0]?.length))}
                            alt="ellipse"
                        />
                        <div
                            className="toplayer-ellip-title font-style-percent"
                            style={{ margin: '0px', width: '100%' }}
                        >
                            {data?.skillOverAll[0]?.name}
                        </div>
                    </div>
                </div>
                <div className="vector-wrapper">
                    <div className="vector-tl-div">
                        <img className="vector-tl" src={vectortl} alt="vectortl"></img>
                    </div>
                    <div className="vector-tl-div">
                        <img className="vector-tl" src={vectortr} alt="vectortr"></img>
                    </div>
                </div>
            </div>
            <div className="secondlayer-ellip">
                <div className="ellip-div">
                    <img
                        className="toplayer-ellip-img"
                        src={getSkill(Math.round(data?.skillOverAll[1]?.total / data?.skillOverAll[1]?.length))}
                        alt="ellipse"
                    />
                    <div className="toplayer-ellip-title font-style-percent" style={{ margin: '0px' }}>
                        {data?.skillOverAll[1]?.name}
                    </div>
                </div>
                <div className="ellip-div vector-center-div">
                    <img className="vector-center" src={vectorb} alt="vectorb"></img>
                </div>
                <div className="ellip-div">
                    <img
                        className="toplayer-ellip-img"
                        src={getSkill(Math.round(data?.skillOverAll[2]?.total / data?.skillOverAll[2]?.length))}
                        alt="ellipse"
                    />
                    <p className="toplayer-ellip-title font-style-percent" style={{ margin: '0px' }}>
                        {data?.skillOverAll[2]?.name}
                    </p>
                </div>
            </div>
        </div>
    );
};

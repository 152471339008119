import React, { Component } from 'react';
import ErrorPage from './PageComponent/ErrorPage';

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null, isErrorCopied: false };
    }

    componentDidCatch(error, errorInfo) {
        console.error(`react error boundary - ${error.message}`, error, errorInfo);
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error?.message,
            errorInfo: errorInfo,
        });
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <ErrorPage title={JSON.stringify(this.state.error)} message={JSON.stringify(this.state.errorInfo)} />
            );
        }

        return this.props.children;
    }
}

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import _debounce from 'lodash/debounce';
import aiEdit from '../../../assets/images/ai-edit.svg';
import undo from '../../../assets/images/undo_icon.svg';
import file from '../../../images/img-upload.svg';
import { fetchScreenShotImagev2, postFeedbackDetails, postNoShowFeedbackDetails } from '../../../redux/action';
import { getStatusList } from '../../OtherComponents/Helpers';
import { ToastManager } from '../../Toast/ToastManager';
import ToggleSwitch from './ToggleSwitch';
const FinalRemarksV2 = ({
    form,
    handleRemarks,
    userType,
    setForm,
    roomId,
    role,
    interviewDetails,
    submitDisability,
    setSubmitDisability,
}) => {
    const dispatch = useDispatch();
    const summaryUrls = form?.interviewSummary;
    const [textData, setTextData] = useState(null);
    const [prevText, setPrevText] = useState(form?.finalRemark);
    const [isAIgenerated, setIsAigenerated] = useState(false);
    useEffect(() => {
        fetch(summaryUrls)
            .then((response) => response.text())
            .then((data) => {
                try {
                    const jsonData = JSON.parse(data);
                    setTextData(jsonData);
                } catch (parseError) {
                    console.error(`Error parsing JSON data from ${summaryUrls}:`, parseError);
                }
            })
            .catch((error) => {
                console.error(`Error fetching data from ${summaryUrls}:`, error);
            });
    }, [summaryUrls]);
    const finalRemarksText =
        textData &&
        textData?.sections
            ?.filter((section) => section?.paragraph !== '')
            .map((section, index) => (
                <code
                    key={index}
                    dangerouslySetInnerHTML={{
                        __html: `${section?.paragraph?.replace(/\*\*/g, '')}`,
                    }}
                />
            ));
    const handleFetchSummary = _debounce(() => {
        if (finalRemarksText && !isAIgenerated) {
            setPrevText(form?.finalRemark);
            handleRemarks('finalRemark', finalRemarksText?.[0]?.props?.dangerouslySetInnerHTML.__html ?? '');
            ToastManager.addToast({
                title: 'The summary has been generated successfully.',
            });
            setIsAigenerated(true);
        } else if (finalRemarksText && isAIgenerated) {
            ToastManager.addToast({
                title: 'The summary has already been generated.',
            });
        } else {
            ToastManager.addToast({
                title: 'Summary will be available after 30 minutes of interview completion.',
            });
        }
    }, 300);
    const handleUndo = () => {
        handleRemarks('finalRemark', prevText ?? '');
        setIsAigenerated(false);
        ToastManager.addToast({
            title: 'Remarks restored',
        });
    };
    const [showCodeImages, isShowCodeImages] = useState(false);
    const fetchImageAndUpload = (e) => {
        if (e.target.files[0].type.split('/')[0] === 'image') {
            if (e.target.files[0]) {
                dispatch(
                    fetchScreenShotImagev2({
                        image: e.target.files[0],
                        callback: (image) => {
                            let tmpScreenShotList = form?.codeImages ? form?.codeImages : [];
                            if (tmpScreenShotList.length > 0) {
                                if (!tmpScreenShotList.includes(image)) {
                                    tmpScreenShotList.push(image);
                                }
                            } else {
                                tmpScreenShotList.push(image);
                            }
                            setForm((prevState) => ({
                                ...prevState,
                                codeImages: tmpScreenShotList,
                                codeUpdated: true,
                            }));
                        },
                    })
                );
            }
        } else {
            ToastManager.addToast({
                title: 'Uploaded file is not a valid image. Only JPG, JPEG, PNG and GIF files are allowed.',
            });
        }
    };
    const deleteImages = (url) => {
        let tmpScreenShotList = form?.codeImages ? form?.codeImages : [];
        const index = tmpScreenShotList.indexOf(url);
        if (index > -1) {
            tmpScreenShotList.splice(index, 1);
        }
        setForm((prevState) => ({
            ...prevState,
            codeImages: tmpScreenShotList,
            codeUpdated: true,
        }));
    };
    const handleSubmit = _debounce(() => {
        let error = false;
        let message = '';
        if (form?.status === 'noshow') {
            dispatch(postNoShowFeedbackDetails({ interviewId: form.interviewId }));
        } else {
            if (form?.screeningQuestions && form?.screeningQuestions.length > 0) {
                form?.screeningQuestions.forEach((qs, index) => {
                    if (error) {
                        return;
                    }
                    if (qs?.commentRequired && qs?.comment === '') {
                        message = `Screening Question Number ${index + 1} Is Required.`;
                        error = true;
                    } else if (qs?.commentRequired && qs?.comment.length < qs?.commentLength?.min) {
                        message = `Screening Question ${index + 1} Comments Requires A Minimum Of ${
                            qs?.commentLength?.min
                        } Characters`;
                        error = true;
                    }
                });
            }
            if (form.skills && form.skills.length > 0) {
                form.skills
                    .filter((itm) => itm.skillType === 'mandatory')
                    .map((item) => {
                        if (item.rating === '') {
                            message = item?.skill + " rating can't be empty.";
                            error = true;
                        } else if (item?.commentRequired && item?.comment === '') {
                            message = item?.skill + ' comment cannot be empty';
                            error = true;
                        } else if (
                            item?.comment &&
                            item?.commentRequired &&
                            item?.comment.length < item?.commentLength?.min
                        ) {
                            message = `${item?.skill} should have at least ${item?.commentLength?.min} characters`;
                            error = true;
                        } else if (
                            item?.comment &&
                            item?.commentRequired &&
                            item?.comment.length > item?.commentLength?.max
                        ) {
                            message = `${item?.skill} should not exceed more than ${item?.commentLength?.max} characters`;
                            error = true;
                        }
                    });
                form.skills
                    .filter((itm) => itm.skillType === 'optional')
                    .map((item) => {
                        if (item?.commentRequired && item?.comment === '') {
                            message = item?.skill + ' comment cannot be empty';
                            error = true;
                        } else if (item.comment && item.rating === '') {
                            message = item?.skill + " rating can't be empty.";
                            error = true;
                        } else if (
                            item.comment &&
                            item?.commentRequired &&
                            item.comment.length < item.commentLength?.min
                        ) {
                            message = `${item?.skill} should have at least ${item.commentLength?.min} characters`;
                            error = true;
                        } else if (
                            item.comment &&
                            item?.commentRequired &&
                            item.comment.length > item.commentLength?.max
                        ) {
                            message = `${item?.skill} should not exceed more than ${item.commentLength?.max} characters`;
                            error = true;
                        }
                    });
                if (interviewDetails?.ratingType === 1) {
                    form.skills
                        .filter(
                            (itm) =>
                                itm.skillType !== 'mandatory' &&
                                itm.skillType !== 'optional' &&
                                itm.skillType !== 'leadership'
                        )
                        .map((item) => {
                            if (item.rating === '') {
                                message = item?.skill + " rating can't be empty.";
                                error = true;
                            }
                        });
                } else {
                    form.skills
                        .filter((itm) => itm.skillType !== 'optional')
                        .map((item) => {
                            if (item.rating === '') {
                                message = item?.skill + " rating can't be empty.";
                                error = true;
                            }
                        });
                }
            }
            if (error && message !== '') {
                ToastManager.addToast({
                    title: message,
                });
            } else if (
                interviewDetails?.finalRemarks?.commentRequired &&
                (form?.finalRemark === '' || !form?.finalRemark)
            ) {
                ToastManager.addToast({
                    title: "Final remarks can't be empty.",
                });
            } else if (
                interviewDetails?.finalRemarks?.commentRequired &&
                form?.finalRemark.length < interviewDetails?.finalRemarks?.commentLength.min
            ) {
                ToastManager.addToast({
                    title: `Final remarks can't be less than ${interviewDetails?.finalRemarks?.commentLength.min} characters`,
                });
            } else if (form.finalRating === '') {
                ToastManager.addToast({
                    title: "Overall rating can't be empty.",
                });
            } else if (form.status === 'pending' || form.status === '') {
                ToastManager.addToast({
                    title: 'Please select feedback status to continue',
                });
            } else {
                dispatch(
                    postFeedbackDetails({
                        type: 1,
                        interviewId: form.interviewId,
                        data: {
                            ...form,
                            code: [form?.codeEditor],
                            roomId: roomId,
                            type: 'finalsave',
                            isUpdated: true,
                        },
                        callback: () => {
                            setSubmitDisability(true);
                        },
                    })
                );
            }
        }
    }, 300);
    const styles = {
        option: (provided) => ({
            ...provided,
            color: '#2e2e41',
        }),
    };
    const ratingList = [
        { label: 0, value: 0 },
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
        { label: 6, value: 6 },
        { label: 7, value: 7 },
        { label: 8, value: 8 },
        { label: 9, value: 9 },
        { label: 10, value: 10 },
    ];
    const hiddenFileInput = useRef(null);
    const statusList = getStatusList(interviewDetails?.ratingType, role);
    return (
        <div className="final-remarks-page h-100">
            <div className="fp-content-container">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '0.5rem',
                    }}
                >
                    <div className="f-14 f-sem-bol text-theme-white text-left">Final Remarks</div>
                    <div>
                        <button
                            className={`common-btn ai_btn ${isAIgenerated && 'disabled'}`}
                            onClick={handleFetchSummary}
                        >
                            <img src={aiEdit} alt="edit" className="me-1" />
                            AI summary
                        </button>
                    </div>
                </div>
                <textarea
                    name="finalRemark"
                    className="w-100 final-remarks f-reg"
                    placeholder="Add Comments..."
                    value={form?.finalRemark ? form?.finalRemark : ''}
                    onChange={(e) => handleRemarks('finalRemark', e.target.value)}
                    disabled={false}
                    onPaste={(e) => e.stopPropagation()}
                    onCut={(e) => e.stopPropagation()}
                    onCopy={(e) => e.stopPropagation()}
                ></textarea>
                <div
                    className="f-11 text-limit"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '0.5rem',
                    }}
                >
                    {interviewDetails?.finalRemarks?.commentRequired ? (
                        interviewDetails?.finalRemarks?.commentLength.min && form?.finalRemark ? (
                            form?.finalRemark.length < 175 && (
                                <span className="f-bol text-theme-red text-right" style={{ float: 'left' }}>
                                    *Min. {interviewDetails?.finalRemarks?.commentLength.min} char.
                                </span>
                            )
                        ) : (
                            <span className="f-bol text-theme-red text-right" style={{ float: 'left' }}>
                                *Min. {interviewDetails?.finalRemarks?.commentLength.min} char.
                            </span>
                        )
                    ) : (
                        ''
                    )}
                    <button
                        className={`common-btn ai_btn ${!isAIgenerated && 'disabled'}`}
                        onClick={handleUndo}
                        disabled={!isAIgenerated ? true : false}
                        style={{ marginLeft: 'auto' }}
                    >
                        <img src={undo} alt="edit" className="me-1" />
                        Undo
                    </button>
                </div>
                <div className="f-14 f-sem-bol text-theme-white mt-2 text-left code-title">
                    Paste Code (if any)
                    {form?.codeImages?.length > 0 && (
                        <>
                            <br />
                            {form?.codeImages?.length +
                                (form?.codeImages?.length > 1 ? ' Images' : ' Image') +
                                ' Uploaded'}
                        </>
                    )}
                </div>
                <div className="upload-img-div">
                    <input
                        id="fileInput"
                        type="file"
                        className="upload-img-file"
                        name="image"
                        onChange={fetchImageAndUpload}
                        ref={hiddenFileInput}
                    />
                    <img
                        src={file}
                        alt="file"
                        onClick={() => {
                            hiddenFileInput.current.click();
                        }}
                        className="file h-75 img-fluid"
                    />
                </div>
                {form?.codeImages && form?.codeImages.length > 0 && (
                    <>
                        <div className="d-flex text-left" onClick={() => isShowCodeImages(!showCodeImages)}>
                            <span className="f-12 f-sem-bol add-text-option">
                                {showCodeImages ? 'Hide Images' : 'Show Images'}
                            </span>
                        </div>
                        {showCodeImages &&
                            form?.codeImages.map((itm, keyL) => (
                                <div className="text-left codeImages" key={keyL}>
                                    <img src={itm} alt="file" className="file h-75 img-fluid" />
                                    <span className="deleteImg" onClick={() => deleteImages(itm)}>
                                        x
                                    </span>
                                </div>
                            ))}
                    </>
                )}
                <textarea
                    className="w-100 final-remarks f-14 f-reg mt-2"
                    placeholder="Add Comments..."
                    value={form?.codeEditor || ''}
                    onChange={(e) => handleRemarks('codeEditor', e.target.value)}
                    disabled={userType === 'guest'}
                    onPaste={(e) => e.stopPropagation()}
                    onCut={(e) => e.stopPropagation()}
                    onCopy={(e) => e.stopPropagation()}
                ></textarea>
                <ToggleSwitch
                    form={form}
                    setForm={setForm}
                    roomId={roomId}
                    title="Suspected Proxy"
                    type="suspectedProxy"
                />
                {interviewDetails?.ratingType === 2 && (
                    <ToggleSwitch
                        form={form}
                        setForm={setForm}
                        roomId={roomId}
                        title="Candidate Drop-off"
                        type="candidateDrop"
                    />
                )}
                <div className="overall-height mt-2 w-100 text-end">
                    <span className="f-14 f-sem-bol text-theme-white text-left code-title">Overall Rating</span>
                    <Select
                        name="finalRating"
                        menuPlacement="auto"
                        isSearchable={false}
                        className="f-reg select-container-rating"
                        value={{
                            label: form?.finalRating !== '' ? parseInt(form?.finalRating) : 'Select',
                            value: form?.finalRating !== '' ? parseInt(form?.finalRating) : '',
                        }}
                        onChange={(e) => handleRemarks('finalRating', e.value)}
                        options={ratingList}
                        styles={styles}
                        inputClass="f-l f-reg input-shadow"
                    />
                </div>
                <div className="fr-checkbox-container text-theme-white">
                    {statusList &&
                        statusList.length > 0 &&
                        statusList.map((list, key) => (
                            <label className="checkbox-container f-reg f-12" key={key}>
                                {list?.title}
                                <input
                                    type="checkbox"
                                    onChange={(e) => handleRemarks('status', e.target.checked ? list?.code : '')}
                                    checked={form?.status === list?.code}
                                    disabled={list?.disable}
                                />
                                <span className="checkmark"></span>
                            </label>
                        ))}
                </div>
                <div className="d-center fr-submit">
                    <button
                        className="fr-submit-btn text-theme-white f-sem-bol"
                        onClick={() => handleSubmit()}
                        disabled={submitDisability}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FinalRemarksV2;

import React from 'react';
export const CodeSnippet = ({ data }) => {
    return data !== null ? (
        <div className="code-snippet-wrapper break-stop">
            <div className="skills-div">
                <span className="soft-skills-span">Code Snippet</span>
            </div>
            <div className="code-content">
                <pre className="code-content-pre">
                    <code className="code font-styling-text">{data}</code>
                </pre>
            </div>
        </div>
    ) : (
        ''
    );
};

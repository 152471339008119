// ============================= INTERVIEW =============================

export const GET_CANDIDATE_INTERVIEW_DETAILS = 'GET_CANDIDATE_INTERVIEW_DETAILS';
export const GET_CANDIDATE_INTERVIEW_DETAILS_SUCCESS = 'GET_CANDIDATE_INTERVIEW_DETAILS_SUCCESS';
export const GET_CANDIDATE_INTERVIEW_DETAILS_FAILURE = 'GET_CANDIDATE_INTERVIEW_DETAILS_FAILURE';

export const GET_INTERVIEWER_INTERVIEW_DETAILS = 'GET_INTERVIEWER_INTERVIEW_DETAILS';
export const GET_INTERVIEWER_INTERVIEW_DETAILS_SUCCESS = 'GET_INTERVIEWER_INTERVIEW_DETAILS_SUCCESS';
export const GET_INTERVIEWER_INTERVIEW_DETAILS_FAILURE = 'GET_INTERVIEWER_INTERVIEW_DETAILS_FAILURE';

export const GET_INTERVIEW_DETAILS = 'GET_INTERVIEW_DETAILS';
export const GET_INTERVIEW_DETAILS_SUCCESS = 'GET_INTERVIEW_DETAILS_SUCCESS';
export const GET_INTERVIEW_DETAILS_FAILURE = 'GET_INTERVIEW_DETAILS_FAILURE';

export const GET_INTERVIEW_TOKEN = 'GET_INTERVIEW_TOKEN';
export const GET_INTERVIEW_TOKEN_SUCCESS = 'GET_INTERVIEW_TOKEN_SUCCESS';
export const GET_INTERVIEW_TOKEN_FAILURE = 'GET_INTERVIEW_TOKEN_FAILURE';

export const POST_FEEDBACK_DETAILS = 'POST_FEEDBACK_DETAILS';
export const POST_FEEDBACK_DETAILS_SUCCESS = 'POST_FEEDBACK_DETAILS_SUCCESS';
export const POST_FEEDBACK_DETAILS_FAILURE = 'POST_FEEDBACK_DETAILS_FAILURE';

export const POST_CAR_FEEDBACK_DETAILS = 'POST_CAR_FEEDBACK_DETAILS';
export const POST_CAR_FEEDBACK_DETAILS_SUCCESS = 'POST_CAR_FEEDBACK_DETAILS_SUCCESS';
export const POST_CAR_FEEDBACK_DETAILS_FAILURE = 'POST_CAR_FEEDBACK_DETAILS_FAILURE';

export const POST_NOSHOW_FEEDBACK_DETAILS = 'POST_NOSHOW_FEEDBACK_DETAILS';
export const POST_NOSHOW_FEEDBACK_DETAILS_SUCCESS = 'POST_NOSHOW_FEEDBACK_DETAILS_SUCCESS';
export const POST_NOSHOW_FEEDBACK_DETAILS_FAILURE = 'POST_NOSHOW_FEEDBACK_DETAILS_FAILURE';

export const POST_INTERVIEW_FEEDBACK = 'POST_INTERVIEW_FEEDBACK';
export const POST_INTERVIEW_FEEDBACK_SUCCESS = 'POST_INTERVIEW_FEEDBACK_SUCCESS';
export const POST_INTERVIEW_FEEDBACK_FAILURE = 'POST_INTERVIEW_FEEDBACK_FAILURE';

export const GET_SKILLS = 'GET_SKILLS';
export const GET_SKILLS_SUCCESS = 'GET_SKILLS_SUCCESS';
export const GET_SKILLS_FAILURE = 'GET_SKILLS_FAILURE';

export const NOTIFY_INTERVIEWER = 'NOTIFY_INTERVIEWER';
export const NOTIFY_INTERVIEWER_SUCCESS = 'NOTIFY_INTERVIEWER_SUCCESS';
export const NOTIFY_INTERVIEWER_FAILURE = 'NOTIFY_INTERVIEWER_FAILURE';

export const CONTACT_ADMIN = 'CONTACT_ADMIN';
export const CONTACT_ADMIN_SUCCESS = 'CONTACT_ADMIN_SUCCESS';
export const CONTACT_ADMIN_FAILURE = 'CONTACT_ADMIN_FAILURE';

export const GET_FEEDBACK = 'GET_FEEDBACK';
export const GET_FEEDBACK_SUCCESS = 'GET_FEEDBACK_SUCCESS';
export const GET_FEEDBACK_FAILURE = 'GET_FEEDBACK_FAILURE';

export const VERIFY_INTERVIEW_KEY = 'VERIFY_INTERVIEW_KEY';
export const VERIFY_INTERVIEW_KEY_SUCCESS = 'VERIFY_INTERVIEW_KEY_SUCCESS';
export const VERIFY_INTERVIEW_KEY_FAILURE = 'VERIFY_INTERVIEW_KEY_FAILURE';

export const CANDIDATE_CALL_END = 'CANDIDATE_CALL_END';
export const CANDIDATE_CALL_END_SUCCESS = 'CANDIDATE_CALL_END_SUCCESS';
export const CANDIDATE_CALL_END_FAILURE = 'CANDIDATE_CALL_END_FAILURE';

export const INTERVIEWER_CALL_END = 'INTERVIEWER_CALL_END';
export const INTERVIEWER_CALL_END_SUCCESS = 'INTERVIEWER_CALL_END_SUCCESS';
export const INTERVIEWER_CALL_END_FAILURE = 'INTERVIEWER_CALL_END_FAILURE';

export const GET_RECORDING_LIST = 'GET_RECORDING_LIST';
export const GET_RECORDING_LIST_SUCCESS = 'GET_RECORDING_LIST_SUCCESS';
export const GET_RECORDING_LIST_FAILURE = 'GET_RECORDING_LIST_FAILURE';

export const DELETE_RECORDING = 'DELETE_RECORDING';
export const DELETE_RECORDING_SUCCESS = 'DELETE_RECORDING_SUCCESS';
export const DELETE_RECORDING_FAILURE = 'DELETE_RECORDING_FAILURE';

export const QUESTION_BANK = 'QUESTION_BANK';
export const QUESTION_BANK_SUCCESS = 'QUESTION_BANK_SUCCESS';
export const QUESTION_BANK_FAILURE = 'QUESTION_BANK_FAILURE';

export const QUESTION_BANK_LIST = 'QUESTION_BANK_LIST';
export const QUESTION_BANK_LIST_SUCCESS = 'QUESTION_BANK_LIST_SUCCESS';
export const QUESTION_BANK_LIST_FAILURE = 'QUESTION_BANK_LIST_FAILURE';

export const GET_SCREENSHOT = 'GET_SCREENSHOT';
export const GET_SCREENSHOT_SUCCESS = 'GET_SCREENSHOT_SUCCESS';
export const GET_SCREENSHOT_FAILURE = 'GET_SCREENSHOT_FAILURE';

export const GET_ALL_SCREENSHOT = 'GET_ALL_SCREENSHOT';
export const GET_ALL_SCREENSHOT_SUCCESS = 'GET_ALL_SCREENSHOT_SUCCESS';
export const GET_ALL_SCREENSHOT_FAILURE = 'GET_ALL_SCREENSHOT_FAILURE';

export const GET_CROP_IMAGE = 'GET_CROP_IMAGE';
export const GET_CROP_IMAGE_SUCCESS = 'GET_CROP_IMAGE_SUCCESS';
export const GET_CROP_IMAGE_FAILURE = 'GET_CROP_IMAGE_FAILURE';

export const CREATE_AND_UPDATE_SCREENSHOT = 'CREATE_AND_UPDATE_SCREENSHOT';
export const CREATE_AND_UPDATE_SCREENSHOT_SUCCESS = 'CREATE_AND_UPDATE_SCREENSHOT_SUCCESS';
export const CREATE_AND_UPDATE_SCREENSHOT_FAILURE = 'CREATE_AND_UPDATE_SCREENSHOT_FAILURE';

export const REMOVE_SCREENSHOT_IMAGE = 'REMOVE_SCREENSHOT_IMAGE';
export const FETCH_SCREENSHOT_IMAGE = 'FETCH_SCREENSHOT_IMAGE';
export const FETCH_SCREENSHOT_IMAGE_TYPE1_SUCCESS = 'FETCH_SCREENSHOT_IMAGE_TYPE1_SUCCESS';
export const FETCH_SCREENSHOT_IMAGE_TYPE2_SUCCESS = 'FETCH_SCREENSHOT_IMAGE_TYPE2_SUCCESS';
export const FETCH_SCREENSHOT_IMAGE_TYPE3_SUCCESS = 'FETCH_SCREENSHOT_IMAGE_TYPE3_SUCCESS';
export const FETCH_SCREENSHOT_IMAGE_TYPE4_SUCCESS = 'FETCH_SCREENSHOT_IMAGE_TYPE4_SUCCESS';
export const FETCH_SCREENSHOT_IMAGE_TYPE5_SUCCESS = 'FETCH_SCREENSHOT_IMAGE_TYPE5_SUCCESS';
export const FETCH_SCREENSHOT_IMAGE_FAILURE = 'FETCH_SCREENSHOT_IMAGE_FAILURE';
export const FETCH_SCREENSHOT_IMAGEV2 = 'FETCH_SCREENSHOT_IMAGEV2';
export const FETCH_SCREENSHOT_IMAGEV2_SUCCESS = 'FETCH_SCREENSHOT_IMAGEV2_SUCCESS';
export const FETCH_SCREENSHOT_IMAGEV2_FAILURE = 'FETCH_SCREENSHOT_IMAGEV2_FAILURE';
export const FETCH_SCREENSHOT_IMAGEV3 = 'FETCH_SCREENSHOT_IMAGEV3';
export const FETCH_SCREENSHOT_IMAGEV3_SUCCESS = 'FETCH_SCREENSHOT_IMAGEV3_SUCCESS';
export const FETCH_SCREENSHOT_IMAGEV3_FAILURE = 'FETCH_SCREENSHOT_IMAGEV3_FAILURE';

// ============================= Code Editor  =============================
export const CODE_EDITOR_LANGUAGE = 'CODE_EDITOR_LANGUAGE';
export const CODE_EDITOR_LANGUAGE_SUCCESS = 'CODE_EDITOR_LANGUAGE_SUCCESS';
export const CODE_EDITOR_LANGUAGE_FAILURE = 'CODE_EDITOR_LANGUAGE_FAILURE';

export const SINGLE_CODE_EDITOR_LANGUAGE = 'SINGLE_CODE_EDITOR_LANGUAGE';
export const SINGLE_CODE_EDITOR_LANGUAGE_SUCCESS = 'SINGLE_CODE_EDITOR_LANGUAGE_SUCCESS';
export const SINGLE_CODE_EDITOR_LANGUAGE_FAILURE = 'SINGLE_CODE_EDITOR_LANGUAGE_FAILURE';

export const GET_CODE_SUBMISSION = 'GET_CODE_SUBMISSION';
export const GET_CODE_SUBMISSION_SUCCESS = 'GET_CODE_SUBMISSION_SUCCESS';
export const GET_CODE_SUBMISSION_FAILURE = 'GET_CODE_SUBMISSION_FAILURE';

export const CREATE_CODE_SUBMISSION = 'CREATE_CODE_SUBMISSION';
export const CREATE_CODE_SUBMISSION_SUCCESS = 'CREATE_CODE_SUBMISSION_SUCCESS';
export const CREATE_CODE_SUBMISSION_FAILURE = 'CREATE_CODE_SUBMISSION_FAILURE';

// ============================= Global States =============================
export const SET_SHOW_FEEDBACK_PORTAL = 'SET_SHOW_FEEDBACK_PORTAL';

// ============================= ZOOM SDK =============================
export const START_MEETING = 'START_MEETING';
export const START_MEETING_SUCCESS = 'START_MEETING_SUCCESS';
export const START_MEETING_FAILURE = 'START_MEETING_FAILURE';

// ============================= EXIT =============================
export const GET_EXIT_FEEDBACK = 'GET_EXIT_FEEDBACK';
export const GET_EXIT_FEEDBACK_SUCCESS = 'GET_EXIT_FEEDBACK_SUCCESS';
export const GET_EXIT_FEEDBACK_FAILURE = 'GET_EXIT_FEEDBACK_FAILURE';

export const GET_EXIT_INTERVIEW = 'GET_EXIT_INTERVIEW';
export const GET_EXIT_INTERVIEW_SUCCESS = 'GET_EXIT_INTERVIEW_SUCCESS';
export const GET_EXIT_INTERVIEW_FAILURE = 'GET_EXIT_INTERVIEW_FAILURE';

export const POST_EXIT_FEEDBACK = 'POST_EXIT_FEEDBACK';
export const POST_EXIT_FEEDBACK_SUCCESS = 'POST_EXIT_FEEDBACK_SUCCESS';
export const POST_EXIT_FEEDBACK_FAILURE = 'POST_EXIT_FEEDBACK_FAILURE';

export const POST_EXIT_NOSHOW_FEEDBACK = 'POST_EXIT_NOSHOW_FEEDBACK';
export const POST_EXIT_NOSHOW_FEEDBACK_SUCCESS = 'POST_EXIT_NOSHOW_FEEDBACK_SUCCESS';
export const POST_EXIT_NOSHOW_FEEDBACK_FAILURE = 'POST_EXIT_NOSHOW_FEEDBACK_FAILURE';

export const GET_EXIT_TOKEN = 'GET_EXIT_TOKEN';
export const GET_EXIT_TOKEN_SUCCESS = 'GET_EXIT_TOKEN_SUCCESS';
export const GET_EXIT_TOKEN_FAILURE = 'GET_EXIT_TOKEN_FAILURE';

export const GET_EXIT_RECORDING = 'GET_EXIT_RECORDING';
export const GET_EXIT_RECORDING_SUCCESS = 'GET_EXIT_RECORDING_SUCCESS';
export const GET_EXIT_RECORDING_FAILURE = 'GET_EXIT_RECORDING_FAILURE';

export const SWITCH_TAB = 'SWITCH_TAB';
export const SWITCH_TAB_SUCCESS = 'SWITCH_TAB_SUCCESS';
export const SWITCH_TAB_FAILURE = 'SWITCH_TAB_FAILURE';

export const MOVEMENT = 'MOVEMENT';
export const MOVEMENT_SUCCESS = 'MOVEMENT_SUCCESS';
export const MOVEMENT_FAILURE = 'MOVEMENT_FAILURE';

export const ROOM_UPDATE = 'ROOM_UPDATE';
export const ROOM_UPDATE_SUCCESS = 'ROOM_UPDATE_SUCCESS';
export const ROOM_UPDATE_FAILURE = 'ROOM_UPDATE_FAILURE';

export const ADD_SCREENSHOT = 'ADD_SCREENSHOT';
export const ADD_SCREENSHOT_SUCCESS = 'ADD_SCREENSHOT_SUCCESS';
export const ADD_SCREENSHOT_FAILURE = 'ADD_SCREENSHOT_FAILURE';

// URL GENERATED ID
export const GET_URL_GENERATED_ID = 'GET_URL_GENERATED_ID';
export const GET_URL_GENERATED_ID_SUCCESS = 'GET_URL_GENERATED_ID_SUCCESS';
export const GET_URL_GENERATED_ID_FAILURE = 'GET_URL_GENERATED_ID_FAILURE';

// Regenerate AI summary
export const GET_REGENERATED_AI_SUMMARY = 'GET_REGENERATED_AI_SUMMARY';
export const GET_REGENERATED_AI_SUMMARY_SUCCESS = 'GET_REGENERATED_AI_SUMMARY_SUCCESS';
export const GET_REGENERATED_AI_SUMMARY_FAILURE = 'GET_REGENERATED_AI_SUMMARY_FAILURE';

export const FETCH_TRANSCRIPT = 'FETCH_TRANSCRIPT';
export const FETCH_TRANSCRIPT_SUCCESS = 'FETCH_TRANSCRIPT_SUCCESS';
export const FETCH_TRANSCRIPT_FAILURE = 'FETCH_TRANSCRIPT_FAILURE';

// Video Upload
export const VIDEO_UPLOAD = 'VIDEO_UPLOAD';
export const VIDEO_UPLOAD_SUCCESS = 'VIDEO_UPLOAD_SUCCESS';
export const VIDEO_UPLOAD_FAILURE = 'VIDEO_UPLOAD_FAILURE';

export const GET_MEET_INFO = 'GET_MEET_INFO';
export const GET_MEET_INFO_SUCCESS = 'GET_MEET_INFO_SUCCESS';
export const GET_MEET_INFO_FAILURE = 'GET_MEET_INFO_FAILURE';

export const GET_REGENERATED_SUMMARY = 'GET_REGENERATED_SUMMARY';
export const GET_REGENERATED_SUMMARY_SUCCESS = 'GET_REGENERATED_SUMMARY_SUCCESS';
export const GET_REGENERATED_SUMMARY_FAILURE = 'GET_REGENERATED_SUMMARY_FAILURE';

export const GET_TRANSCRIPT = 'GET_TRANSCRIPT';
export const GET_TRANSCRIPT_SUCCESS = 'GET_TRANSCRIPT_SUCCESS';
export const GET_TRANSCRIPT_FAILURE = 'GET_TRANSCRIPT_FAILURE';

/* eslint-disable no-unused-vars */
import React from 'react';
import { Flex, Text } from '@100mslive/roomkit-react';
import TranscriptText from './TranscriptText';
const Transcript = ({ urls, status, fromMain }) => {
    return status ? (
        <>
            <Text
                variant="body1"
                css={{
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: '#FFFFFF',
                    textAlign: 'center',
                    height: '26px',
                    '@md': { fontSize: '12px' },
                }}
            >
                Transcript
            </Text>
            <div
                style={{
                    backgroundColor: '#191924',
                    padding: '10px',
                    marginTop: '10px',
                    overflowY: 'auto',
                    height: `${fromMain ? 'calc(100vh - 170px)' : 'calc(100vh - 120px)'}`,
                }}
            >
                {urls.map((url, index) => (
                    <TranscriptText key={index} url={url} />
                ))}
            </div>
        </>
    ) : (
        <Flex css={{ size: '100%' }} direction="column">
            <Flex direction="column" css={{ height: '94vh' }}>
                <Flex
                    justify="center"
                    direction="column"
                    align="center"
                    className="recordingDiv"
                    css={{ flex: '1 1 0', position: 'relative' }}
                >
                    <Text
                        variant="body1"
                        css={{
                            fontStyle: 'normal',
                            fontWeight: '700',
                            fontSize: '40px',
                            lineHeight: '55px',
                            color: '#9396A0',
                            textAlign: 'center',
                            '@md': { fontSize: '28px' },
                        }}
                    >
                        No Transcript Found
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default Transcript;

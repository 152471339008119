import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { capitalizeAll } from '../utils/helpers';
import '../assets/css/index.css';
import '../assets/fonts/stylesheet.css';
import { getExitFeedback } from '../redux/action';
import { Header, Profile, Ratings, ScreeningQuestions, FinalRemarks, OverallSkills, Footer } from './FeedbackComponent';

const Other = React.lazy(() => import('../components/Pages/Other'));
const FeedbackExitRoom = () => {
    const { roomId, interviewId } = useParams();
    const [profileForm, setProfileForm] = useState({
        image: '',
        intId: '',
        name: '',
        designation: '',
        company: '',
        email: '',
        phone: '',
        dateOfBirth: '',
        joiningDate: '',
        relievingDate: '',
        reJoin: '',
        newEmployer: '',
        industry: '',
        scheduledDate: '',
    });
    const [feedbackForm, setFeedbackForm] = useState({
        ratings: [
            {
                title: 'Work Life Balance',
                value: 0,
            },
            {
                title: 'Transparency in Promotion and Appraisal',
                value: 0,
            },
            {
                title: 'Recommend Friends & Family',
                value: 0,
            },
            {
                title: 'Overall Rating',
                value: 0,
            },
        ],
        screeningQuestions: [],
        finalRemarks: '',
        positiveNotes: { title: 'Positive Notes', values: [] },
        exitReasons: { title: 'Exit Reason', values: [] },
    });
    useEffect(() => {
        if (profileForm?.name) {
            document.title = `${capitalizeAll(profileForm?.name)} Feedback Report`;
        }
    }, [profileForm]);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.ExitInterview.loading);
    const feedbackDetails = useSelector((state) => state.ExitInterview.feedbackDetails);
    useEffect(() => {
        if (roomId && interviewId && !feedbackDetails) {
            dispatch(getExitFeedback({ roomId: roomId, interviewId: interviewId }));
        } else if (roomId && feedbackDetails) {
            if (feedbackDetails?.interviewId?.candidate && feedbackDetails?.interviewId?.feedback) {
                setProfileForm((prevState) => ({
                    ...prevState,
                    image: '',
                    intId: feedbackDetails?.interviewId?.code,
                    name: feedbackDetails?.interviewId?.candidate?.name,
                    designation: feedbackDetails?.interviewId?.designation,
                    company: feedbackDetails?.interviewId?.employer?.employerName ?? 'InCruiter',
                    email: feedbackDetails?.interviewId?.candidate?.email,
                    phone: feedbackDetails?.interviewId?.candidate?.phone,
                    dateOfBirth: feedbackDetails?.interviewId?.candidate?.dateOfBirth,
                    joiningDate: feedbackDetails?.interviewId?.joiningDate,
                    relievingDate: feedbackDetails?.interviewId?.relievingDate,
                    reJoin: feedbackDetails?.interviewId?.feedback?.reJoin,
                    newEmployer: feedbackDetails?.interviewId?.feedback?.newEmployer,
                    industry: feedbackDetails?.interviewId?.feedback?.industry,
                    scheduledDate: feedbackDetails?.interviewId?.scheduledDate,
                }));
                setFeedbackForm({
                    ratings: [
                        {
                            title: 'Work Life Balance',
                            value: parseInt(feedbackDetails?.interviewId?.feedback?.workLifeBalance),
                        },
                        {
                            title: 'Transparency in Promotion and Appraisal',
                            value: parseInt(feedbackDetails?.interviewId?.feedback?.transparency),
                        },
                        {
                            title: 'Recommend Friends & Family',
                            value: parseInt(feedbackDetails?.interviewId?.feedback?.recommendFriendsFamily),
                        },
                        {
                            title: 'Overall Rating',
                            value: parseInt(feedbackDetails?.interviewId?.feedback?.overallRating),
                        },
                    ],
                    screeningQuestions: feedbackDetails?.interviewId?.feedback?.screeningQuestions
                        ? feedbackDetails?.interviewId?.feedback?.screeningQuestions.filter((itm) => itm.comment !== '')
                        : [],
                    finalRemarks: feedbackDetails?.interviewId?.feedback?.finalRemarks,
                    positiveNotes: {
                        title: 'Positive Notes',
                        values: feedbackDetails?.interviewId?.feedback?.positionNotes ?? [],
                    },
                    exitReasons: {
                        title: 'Exit Reason',
                        values: feedbackDetails?.interviewId?.feedback?.exitReasons ?? [],
                    },
                });
            }
        }
    }, [dispatch, roomId, interviewId, feedbackDetails]);
    const [, setWidth] = useState(window.innerWidth);
    window.addEventListener('resize', () => setWidth(window.innerWidth));

    return (
        <div className="App">
            {loading ? (
                <Loader />
            ) : feedbackDetails?.interviewId?.feedback ? (
                <>
                    <Header data={profileForm} />
                    <Profile data={profileForm} />
                    <Ratings data={feedbackForm?.ratings} />
                    {feedbackForm?.screeningQuestions && feedbackForm?.screeningQuestions.length > 0 && (
                        <ScreeningQuestions data={feedbackForm?.screeningQuestions} />
                    )}
                    {feedbackForm?.finalRemarks && <FinalRemarks data={feedbackForm?.finalRemarks} />}
                    <OverallSkills data={feedbackForm} />
                    <Footer data={profileForm} />
                </>
            ) : (
                <Other page="404Page" />
            )}
        </div>
    );
};

const Loader = memo(() => {
    return (
        <div className="wrapper">
            <div className="loader"></div>
        </div>
    );
});

export default FeedbackExitRoom;

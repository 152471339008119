export { CodeSnippet } from './CodeSnippet';
export { FinalRemarks } from './FinalRemarks';
export { Footer } from './Footer';
export { Header } from './Header';
export { OtherSkills } from './OtherSkills';
export { OverallSkills } from './OverallSkills';
export { Profile } from './Profile';
export { ScreeningQuestions } from './ScreeningQuestions';
export { Screenshots } from './Screenshots';
export { Skills } from './Skills';
export { SkillsComment } from './SkillsComment';
export { Space } from './Space';
export { Space2 } from './Space2';
export { CodeSnippetImages } from './CodeSnippetImages';
export { Proctoring } from './Proctoring';

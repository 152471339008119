import React from 'react';
import { useParams } from 'react-router-dom';

function ToggleSwitch({ form, setForm, socket, roomId, type, title }) {
    const { role } = useParams();
    const toggleState = form[type];
    const handleChange = () => {
        const updatedState = !toggleState;
        setForm((prevState) => ({
            ...prevState,
            [type]: updatedState,
            codeUpdated: true,
        }));
        if (socket) {
            const nData = {
                [type]: updatedState,
                roleName: role,
            };
            socket.emit('codeWritten', nData, roomId);
        }
    };

    return (
        <div className="suspected-div">
            <h4 className="f-14 f-sem-bol text-theme-white text-left">{title} :</h4>
            <label className="toggle-switch">
                <input type="checkbox" checked={toggleState} onChange={handleChange} />
                <span className="switch" />
            </label>
        </div>
    );
}

export default ToggleSwitch;
